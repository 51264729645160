import { Icon } from '@mui/material';
import { Button, Typography } from 'components/common';
import { IReservation, ICar } from 'types';
import variables from 'assets/scss/variables.scss';

const Complete = ({
  reservation,
  car,
  onStartOver,
}: {
  reservation: IReservation;
  car: ICar;
  onStartOver: () => void;
}) => {
  return (
    <div>
      <Typography variant="h2">
        Enjoy your ride!
      </Typography>

      <Typography>
        The check in is complete. You need to fill in the following pin on your cars dashboard.
        We recommend taking a snapshot of this screen for future reference.
      </Typography>

      <div className="d-flex flex-column gap-2">
        <div
          className="d-flex gap-4 align-items-center"
          style={{
            backgroundColor: variables.gray4,
            borderRadius: '10px',
            padding: '8px 8px 8px 16px',
          }}
        >
          <Icon>directions_car</Icon>

          <div className="d-flex flex-column">
            <Typography>
              License plate
            </Typography>

            <Typography>
              {car.license_plate}
            </Typography>
          </div>
        </div>

        <div
          className="d-flex gap-4 align-items-center"
          style={{
            backgroundColor: variables.gray4,
            borderRadius: '10px',
            padding: '8px 8px 8px 16px',
          }}
        >
          <Icon>shield</Icon>

          <div className="d-flex flex-column">
            <Typography>
              Activate your car
            </Typography>

            <Typography>
              {`Pin: ${reservation.checkin_pin}`}
            </Typography>
          </div>
        </div>

        <div
          className="d-flex gap-4 align-items-center"
          style={{
            backgroundColor: variables.gray4,
            borderRadius: '10px',
            padding: '8px 8px 8px 16px',
          }}
        >
          <Icon>call</Icon>

          <div className="d-flex flex-column">
            <Typography>
              {`Call ${reservation.agent_phone}`}
            </Typography>

            <Typography>
              Call your agent
            </Typography>
          </div>
        </div>

        <div>
          <Typography>
            Pickup instructions:
          </Typography>

          <Typography>
            {reservation.pickup_location.pickup_instructions}
          </Typography>
        </div>

        <div>
          <Typography style={{ textDecoration: 'underline' }}>
            Reminder:
          </Typography>

          <Typography>
            Don&apos;t forget to capture photos of the car&apos;s current condition before you get going.
          </Typography>

          <Button
            onClick={() => {
              window.open(`/trips/${reservation.id}/photos`, '_blank');
            }}
            className="mt-1"
          >
            Upload Photos
          </Button>
        </div>

        <div className="mb-3 mt-3">
          <Button
            onClick={onStartOver}
            className="m-0 p-0"
            style={{ color: 'blue', boxShadow: 'none' }}
          >
            View tutorial again
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Complete;
