import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { uiActions, uiSelectors } from '../redux/slices';

import variables from '../assets/scss/variables.scss';

const useScrollTop = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [scrollY, setScrollY] = useState(0);
  const [currentPage, setCurrentPage] = useState(pathname);
  const scrollTop = useSelector(state => uiSelectors.selectScrollTop(state, pathname));

  const element = window.innerWidth > Number(variables.sizemd)
    ? (document.querySelector('.app-data') || window)
    : window;

  const handleScroll = () => {
    // @ts-ignore
    setScrollY(element?.scrollTop || element?.scrollY);
  };

  useEffect(() => {
    setCurrentPage(pathname);
    setTimeout(() => element.scrollTo(0, scrollTop), 200);
  }, [scrollTop, pathname, element]);

  useEffect(() => () => {
    if (pathname !== currentPage) {
      setCurrentPage(pathname);
      dispatch(uiActions.setScrollTop.base({ page: currentPage, height: scrollY }));
    }
  }, [pathname, scrollY]);

  useEffect(() => {
    element.addEventListener('scroll', handleScroll);
    return () => element.removeEventListener('scroll', handleScroll);
  }, [element]);
};

export { useScrollTop };
