import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import { useDeepEffect } from '../../../hooks';
import { ICar, IMap, IReservation, IStoreCarsSearch } from '../../../types';

import { checkoutSelectors, uiSelectors } from '../../../redux/slices';

declare const google: any;

interface IProps {
  allMarkers: any;
}

const ReservationMarkers = ({ allMarkers }: IProps) => {
  const map = useSelector(uiSelectors.selectMap) as IMap;
  const { address } = useSelector(uiSelectors.selectCarsSearch) as IStoreCarsSearch;
  const carDetails = useSelector(checkoutSelectors.selectCarDetails) as ICar;
  const reservation = useSelector(checkoutSelectors.selectReservation) as IReservation;

  useDeepEffect(() => {
    if (reservation || carDetails) {
      allMarkers.forEach((marker: any) => {
        marker.setMap(null);
      });
      allMarkers.splice(0, allMarkers.length);
    }

    if (!map) return;

    // not a delivery
    if (reservation && reservation.custom_pickup_location === '') {
      const pickupMarker = new google.maps.Marker({
        position: new google.maps.LatLng(reservation.pickup_location.latitude, reservation.pickup_location.longitude),
        map,
        icon: {
          url: '/images/nav-start-blue.svg',
        },
        title: reservation.pickup_location.address,
      });
      allMarkers.push(pickupMarker);
      map.setCenter({
        lat: reservation.pickup_location.latitude,
        lng: reservation.pickup_location.longitude
      });
      map.setZoom(16);
      if (!isEqual(reservation.pickup_location, reservation.return_location)) {
        const returnMarker = new google.maps.Marker({
          position: new google.maps.LatLng(reservation.return_location.latitude, reservation.return_location.longitude),
          map,
          icon: {
            url: '/images/nav-finish-blue.svg',
          },
          title: reservation.return_location.address,
        });
        allMarkers.push(returnMarker);
      }
    } else if (carDetails && address.deliveryTo && address.collectAt) {
      allMarkers.push(new google.maps.Marker({
        position: new google.maps.LatLng(address.deliveryTo.geo.lat, address.deliveryTo.geo.lng),
        map,
        icon: {
          url: carDetails ? '/images/nav-start-blue.svg' : '/images/nav-start.svg',
        },
        title: address.deliveryTo.address,
      }));
      allMarkers.push(new google.maps.Marker({
        position: new google.maps.LatLng(address.collectAt.geo.lat, address.collectAt.geo.lng),
        map,
        icon: {
          url: carDetails ? '/images/nav-finish-blue.svg' : '/images/nav-finish.svg',
        },
        title: address.collectAt.address,
      }));
      const bounds = new google.maps.LatLngBounds();
      bounds.extend(address.deliveryTo.geo);
      bounds.extend(address.collectAt.geo);

      map.setCenter(bounds.getCenter());
      map.fitBounds(bounds);
      if (address.deliveryTo.address === address.collectAt.address) {
        map.setZoom(16);
      }
    } else if (carDetails) {
      const marker = new google.maps.Marker({
        position: new google.maps.LatLng(carDetails.default_location.latitude, carDetails.default_location.longitude),
        map,
        icon: {
          url: '/images/nav-start-blue.svg',
        },
        title: carDetails.default_location.address,
      });
      map.setCenter({
        lat: carDetails.default_location.latitude,
        lng: carDetails.default_location.longitude
      });
      map.setZoom(16);
      allMarkers.push(marker);
    }
  }, [carDetails, reservation, map]);

  return null;
};

export { ReservationMarkers };
