// @ts-ignore
/* eslint-disable */

import React from 'react';
import cx from 'classnames';

const Warning = ({ className, ...props }: any) => (
  <svg className={cx(className)} xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none" {...props}>
    <g clipPath="url(#clip0_881_27704)">
      <path d="M9.55999 2.5572L2.05637 10.0608C1.81381 10.3034 1.81381 10.6966 2.05637 10.9392L9.55999 18.4428C9.80254 18.6854 10.1958 18.6853 10.4383 18.4428L17.942 10.9392C18.1845 10.6966 18.1845 10.3034 17.942 10.0608L10.4383 2.5572C10.1958 2.31465 9.80254 2.31465 9.55999 2.5572Z" fill="#FDD238" stroke="#76CCCC" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 11.125V6.75" stroke="#1A1A1F" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 14.875C10.5178 14.875 10.9375 14.4553 10.9375 13.9375C10.9375 13.4197 10.5178 13 10 13C9.48223 13 9.0625 13.4197 9.0625 13.9375C9.0625 14.4553 9.48223 14.875 10 14.875Z" fill="#1A1A1F" />
    </g>
    <defs>
      <clipPath id="clip0_881_27704">
        <rect width="20" height="20" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export { Warning };
