import React from 'react';

import { Account } from '../../../assets/icons';

import { Typography } from '../Typography';
import { InputField } from '../InputField';

const Inputs = () => (
  <div>
    <Typography variant="h1">Inputs</Typography>
    <code>
      {'<InputField label="Default" startIcon={<Account />} />'}
      <br />
      Props:
      <ul>
        <li>label: string</li>
        <li>value?: string</li>
        <li>error?: string</li>
        <li>className?: string</li>
        <li>inputClassName?: string</li>
        <li>disabled?: boolean</li>
        <li>startIcon?: ReactElement</li>
        <li>endIcon?: ReactElement</li>
        <li>{'onClick?: (e: any) => void;'}</li>
        <li>{'onChange?: (e: any) => void;'}</li>
        <li>otherProps?: object; - sets to the input tag</li>
      </ul>
    </code>
    <div className="d-flex flex-wrap gap-3 mt-4">
      <InputField label="Default" className="mb-4" startIcon={<Account />} />
      <InputField label="Hover" className="mb-4 hover" inputClassName="hover" startIcon={<Account />} />
      <InputField label="Active" className="mb-4" inputClassName="active" value="Input" startIcon={<Account />} />
      <InputField label="Error" className="mb-4" error="Invalid data" value="Input" startIcon={<Account />} />
      <InputField label="Disabled" className="mb-4" value="Input" startIcon={<Account />} disabled />
      <InputField label="Focus" className="mb-4" inputClassName="focus" value="Input" startIcon={<Account />} />
    </div>
  </div>
);

export { Inputs };
