import React from 'react';
import cx from 'classnames';
import { Link as RouterLink } from 'react-router-dom';

interface IProps {
  children: any;
  className?: string;
  to?: string;
  href?: string;
  onClick?: () => void;
  active?: boolean;
  [k: string]: any;
}

const Link = ({ children, className, to, href, onClick, active, ...props }: IProps) => {
  if (typeof to !== 'undefined') {
    return (
      <RouterLink to={to} className={cx('link', className, { active })} {...props}>
        {children}
      </RouterLink>
    );
  }
  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      className={cx('link', className, { active })}
      onClick={onClick}
      {...props}
    >
      {children}
    </a>
  );
};

Link.defaultProps = {
  className: undefined,
  to: undefined,
  href: undefined,
  onClick: undefined,
  active: undefined,
};

export { Link };
