import { useNavigate } from 'react-router-dom';
import { Icon } from '@mui/material';

import { Button } from 'components/common/Button';

interface Props {
  style?: any;
}

const BackButton = ({
  style,
}: Props) => {
  const navigate = useNavigate();

  return (
    <Button
      onClick={() => navigate(-1)}
      style={{
        margin: 0,
        padding: 0,
        borderRadius: 0,
        boxShadow: 'none',
        width: '12px',
        ...style,
      }}
    >
      <Icon>arrow_back_ios</Icon>
    </Button>
  );
};

export default BackButton;
