import { Card, Typography } from 'components/common';
import { ICar, CarStatusEnum } from 'types';

import variables from 'assets/scss/variables.scss';
import { getBackgroundImageUri } from 'helpers/cars';

interface Props {
  car: ICar;
  onClick?: () => void;
}

const CarCard = ({
  car,
  onClick,
}: Props) => {
  const backgroundImageURI = getBackgroundImageUri(car);
  let backgroundColor = variables.$gray2;
  let statusText = '';

  if (car.status === CarStatusEnum.NotConnected) {
    backgroundColor = variables.red6;
    statusText = 'Tap To Connect';
  } else if (car.status === CarStatusEnum.Approved) {
    backgroundColor = variables.green3;
    statusText = 'Connected';
  } else if (car.status === CarStatusEnum.AwaitingApproval) {
    backgroundColor = variables.blue4;
    statusText = 'Awaiting Approval';
  } else if (car.status === CarStatusEnum.SoftDeleted) {
    backgroundColor = variables.red2;
    statusText = 'Unlisted';
  } else {
    backgroundColor = variables.gray4;
    statusText = 'Missing Information - Click to Add';
  }

  return (
    <Card
      type="button"
      radius="large"
      className="w-100"
      style={{ backgroundColor }}
      onClick={onClick}
    >
      <div className="d-flex justify-content-between">
        <div>
          <Typography variant="h2" className="m-0">
            {car.license_plate}
          </Typography>

          <Typography variant="body3">
            {car.vin}
          </Typography>
        </div>

        <div>
          <Typography variant="h3" className="m-0">
            {car.model?.display_name}
          </Typography>

          <Typography variant="body2" className="d-flex">
            {car.trim?.display_name}
          </Typography>
        </div>
      </div>

      <div className="d-flex w-100 justify-content-center">
        <img
          src={backgroundImageURI}
          alt="car"
          style={{ objectFit: 'contain', height: '150px' }}
        />
      </div>

      <div className="d-flex justify-content-between align-items-baseline gap-1">
        <div>
          <Typography variant="body1" className="d-flex">
            {statusText}
          </Typography>
        </div>

        <div>
          <Typography variant="body2" className="d-flex">
            {car.default_location?.address}
          </Typography>
        </div>
      </div>
    </Card>
  );
};

export default CarCard;
