import React, { ReactElement, useEffect, useRef, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import cx from 'classnames';

import { Typography } from '../Typography';
import { ArrowDown } from '../../../assets/icons';
import variables from '../../../assets/scss/variables.scss';

interface IProps {
  text: string | ReactElement;
  show: boolean;
  onShow: (e: any) => void;
  onClose: (e: any) => void;
  icon?: ReactElement;
  selected?: number;
  className?: string;
  disabled?: boolean;
  children?: any;
  dropdownWidth?: number;
  showDropdownIcon?: boolean;
  style?: any;
  dropdownClassName?: string;
}

const DROPDOWN_WIDTH = 320;

const Filter = ({
  icon,
  text,
  className,
  selected,
  disabled,
  children,
  dropdownWidth,
  showDropdownIcon,
  show,
  onShow,
  onClose,
  style,
  dropdownClassName,
}: IProps) => {
  const ref = useRef<HTMLButtonElement | null>(null);
  const [position, setPosition] = useState<any>({ bottom: '-100vh' });

  const handleClose = (e: any) => {
    onClose(e);
    setPosition({ bottom: '-100vh' });
    window.document.body.style.overflow = 'auto';
    // @ts-ignore
    window.document.querySelector('.app-data')?.classList?.remove('z-index-10');
    window.document.querySelector('.app-data')?.classList?.remove('overflow-y-hidden');
  };

  const calculatePosition = () => {
    if (ref.current) {
      const wWidth = window.innerWidth;
      if (wWidth < Number(variables.sizemd)) {
        setPosition({
          bottom: 0,
          left: 0,
          right: 0,
          minWidth: dropdownWidth
        });
      } else {
        const data = ref.current.getBoundingClientRect();
        setPosition({
          top: data.top + data.height + 10,
          left: data.left + Number(dropdownWidth) > wWidth - 24 ? data.right - Number(dropdownWidth) : data.left,
          isRight: data.left + Number(dropdownWidth) > wWidth - 24,
          minWidth: dropdownWidth
        });
      }
    }
  };

  const handleClick = (e: any) => {
    if (e.target.classList.contains('filter-backdrop')) {
      handleClose(e);
    } else if (!e.currentTarget.classList.contains('show')) {
      onShow(e);
      window.document.body.style.overflow = 'hidden';
      if (window.innerWidth > Number(variables.sizemd)) {
        // @ts-ignore
        window.document.querySelector('.app-data')?.classList?.add('z-index-10');
        window.document.querySelector('.app-data')?.classList?.add('overflow-y-hidden');
      }
      calculatePosition();
    }
  };

  const handleResize = () => {
    if (show) {
      calculatePosition();
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    ref.current?.parentNode?.addEventListener('scroll', handleResize);
    handleResize();
    if (!show) {
      handleClose(null);
    }
    return () => {
      window.removeEventListener('resize', handleResize);
      ref.current?.parentNode?.removeEventListener('scroll', handleResize);
    };
  }, [show, ref]);

  return (
    <ClickAwayListener onClickAway={(e) => show ? handleClose(e) : undefined}>
      <button className={cx('filter', className, { show })} disabled={disabled} onClick={handleClick} ref={ref}>
        <div
          className="w-100 d-flex gap-2 align-items-center"
          onClick={(e) => {
            handleClose(e);
          }}
        >
          {icon && React.cloneElement(icon, { className: 'icon' })}
          {React.isValidElement(text) ? text : (
            <Typography inline variant="body2">{text}</Typography>
          )}
          {Boolean(selected) && (
            <div className="icon icon-selected">{selected}</div>
          )}
          {showDropdownIcon && !selected && (
            <ArrowDown className="icon" />
          )}
        </div>
        <div
          className={cx('filter-dropdown', dropdownClassName, { left: !position.isRight, right: position.isRight })}
          style={{ ...position, ...style }}
        >
          {show && children}
        </div>
        <div className="filter-backdrop" onClick={handleClose} />
      </button>
    </ClickAwayListener>
  );
};

Filter.defaultProps = {
  icon: undefined,
  className: undefined,
  selected: undefined,
  disabled: undefined,
  children: undefined,
  dropdownWidth: DROPDOWN_WIDTH,
  showDropdownIcon: true,
  style: {},
  dropdownClassName: undefined,
};

export { Filter };
