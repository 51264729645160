import moment from 'moment';
import {
  DAY_MILLISECONDS,
} from '../constants';
import { IDates } from '../types';

export const dataURLtoFile = (dataURL: string, filename: string) => {
  const arr = dataURL.split(',') as any;
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export const getConvertedDateWithOffset = (inputDate: any, offset: string) => {
  const newDate = new Date(inputDate);
  const newDateDay = newDate.getDate().toString().padStart(2, '0');
  const newDateMonth = (newDate.getMonth() + 1).toString().padStart(2, '0');
  const newDateYear = newDate.getFullYear();
  const newDateHour = (newDate.getHours()).toString().padStart(2, '0');
  const newDateMinute = (newDate.getMinutes()).toString().padStart(2, '0');
  let newDateString = `${newDateYear}-${newDateMonth}-${newDateDay}`;
  newDateString += `T${newDateHour}:${newDateMinute}:00${offset}`;
  return new Date(newDateString);
};

export const getFormattedDisplayParameterString = (inputDateString: string, optionalOffset?: string) => {
  if (inputDateString.length >= 24) {
    if (inputDateString.substring(inputDateString.length - 1).toLowerCase() !== 'z') {
      inputDateString = inputDateString.substring(0, inputDateString.length - 5);
    }
    const date = new Date(inputDateString);
    const dateDay = date.getDate().toString().padStart(2, '0');
    const dateMonth = (date.getMonth() + 1).toString().padStart(2, '0');
    const dateYear = date.getFullYear();
    const dateHour = (date.getHours()).toString().padStart(2, '0');
    const dateMinute = (date.getMinutes()).toString().padStart(2, '0');
    let dateString = `${dateYear}-${dateMonth}-${dateDay}`;
    dateString += `T${dateHour}:${dateMinute}:00${optionalOffset || ''}`;
    return dateString;
  }
  return inputDateString;
};

export const getFormattedDateStringFromInput = (inputDate: Date, optionalOffset?: string) => {
  inputDate = new Date(inputDate);
  const dateDay = inputDate.getDate().toString().padStart(2, '0');
  const dateMonth = (inputDate.getMonth() + 1).toString().padStart(2, '0');
  const dateYear = inputDate.getFullYear();
  const dateHour = (inputDate.getHours()).toString().padStart(2, '0');
  const dateMinute = (inputDate.getMinutes()).toString().padStart(2, '0');
  let dateString = `${dateYear}-${dateMonth}-${dateDay}`;
  dateString += `T${dateHour}:${dateMinute}:00${optionalOffset || ''}`;
  return dateString;
};

export const getNumDays = (dates: IDates): number => {
  const numMilliSeconds = moment(dates.endDate).diff(moment(dates.startDate));
  const days = numMilliSeconds / DAY_MILLISECONDS;

  if (days - Math.round(days) >= 0.05) {
    return Math.round(days) + 1;
  }

  return Math.round(numMilliSeconds / DAY_MILLISECONDS);
};

export const toSnakeCase = (str: string): string => {
  return str
    .split(' ')
    .map((word) => word.toLowerCase())
    .join('_');
};

export const capitalizeFirstChar = (str: string): string => {
  if (!str) return '';

  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const snakeToTitleCase = (str: string): string => {
  return str
    ?.split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};
