// @ts-ignore
/* eslint-disable */

import React from 'react';
import cx from 'classnames';

const NavStart = ({ className, ...props }: any) => (
  <svg className={cx('svg-icon', className)} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g>
      <path d="M5.5 20.75V4.5H13.6L14 6.5H19.5V15.5H13.4L13 13.5H7V20.75H5.5ZM14.65 14H18V8H12.75L12.35 6H7V12H14.25L14.65 14Z" />
    </g>
  </svg>
);

export { NavStart };
