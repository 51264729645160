// @ts-ignore
/* eslint-disable */

import React from 'react';
import cx from 'classnames';

const Mileage = ({ className, ...props }: any) => (
  <svg className={cx('svg-icon', className)} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g>
      <path d="M12 21.675C9.4 19.4417 7.45833 17.375 6.175 15.475C4.89167 13.575 4.25 11.8167 4.25 10.2C4.25 7.78333 5.02933 5.854 6.588 4.412C8.146 2.97067 9.95 2.25 12 2.25C14.15 2.25 16.004 3.01233 17.562 4.537C19.1207 6.06233 19.85 8.15 19.75 10.8L22.1 8.45L23.15 9.5L19 13.65L14.85 9.5L15.9 8.45L18.25 10.8C18.35 8.68333 17.796 6.97933 16.588 5.688C15.3793 4.396 13.85 3.75 12 3.75C10.25 3.75 8.77067 4.35 7.562 5.55C6.354 6.75 5.75 8.3 5.75 10.2C5.75 11.4167 6.26267 12.8167 7.288 14.4C8.31267 15.9833 9.88333 17.7417 12 19.675C12.3333 19.375 12.6833 19.046 13.05 18.688C13.4167 18.3293 13.7417 18.0083 14.025 17.725C13.9083 17.5417 13.825 17.3457 13.775 17.137C13.725 16.929 13.7 16.7167 13.7 16.5C13.7 15.85 13.921 15.3043 14.363 14.863C14.8043 14.421 15.35 14.2 16 14.2C16.65 14.2 17.196 14.421 17.638 14.863C18.0793 15.3043 18.3 15.85 18.3 16.5C18.3 17.15 18.0793 17.696 17.638 18.138C17.196 18.5793 16.65 18.8 16 18.8C15.85 18.8 15.7083 18.7917 15.575 18.775C15.4417 18.7583 15.3083 18.7167 15.175 18.65C14.7083 19.15 14.2127 19.65 13.688 20.15C13.1627 20.65 12.6 21.1583 12 21.675Z" />
    </g>
  </svg>
);

export { Mileage };
