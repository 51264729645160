import { createSlice } from 'helpers/store';
import { IPartner } from 'types';

export interface IPartnerState {
  isLoading: boolean;
  data: IPartner | null;
}

export interface IState {
  partner: IPartnerState;
}

const initialState: IState = {
  partner: {
    isLoading: false,
    data: null,
  },
};

const { actions, selectors, reducer } = createSlice({
  prefix: 'partner',
  initialState,

  selectors: {
    selectPartner: (state: IState) => state.partner,
  },

  reducers: {
    loadPartner: {
      base: (state: IState) => {
        state.partner.isLoading = true;
      },
      success: (state: IState, { payload: { partner } }: any) => {
        state.partner = {
          data: partner,
          isLoading: false,
        };
      },
      failed: (state: IState) => {
        state.partner.isLoading = false;
      }
    },
  },
});

export { reducer as partner, actions as partnerActions, selectors as partnerSelectors };
