// @ts-ignore
/* eslint-disable */

import React from 'react';
import cx from 'classnames';

const Savings = ({ className, ...props }: any) => (
  <svg className={cx('svg-icon', className)} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g>
      <path d="M16 10.875C16.25 10.875 16.4583 10.7917 16.625 10.625C16.7917 10.4583 16.875 10.25 16.875 10C16.875 9.75 16.7917 9.54167 16.625 9.375C16.4583 9.20833 16.25 9.125 16 9.125C15.75 9.125 15.5417 9.20833 15.375 9.375C15.2083 9.54167 15.125 9.75 15.125 10C15.125 10.25 15.2083 10.4583 15.375 10.625C15.5417 10.7917 15.75 10.875 16 10.875ZM8 8.75H13V7.25H8V8.75ZM4.875 20.5C4.375 18.8167 3.975 17.4 3.675 16.25C3.375 15.1 3.13767 14.1167 2.963 13.3C2.78767 12.4833 2.66667 11.7873 2.6 11.212C2.53333 10.6373 2.5 10.0667 2.5 9.5C2.5 8.1 2.98333 6.91667 3.95 5.95C4.91667 4.98333 6.1 4.5 7.5 4.5H12.75C13.2 3.9 13.75 3.41667 14.4 3.05C15.05 2.68333 15.75 2.5 16.5 2.5C16.7833 2.5 17.0207 2.59567 17.212 2.787C17.404 2.979 17.5 3.21667 17.5 3.5C17.5 3.56667 17.4917 3.63333 17.475 3.7C17.4583 3.76667 17.4417 3.83333 17.425 3.9C17.3417 4.08333 17.2667 4.29167 17.2 4.525C17.1333 4.75833 17.0667 5.05 17 5.4L19.625 8H21.5V14.1L18.775 15L17.125 20.5H12.5V18.5H9.5V20.5H4.875ZM6 19H8V17H14V19H16L17.55 13.85L20 13.025V9.5H19L15.5 6C15.5 5.68333 15.5207 5.36233 15.562 5.037C15.604 4.71233 15.675 4.4 15.775 4.1C15.3083 4.23333 14.8877 4.47067 14.513 4.812C14.1377 5.154 13.8583 5.55 13.675 6H7.5C6.53333 6 5.70833 6.34167 5.025 7.025C4.34167 7.70833 4 8.53333 4 9.5C4 10.1833 4.175 11.354 4.525 13.012C4.875 14.6707 5.36667 16.6667 6 19Z" />
    </g>
  </svg>
);

export { Savings };
