// @ts-ignore
/* eslint-disable */

import React from 'react';
import cx from 'classnames';

const EyeVisible = ({ className, ...props }: any) => (
  <svg className={cx('svg-icon', className)} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g>
      <path d="M11.9998 15.575C13.1331 15.575 14.0955 15.179 14.8868 14.387C15.6788 13.5957 16.0748 12.6333 16.0748 11.5C16.0748 10.3667 15.6788 9.40433 14.8868 8.613C14.0955 7.821 13.1331 7.425 11.9998 7.425C10.8665 7.425 9.90414 7.821 9.1128 8.613C8.3208 9.40433 7.9248 10.3667 7.9248 11.5C7.9248 12.6333 8.3208 13.5957 9.1128 14.387C9.90414 15.179 10.8665 15.575 11.9998 15.575ZM11.9998 14.2C11.2498 14.2 10.6125 13.9373 10.0878 13.412C9.56247 12.8873 9.2998 12.25 9.2998 11.5C9.2998 10.75 9.56247 10.1123 10.0878 9.587C10.6125 9.06233 11.2498 8.8 11.9998 8.8C12.7498 8.8 13.3875 9.06233 13.9128 9.587C14.4375 10.1123 14.6998 10.75 14.6998 11.5C14.6998 12.25 14.4375 12.8873 13.9128 13.412C13.3875 13.9373 12.7498 14.2 11.9998 14.2ZM11.9998 18.5C9.6998 18.5 7.60414 17.8667 5.7128 16.6C3.8208 15.3333 2.43314 13.6333 1.5498 11.5C2.43314 9.36667 3.8208 7.66667 5.7128 6.4C7.60414 5.13333 9.6998 4.5 11.9998 4.5C14.2998 4.5 16.3958 5.13333 18.2878 6.4C20.1791 7.66667 21.5665 9.36667 22.4498 11.5C21.5665 13.6333 20.1791 15.3333 18.2878 16.6C16.3958 17.8667 14.2998 18.5 11.9998 18.5ZM11.9998 17C13.8831 17 15.6125 16.504 17.1878 15.512C18.7625 14.5207 19.9665 13.1833 20.7998 11.5C19.9665 9.81667 18.7625 8.479 17.1878 7.487C15.6125 6.49567 13.8831 6 11.9998 6C10.1165 6 8.38714 6.49567 6.8118 7.487C5.23714 8.479 4.03314 9.81667 3.1998 11.5C4.03314 13.1833 5.23714 14.5207 6.8118 15.512C8.38714 16.504 10.1165 17 11.9998 17Z" />
    </g>
  </svg>
);

export { EyeVisible };
