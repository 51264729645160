export const TOKEN_STORAGE = 'token';
export const TOKEN_STORAGE_TIMESTAMP = 'token_timestamp';
export const OWNER_TOKEN_STORAGE = 'owner_token';
export const OWNER_TOKEN_STORAGE_TIMESTAMP = 'owner_token_timestamp';
export const AGENCY_TOKEN_STORAGE = 'agency_token';
export const TOKEN_STORAGE_LIVE = 2 * 24 * 60 * 60 * 1000; // 2 days

export const DEFAULT_MAP_HEIGHT = 300;
export const MIN_MAP_HEIGHT = 180;

export const DEFAULT_GOOGLE_DATA = {
  center: {
    lat: 38.0019325,
    lng: -97.5905178,
  },
  zoom: window.innerWidth < 720 ? 3 : 5,
  lightStyles: [
    {
      featureType: 'all',
      elementType: 'labels.text',
      stylers: [
        {
          color: '#878787'
        }
      ]
    },
    {
      featureType: 'all',
      elementType: 'labels.text.stroke',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'landscape',
      elementType: 'all',
      stylers: [
        {
          color: '#f9f5ed'
        }
      ]
    },
    {
      featureType: 'poi',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'road',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'simplified'
        }
      ]
    },
    {
      featureType: 'road.highway',
      elementType: 'all',
      stylers: [
        {
          color: '#f5f5f5'
        }
      ]
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: '#c9c9c9'
        }
      ]
    },
    {
      featureType: 'transit',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'water',
      elementType: 'all',
      stylers: [
        {
          color: '#9ec7e6'
        }
      ]
    }
  ],
  darkStyles: [
    {
      featureType: 'all',
      elementType: 'labels.text.fill',
      stylers: [
        {
          saturation: 36
        },
        {
          color: '#000000'
        },
        {
          lightness: 40
        }
      ]
    },
    {
      featureType: 'all',
      elementType: 'labels.text.stroke',
      stylers: [
        {
          visibility: 'on'
        },
        {
          color: '#000000'
        },
        {
          lightness: 16
        }
      ]
    },
    {
      featureType: 'all',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'administrative',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#000000'
        },
        {
          lightness: 20
        }
      ]
    },
    {
      featureType: 'administrative',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: '#000000'
        },
        {
          lightness: 17
        },
        {
          weight: 1.2
        }
      ]
    },
    {
      featureType: 'landscape',
      elementType: 'geometry',
      stylers: [
        {
          color: '#000000'
        },
        {
          lightness: 20
        }
      ]
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        {
          color: '#000000'
        },
        {
          lightness: 21
        }
      ]
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#000000'
        },
        {
          lightness: 17
        }
      ]
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: '#000000'
        },
        {
          lightness: 29
        },
        {
          weight: 0.2
        }
      ]
    },
    {
      featureType: 'road.arterial',
      elementType: 'geometry',
      stylers: [
        {
          color: '#000000'
        },
        {
          lightness: 18
        }
      ]
    },
    {
      featureType: 'road.local',
      elementType: 'geometry',
      stylers: [
        {
          color: '#000000'
        },
        {
          lightness: 16
        }
      ]
    },
    {
      featureType: 'transit',
      elementType: 'geometry',
      stylers: [
        {
          color: '#000000'
        },
        {
          lightness: 19
        }
      ]
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: '#000000'
        },
        {
          lightness: 17
        }
      ]
    },
    {
      featureType: 'water',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#275891'
        }
      ]
    },
  ],
};

export const CARD_TYPES = {
  BUTTON: 'button',
  CHECKBOX: 'checkbox',
  RADIO: 'radio',
};

export const TIME_HOURS = ['12', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11'];
export const TIME_MINUTES = ['00', '30'];
export const TIME_FORMAT = ['AM', 'PM'];
// @ts-ignore
export const TIME_PERIODS = TIME_FORMAT.reduce((arr, p) => (
  // @ts-ignore
  arr.concat(TIME_HOURS.reduce((arr2, h) => arr2.concat(TIME_MINUTES.map(m => `${h}:${m} ${p}`)), []))
), []);
export const DAY_MILLISECONDS = 24 * 60 * 60 * 1000;

export const CAR_TABS_MODE = {
  PICK_UP: 'PICK_UP',
  DELIVERY: 'DELIVERY'
};

export const DATES_FORMAT = 'MMM DD';
export const DATES_FORMAT_FULL = 'MMM DD - hh:mm A';

const STANDARD_AP_DESCRIPTION = `
Autosteer, Smart Cruise Control, and emergency braking.
`;

const FSD_DESCRIPTION = `
+  Vehicle recognizes stop signs and traffic lights.
Note that FSD still requires hands on the wheel at all times.
`;

export const DATES = {
  START: 'startDate',
  END: 'endDate',
  GMT_OFFSET: 'gmtOffset',
};

export const DELIVERY_ADDRESSES = {
  DELIVERY_TO: 'deliveryTo',
  COLLECT_AT: 'collectAt'
};

export const CARS_FILTERS = {
  AUTOPILOT: {
    key: 'AUTOPILOT',
    title: 'Autopilot',
    description: '',
    type: CARD_TYPES.CHECKBOX,
    filterKey: 'autopilot_type',
    className: 'd-grid gap-2',
    style: {},
    options: [
      {
        value: 'Basic',
        title: 'Standard Autopilot',
        description: STANDARD_AP_DESCRIPTION,
        image: ''
      },
      {
        value: 'Enhanced',
        title: 'Enhanced Autopilot',
        description: '+ Navigate on Autopilot, automatic lane change, autopark.',
        image: ''
      },
      {
        value: 'Full',
        title: 'Full Self-Driving Autopilot',
        description: FSD_DESCRIPTION,
        image: ''
      },
    ],
    conditions: {
      Basic: { '==': 'Basic' },
      Enhanced: { '==': 'Enhanced' },
      Full: { '==': 'Full' },
    }
  },
  SPEED: {
    key: 'SPEED',
    title: 'Speed',
    description: 'From 0 to 60 miles per hour in.',
    type: CARD_TYPES.CHECKBOX,
    filterKey: 'zero_to_sixty',
    className: 'd-grid gap-2',
    style: { gridTemplateColumns: '1fr 1fr' },
    options: [
      {
        value: '1.9-2.6',
        title: '1.9-2.6 s',
        description: '',
        image: ''
      },
      {
        value: '2.6-3.3',
        title: '2.6-3.3 s',
        description: '',
        image: ''
      },
      {
        value: '3.3-4.6',
        title: '3.3-4.6 s',
        description: '',
        image: ''
      },
      {
        value: '4.6+',
        title: '4.6+ s',
        description: '',
        image: ''
      }
    ],
    conditions: {
      '1.9-2.6': { '>=': 1.9, '<=': 2.6 },
      '2.6-3.3': { '>=': 2.6, '<=': 3.3 },
      '3.3-4.6': { '>=': 3.3, '<=': 4.6 },
      '4.6+': { '>=': 4.6 },
    }
  },
  MODEL: {
    key: 'MODEL',
    title: 'Model',
    description: '',
    type: CARD_TYPES.CHECKBOX,
    filterKey: 'model_name',
    className: 'd-grid gap-2',
    style: {},
    options: [
      {
        value: 'Model_S',
        title: 'Model S',
        description: '',
        image: '/images/cars/model_s_white.png'
      },
      {
        value: 'Model_3',
        title: 'Model 3',
        description: '',
        image: '/images/cars/model_3_white.png'
      },
      {
        value: 'Model_X',
        title: 'Model X',
        description: '',
        image: '/images/cars/model_x_white.png'
      },
      {
        value: 'Model_Y',
        title: 'Model Y',
        description: '',
        image: '/images/cars/model_y_white.png'
      },
      {
        value: 'Cybertruck',
        title: 'Cybertruck',
        description: '',
        image: '/images/cars/cybertruck_grey.png'
      }
    ],
    conditions: {
      Model_S: { '==': 'Model S' },
      Model_3: { '==': 'Model 3' },
      Model_X: { '==': 'Model X' },
      Model_Y: { '==': 'Model Y' },
      Cybertruck: { '==': 'Cybertruck' },
    }
  },
  RANGE: {
    key: 'RANGE',
    title: 'Range',
    description: '',
    type: CARD_TYPES.CHECKBOX,
    filterKey: 'battery_range',
    className: 'd-grid gap-2',
    style: {},
    options: [
      {
        value: '180-300',
        title: '180 - 300 miles per charge',
        description: '',
        image: ''
      },
      {
        value: '300-420',
        title: '300 - 420 miles per charge',
        description: '',
        image: ''
      }
    ],
    conditions: {
      '180-300': { '>=': 180, '<=': 300 },
      '300-420': { '>=': 300, '<=': 420 },
    }
  },
};

export const CARS_OPTIONS = {
  DELIVERY: {
    id: 'Custom Delivery',
    title: 'Delivery fee',
    amount: 150
  },
  INSURANCE: 'insurance',
  OPTIONS: 'options',
  ENABLE_DISCOUNT: true,
};

export const VALIDATIONS = {
  OTP_LENGTH: 6,
  FIRSTNAME_LENGTH: 1,
  LASTNAME_LENGTH: 1,
  EMAIL_REGEXP: /\S+@\S+\.\S+/,
  PASSWORD_LENGTH: 8,
  MIN_AGE: 21
};

export const RESERVATION_STATUSES = {
  PENDING: 'pending',
};

export const APPSTORE_LINK = 'https://apps.apple.com/us/app/eon-instant-ev-rentals/id6448188911';

export const SUPPORT_EMAIL = 'support@eonrides.com';
export const SUPPORT_PHONE = '+1-857-399-0442';

export const DISCOUNTS = [
  {
    key: 'week',
    daysMore: 7,
    daysLess: 30,
    discount: 15 // in percent
  },
  {
    key: 'month',
    daysMore: 30,
    discount: 30 // in percent
  }
];

export const PARTNER_KEY = 'partner';
export const REFERRAL_CODE_KEY = 'ref';
