// @ts-ignore
/* eslint-disable */

import React from 'react';
import cx from 'classnames';

const Min = ({ className, ...props }: any) => (
  <svg className={cx('svg-icon', className)} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g>
      <path d="M5.25 12.75V11.25H18.75V12.75H5.25Z" />
    </g>
  </svg>
);

export { Min };
