import React from 'react';

import { Typography as BTypography } from '../Typography';
import { Chip } from '../Chip';

import { Speed, ColorRed } from '../../../assets/icons';

const Typography = () => (
  <div>
    <BTypography variant="h1">Typography</BTypography>
    <div className="mb-3">
      <BTypography variant="h1">Header 1</BTypography>
      <code>{'<Typography variant="h1">Text</Typography>'}</code>
    </div>
    <div className="mb-3">
      <BTypography variant="h2">Header 2</BTypography>
      <code>{'<Typography variant="h2">Text</Typography>'}</code>
    </div>
    <div className="mb-3">
      <BTypography variant="h3">Header 3</BTypography>
      <code>{'<Typography variant="h3">Text</Typography>'}</code>
    </div>
    <div className="mb-3">
      <BTypography variant="body1">Body 1</BTypography>
      <code>{'<Typography variant="body1">Text</Typography>'}</code>
    </div>
    <div className="mb-3">
      <BTypography variant="body2">Body 2</BTypography>
      <code>{'<Typography variant="body2">Text</Typography>'}</code>
    </div>
    <div className="mb-3">
      <BTypography variant="body3">Body 3</BTypography>
      <code>{'<Typography variant="body3">Text</Typography>'}</code>
    </div>
    <BTypography className="mt-5" variant="h1">Labels</BTypography>
    <div className="d-flex gap-3">
      <Chip text="Speed" />
      <Chip text="Speed" icon={<Speed />} />
      <Chip text="Interior" icon={<ColorRed />} />
    </div>
  </div>
);

export { Typography };
