import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import cx from 'classnames';

import { useDeepEffect } from '../../../hooks';

interface ITabs {
  options: { label: string; value: string; }[] | [];
  onChange: (value: string) => void;
  value?: string;
  className?: string;
  disabled?: boolean;
  [k: string]: any;
}

const Tabs = ({ options, onChange, value: __value, className, disabled, ...props }: ITabs) => {
  const [value, setValue] = useState<string | undefined>();
  const navigate = useNavigate();
  const { hash, search } = useLocation();

  const handleChange = (newValue: string) => () => {
    setValue(newValue);
    onChange(newValue);
  };

  useEffect(() => {
    if (value !== __value) {
      setValue(__value);
      navigate({ hash: __value, search }, { replace: true });
    }
  }, [__value, value]);

  useDeepEffect(() => {
    const defaultTab = options.find(opt => `#${opt.value}` === hash)?.value;
    if (defaultTab && defaultTab !== __value) {
      setValue(defaultTab);
      onChange(defaultTab);
    }
  }, [hash, __value, options]);

  return (
    <div className={cx('tabs', className, { disabled })} {...props}>
      {options.map(opt => (
        <button
          key={opt.value}
          type="button"
          disabled={disabled}
          className={cx('tab', { selected: value === opt.value })}
          onClick={handleChange(opt.value)}
        >
          {opt.label}
        </button>
      ))}
    </div>
  );
};

Tabs.defaultProps = {
  value: undefined,
  className: undefined,
  disabled: undefined,
};

export { Tabs };
