import { useSearchParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';

import { Eon } from 'assets/icons';
import { Typography, InputField, Button } from 'components/common';
import { ownerActions, ownerSelectors } from 'redux/slices';

const ForgotPasswordConfirm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const resetSuccessful = useSelector(ownerSelectors.selectPasswordResetSuccessful) as boolean;
  const resetError = useSelector(ownerSelectors.selectPasswordResetError) as string;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  const passwordResetToken = searchParams.get('token');

  const formikForgotPasswordConfirm = useFormik({
    initialValues: {
      newPassword: '',
      confirmNewPassword: '',
    },
    validateOnChange: true,
    validateOnBlur: true,
    validate: vals => {
      const error = {} as any;

      if (!vals.newPassword) {
        error.newPassword = 'Required';
      }

      if (!vals.confirmNewPassword) {
        error.confirmNewPassword = 'Required';
      }

      if (vals.newPassword && vals.confirmNewPassword && vals.newPassword !== vals.confirmNewPassword) {
        error.newPassword = true;
        error.confirmNewPassword = 'Passwords do not match';
      }

      return error;
    },
    onSubmit: vals => {
      dispatch(ownerActions.resetPasswordConfirm.base({ ...vals, token: passwordResetToken }));
    }
  });

  if (resetSuccessful) {
    return (
      <div className="d-flex flex-column align-items-center justify-content-center">
        <div style={{ marginTop: '60px' }}>
          <Eon style={{ width: '60px', height: '60px' }} />
        </div>

        <div className="mt-2 d-flex gap-2 flex-column p-3">
          <Typography variant="h2" style={{ textAlign: 'center' }}>
            Your password has been updated.
          </Typography>

          <Button
            variant="primary"
            className="flex-grow-1 w-100 mt-3"
            type="submit"
            onClick={() => {
              navigate('/owner/login');
            }}
          >
            Click here to proceed
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <div style={{ marginTop: '60px' }}>
        <Eon style={{ width: '60px', height: '60px' }} />
      </div>

      <div className="mt-2 d-flex gap-2 flex-column p-3">
        <Typography variant="h1" style={{ textAlign: 'center' }}>
          Reset your password
        </Typography>

        <Typography style={{ textAlign: 'center' }}>
          We will send you an email with instructions on how to reset your password.
        </Typography>
      </div>

      <div
        className="d-flex flex-column align-items-center"
        style={{ width: '300px' }}
      >
        <InputField
          className="mb-2"
          label="New Password"
          type="password"
          value={formikForgotPasswordConfirm.values.newPassword}
          error={formikForgotPasswordConfirm.touched.newPassword && formikForgotPasswordConfirm.errors.newPassword}
          onChange={({ target: { value } }: any) => {
            formikForgotPasswordConfirm.setFieldValue('newPassword', value);
          }}
          required
        />

        <InputField
          className="mb-2"
          label="Confirm New Password"
          type="password"
          value={formikForgotPasswordConfirm.values.confirmNewPassword}
          onChange={({ target: { value } }: any) => {
            formikForgotPasswordConfirm.setFieldValue('confirmNewPassword', value);
          }}
          required
          error={
            formikForgotPasswordConfirm.touched.confirmNewPassword
            && formikForgotPasswordConfirm.errors.confirmNewPassword
          }
        />

        {
          resetError && (
            <Typography variant="body2" className="text-danger">
              {resetError}
            </Typography>
          )
        }

        <Button
          variant="primary"
          className="flex-grow-1 w-100 mt-3"
          type="submit"
          onClick={() => {
            formikForgotPasswordConfirm.handleSubmit();
          }}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default ForgotPasswordConfirm;
