import { createSlice } from '../../helpers/store';
import { IProfile, IPaymentMethod } from '../../types';

interface IState {
  profile: IProfile | null | undefined;
  profileLoading: boolean;
  isLoginFailed: boolean;
  isNewCustomer: boolean;
  isSetupComplete: boolean;
  paymentMethods: [IPaymentMethod] | [];
  paymentMethodsLoading: boolean;
}

const initialState: IState = {
  profile: null,
  profileLoading: false,
  isLoginFailed: false,
  isNewCustomer: false,
  isSetupComplete: false,
  paymentMethods: [],
  paymentMethodsLoading: false
};

const { actions, selectors, reducer } = createSlice({
  prefix: 'profile',
  initialState,

  selectors: {
    selectProfile: (state: IState) => state.profile,
    selectProfileLoading: (state: IState) => state.profileLoading,
    selectIsNewCustomer: (state: IState) => state.isNewCustomer,
    selectIsLoginFailed: (state: IState) => state.isLoginFailed,
    selectPaymentMethods: (state: IState) => state.paymentMethods,
    selectPaymentMethodsLoading: (state: IState) => state.paymentMethodsLoading,
  },

  reducers: {
    getProfile: {
      base: (state: IState) => {
        state.profileLoading = true;
      },
      success: (state: IState, { payload }: any) => {
        state.profile = payload;
        state.profileLoading = false;
        state.isNewCustomer = (
          state.isNewCustomer
          || !(payload.email && payload.firstname && payload.lastname && payload.dob)
          || payload.firstname === 'MobileApp'
          || payload.new_customer
        );
      },
      failed: (state: IState) => {
        state.profileLoading = false;
      }
    },
    updateProfile: {
      success: (state: IState, { payload }: any) => {
        state.profile = payload;
        state.isNewCustomer = false;
      },
    },
    clearProfile: {
      base: (state: IState) => {
        state.profile = null;
      },
    },
    loginStart: {
      success: (state: IState, { payload }: any) => {
        state.isNewCustomer = Boolean(payload.new_customer);
      },
    },
    resetLoginFailed: {
      base: (state: IState) => {
        state.isLoginFailed = false;
      },
    },
    loginComplete: {
      base: (state: IState) => {
        state.isLoginFailed = false;
      },
      success: (state: IState, { payload: { customer } }: any) => {
        state.profile = customer;
        state.isLoginFailed = false;
      },
      failed: (state: IState) => {
        state.isLoginFailed = true;
      },
    },
    logout: {
      success: (state: IState) => {
        Object.keys(initialState).forEach((key: string) => {
          // @ts-ignore
          state[key] = initialState[key];
        });
      }
    },
    loadPaymentMethods: {
      base: (state: IState) => {
        state.paymentMethodsLoading = true;
      },
      success: (state: IState, { payload: { payment_methods } }: any) => {
        state.paymentMethods = payment_methods;
        state.paymentMethodsLoading = false;
      },
      failed: (state: IState) => {
        state.paymentMethodsLoading = false;
      },
    },
    deletePaymentMethod: {
      success: (state: IState, { payload: { id } }: any) => {
        const index = state.paymentMethods.findIndex((method: IPaymentMethod) => method.id === id);
        if (index > -1) {
          state.paymentMethods.splice(index, 1);
        }
      },
    },
    setProfile: {
      base: (state: IState, { payload }: any) => {
        state.profile = payload;
      },
    },
  },
});

export { reducer as profile, actions as profileActions, selectors as profileSelectors };
