import { useState, useRef, useEffect } from 'react';
import cx from 'classnames';
import Slider from 'react-slick';

import './styles.scss';
import { Check } from 'assets/icons';
import Banner from '../Banner';

const Questionnaire = ({
  questions,
  reset,
  onDone,
  onRedo,
}: {
  questions: any[];
  reset: boolean;
  onDone: (hasCorrectAnswers: boolean) => void;
  onRedo: () => void;
}) => {
  const [currentSlideIdx, setCurrentSlideIdx] = useState<number>(0);
  const [selectedAnswers, setSelectedAnswers] = useState<any>({});
  const sliderRef = useRef<Slider>(null);
  const [bannerMeta, setBannerMeta] = useState({
    visible: false,
    title: '',
    type: 'error',
    body: '',
  });

  useEffect(() => {
    if (reset) {
      setCurrentSlideIdx(0);
      setSelectedAnswers({});

      sliderRef.current?.slickGoTo(0);
    }
  }, [reset]);

  const handleNextClick = () => {
    const hasCorrectAnswers = (
      questions[currentSlideIdx].answers.sort().toString() === selectedAnswers[currentSlideIdx].sort().toString()
    );

    if (!hasCorrectAnswers) {
      setBannerMeta({
        visible: true,
        title: 'Oops...',
        type: 'error',
        body: "You missed that one, but you're almost there 💪 Let's try that again",
      });

      return;
    }

    if (currentSlideIdx === questions.length - 1) {
      setBannerMeta({
        visible: true,
        title: 'Done!',
        type: 'success',
        body: "You did it, Bravo 👏 Now it's time to proceed to the next step",
      });

      onDone(true);

      return;
    }

    sliderRef.current?.slickNext();
    setCurrentSlideIdx((prevSlideIdx) => prevSlideIdx + 1);
  };

  if (bannerMeta.visible) {
    return (
      <>
        <Banner
          visible={bannerMeta.visible}
          title={bannerMeta.title}
          type={bannerMeta.type as ('error' | 'success')}
          body={bannerMeta.body}
        />

        {
          bannerMeta.type === 'error' && (
            <button
              style={{
                border: '2px solid #FF273A',
                borderRadius: '0.5rem',
                width: '21.375rem',
                height: '3.5rem',
                color: '#FF273A',
                fontFamily: 'sf-pro',
                fontSize: '1.125rem',
                fontWeight: 500,
                lineHeight: '1.5rem',
                marginTop: '1rem',
              }}
              onClick={() => {
                setBannerMeta({
                  visible: false,
                  title: '',
                  type: '',
                  body: '',
                });

                setCurrentSlideIdx(0);
                setSelectedAnswers({});

                sliderRef.current?.slickGoTo(0);

                onRedo();
              }}
            >
              Redo
            </button>
          )
        }
      </>
    );
  }

  return (
    <div style={{ width: '21.375rem' }}>
      <Slider
        ref={sliderRef}
        {
        ...{
          dots: false,
          infinite: false,
          arrows: false,
          swipe: false,
          draggable: false,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
        }
        }
      >
        {
          questions.map((question, idx) => {
            return (
              <div key={idx} style={{ minHeight: '30rem' }}>
                <div className="questionnaire">
                  <div>
                    <div className="questionnaire__title mb-4">
                      {question.question}

                      {
                        question.type === 'multiple' && (
                          <div
                            style={{
                              color: '#999999',
                              fontFamily: 'neue-haas-otesk-display',
                              fontSize: '0.75rem',
                              fontStyle: 'italic',
                              fontWeight: 400,
                            }}
                          >
                            (Select all that apply)
                          </div>
                        )
                      }
                    </div>

                    <div className="d-flex flex-column gap-3">
                      {
                        question.options.map((option: string, idx: number) => {
                          return (
                            <div
                              key={idx}
                              className="d-flex flex-row gap-3 align-items-center"
                              onClick={() => {
                                const questionSelectedAnswers = selectedAnswers[currentSlideIdx] || [];
                                let updatedQuestionSelectAnswers: number[];

                                if (question.type === 'single') {
                                  updatedQuestionSelectAnswers = [idx];
                                } else if (questionSelectedAnswers.includes(idx)) {
                                  const filteredAnswers = questionSelectedAnswers
                                    .filter((answer: number) => answer !== idx);

                                  updatedQuestionSelectAnswers = filteredAnswers;
                                } else {
                                  updatedQuestionSelectAnswers = [...questionSelectedAnswers, idx];
                                }

                                const yoyoy = questionSelectedAnswers.map((questionAnswers: number, index: number) => {
                                  if (questionAnswers === index) {
                                    return updatedQuestionSelectAnswers;
                                  }

                                  return questionAnswers;
                                });

                                setSelectedAnswers({
                                  ...selectedAnswers,
                                  [currentSlideIdx]: updatedQuestionSelectAnswers,
                                });
                              }}
                            >
                              <div
                                className={cx(
                                  'questionnaire__option',
                                  {
                                    'questionnaire__option--selected': selectedAnswers[currentSlideIdx]
                                      && selectedAnswers[currentSlideIdx].includes(idx),
                                  },
                                )}
                              >
                                {
                                  selectedAnswers[currentSlideIdx]
                                  && selectedAnswers[currentSlideIdx].includes(idx)
                                  && (
                                    <div className="questionnaire__option-icon d-flex align-items-center">
                                      <Check />
                                    </div>
                                  )
                                }
                              </div>

                              <div className="questionnaire__option-text">
                                {option}
                              </div>
                            </div>
                          );
                        })
                      }
                    </div>
                  </div>

                  <div className="d-flex flex-row align-items-center justify-content-between">
                    <div
                      style={{
                        backgroundColor: '#76CCCC',
                        width: '14.8125rem',
                        height: '1px',
                      }}
                    />

                    <div
                      style={{
                        color: '#999',
                        fontSize: '0.875rem',
                        fontFamily: 'neue-haas-grotesk-display',
                        fontWeight: 500,
                      }}
                    >
                      {
                        `${currentSlideIdx + 1} of ${questions.length}`
                      }
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        }
      </Slider>

      <button
        className="btn btn-primary btn-lg w-100 mt-3"
        style={{
          backgroundColor: selectedAnswers[currentSlideIdx]?.length > 0 ? '#1A1A1F' : '#D9D9DF',
          color: 'white',
          fontFamily: 'sf-pro',
          lineHeight: '1.5rem',
          fontSize: '1.125rem',
          height: '3.5rem',
        }}
        onClick={handleNextClick}
        disabled={!selectedAnswers[currentSlideIdx]?.length}
      >
        {
          currentSlideIdx === questions.length - 1 ? 'Submit' : 'Next Question'
        }
      </button>
    </div>
  );
};

export default Questionnaire;
