import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import 'react-phone-number-input/style.css';
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  Country,
  parsePhoneNumber,
  Value,
} from 'react-phone-number-input';
import './styles.scss';

import { Typography, InputField, Button, Link } from '../../common';
import { VALIDATIONS } from '../../../constants';
import { partnerSelectors, profileActions, profileSelectors, uiSelectors, IPartnerState } from '../../../redux/slices';
import { LoadingSpinner } from '../../LoadingSpinner';

interface IProps {
  buttonText: string;
  style?: any;
}

const LoginFlow = ({ buttonText, style }: IProps) => {
  const dispatch = useDispatch();
  const isMapFullSize = useSelector(uiSelectors.selectMapFullSize) as boolean;
  const isLoading = useSelector(profileSelectors.selectProfileLoading) as boolean;
  const isLoginFailed = useSelector(profileSelectors.selectIsLoginFailed) as boolean;
  const partner = useSelector(partnerSelectors.selectPartner) as IPartnerState;
  const [codeRequested, setCodeRequested] = useState<boolean>(false);

  useEffect(() => {
    dispatch(profileActions.getProfile.base());
  }, []);

  const formikLoginComplete = useFormik({
    initialValues: {
      code: '',
      phone: ''
    },
    validateOnChange: true,
    validateOnBlur: true,
    validate: values => {
      const error = {} as any;
      if (String(values.code).length !== VALIDATIONS.OTP_LENGTH) {
        error.code = true;
      }
      return error;
    },
    onSubmit: values => dispatch(profileActions.loginComplete.base(values))
  });

  const formikLoginStart = useFormik({
    initialValues: {
      phone: '+1',
      country: '',
    },
    validateOnChange: true,
    validateOnBlur: true,
    validate: vals => {
      const error = {} as any;
      if (!vals.phone || vals.phone.length < 2) {
        error.phone = true;
      }
      if (vals.phone && !isValidPhoneNumber(vals.phone)) {
        error.phone = true;
      }
      return error;
    },
    onSubmit: vals => {
      const phone = parsePhoneNumber(vals.phone) as any;

      dispatch(profileActions.loginStart.base({
        phone: phone.number,
        partner: partner.data?.url_slug,
      }));

      formikLoginComplete.setFieldValue('phone', phone.number);

      setCodeRequested(true);
    }
  });

  const handleGoToPhone = () => {
    setCodeRequested(false);
    formikLoginComplete.resetForm();
  };

  const handleSetCode = (e: any) => {
    if (isLoginFailed) {
      dispatch(profileActions.resetLoginFailed.base());
    }
    formikLoginComplete.setFieldValue('code', e.target.value);
  };

  const handlePhoneChange = (phoneNumber: Value) => {
    if (phoneNumber && isValidPhoneNumber(phoneNumber)) {
      formikLoginStart.setFieldValue('phone', formatPhoneNumberIntl(phoneNumber));
    } else {
      formikLoginStart.setFieldValue('phone', phoneNumber);
    }
  };

  const handleCountryChange = (country: Country) => {
    formikLoginStart.setFieldValue('country', country);
  };

  if (isLoading) {
    return <LoadingSpinner className="mt-5" />;
  }

  if (codeRequested) {
    return (
      <div className="mt-5">
        <Typography variant="h2" className="m-0">Enter Code</Typography>
        <Typography variant="body2" className="mb-3">Fill in the code we&apos;ve sent to your phone.</Typography>
        <InputField
          label="Code"
          type="text"
          pattern="\d{6}"
          name="code"
          autoComplete="one-time-code"
          inputMode="numeric"
          value={formikLoginComplete.values.code}
          // eslint-disable-next-line
          error={formikLoginComplete.touched.code && formikLoginComplete.errors.code
            ? 'Enter code that contains 6 numbers'
            : (isLoginFailed ? 'Invalid code' : undefined)}
          onChange={handleSetCode}
          onKeyDown={(e: any) => e.keyCode === 13 ? formikLoginComplete.handleSubmit() : {}}
          autoFocus
        />
        <Typography variant="body2" className="mt-3 mb-0">
          <span>Didn’t receive anything? </span>
          <Link onClick={handleGoToPhone}>Resend code.</Link>
        </Typography>
        <div style={{ paddingBottom: 20 }} />
        <div
          className="d-flex w-100 justify-content-center align-items-center"
          style={{ opacity: isMapFullSize ? 0 : 1 }}
        >
          <Button
            variant="primary"
            onClick={formikLoginComplete.handleSubmit}
            className="flex-grow-1 w-md-fit m-0"
          >
            Login
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="mt-4 d-flex flex-column position-relative" style={{ ...style }}>
      <Typography variant="h2" className="m-0">Enter Phone Number</Typography>
      <Typography variant="body2" className="mb-3">
        Continue with SMS to confirm you&apos;re not a robot.
      </Typography>
      <PhoneInput
        international
        limitMaxLength
        defaultCountry="US"
        value={formikLoginStart.values.phone}
        onChange={handlePhoneChange}
        onCountryChange={handleCountryChange}
        countryCallingCodeEditable={false}
        onKeyDown={(e: any) => e.keyCode === 13 ? formikLoginStart.handleSubmit() : {}}
      />
      {
        formikLoginStart.touched.phone && formikLoginStart.errors.phone && (
          <div
            className="error-msg pt-2"
            style={{
              whiteSpace: 'normal',
              textAlign: 'center',
            }}
          >
            Please enter a valid phone number.
          </div>
        )
      }
      <div style={{ paddingBottom: 40 }} />
      <div
        className="d-flex w-100 justify-content-center align-items-center"
        style={{ opacity: isMapFullSize ? 0 : 1 }}
      >
        <Button
          variant="primary"
          onClick={formikLoginStart.handleSubmit}
          className="flex-grow-1 w-md-fit m-0"
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );
};

export { LoginFlow };
