import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

import { useDeepEffect } from '../../../hooks';
import { ICar, ILocation, IMap, IStoreCars, IStoreCarsSearch } from '../../../types';

import variables from '../../../assets/scss/variables.scss';

import { checkoutSelectors, locationsSelectors, uiActions, uiSelectors } from '../../../redux/slices';
import { CAR_TABS_MODE } from '../../../constants';

declare const google: any;

interface IProps {
  allMarkers: any;
}

const CityMarkers = ({ allMarkers }: IProps) => {
  const dispatch = useDispatch();
  const { city } = useParams();
  const { hash } = useLocation();
  const map = useSelector(uiSelectors.selectMap) as IMap;
  const { data } = useSelector(state => locationsSelectors.selectCars(state, true)) as IStoreCars;
  const { location: selectedLocation, address } = useSelector(uiSelectors.selectCarsSearch) as IStoreCarsSearch;
  const carDetails = useSelector(checkoutSelectors.selectCarDetails) as ICar;
  const location = useSelector(state => locationsSelectors.selectLocationById(state, Number(city))) as ILocation;

  const isDelivery = `#${CAR_TABS_MODE.DELIVERY}` === hash;

  const handleAddFilter = (location: ILocation) => {
    mixpanel.track('Button Clicked', {
      'Button Name': 'City Marker',
      'City Marker Address': location.address,
    });
    dispatch(uiActions.setLocationFilter.base({ location }));
  };

  useDeepEffect(() => {
    if (!carDetails && location && map && !isDelivery) {
      allMarkers.forEach((marker: any) => {
        marker.setMap(null);
      });
      allMarkers.splice(0, allMarkers.length);
      // @ts-ignore
      const carsLocations = (data || []).reduce((map: any, car: ICar) => ({
        ...map,
        [`${car.default_location.latitude}_${car.default_location.longitude}`]:
          (map[`${car.default_location.latitude}_${car.default_location.longitude}`] || []).concat(car)
      }), []);

      Object.values(carsLocations)
        .forEach((cars: any) => {
          const marker = new google.maps.Marker({
            position: new google.maps.LatLng(cars[0].default_location.latitude, cars[0].default_location.longitude),
            map,
            icon: {
              url: '/images/pin-black.svg',
            },
            label: {
              text: String(cars.length),
              color: variables.white,
              fontSize: '12px',
            }
          });
          marker.addListener('click', () => handleAddFilter(cars[0].default_location));
          allMarkers.push(marker);
        });

      if (Object.values(carsLocations).length) {
        const bounds = new google.maps.LatLngBounds();
        Object.values(carsLocations).forEach((cars: any) => {
          bounds.extend({
            lat: cars[0].default_location.latitude,
            lng: cars[0].default_location.longitude
          });
        });
        map.setCenter(bounds.getCenter());
        if (Object.values(carsLocations).length > 1) {
          map.fitBounds(bounds);
        } else {
          map.setZoom(12);
        }
      } else {
        map.setCenter({ lat: location.latitude, lng: location.longitude });
        map.setZoom(12);
      }
    }
  }, [data, map, isDelivery, carDetails, location]);

  useDeepEffect(() => {
    if (!carDetails) {
      allMarkers.forEach((marker: any) => {
        const isSelectedLocation = (
          (marker.position.lat() === selectedLocation?.latitude)
          && (marker.position.lng() === selectedLocation?.longitude)
        );
        if (marker.label) {
          marker.setLabel({
            text: marker.label.text,
            fontSize: marker.label.fontSize,
            color: variables.white,
          });
        }
        if (marker.icon) {
          marker.setIcon({
            url: isSelectedLocation ? '/images/pin-blue.svg' : '/images/pin-black.svg',
            origin: marker.icon.origin,
          });
        }
      });
    }
  }, [selectedLocation, allMarkers, carDetails]);

  useDeepEffect(() => {
    if (isDelivery) {
      allMarkers.forEach((marker: any) => {
        marker.setMap(null);
      });
      allMarkers.splice(0, allMarkers.length);
      if (address.deliveryTo) {
        const marker = new google.maps.Marker({
          position: new google.maps.LatLng(address.deliveryTo.geo.lat, address.deliveryTo.geo.lng),
          map,
          icon: {
            url: carDetails ? '/images/nav-start-blue.svg' : '/images/nav-start.svg',
          },
          title: address.deliveryTo.address,
        });
        allMarkers.push(marker);
      }
      if (address.collectAt) {
        const marker = new google.maps.Marker({
          position: new google.maps.LatLng(address.collectAt.geo.lat, address.collectAt.geo.lng),
          map,
          icon: {
            url: carDetails ? '/images/nav-finish-blue.svg' : '/images/nav-finish.svg',
          },
          title: address.collectAt.address,
        });
        allMarkers.push(marker);
      }
    }
  }, [map, address, isDelivery, carDetails]);

  useDeepEffect(() => {
    if (carDetails && !isDelivery && map) {
      allMarkers.forEach((marker: any) => {
        marker.setMap(null);
      });
      allMarkers.splice(0, allMarkers.length);
      const marker = new google.maps.Marker({
        position: new google.maps.LatLng(carDetails.default_location.latitude, carDetails.default_location.longitude),
        map,
        icon: {
          url: '/images/nav-start-blue.svg',
        },
        title: carDetails.default_location.address,
      });
      allMarkers.push(marker);
      map.setCenter({
        lat: carDetails.default_location.latitude,
        lng: carDetails.default_location.longitude
      });
      map.setZoom(16);
    }
  }, [carDetails, isDelivery]);

  return null;
};

export { CityMarkers };
