import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import { useFormik } from 'formik';
import {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  parsePhoneNumber,
  Value,
} from 'react-phone-number-input';

import { Eon } from 'assets/icons';
import { InputField, InputPhone, Typography, Button } from 'components/common';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { ownerActions, ownerSelectors } from 'redux/slices';
import { IOwner } from 'types';
import { DAY_MILLISECONDS, VALIDATIONS } from '../../../constants';

const SignupFlow = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const owner = useSelector(ownerSelectors.selectOwner) as IOwner;
  const ownerUpdateLoading = useSelector(ownerSelectors.selectOwnerUpdateLoading) as boolean;
  const ownerUpdateError = useSelector(ownerSelectors.selectOwnerUpdateError) as string;

  useEffect(() => {
    if (owner?.setup_complete) {
      navigate(location.state?.from || '/owner/home');
    }
  }, [owner?.setup_complete]);

  const formikCompleteSignup = useFormik({
    initialValues: {
      firstname: owner?.firstname || '',
      lastname: owner?.lastname || '',
      dob: owner?.dob || '',
      phone: owner?.phone || '',
    },
    validateOnChange: true,
    validateOnBlur: true,
    validate: vals => {
      const error = {} as any;
      if (String(vals.firstname).length < VALIDATIONS.FIRSTNAME_LENGTH) {
        error.firstname = 'First name is required';
      }

      if (String(vals.lastname).length < VALIDATIONS.LASTNAME_LENGTH) {
        error.lastname = 'Last name is required';
      }

      if (vals.dob && Math.floor(moment().diff(vals.dob) / DAY_MILLISECONDS / 365) < VALIDATIONS.MIN_AGE) {
        error.dob = 'You must be at least 21 years old';
      }

      if (!vals.phone || vals.phone.length < 2) {
        error.phone = true;
      }
      if (vals.phone && !isValidPhoneNumber(vals.phone)) {
        error.phone = 'Invalid phone number';
      }
      return error;
    },
    onSubmit: vals => {
      const phone = parsePhoneNumber(vals.phone) as any;
      formikCompleteSignup.setFieldValue('phone', phone.number);

      const payload = {
        ...vals,
        email: owner.email,
        phone: phone.number,
      };

      dispatch(ownerActions.updateOwner.base(payload));
    },
    enableReinitialize: true,
  });

  const handlePhoneChange = (phoneNumber: Value) => {
    if (phoneNumber && isValidPhoneNumber(phoneNumber)) {
      formikCompleteSignup.setFieldValue('phone', formatPhoneNumberIntl(phoneNumber));
    } else {
      formikCompleteSignup.setFieldValue('phone', phoneNumber);
    }
  };

  return (
    <div className="d-flex flex-column align-items-center">
      <div style={{ marginTop: '60px' }}>
        <Eon style={{ width: '60px', height: '60px' }} />
      </div>

      {
        ownerUpdateLoading && <LoadingSpinner absolute />
      }

      <div className="mt-4 d-flex gap-2 flex-column">
        <Typography variant="h1" style={{ textAlign: 'center' }}>
          Get Started
        </Typography>

        <Typography style={{ textAlign: 'center' }}>
          Complete your profile and start earning
        </Typography>
      </div>

      <div className="mt-4 d-flex flex-column align-items-center gap-2" style={{ width: '300px' }}>
        <InputField
          className="mb-2"
          label="First Name"
          type="text"
          name="firstname"
          value={formikCompleteSignup.values.firstname}
          error={formikCompleteSignup.touched.firstname && formikCompleteSignup.errors.firstname}
          onChange={({ target: { value } }: any) => {
            formikCompleteSignup.setFieldValue('firstname', value);
          }}
          required
        />

        <InputField
          className="mb-2"
          label="Last Name"
          type="text"
          name="lastname"
          value={formikCompleteSignup.values.lastname}
          error={formikCompleteSignup.touched.lastname && formikCompleteSignup.errors.lastname}
          onChange={({ target: { value } }: any) => {
            formikCompleteSignup.setFieldValue('lastname', value);
          }}
          required
        />

        <InputField
          className="mb-2"
          label="Date of birth"
          type="date"
          name="dob"
          value={formikCompleteSignup.values.dob}
          error={formikCompleteSignup.touched.dob && formikCompleteSignup.errors.dob}
          onChange={({ target: { value } }: any) => {
            formikCompleteSignup.setFieldValue('dob', value);
          }}
          required
        />

        <InputPhone
          value={formikCompleteSignup.values.phone}
          onChange={handlePhoneChange}
          onKeyDown={(e: any) => e.keyCode === 13 ? formikCompleteSignup.handleSubmit() : {}}
          style={{ width: '100%' }}
          error={formikCompleteSignup.touched.phone && formikCompleteSignup.errors.phone}
        />

        <Button
          variant="primary"
          className="flex-grow-1 w-100 mt-3"
          type="submit"
          onClick={() => formikCompleteSignup.handleSubmit()}
        >
          Continue
        </Button>

        {
          ownerUpdateError && (
            <div
              className="error-msg pt-2"
              style={{
                whiteSpace: 'normal',
                textAlign: 'center',
                color: 'red',
              }}
            >
              {ownerUpdateError}
            </div>
          )
        }
      </div>
    </div>
  );
};

export default SignupFlow;
