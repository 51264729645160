import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-google-places-autocomplete';
import { useFormik } from 'formik';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';
import { Icon } from '@mui/material';
import Select from 'react-select';
import moment from 'moment-timezone';

import * as api from 'api';
import { Typography, Button, InputField } from 'components/common';
import { snakeToTitleCase, toSnakeCase } from 'helpers/utils';
import { ownerActions, ownerSelectors } from 'redux/slices';
import { APIStatus, IOwner } from 'types';
import { LoadingSpinner } from 'components/LoadingSpinner';

interface Props {
  isOpen?: boolean;
  onClose?: () => void;
}

const AddLocationForm = ({
  isOpen = false,
  onClose,
}: Props) => {
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(isOpen);
  const owner = useSelector(ownerSelectors.selectOwner) as IOwner;
  const [states, setStates] = useState<{ [key: string]: number }>();
  const [createLocationStatus, setCreateLocationStatus] = useState<APIStatus>({
    loading: false,
    success: false,
    error: null,
  });
  const [addressSelected, setAddressSelected] = useState(false);

  useEffect(() => {
    setIsVisible(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (!isVisible && onClose) {
      onClose();
    }
  }, [isVisible]);

  useEffect(() => {
    const fetchStates = async () => {
      const data = await api.getLocationStates();
      setStates(data);
    };

    fetchStates();
  }, []);

  const timeZones = useMemo(() => {
    return moment.tz.names();
  }, []);

  const formikAddLocationForm = useFormik({
    initialValues: {
      address: '',
      zipcode: '',
      city: '',
      latitude: 0,
      longitude: 0,
      time_zone: '',
      location_state: -1,
      sales_tax_percent: 0,
      neighborhood: '',
      pickup_instructions: '',
      dropoff_instructions: '',
    },
    validateOnChange: true,
    validateOnBlur: true,
    validate: vals => {
      const error = {} as any;

      if (!vals.address) {
        error.address = 'Required';
      }

      if (!vals.zipcode) {
        error.zipcode = 'Required';
      }

      if (!vals.city) {
        error.city = 'Required';
      }

      if (!vals.latitude) {
        error.latitude = 'Required';
      }

      if (!vals.longitude) {
        error.longitude = 'Required';
      }

      if (!vals.time_zone) {
        error.time_zone = 'Required';
      }

      if (vals.time_zone === 'International Date Line West') {
        error.time_zone = 'Invalid time zone. Please search for the address again.';
      }

      if (vals.location_state === -1) {
        error.location_state = 'Required';
      }

      if (!vals.neighborhood) {
        error.neighborhood = 'Required';
      }

      if (!vals.pickup_instructions) {
        error.pickup_instructions = 'Required';
      }

      if (!vals.dropoff_instructions) {
        error.dropoff_instructions = 'Required';
      }

      return error;
    },
    onSubmit: async vals => {
      const payload = {
        ...vals,
        admin_id: owner.id,
      };

      setCreateLocationStatus({ loading: true, success: false, error: null });

      try {
        const data = await api.addOwnerLocation(payload);
        dispatch(ownerActions.addLocation.success(data));
        setCreateLocationStatus({ loading: false, success: true, error: null });
        toast.success('Location created successfully');
        formikAddLocationForm.resetForm();
        setAddressSelected(false);
        setIsVisible(false);
      } catch (error: any) {
        setCreateLocationStatus({
          loading: false,
          success: false,
          error: { code: error.response.status, message: error.response.data.error },
        });
        toast.error(error.response.data.error);
      }
    },
  });

  const handleClose = () => {
    // eslint-disable-next-line no-unused-expressions
    onClose && onClose();
    setIsVisible(false);
  };

  const getTimeZone = async ({ lat, lng }: any) => {
    const now = Date.now() / 1000;
    const key = process.env.REACT_APP_GOOGLE_TIMEZONE_API_KEY;

    const url = `https://maps.googleapis.com/maps/api/timezone/json?location=${lat}%2C${lng}&timestamp=${now}&key=${key}`;

    const res = await fetch(url, {
      method: 'GET',
    });
    const data = await res.json();

    return data;
  };

  const handleAutoComplete = async (e: any) => {
    try {
      const data = await geocodeByAddress(e.label);
      const geoData = await getLatLng(data[0]);
      const timeZone = await getTimeZone(geoData);

      const address = e.value.structured_formatting.main_text;
      const city = data[0].address_components.find((c: any) => c.types.includes('locality'))?.long_name;
      const state = data[0].address_components.find(
        (c: any) => c.types.includes('administrative_area_level_1')
      );
      const zipcode = data[0].address_components.find((c: any) => c.types.includes('postal_code'));

      if (timeZone.errorMessage) {
        toast.error('There was an issue fetching data for that address. Please try again.');
        // eslint-disable-next-line no-console
        console.error(timeZone.errorMessage);
        return;
      }

      const newValues = {
        ...formikAddLocationForm.values,
        address: address || '',
        zipcode: zipcode ? zipcode.long_name : '',
        city: city || '',
        latitude: geoData.lat,
        longitude: geoData.lng,
        time_zone: timeZone.timeZoneId || '',
        location_state: (states && state) ? states[toSnakeCase(state.long_name)] : -1,
      };

      formikAddLocationForm.setValues(newValues);

      setAddressSelected(true);
    } catch (e: any) {
      toast.error(e);
    }
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isVisible}
      style={{
        overlay: {
          zIndex: 1000,
        },
        content: {
          width: '400px',
          maxWidth: '90%',
          maxHeight: '90%',
          zIndex: 1000,
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          padding: '29px',
        },
      }}
      onRequestClose={handleClose}
    >
      {
        createLocationStatus.loading && (
          <LoadingSpinner absolute />
        )
      }

      <div className="d-flex justify-content-end">
        <Button
          onClick={handleClose}
          style={{ width: '25px', height: '25px', padding: 0 }}
        >
          X
        </Button>
      </div>

      <div className="d-flex flex-column gap-3 mt-4 align-items-center">
        <Typography variant="h2">
          Add New Location
        </Typography>

        <div className="w-100">
          <GooglePlacesAutocomplete
            apiKey={process.env.REACT_APP_GOOGLE_API_KEY || ''}
            selectProps={{
              onChange: handleAutoComplete,
              placeholder: 'Address (Type in your address)',
            }}
          />

          {
            formikAddLocationForm.touched.address
            && formikAddLocationForm.errors.address
            && (
              <div
                style={{
                  color: 'red',
                  whiteSpace: 'normal',
                  textAlign: 'center',
                }}
              >
                {formikAddLocationForm.errors.address}
              </div>
            )
          }
        </div>

        {
          addressSelected && (
            <div className="d-flex flex-column w-100 align-items-center gap-2">
              <InputField
                label="Address"
                type="text"
                name="address"
                value={formikAddLocationForm.values.address}
                onChange={formikAddLocationForm.handleChange}
                error={formikAddLocationForm.errors.address}
                required
              />

              <InputField
                label="City"
                type="text"
                name="city"
                value={formikAddLocationForm.values.city}
                onChange={formikAddLocationForm.handleChange}
                error={formikAddLocationForm.errors.city}
                required
              />

              <div className="w-100">
                <Typography>State</Typography>
                <Select
                  onChange={
                    (option: any) => formikAddLocationForm.setFieldValue('location_state', parseInt(option.value, 10))
                  }
                  options={
                    states && Object.entries(states).map(([key, value]) => ({
                      value,
                      label: snakeToTitleCase(key),
                    }))
                  }
                  value={
                    formikAddLocationForm.values.location_state !== -1
                    && states
                    && Object.entries(states)
                      .map(([key, value]) => ({
                        value,
                        label: snakeToTitleCase(key),
                      })).find(option => option.value === formikAddLocationForm.values.location_state)
                  }
                  isSearchable
                />
              </div>

              <InputField
                label="Zipcode"
                type="text"
                name="zipcode"
                value={formikAddLocationForm.values.zipcode}
                onChange={formikAddLocationForm.handleChange}
                error={formikAddLocationForm.errors.zipcode}
                required
              />

              <div className="w-100">
                <Typography>Time Zone</Typography>

                <Select
                  onChange={(option: any) => formikAddLocationForm.setFieldValue('time_zone', option.value)}
                  options={timeZones.map(tz => ({ value: tz, label: tz }))}
                  value={{
                    value: formikAddLocationForm.values.time_zone,
                    label: formikAddLocationForm.values.time_zone,
                  }}
                  isSearchable
                />
              </div>

              {
                formikAddLocationForm.errors.time_zone
                && (
                  <div
                    style={{
                      color: 'red',
                      whiteSpace: 'normal',
                      textAlign: 'center',
                    }}
                  >
                    {formikAddLocationForm.errors.time_zone}
                  </div>
                )
              }
            </div>
          )
        }

        <div className="d-flex flex-row w-100 align-items-center gap-2">
          <InputField
            label="Neighborhood Name"
            type="text"
            name="neighborhood"
            value={formikAddLocationForm.values.neighborhood}
            onChange={formikAddLocationForm.handleChange}
            error={formikAddLocationForm.touched.neighborhood && formikAddLocationForm.errors.neighborhood}
            required
          />

          <Icon
            data-tooltip-id="neighborhood-tooltip"
            data-tooltip-content="The neighborhood the car is parked in."
          >
            info
          </Icon>

          <Tooltip id="neighborhood-tooltip" />
        </div>

        <div className="d-flex flex-row w-100 align-items-center gap-2">
          <InputField
            label="Pickup Instructions"
            type="text"
            name="pickup_instructions"
            value={formikAddLocationForm.values.pickup_instructions}
            onChange={formikAddLocationForm.handleChange}
            error={
              formikAddLocationForm.touched.pickup_instructions
              && formikAddLocationForm.errors.pickup_instructions
            }
            required
          />
          <Icon
            data-tooltip-id="pickup-instructions-tooltip-multiline"
            data-tooltip-html={`
              1-2 sentences about the exact pickup tips.<br/>
              (e.g. 'The car is parked behind the building,<br/>
              take the alley to the right of the building<br/> - key is in the glovebox.')
              <br/>Address, your phone number, tutorial, and code to drive<br/> will be provided automatically.`}
          >
            info
          </Icon>

          <Tooltip id="pickup-instructions-tooltip-multiline" />
        </div>

        <div className="d-flex flex-row w-100 align-items-center gap-2">
          <InputField
            label="Dropoff Instructions"
            type="text"
            name="dropoff_instructions"
            value={formikAddLocationForm.values.dropoff_instructions}
            onChange={formikAddLocationForm.handleChange}
            error={
              formikAddLocationForm.touched.dropoff_instructions
              && formikAddLocationForm.errors.dropoff_instructions
            }
            required
          />
          <Icon
            data-tooltip-id="dropoff-instructions-tooltip-multiline"
            data-tooltip-html={`
              1-2 sentences about dropping off.<br/>
              (e.g. 'plug in the car, park in the same spot,<br/> and leave keys inside.')`}
          >
            info
          </Icon>

          <Tooltip id="dropoff-instructions-tooltip-multiline" />
        </div>

        {
          createLocationStatus.error && (
            <div
              style={{
                color: 'red',
                whiteSpace: 'normal',
                textAlign: 'center',
              }}
            >
              {createLocationStatus.error.message}
            </div>
          )
        }

        <Button
          onClick={formikAddLocationForm.handleSubmit}
        >
          Create
        </Button>
      </div>
    </Modal>
  );
};

export default AddLocationForm;
