import { ICar, CarStatusEnum } from 'types';

export const formatCar = (car: ICar): ICar => {
  let { status } = car;

  if (car.soft_delete) {
    status = CarStatusEnum.SoftDeleted;
  } else if ((!car.is_smartcar_connected || car.requires_smartcar_migration) && !car.soft_delete && !car.is_resla_car) {
    status = CarStatusEnum.NotConnected;
  } else if (car.is_awaiting_approval) {
    status = CarStatusEnum.AwaitingApproval;
  } else if (car.is_approved) {
    status = CarStatusEnum.Approved;
  } else {
    status = CarStatusEnum.MissingInformation;
  }

  return {
    ...car,
    status,
  };
};
