/* eslint-disable */
import cx from 'classnames';

const Wave = ({ className, ...props }: any) => {
  return (
    <svg className={cx(className)} viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g>
        <path
          d="M8.06166 4.26869C7.77615 3.76653 7.70086 3.17183 7.85221 2.61436C8.00355 2.05689 8.36926 1.58192 8.86951 1.29309C9.36977 1.00426 9.96396 0.925022 10.5224 1.07267C11.0809 1.22032 11.5583 1.58286 11.8504 2.08119L15.1317 7.76869M5.07244 7.84197L3.51494 5.14369C3.22486 4.64112 3.1463 4.04391 3.29655 3.48343C3.4468 2.92294 3.81354 2.4451 4.31611 2.15502C4.81867 1.86494 5.41589 1.78638 5.97637 1.93663C6.53686 2.08688 7.0147 2.45362 7.30478 2.95619L10.714 8.86244M14.867 14.9874C14.2372 14.0569 13.9897 12.9197 14.1756 11.8116C14.3615 10.7035 14.9666 9.70935 15.8656 9.03525L15.1349 7.76869C14.8449 7.26569 14.7665 6.66806 14.917 6.10726C15.0676 5.54647 15.4348 5.06846 15.9378 4.77838C16.4407 4.4883 17.0384 4.40991 17.5992 4.56047C18.16 4.71103 18.638 5.07819 18.9281 5.58119L20.8213 8.86244C21.3959 9.85789 21.7687 10.9568 21.9186 12.0963C22.0684 13.2358 21.9924 14.3937 21.6947 15.5039C21.3971 16.614 20.8837 17.6547 20.1839 18.5664C19.4841 19.4782 18.6116 20.2431 17.6161 20.8177C15.6057 21.978 13.2167 22.2922 10.9747 21.6911C8.73262 21.09 6.8212 19.6228 5.66087 17.6124L1.28587 10.0306C1.00037 9.52841 0.925076 8.9337 1.07642 8.37624C1.22777 7.81877 1.59348 7.34379 2.09373 7.05496C2.59398 6.76613 3.18817 6.6869 3.74663 6.83454C4.30509 6.98219 4.78248 7.34474 5.07462 7.84306L7.17791 11.4874M18.4906 1.18213C19.2142 1.37321 19.8929 1.70567 20.4874 2.16033C21.0819 2.615 21.5805 3.18287 21.9545 3.83119L21.9906 3.89353M5.65213 22.8624C4.43189 21.8983 3.40662 20.7105 2.63119 19.3624"
        />
      </g>
    </svg>
  );
}

export { Wave };

