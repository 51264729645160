import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import { uiActions, uiSelectors } from '../../../redux/slices';
import variables from '../../../assets/scss/variables.scss';

interface IProps {
  children: any;
  id: string;
  className?: string;
  additionalHeight?: number;
  style?: any;
}

const Scrollable = ({ children, id, className, style, additionalHeight }: IProps) => {
  const dispatch = useDispatch();
  const ref = useRef<any>(null);
  const [data, setData] = useState<any>({});
  const [isSmallSize, setIsSmallSize] = useState<boolean>(false);
  const mapHeight = useSelector(state => uiSelectors.selectScrollableData(state, 'map'));
  const storeData = useSelector(state => uiSelectors.selectScrollableData(state, id));

  const handleScroll = () => {
    setData({
      scrollTop: ref.current.scrollTop,
      offsetTop: ref.current.offsetTop,
    });
  };

  const handleResize = () => {
    setIsSmallSize(window.innerWidth <= Number(variables.sizemd));
  };

  useEffect(() => {
    if (
      (data?.scrollTop > 0 && !storeData?.scrollTop)
      || (!data.scrollTop && storeData?.scrollTop > 0)
    ) {
      dispatch(uiActions.setScrollableData.base({ id, data }));
    }
  }, [id, data, storeData]);

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener('scroll', handleScroll);
    }
    return () => ref.current ? ref.current.removeEventListener('scroll', handleScroll) : {};
  }, [ref]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [ref]);

  useEffect(() => {
    handleScroll();
  }, [mapHeight]);

  return (
    <div
      ref={ref}
      data-id={id}
      className={cx('scrollable', className)}
      style={{
        height: '100%',
        ...style,
      }}
    >
      {children}
    </div>
  );
};

Scrollable.defaultProps = {
  className: undefined,
  additionalHeight: 0,
  style: {},
};

export { Scrollable };
