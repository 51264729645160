import { useState, useEffect } from 'react';
import { Tooltip } from 'react-tooltip';
import moment from 'moment';

import * as api from 'api';
import { Card, Typography, Link } from 'components/common';
import { IReservation, APIStatus } from 'types';
import { Icon } from '@mui/material';
import { formatNumber } from 'utils';

interface IProps {
  res: IReservation;
}

const TripSummary = ({
  res,
}: IProps) => {
  const [subscriptionEstimatedEarningsStatus, setSubscriptionEstimatedEarningsStatus] = useState<APIStatus>({
    loading: false,
    success: false,
    error: null,
  });
  const [subscriptionEstimatedEarnings, setSubscriptionEstimatedEarnings] = useState<any>();

  const handleSubscriptionEstimatedEarnings = async () => {
    setSubscriptionEstimatedEarningsStatus({ loading: true, success: false, error: null });

    try {
      const data = await api.subscriptionEstimatedEarnings(res.id);
      setSubscriptionEstimatedEarnings(data.data);
      setSubscriptionEstimatedEarningsStatus({ loading: false, success: true, error: null });
    } catch (error: any) {
      setSubscriptionEstimatedEarningsStatus({
        loading: false,
        success: false,
        error: { code: error.response.status, message: error.response.data.error },
      });
    }
  };

  useEffect(() => {
    if (!res || !res.car.default_location || !res.subscription) return;

    handleSubscriptionEstimatedEarnings();
  }, [res.car]);

  const getOwnerDeliveryPayout = (res: IReservation) => {
    let deliveryPayout = 0;

    if (!res.car.is_fully_managed && res.custom_pickup_location !== '') {
      deliveryPayout = 0.85 * res.total_delivery_cost;
    }

    return deliveryPayout;
  };

  return (
    <Card
      type="button"
      className="d-flex flex-column gap-3 cursor-default focus-disable active-disable"
    >
      <div className="w-100">
        {!res.custom_pickup_location && (
          <Typography variant="body2" className="m-0 d-flex gap-3 align-items-start justify-content-between">
            <span>Where</span>
            <span className="text-right">
              {res.pickup_location.address}
            </span>
          </Typography>
        )}

        {res.custom_pickup_location && res.custom_return_location && (
          <>
            <Typography variant="body2" className="m-0 d-flex gap-3 align-items-center justify-content-between">
              <span>Delivery address</span>
              <span className="text-right">{res.custom_pickup_location}</span>
            </Typography>

            <Typography variant="body2" className="m-0 d-flex gap-3 align-items-center justify-content-between">
              <span>Return address</span>
              <span className="text-right">{res.custom_return_location}</span>
            </Typography>
          </>
        )}

        <Typography variant="body2" className="m-0 d-flex gap-3 align-items-center justify-content-between">
          <span>Start Time</span>
          <span className="text-right">
            {
              moment.tz(
                res.start_time,
                res.pickup_location.timezone,
              ).format('MMM DD - h:mma z')
            }
          </span>
        </Typography>

        <Typography variant="body2" className="m-0 d-flex gap-3 align-items-center justify-content-between">
          <span>End Time</span>
          <span className="text-right">
            {
              moment.tz(
                res.end_time,
                res.return_location.timezone,
              ).format('MMM DD - h:mma z')
            }
          </span>
        </Typography>

        {
          res.subscription ? (
            <Typography variant="body2" className="m-0 d-flex gap-3 align-items-center justify-content-between mt-3">
              <span>Subscription Period</span>
              <span className="text-right">
                {
                  res.subscription.renewal_period
                    .split('_')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                    .join(' ')
                }
              </span>
            </Typography>
          ) : (
            <Typography variant="body2" className="m-0 d-flex gap-3 align-items-center justify-content-between mt-3">
              <span>Total Trip Days</span>
              <span className="text-right">
                {res.num_days}
              </span>
            </Typography>
          )
        }

        {
          !res.subscription ? (
            <div className="m-0 d-flex gap-3 align-items-center justify-content-between mt-3">
              <Typography variant="body2" className="m-0">Price</Typography>
              <div>
                {
                  res.reservation_rates?.map(rate => (
                    <Typography variant="body2" className="m-0" key={rate.id}>
                      {`$${rate.daily_rate.toFixed(2)} x ${rate.num_days} days`}
                    </Typography>
                  ))
                }
              </div>
            </div>
          ) : null
        }

        {
          res.weekly_discount > 0 && (
            <div className="d-flex flex-row w-100 align-items-center justify-content-space-between mt-3">
              <div className="d-flex flex-row align-items-center gap-1">
                <Typography
                  variant="body2"
                  className="m-0"
                >
                  Weekly Discount (7+ days)
                </Typography>

                <Icon
                  data-tooltip-id="weekly-discount"
                  data-tooltip-html={`
                  Customers automatically get a discount for booking longer.<br/>
                  See the FAQ for more details.
                `}
                >
                  info
                </Icon>
              </div>

              <div className="d-flex align-items-center justify-content-center">
                <Typography
                  variant="body2"
                  className="m-0 text-right text-danger"
                >
                  {`-$${res.weekly_discount.toFixed(2)}`}
                </Typography>
              </div>

              <Tooltip
                id="weekly-discount"
              />
            </div>
          )
        }

        {
          res.monthly_discount > 0 && (
            <div className="d-flex flex-row w-100 align-items-center justify-content-space-between mt-3">
              <div className="d-flex flex-row align-items-center gap-1">
                <Typography
                  variant="body2"
                  className="m-0"
                >
                  Monthly Discount (7+ days)
                </Typography>

                <Icon
                  data-tooltip-id="monthly-discount"
                  data-tooltip-html={`
                  Customers automatically get a discount for booking longer.<br/>
                  See the FAQ for more details.
                `}
                >
                  info
                </Icon>
              </div>

              <div className="d-flex align-items-center justify-content-center">
                <Typography
                  variant="body2"
                  className="m-0 text-right text-danger"
                >
                  {`-$${res.monthly_discount.toFixed(2)}`}
                </Typography>
              </div>

              <Tooltip
                id="monthly-discount"
              />
            </div>
          )
        }

        {
          subscriptionEstimatedEarnings && res.subscription && !['cleaning', 'completed', 'billable']
            .includes(res.status) ? (
              <Typography
                variant="body2"
                className="m-0 d-flex gap-3 align-items-center justify-content-between mt-3"
              >
                <span>Earnings Range</span>
                <span className="text-right">
                  {`$${formatNumber(subscriptionEstimatedEarnings.min_earnings)} - $${formatNumber(subscriptionEstimatedEarnings.max_earnings)}`}
                </span>
              </Typography>
            ) : null
        }

        {
          res.subscription && ['cleaning', 'completed', 'billable']
            .includes(res.status) ? (
              <div>
                <Typography
                  variant="body2"
                  className="m-0 d-flex gap-3 align-items-center justify-content-between mt-3"
                >
                  <span>Mileage Tier</span>
                  <span className="text-right">
                    {`${res.subscription.mileage_tier} miles`}
                  </span>
                </Typography>

                <Typography
                  variant="body2"
                  className="m-0 d-flex gap-3 align-items-center justify-content-between mt-3"
                >
                  <span>Tier Cost</span>
                  <span className="text-right">
                    {`$${res.subscription.tier_cost}`}
                  </span>
                </Typography>
              </div>
            ) : null
        }

        {
          res.subscription === null
            ? (
              <Typography
                variant="body2"
                className="m-0 d-flex gap-3 align-items-center justify-content-between mt-3"
              >
                <span>You Keep</span>
                <span className="text-right">
                  {`${(100 - (res.management_commission_percentage * 100)).toFixed(2)}%`}
                </span>
              </Typography>
            ) : null
        }

        {
          res.subscription ? (
            <div className="d-flex justify-content-end mt-2">
              <Link
                onClick={() => {
                  window.open(`/owner/cars/${res.car.id}/edit`, '_blank');
                }}
                style={{ textDecoration: 'underline' }}
              >
                Price Chart
              </Link>
            </div>
          ) : null
        }

        <hr style={{ border: 'none', borderTop: '2px solid #000', marginTop: '20px' }} />

        {
          res.subscription === null
            ? (
              <Typography variant="body2" className="m-0 d-flex gap-3 align-items-center justify-content-between mt-3">
                <span>Extra Miles</span>
                <span className="text-right">
                  {`$${(res.extra_miles * 0.7).toFixed(2)}`}
                </span>
              </Typography>
            ) : null
        }

        <Typography variant="body2" className="m-0 d-flex gap-3 align-items-center justify-content-between mt-3">
          <span>Charging</span>
          <span className="text-right">
            {`$${res.charging_used.toFixed(2)}`}
          </span>
        </Typography>

        <Typography variant="body2" className="m-0 d-flex gap-3 align-items-center justify-content-between mt-3">
          <span>Tolls</span>
          <span className="text-right">
            {`$${res.tolls_used.toFixed(2)}`}
          </span>
        </Typography>

        {
          getOwnerDeliveryPayout(res) ? (
            <Typography variant="body2" className="m-0 d-flex gap-3 align-items-center justify-content-between mt-3">
              <span>Delivery</span>
              <span className="text-right">
                {`$${getOwnerDeliveryPayout(res).toFixed(2)}`}
              </span>
            </Typography>
          ) : null
        }

        {
          res.owner_discount > 0 ? (
            <Typography
              variant="body2"
              className="m-0 d-flex gap-3 align-items-center justify-content-between mt-3"
            >
              <span>Owner Discount</span>
              <span className="text-right text-danger">
                {`-$${res.owner_discount.toFixed(2)}`}
              </span>
            </Typography>
          ) : null
        }

        {
          res.subscription && ['cleaning', 'completed', 'billable']
            .includes(res.status) ? (
              <Typography
                variant="body2"
                className="m-0 d-flex gap-3 align-items-center justify-content-between mt-4"
                style={{ fontWeight: 600 }}
              >
                <span>Your Earnings</span>
                <span className="text-right">
                  {`$${
                    formatNumber(
                      res.subscription.tier_cost
                      + res.charging_used
                      + res.tolls_used
                      + getOwnerDeliveryPayout(res)
                      - res.owner_discount
                    )
                  }`}
                </span>
              </Typography>
            ) : null
        }

        {
          subscriptionEstimatedEarnings && res.subscription && !['cleaning', 'completed', 'billable']
            .includes(res.status) ? (
              <Typography
                variant="body2"
                className="m-0 d-flex gap-3 align-items-center justify-content-between mt-4"
                style={{ fontWeight: 600 }}
              >
                <span>Your Earnings</span>
                <span className="text-right">
                  {`$${
                    formatNumber(
                      subscriptionEstimatedEarnings.min_earnings
                      + res.charging_used
                      + res.tolls_used
                      + getOwnerDeliveryPayout(res)
                      - res.owner_discount
                    )
                  }
                  - $${
                    formatNumber(
                      subscriptionEstimatedEarnings.max_earnings
                      + res.charging_used
                      + res.tolls_used
                      + getOwnerDeliveryPayout(res)
                      - res.owner_discount
                    )
                  }`}
                </span>
              </Typography>
            ) : null
        }

        {
          !res.subscription
            ? (
              <Typography
                variant="body2"
                className="m-0 d-flex gap-3 align-items-center justify-content-between mt-4"
                style={{ fontWeight: 600 }}
              >
                <span>Your Earnings</span>
                <span className="text-right">
                  {`$${res.owner_earnings.toFixed(2)}`}
                </span>
              </Typography>
            ) : null
        }
      </div>
    </Card>
  );
};

export default TripSummary;
