import React from 'react';
import cx from 'classnames';

import './styles.scss';

interface Types {
  absolute?: boolean;
  className?: string;
  children?: any;
}

const LoadingSpinner = ({ absolute, className, ...props }: Types) => (
  <div className={cx('lds-ring', { absolute }, className)} {...props}>
    {['spin1', 'spin2', 'spin3', 'spin4'].map((s) => (
      <div key={s} />
    ))}
    {props.children}
  </div>
);

LoadingSpinner.defaultProps = {
  absolute: false,
  className: undefined,
};

export { LoadingSpinner };
