import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { Search } from 'assets/icons';
import { InputField } from 'components/common';
import * as api from 'api';
import { IPayout } from 'types';

const Payouts = () => {
  const [searchText, setSearchText] = useState('');
  const [payouts, setPayouts] = useState<IPayout[]>();
  const [filteredPayouts, setFilteredPayouts] = useState<IPayout[]>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await api.getOwnerPayouts();
        setPayouts(data);
        setFilteredPayouts(data);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (!payouts) return;

    const filtered = payouts.filter((payout) => {
      const startDate = moment(payout.start_time).format('MM/DD/YY');
      const endDate = moment(payout.end_time).format('MM/DD/YY');

      return startDate.includes(searchText)
        || endDate.includes(searchText)
        || payout.id.toString().includes(searchText);
    }).sort((a, b) => b.end_time > a.end_time ? 1 : -1);

    setFilteredPayouts(filtered);
  }, [searchText]);

  return (
    <div style={{ padding: '24px', overflow: 'scroll' }}>
      <div style={{ width: '250px' }}>
        <InputField
          label="Search Payouts"
          type="text"
          name="Search Payouts"
          value={searchText}
          onChange={(e: any) => setSearchText(e.target.value)}
          required
          startIcon={<Search />}
          style={{ width: '95%' }}
        />
      </div>

      <div className="mt-4">
        <table style={{ width: '100%' }}>
          <thead>
            <tr>
              <th>ID</th>
              <th>Start Time</th>
              <th>End Time</th>
              <th>Net Earnings</th>
            </tr>
          </thead>

          <tbody>
            {
              filteredPayouts?.map((payout: IPayout) => (
                <tr key={payout.id}>
                  <td>
                    <Link
                      to={`/owner/payouts/${payout.id}`}
                    >
                      {payout.id}
                    </Link>
                  </td>
                  <td>
                    {moment(payout.start_time).format('MM/DD/YY')}
                  </td>
                  <td>
                    {moment(payout.end_time).format('MM/DD/YY')}
                  </td>
                  <td>
                    {`$${payout.earnings_minus_expenses.toFixed(2)}`}
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Payouts;
