// @ts-nocheck

import { all, takeLatest } from 'redux-saga/effects';

import * as slices from '../slices';

const allFailedActions = Object.keys(slices)
  .filter(i => i.toLowerCase().includes('actions'))
  .reduce((map, key) => ([
    ...map,
    ...Object.keys(slices[key] || {}).reduce((map2, key2) => ([
      ...map2,
      slices[key][key2]?.types?.FAILED
    ]), []).filter((i: any) => !!i)
  ]), []);

export function* handleErrorFlow({ payload: error }) {
  try {
    // TODO: add `notify` for show notification
    console.error(error); // eslint-disable-line
    if (error?.response?.status && (error?.response?.status < 500 || error?.response?.status !== 401)) {
      // FIXME: We don't show an alert if the error is 404 and stripe does not have a linked source.
      // In fact, we shouldn't even be dispatching a finalizeReservation action if adding a new payment method fails.
      if (error?.response?.status === 500 && error?.response?.data?.error?.includes('linked source')) {
        return;
      }
      if (error?.response?.status === 401) {
        return;
      }

      let errMsg = error?.response?.data?.error || error?.message || 'Unexpected error';
      if (error?.response?.data?.errors) {
        errMsg = error.response.data.errors.join('\n');
      }

      yield alert(errMsg); // eslint-disable-line
    }
  } catch (err) {
    console.error(error); // eslint-disable-line
  }
}

export default function* root() {
  yield all(allFailedActions.map(action => (
    takeLatest(action, handleErrorFlow)
  )));
}
