import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import mixpanel from 'mixpanel-browser';
import './styles.scss';

import { useDeepEffect } from '../../hooks';
import { IProfile, IReservation } from '../../types';
import { PARTNER_KEY, RESERVATION_STATUSES } from '../../constants';
import { checkoutActions, checkoutSelectors, profileActions, profileSelectors, uiActions } from '../../redux/slices';

import { PageTitle } from '../common';
import { LoadingSpinner } from '../LoadingSpinner';
import { LoginFlow } from '../Authentication';
import { ReservationFinalize } from './ReservationFinalize';
import { ReservationInfo } from './ReservationInfo';
import { getFormattedDateStringFromInput } from '../../helpers/utils';

const Reservation = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const { search } = useLocation();

  const profile = useSelector(profileSelectors.selectProfile) as IProfile;
  const isProfileLoading = useSelector(profileSelectors.selectProfileLoading) as boolean;
  const reservation = useSelector(checkoutSelectors.selectReservation) as IReservation;

  const subdomain = window.location.hostname.replace(process.env.REACT_APP_BASE_URL!, '').replace('.', '');

  useEffect(() => {
    if (reservation && reservation.start_time && reservation.end_time) {
      dispatch(uiActions.setDisplayDates.base({
        startDate: getFormattedDateStringFromInput(new Date(reservation.start_time)),
        endDate: getFormattedDateStringFromInput(new Date(reservation.end_time)),
      }));
    }
  }, [reservation]);

  useEffect(() => {
    if (!profile) {
      dispatch(profileActions.getProfile.base());
    }
  }, [profile]);

  useDeepEffect(() => {
    if (profile || subdomain === 'agent') {
      const params = new URLSearchParams(search);
      dispatch(checkoutActions.loadReservation.base({
        id,
        navigate,
        search: params.get(PARTNER_KEY) ? `?${PARTNER_KEY}=${params.get(PARTNER_KEY)}` : '',
        fromAgency: subdomain === 'agent',
      }));
    }
    return () => dispatch(checkoutActions.clearReservation.base());
  }, [id, profile]);

  useEffect(() => {
    if (reservation?.status) {
      mixpanel.track('Page Viewed', {
        Page: reservation.status === RESERVATION_STATUSES.PENDING
          ? 'Reservation Finalize'
          : 'Reservation Complete',
      });
    }
  }, [reservation]);

  return (
    <div className="reservation-container overflow-y-scroll">
      {(!reservation && (profile || isProfileLoading)) && <LoadingSpinner className="mt-5" />}
      {reservation?.creator_type === 'Agency' && reservation?.status === RESERVATION_STATUSES.PENDING && (
        <ReservationFinalize />
      )}
      {reservation?.creator_type === 'Agency' && reservation?.status !== RESERVATION_STATUSES.PENDING && (
        <ReservationInfo />
      )}

      {reservation?.creator_type !== 'Agency' && !profile && !isProfileLoading && (
        <div className="p-4">
          <PageTitle title="Login" />
          <LoginFlow buttonText="Continue" />
        </div>
      )}

      {reservation?.creator_type !== 'Agency' && reservation?.status === RESERVATION_STATUSES.PENDING && (
        <ReservationFinalize />
      )}
      {reservation?.creator_type !== 'Agency'
      && reservation && reservation?.status !== RESERVATION_STATUSES.PENDING && (
        <ReservationInfo />
      )}
    </div>
  );
};

export { Reservation };
