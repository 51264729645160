import React, { ReactElement, useEffect, useState } from 'react';
import { debounce } from 'lodash';

import { InputField, PageTitle, Typography, Navigation } from '../common';
import { IAddress, IMapLocation, IPlace } from '../../types';
import { useDeepEffect } from '../../hooks';
import { MIN_MAP_HEIGHT } from '../../constants';

interface IProps {
  title?: string;
  onGoBack: () => void;
  toggleFocus: (status: boolean) => void;
  isFocused: boolean;
  onSelect: (item: IAddress | null) => void;
  value: IAddress | null;
  label: string;
  icon: ReactElement;
  hintText?: string;
  className?: string;
  autoFocus?: boolean;
  origin: IMapLocation;
  location: IMapLocation;
}

declare const google: any;

const PlaceAutocomplete = ({
  className, title, onGoBack, label, icon, isFocused, toggleFocus, onSelect, value, hintText,
  location, origin, autoFocus
}: IProps) => {
  if (typeof google === 'undefined' || !google.maps || !google.maps.Geocoder) {
    return null;
  }
  const geocoder = new google.maps.Geocoder();
  const sessionToken = new google.maps.places.AutocompleteSessionToken();
  const service = new google.maps.places.AutocompleteService(null, {
    types: ['geocode']
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [places, setPlaces] = useState<IPlace[]>([]);

  const handleFocus = () => {
    toggleFocus(true);
  };

  const handleGoBack = () => {
    onGoBack();
  };

  const displaySuggestions = (predictions: any) => {
    setIsLoading(false);

    if (predictions?.map) {
      setPlaces(predictions.map((item: any) => ({
        fullAddress: item.description,
        address: item.structured_formatting.main_text,
        city: item.structured_formatting.secondary_text,
        placeId: item.place_id,
        distance: item.distance_meters,
      })).sort((a: IPlace, b: IPlace) => b.distance - a.distance));
    }
  };

  const handleSelectPlace = (place: IPlace) => () => {
    geocoder.geocode({ placeId: place.placeId }, (data: any) => {
      if (!data[0]) {
        onSelect(null);
      } else {
        onSelect({
          placeId: place.placeId,
          address: place.fullAddress,
          geo: {
            lat: data[0].geometry.location.lat(),
            lng: data[0].geometry.location.lng()
          }
        });
      }
    });
    setPlaces([]);
    toggleFocus(false);
  };

  const handleSearch = debounce((query) => {
    service?.getPlacePredictions({
      input: query,
      componentRestrictions: { country: 'us' },
      location: new google.maps.LatLng(location.lat, location.lng),
      radius: 10000,
      origin,
      region: 'us',
      sessionToken
    }, displaySuggestions);
  }, 500);

  useDeepEffect(() => {
    if (isFocused && !places.length && value?.address) {
      handleSearch(value.address);
    }
  }, [value, isFocused, places]);

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, MIN_MAP_HEIGHT), 300);
  }, []);

  return (
    <div>
      {title && (
        <PageTitle title={title} className="pb-4" onGoBack={handleGoBack} />
      )}
      <InputField
        className={className}
        label={label}
        startIcon={icon}
        type="search"
        value={value?.address}
        onChange={({ target }: any) => {
          setIsLoading(Boolean(target.value.length));
          handleSearch(target.value);
        }}
        onFocus={handleFocus}
        autoComplete="off"
        autoFocus={autoFocus}
      />
      {hintText && title && !places.length && (
        <Typography variant="body2" className="mb-2">{hintText}</Typography>
      )}
      {isLoading && (
        <>
          <Navigation className="mb-2" showPlaceholder title="-" subtitle="-" />
          <Navigation className="mb-2" showPlaceholder title="-" subtitle="-" />
        </>
      )}
      {!isLoading && isFocused && places.map(item => (
        <Navigation
          className="mb-2"
          key={item.fullAddress}
          title={item.address}
          subtitle={item.city}
          onClick={handleSelectPlace(item)}
        />
      ))}
    </div>
  );
};

PlaceAutocomplete.defaultProps = {
  title: undefined,
  hintText: undefined,
  className: undefined,
  autoFocus: undefined,
};

export { PlaceAutocomplete };
