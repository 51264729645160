// @ts-ignore
import Smartcar from '@smartcar/auth';

import { Button } from 'components/common';

const scope = [
  'required:read_vin', 'required:read_odometer', 'required:read_location', 'required:control_security',
  'required:read_battery', 'required:read_vehicle_info', 'required:read_extended_vehicle_info',
  'required:read_charge', 'required:control_charge', 'required:read_charge_records', 'required:read_tires',
  'required:read_thermometer', 'required:read_climate', 'required:control_climate', 'required:read_compass',
  'required:read_speedometer', 'required:read_user_profile', 'required:control_pin',
  'required:read_alerts'
];

const smartcar = new Smartcar({
  clientId: process.env.REACT_APP_SMARTCAR_CLIENT_ID,
  redirectUri: process.env.REACT_APP_SMARTCAR_REDIRECT_URI,
  scope,
  mode: process.env.REACT_APP_SMARTCAR_MODE,
  // onComplete: (err: any, code: any, status: any) => {
  //   console.log('hell yeah', err, code, status);
  // },
});

interface IProps {
  text: string;
  className?: string;
  style?: React.CSSProperties;
}

const SmartcarConnect = ({
  text,
  className,
  style,
}: IProps) => {
  const handleSmartcarConnect = () => {
    smartcar.openDialog({
      forcePrompt: true,
      windowOptions: { width: '200px', height: '200px', top: 0, right: 0 },
      flags: ['tesla_auth:true'],
    });
  };

  return (
    <Button
      onClick={handleSmartcarConnect}
      className={className}
      style={{ ...style }}
    >
      {text}
    </Button>
  );
};

export default SmartcarConnect;
