import React from 'react';
import cx from 'classnames';

interface IProps {
  children?: any;
  className?: string;
  type?: 'button' | 'reset' | 'submit' | undefined;
  variant?: 'primary' | 'default' | undefined;
  onClick?: () => void;
  [k: string]: any;
}

const IconButton = ({ className, variant, type, onClick, children, ...props }: IProps) => (
  <button
    className={cx('btn', 'icon-btn', className, variant && `btn-${variant}`)}
    type={type}
    onClick={onClick}
    {...props}
  >
    {children}
  </button>
);

IconButton.defaultProps = {
  className: undefined,
  children: undefined,
  variant: undefined,
  onClick: undefined,
  type: 'button',
};

export { IconButton };
