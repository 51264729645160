import { all, fork } from 'redux-saga/effects';

import locations from './locations';
import checkout from './checkout';
import profile from './profile';
import ui from './ui';
import errorHandler from './errorHandler';
import owner from './owner';

export default function* root() {
  yield all([
    fork(locations),
    fork(checkout),
    fork(profile),
    fork(ui),
    fork(owner),
    fork(errorHandler),
  ]);
}
