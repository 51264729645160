export enum ManualInsuranceStatusEnum {
  Pending = 'pending',
  Verified = 'verified',
  Rejected = 'rejected',
}

export interface IProfile {
  id: number;
  avatar: string;
  dob: string;
  email: string;
  firstname: string;
  lastname: string;
  phone: string;
  setup_complete: boolean;
  credits: number;
  referral_code: string;
  is_insurance_connected: boolean;
  insurance_liability_verified_until: string;
  manual_insurance_status: ManualInsuranceStatusEnum;
}

export interface ICleaner {
  id: number;
  firstname: string;
  lastname: string;
  phone: string;
  email: string;
  dob: string;
}

export interface IPaymentMethod {
  id: string;
  last4: string;
  exp: string;
  brand: string;
}
