import { all, put, takeLatest } from 'redux-saga/effects';
import { checkoutActions, locationsActions, uiActions } from '../slices';

export function* initializeFlow(): Generator {
  yield all([
    put(locationsActions.loadLocations.base()),
    put(checkoutActions.loadExtraData.base()),
    put(checkoutActions.loadInsurances.base()),
  ]);
}

export default function* root() {
  yield all([
    takeLatest(uiActions.initialize.types.BASE, initializeFlow),
  ]);
}
