export const pages = {
  home: 'home',
  city: 'city',
  checkout: 'checkout',
  reservation: 'reservation',
};

export const routes = {
  [pages.home]: '/',
  [pages.city]: '/cities/:city',
  [pages.checkout]: '/cities/:city/checkout',
  [pages.reservation]: '/reservation/:id',
};
