import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';

import variables from '../../assets/scss/variables.scss';
import { Close, ArrowLeft, ArrowRight } from '../../assets/icons';

interface IProps {
  images?: string[] | [];
  className?: string;
  style?: any;
}

const CarImages = ({ images, className, style }: IProps) => {
  const [isFullSize, setFullSize] = useState<boolean>(false);
  const [slide, setSlide] = useState<number>(0);
  const ref = useRef<any>();
  const SMALL_WIDTH = 320;
  const FULL_WIDTH = window.innerWidth * 0.8;

  const getSlider = () => {
    return ref.current ? Math.round(ref.current.scrollLeft / (isFullSize ? FULL_WIDTH : SMALL_WIDTH)) : 0;
  };

  const handleNext = () => {
    const nextSlide = slide + 1 >= Number(images?.length) ? 0 : slide + 1;
    setSlide(nextSlide);
  };

  const handlePrev = () => {
    setSlide(slide - 1 < 0 ? Number(images?.length) : slide - 1);
  };

  const handleScroll = () => {
    const currSlider = getSlider();
    return (slide !== currSlider) ? setSlide(currSlider) : {};
  };

  const handleEscape = (e: any) => {
    switch (e.key) {
      case 'Escape': setFullSize(false); break;
      // case 'ArrowRight': handleNext(); break;
      // case 'ArrowLeft': handlePrev(); break;
      default: break;
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleEscape);
    return () => document.removeEventListener('keydown', handleEscape);
  }, []);

  useEffect(() => {
    if (ref.current) {
      if (getSlider() !== slide && !isFullSize) {
        ref.current.scrollTo(slide * (SMALL_WIDTH + 16), 0);
      }
      if (getSlider() !== slide && isFullSize) {
        ref.current.scrollTo(slide * (FULL_WIDTH + 16), 0);
      }
      ref.current.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (ref.current) {
        ref.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [slide, isFullSize, ref]);

  return (
    <>
      <div
        className={cx('slider', className, { full: isFullSize })}
        style={{ ...style }}
      >
        <ArrowLeft className="slider-arrow left" onClick={handlePrev} />
        <ArrowRight className="slider-arrow right" onClick={handleNext} />
        {isFullSize && <Close className="slider-close" onClick={() => setFullSize(false)} />}
        <div className="slider-images overflow-x-auto d-flex gap-3 hidden-scroll" ref={ref}>
          {(images || []).map((url: string, idx: number) => (
            <div
              key={`${url}-${idx}`}
              onClick={() => !isFullSize && window.innerWidth > Number(variables.sizemd) ? setFullSize(true) : {}}
              className="img-background"
              style={{
                minWidth: isFullSize ? FULL_WIDTH : SMALL_WIDTH,
                backgroundImage: `url('${url}')`,
              }}
            />
          ))}
        </div>
      </div>
      {isFullSize && <div className="slider-backdrop" onClick={() => setFullSize(false)} />}
    </>
  );
};

CarImages.defaultProps = {
  images: [],
  className: undefined,
  style: {},
};

export { CarImages };
