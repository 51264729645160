import React, { useEffect, useRef } from 'react';
import cx from 'classnames';

import { Placeholder } from '../Placeholder';
import { CARD_TYPES } from '../../../constants';

interface IProps {
  type?: 'button' | 'checkbox' | 'radio' | string;
  radius?: 'small' | 'large'
  className?: string;
  children?: any;
  disabled?: boolean;
  showPlaceholder?: boolean;
  onClick?: (e: any) => void;
  name?: string;
  checked?: boolean;
  [k: string]: any;
}

const Card = ({
  type, radius, className, children, disabled, showPlaceholder, onClick, name, checked, ...props
}: IProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  let element = {} as any;

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.checked = Boolean(checked);

      if (checked) {
        inputRef.current.blur();
      }
    }
  }, [inputRef, checked]);

  if (showPlaceholder) {
    return (
      <Placeholder animation className={cx('card', className, `radius-${radius}`)}>
        {children}
      </Placeholder>
    );
  }

  switch (type) {
    case CARD_TYPES.CHECKBOX: element = {
      tag: 'label',
      options: { className: 'as-checkbox' },
      children: <input type="checkbox" ref={inputRef} name={name} disabled={disabled} />
    }; break;
    case CARD_TYPES.RADIO: element = {
      tag: 'label',
      options: { className: cx('as-radio', className) },
      children: <input type="radio" ref={inputRef} name={name} disabled={disabled} />
    }; break;
    case CARD_TYPES.BUTTON: element = {
      tag: 'button',
      options: { className: 'as-button', type: 'button' }
    }; break;
    default: element = {
      tag: 'div',
      options: {}
    }; break;
  }

  return React.createElement(
    element.tag,
    {
      ...element.options,
      className: cx('card', className, `radius-${radius}`, element.options.className, { disabled }),
      onClick,
      ...props,
    },
    (
      <>
        {element.children}
        {children}
      </>
    )
  );
};

Card.defaultProps = {
  radius: 'small',
  type: undefined,
  className: undefined,
  children: undefined,
  disabled: undefined,
  showPlaceholder: undefined,
  name: undefined,
  checked: undefined,
  onClick: undefined,
};

export { Card };
