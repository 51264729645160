import React from 'react';

import * as Icon from '../../../assets/icons';

const BoxStyle = { marginBottom: 10, padding: 10 };
const IconStyle = { width: 24, height: 24, marginRight: 10 };

const Icons = () => (
  <div>
    <h1>Icons</h1>
    {Object.keys(Icon).map(key => {
      // @ts-ignore
      const Comp = Icon[key];
      return (
        <div style={BoxStyle} key={key}>
          <Comp style={IconStyle} />
          <span>{key}</span>
        </div>
      );
    })}
  </div>
);

export { Icons };
