import React from 'react';
import { useSelector } from 'react-redux';

import { Typography, Navigation } from '../common';
import { Savings, TravelMode, EventBusy, Terms } from '../../assets/icons';

import { checkoutSelectors } from '../../redux/slices';
import { IReservation } from '../../types';
import { getContractFullUrl } from '../../api';

const INFO = [
  {
    title: 'Security Deposit',
    description: 'An authorization of $500 will be placed 24 hours before '
      + 'pickup and released upon safe return of the vehicle.',
    icon: <Savings />
  },
  {
    title: 'Mileage',
    description:
      'Free 300 miles per day. Excess miles will be charged at a rate of $0.85 per additional mile.',
    icon: <TravelMode />
  },
  {
    title: 'Cancellation',
    description:
      'You may cancel the reservation for a full refund up to 48 hours before the start of the trip. '
      + 'Cancelling within 48 hours of the start time will be a 50% refund '
      + 'and 50% sent to you as credit for a future booking.',
    icon: <EventBusy />
  }
];

interface IProps {
  className?: string
}

const Information = ({ className }: IProps) => {
  const reservation = useSelector(checkoutSelectors.selectReservation) as IReservation;

  const handleOpenTerms = () => {
    window.open(getContractFullUrl(reservation), '_blank');
  };

  return (
    <div className={className}>
      <Typography variant="h2">Important Information</Typography>
      {INFO.map((item: any) => (
        <div key={item.title} className="d-flex gap-3 align-items-start mb-3">
          {item.icon}
          <div className="w-fit">
            <Typography variant="h3" className="m-0">{item.title}</Typography>
            <Typography variant="body2" className="m-0">{item.description}</Typography>
          </div>
        </div>
      ))}
      <Navigation
        title="All terms & conditions"
        icon={<Terms />}
        onClick={handleOpenTerms}
      />
    </div>
  );
};

Information.defaultProps = {
  className: undefined,
};

export { Information };
