import React from 'react';

import { Typography } from '../Typography';
import { Button } from '../Button';
import { IconButton } from '../IconButton';
import { Link } from '../Link';
import { Navigation } from '../Navigation';

import { ArrowLeft, Deposit } from '../../../assets/icons';

const Buttons = () => (
  <div>
    <Typography variant="h1">Buttons</Typography>
    <h2>Primary</h2>
    <code>{'<Button variant="primary">Text</Button>'}</code>
    <div className="d-flex gap-3 mt-3">
      <Button variant="primary">default</Button>
      <Button variant="primary" className="hover">hover</Button>
      <Button variant="primary" className="active">active</Button>
      <Button variant="primary" className="disabled" disabled>disabled</Button>
      <Button variant="primary" className="focus">focus</Button>
    </div>
    <Typography variant="h2">Primary Sticky (all, sm, lg)</Typography>
    <code>{'<Button variant="primary" sticky="all">Text</Button>'}</code>
    <div className="d-flex gap-3 mt-3">
      <Button variant="primary" sticky="all">default</Button>
      <Button variant="primary" sticky="all" className="hover">hover</Button>
      <Button variant="primary" sticky="all" className="active">active</Button>
      <Button variant="primary" sticky="all" className="disabled" disabled>disabled</Button>
      <Button variant="primary" sticky="all" className="focus">focus</Button>
    </div>
    <Typography variant="h2">Secondary</Typography>
    <code>{'<Button>Text</Button>'}</code>
    <div className="d-flex gap-3 mt-3">
      <Button>default</Button>
      <Button className="hover">hover</Button>
      <Button className="active">active</Button>
      <Button className="disabled" disabled>disabled</Button>
      <Button className="focus">focus</Button>
    </div>

    <Typography variant="h1" className="mt-5">Icon Buttons</Typography>
    <Typography variant="h2">Primary</Typography>
    <code>{'<IconButton variant="primary"><ArrowLeft /></IconButton>'}</code>
    <div className="d-flex gap-3 mt-3">
      <IconButton variant="primary"><ArrowLeft /></IconButton>
      <IconButton variant="primary" className="hover"><ArrowLeft /></IconButton>
      <IconButton variant="primary" className="active"><ArrowLeft /></IconButton>
      <IconButton variant="primary" className="disabled" disabled><ArrowLeft /></IconButton>
      <IconButton variant="primary" className="focus"><ArrowLeft /></IconButton>
    </div>
    <Typography variant="h2">Secondary</Typography>
    <code>{'<IconButton><ArrowLeft /></IconButton>'}</code>
    <div className="d-flex gap-3 mt-3">
      <IconButton><ArrowLeft /></IconButton>
      <IconButton className="hover"><ArrowLeft /></IconButton>
      <IconButton className="active"><ArrowLeft /></IconButton>
      <IconButton className="disabled" disabled><ArrowLeft /></IconButton>
      <IconButton className="focus"><ArrowLeft /></IconButton>
    </div>

    <Typography variant="h1" className="mt-5">Links</Typography>
    <code>
      {'<Link to="/buttons">Router Link</Link>'}
      <br />
      {'<Link href="//google.com">External Link</Link>'}
    </code>
    <div className="d-flex gap-3 mt-3">
      <Link>default</Link>
      <Link className="hover">hover</Link>
      <Link className="active">active</Link>
      <Link className="disabled" disabled>disabled</Link>
      <Link className="focus">focus</Link>
    </div>

    <Typography variant="h1" className="mt-5">Navigation</Typography>
    <code>
      {'<Navigation title="Page context" subtitle="Page context" icon={<Deposit />} showPlaceholder />'}
    </code>
    <div className="d-flex gap-3 mt-3">
      <Navigation title="Page context" />
      <Navigation title="Page context" showPlaceholder />
    </div>
    <div className="d-flex gap-3 mt-3">
      <Navigation title="Page context" subtitle="Page context" />
      <Navigation title="Page context" subtitle="Page context" showPlaceholder />
    </div>
    <div className="d-flex gap-3 mt-3">
      <Navigation title="Page context" subtitle="Page context" icon={<Deposit />} />
      <Navigation title="Page context" subtitle="Page context" icon={<Deposit />} showPlaceholder />
    </div>
    <div className="d-flex gap-3 mt-3">
      <Navigation title="Page context" />
      <Navigation className="hover" title="Page context" />
      <Navigation className="active" title="Page context" />
      <Navigation className="disabled" title="Page context" />
      <Navigation className="focus" title="Page context" />
    </div>
    <div className="d-flex gap-3 mt-3">
      <Navigation title="Page context" subtitle="Page context" />
      <Navigation className="hover" title="Page context" subtitle="Page context" />
      <Navigation className="active" title="Page context" subtitle="Page context" />
      <Navigation className="disabled" title="Page context" subtitle="Page context" />
      <Navigation className="focus" title="Page context" subtitle="Page context" />
    </div>
    <div className="d-flex gap-3 mt-3">
      <Navigation title="Page context" subtitle="Page context" icon={<Deposit />} />
      <Navigation className="hover" title="Page context" subtitle="Page context" icon={<Deposit />} />
      <Navigation className="active" title="Page context" subtitle="Page context" icon={<Deposit />} />
      <Navigation className="disabled" title="Page context" subtitle="Page context" icon={<Deposit />} />
      <Navigation className="focus" title="Page context" subtitle="Page context" icon={<Deposit />} />
    </div>
  </div>
);

export { Buttons };
