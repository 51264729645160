// @ts-ignore
/* eslint-disable */

import React from 'react';
import cx from 'classnames';

const Eon = ({ className, ...props }: any) => (
  <svg className={cx('svg-icon', className)} width="24" height="24" viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M48.8579 11.3292C46.0581 10.4652 43.0834 10 40 10C23.4315 10 10 23.4315 10 40C10 56.5685 23.4315 70 40 70C56.5685 70 70 56.5685 70 40C70 35.0755 68.8135 30.4281 66.7107 26.3282C65.65 27.6904 64.2937 28.8112 62.7381 29.5942C64.1904 32.7624 65 36.2867 65 40C65 53.8071 53.8071 65 40 65C26.1929 65 15 53.8071 15 40C15 26.1929 26.1929 15 40 15C42.1849 15 44.3044 15.2803 46.3244 15.8069C46.83 14.1225 47.7061 12.5984 48.8579 11.3292Z" />
    <path d="M62.5 19.1667C62.5 21.9281 60.2614 24.1667 57.5 24.1667C54.7386 24.1667 52.5 21.9281 52.5 19.1667C52.5 16.4052 54.7386 14.1667 57.5 14.1667C60.2614 14.1667 62.5 16.4052 62.5 19.1667Z" />
  </svg>
);

export { Eon };

