// @ts-ignore
/* eslint-disable */

import React from 'react';
import cx from 'classnames';

const ArrowUp = ({ className, ...props }: any) => (
  <svg className={cx('svg-icon', className)} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g>
      <path d="M2.23906 16.1125L11.8891 6.46247L21.5391 16.1125L20.1141 17.5375L11.8891 9.28747L3.66406 17.5375L2.23906 16.1125Z" />
    </g>
  </svg>
);

export { ArrowUp };

