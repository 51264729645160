import { useState, useEffect, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';

import { Typography } from 'components/common';
import * as api from 'api';
import { IPayout, IReservation, IExpense } from 'types';
import { Input } from 'components/common/Input';

const Payout = () => {
  const [payout, setPayout] = useState<IPayout>();
  const { payoutId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await api.getOwnerPayout(payoutId as string);
        setPayout(data);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const getOwnerDeliveryPayout = (res: IReservation) => {
    let deliveryPayout = 0;

    if (!res.car.is_fully_managed && res.custom_pickup_location !== '') {
      deliveryPayout = 0.85 * res.total_delivery_cost;
    }

    return deliveryPayout;
  };

  const totals = useMemo(() => {
    const out = {
      days: 0,
      extraMiles: 0,
      supercharging: 0,
      tolls: 0,
      delivery: 0,
      total: 0,
    };

    if (!payout) return out;

    return payout.reservations
      .filter((res) => res.subscription === null)
      .reduce((acc, trip) => {
        acc.days += trip.num_days;
        acc.extraMiles += trip.extra_miles * 0.7;
        acc.supercharging += trip.charging_used;
        acc.tolls += trip.tolls_used;
        acc.delivery += getOwnerDeliveryPayout(trip);
        acc.total += trip.owner_earnings;

        return acc;
      }, out);
  }, [payout]);

  const subTotals = useMemo(() => {
    const out = {
      tierCost: 0,
      extraMiles: 0,
      supercharging: 0,
      tolls: 0,
      delivery: 0,
      total: 0,
    };

    if (!payout) return out;

    return payout.reservations
      .filter((res) => res.subscription !== null)
      .reduce((acc, trip) => {
        acc.tierCost = trip.subscription.tier_cost;
        acc.supercharging += trip.charging_used;
        acc.tolls += trip.tolls_used;
        acc.delivery += getOwnerDeliveryPayout(trip);
        acc.total += trip.owner_earnings;

        return acc;
      }, out);
  }, [payout]);

  if (!payout) return null;

  return (
    <div
      className="d-flex flex-column gap-4"
      style={{ padding: '24px', overflow: 'scroll' }}
    >
      <div>
        <table style={{ width: '100%' }}>
          <thead>
            <tr>
              <th>ID</th>
              <th>Car</th>
              <th>Start Time</th>
              <th>End Time</th>
              <th>Start Location</th>
              <th>Guest</th>
              <th>Days</th>
              <th>Daily Price</th>
              <th>Weekly Discount</th>
              <th>Monthly Discount</th>
              <th>Your Earnings</th>
              <th>Extra Miles</th>
              <th>Supercharging</th>
              <th>Tolls</th>
              <th>Owner Discount</th>
              <th>Delivery</th>
              <th>Total</th>
              <th>Notes</th>
            </tr>
          </thead>

          <tbody>
            {
              payout.reservations
                ?.filter((res) => res.subscription === null)
                .map((res: IReservation) => (
                  <tr key={res.id}>
                    <td>
                      <Link to={`/owner/reservations/${res.id}/edit`}>{res.id}</Link>
                    </td>
                    <td>
                      <Link to={`/owner/cars/${res.car.id}/edit`}>{res.car.license_plate}</Link>
                    </td>
                    <td>
                      {moment(res.start_time).format('MM/DD/YY')}
                    </td>
                    <td>
                      {moment(res.end_time).format('MM/DD/YY')}
                    </td>
                    <td>
                      {res.custom_pickup_location || res.car.default_location.address}
                    </td>
                    <td>
                      {res.customer_name}
                    </td>
                    <td>
                      {
                        res.num_days
                      }
                    </td>
                    <td>
                      {
                        res.reservation_rates?.map((rate) => (
                          <div key={rate.id}>
                            {`${rate.daily_rate.toFixed(2)} x ${rate.num_days} days`}
                          </div>
                        ))
                      }
                    </td>
                    <td>
                      {
                        res.weekly_discount ? `-${res.weekly_discount.toFixed(2)}` : '0.00'
                      }
                    </td>
                    <td>
                      {
                        res.monthly_discount ? `-${res.monthly_discount.toFixed(2)}` : '0.00'
                      }
                    </td>
                    <td>
                      {
                      `${(100 - (res.car.management_commission_percentage * 100)).toFixed(2)}%`
                      }
                    </td>
                    <td>
                      {
                        (res.extra_miles * 0.7).toFixed(2)
                      }
                    </td>
                    <td>
                      {
                        res.charging_used.toFixed(2)
                      }
                    </td>
                    <td>
                      {
                        res.tolls_used.toFixed(2)
                      }
                    </td>
                    <td>
                      {
                        res.owner_discount ? `-${res.owner_discount.toFixed(2)}` : '0.00'
                      }
                    </td>
                    <td>
                      {
                        getOwnerDeliveryPayout(res)
                      }
                    </td>
                    <td>
                      {
                        res.owner_earnings.toFixed(2)
                      }
                    </td>
                    <td>
                      {
                        res.host_notes && (
                          <Input
                            type="textbox"
                            value={res.host_notes}
                            placeholder="Notes"
                            disabled
                            style={{ height: '150px' }}
                          />
                        )
                      }
                    </td>
                  </tr>
                ))
            }

            <tr>
              <td>Total</td>
              <td />
              <td />
              <td />
              <td />
              <td>{totals.days}</td>
              <td />
              <td />
              <td />
              <td />
              <td>{totals.extraMiles.toFixed(2)}</td>
              <td>{totals.supercharging.toFixed(2)}</td>
              <td>{totals.tolls.toFixed(2)}</td>
              <td />
              <td>{totals.delivery.toFixed(2)}</td>
              <td>{totals.total.toFixed(2)}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div style={{ paddingTop: '16px' }}>
        <Typography variant="h3">
          Subscription Reservations
        </Typography>

        <table style={{ width: '100%' }}>
          <thead>
            <tr>
              <th>ID</th>
              <th>Car</th>
              <th>Start Time</th>
              <th>End Time</th>
              <th>Start Location</th>
              <th>Guest</th>
              <th>Period</th>
              <th>Mileage Tier</th>
              <th>Tier Cost</th>
              <th>Supercharging</th>
              <th>Tolls</th>
              <th>Owner Discount</th>
              <th>Delivery</th>
              <th>Total</th>
              <th>Notes</th>
            </tr>
          </thead>

          <tbody>
            {
              payout.reservations
                ?.filter((res) => res.subscription !== null)
                .map((res: IReservation) => (
                  <tr key={res.id}>
                    <td>
                      <Link to={`/owner/reservations/${res.id}/edit`}>{res.id}</Link>
                    </td>
                    <td>
                      <Link to={`/owner/cars/${res.car.id}/edit`}>{res.car.license_plate}</Link>
                    </td>
                    <td>
                      {moment(res.start_time).format('MM/DD/YY')}
                    </td>
                    <td>
                      {moment(res.end_time).format('MM/DD/YY')}
                    </td>
                    <td>
                      {res.custom_pickup_location || res.car.default_location.address}
                    </td>
                    <td>
                      {res.customer_name}
                    </td>
                    <td>
                      {
                        res.subscription.renewal_period === '6_months'
                          ? 'monthly'
                          : res.subscription.renewal_period
                      }
                    </td>
                    <td>
                      {
                        res.subscription.mileage_tier
                      }
                    </td>
                    <td>
                      {
                        res.subscription.tier_cost.toFixed(2)
                      }
                    </td>
                    <td>
                      {
                        res.charging_used.toFixed(2)
                      }
                    </td>
                    <td>
                      {
                        res.tolls_used.toFixed(2)
                      }
                    </td>
                    <td>
                      {
                        res.owner_discount ? `-${res.owner_discount.toFixed(2)}` : '0.00'
                      }
                    </td>
                    <td>
                      {
                        getOwnerDeliveryPayout(res)
                      }
                    </td>
                    <td>
                      {
                        res.owner_earnings.toFixed(2)
                      }
                    </td>
                    <td>
                      {
                        res.host_notes && (
                          <Input
                            type="textbox"
                            value={res.host_notes}
                            placeholder="Notes"
                            disabled
                            style={{ height: '150px' }}
                          />
                        )
                      }
                    </td>
                  </tr>
                ))
            }

            <tr>
              <td>Total</td>
              <td />
              <td />
              <td />
              <td />
              <td />
              <td />
              <td>{subTotals.tierCost.toFixed(2)}</td>
              <td>{subTotals.supercharging.toFixed(2)}</td>
              <td>{subTotals.tolls.toFixed(2)}</td>
              <td />
              <td>{subTotals.delivery.toFixed(2)}</td>
              <td>{subTotals.total.toFixed(2)}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div>
        <table style={{ width: '100%' }}>
          <thead>
            <tr>
              <th>Adjustments</th>
              <th>Quantity/Days Total</th>
              <th>Price Per Unit</th>
              <th>Subtotal</th>
            </tr>
          </thead>

          <tbody>
            {
              payout.expenses?.map((exp: IExpense) => (
                <tr key={exp.id}>
                  <td>
                    {exp.title}
                  </td>
                  <td>
                    {exp.quantity}
                  </td>
                  <td>
                    {(exp.amount * -1).toFixed(2)}
                  </td>
                  <td>
                    {(exp.total_cost * -1).toFixed(2)}
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>

      {
        payout.owner_cancellations?.length > 0 && (
          <div>
            <div>
              <Typography>
                {
                  `# Trip Cancellations: ${payout.owner_cancellations.length}`
                }
              </Typography>
            </div>

            <div>
              {
                payout.owner_cancellations.map((cancellation) => (
                  <div
                    key={cancellation.id}
                    className="d-flex flex-row gap-1"
                  >
                    <Typography>
                      {`Trip #${cancellation.reservation_id}`}
                    </Typography>

                    <Typography className="text-danger">
                      {`-$${(cancellation.penalty_amount / 100).toFixed(2)}`}
                    </Typography>
                  </div>
                ))
              }
            </div>
          </div>
        )
      }

      <div>
        <Typography>
          {`Net Earnings: $${payout.earnings_minus_expenses.toFixed(2)}`}
        </Typography>
      </div>

      <div style={{ width: '400px' }}>
        <Input
          type="textbox"
          value={payout.memo}
          placeholder="Notes"
          disabled
          style={{ height: '150px', resize: 'none' }}
        />
      </div>
    </div>
  );
};

export default Payout;
