import React, { useState } from 'react';

import { Typography } from '../Typography';
import { Filter } from '../Filter';

import { Price } from '../../../assets/icons';
import { Tabs } from '../Tabs';

const TABS = [
  { value: 'Pick Up', label: 'Pick Up' },
  { value: 'Delivery', label: 'Delivery' },
];

const Filters = () => {
  const [tab, setTab] = useState<string>(TABS[0].value);
  const [show, setShow] = useState<any>({});
  return (
    <div>
      <Typography variant="h1">Filters</Typography>
      <code>
        {'<Filter icon={<Price />} text="Default" selected={2} show onShow={() => {}} onClose={() => {}}>test</Filter>'}
      </code>
      <div className="d-flex gap-3 mt-3 flex-wrap">
        <Filter
          icon={<Price />}
          text="Default"
          show={show === 'Default'}
          onShow={() => setShow('Default')}
          onClose={() => setShow(null)}
        />
        <Filter
          icon={<Price />}
          text="Hover"
          className="hover"
          show={show === 'Hover'}
          onShow={() => setShow('Hover')}
          onClose={() => setShow(null)}
        />
        <Filter
          icon={<Price />}
          text="Active"
          className="active"
          show={show === 'Active'}
          onShow={() => setShow('Actvie')}
          onClose={() => setShow(null)}
        />
        <Filter
          icon={<Price />}
          text="Disabled"
          disabled
          show={show === 'Disabled'}
          onShow={() => setShow('Disabled')}
          onClose={() => setShow(null)}
        />
        <Filter
          icon={<Price />}
          text="Focus"
          className="focus"
          show={show === 'Focus'}
          onShow={() => setShow('Focus')}
          onClose={() => setShow(null)}
        />
        <Filter
          icon={<Price />}
          text="Selected"
          selected={2}
          show={show === 'Selected'}
          onShow={() => setShow('Selected')}
          onClose={() => setShow(null)}
        />
      </div>
      <Typography variant="h1">Tabs</Typography>
      <code>{'<Tabs options={[{ label: "L", value: "L" }]} value="L" onChange={() => {}} disabled />'}</code>
      <div className="d-flex gap-3 mt-3 flex-wrap">
        <Tabs options={TABS} value={tab} onChange={setTab} />
        <Tabs options={TABS} value={tab} onChange={setTab} className="hover" />
        <Tabs options={TABS} value={tab} onChange={setTab} className="active" />
        <Tabs options={TABS} value={tab} onChange={setTab} disabled />
        <Tabs options={TABS} value={tab} onChange={setTab} className="focus" />
      </div>
    </div>
  );
};

export { Filters };
