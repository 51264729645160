/* eslint-disable */
import cx from 'classnames';

const Settings = ({ className, ...props }: any) => {
  return (
    <svg className={cx(className)} width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M1.5 6.25V1.875C1.5 1.64294 1.59219 1.42038 1.75628 1.25628C1.92038 1.09219 2.14294 1 2.375 1H21.625C21.8571 1 22.0796 1.09219 22.2437 1.25628C22.4078 1.42038 22.5 1.64294 22.5 1.875V17.625C22.5 17.8571 22.4078 18.0796 22.2437 18.2437C22.0796 18.4078 21.8571 18.5 21.625 18.5H13.75M10 13.25C10 14.6307 8.88071 15.75 7.5 15.75C6.11929 15.75 5 14.6307 5 13.25C5 11.8693 6.11929 10.75 7.5 10.75C8.88071 10.75 10 11.8693 10 13.25ZM7.62878 17.6319C7.54501 17.6319 7.46061 17.6319 7.37872 17.6319L5.37507 18.75C4.59506 18.4877 3.87159 18.0806 3.24263 17.55L3.23513 15.3C3.19074 15.23 3.14886 15.1594 3.1101 15.0869L1.1177 13.9525C0.960767 13.1589 0.960767 12.3423 1.1177 11.5487L3.10822 10.4175C3.14886 10.3456 3.19074 10.2744 3.23325 10.2044L3.24326 7.95438C3.87165 7.42228 4.59494 7.01365 5.37507 6.75L7.37559 7.86812C7.45936 7.86812 7.54376 7.86812 7.62566 7.86812L9.62618 6.75C10.4062 7.0123 11.1297 7.41943 11.7586 7.95L11.7661 10.2C11.8105 10.27 11.8524 10.3406 11.8912 10.4131L13.8823 11.5469C14.0392 12.3404 14.0392 13.1571 13.8823 13.9506L11.8918 15.0819C11.8511 15.1537 11.8093 15.225 11.7667 15.295L11.7567 17.545C11.1288 18.0772 10.4059 18.486 9.62618 18.75L7.62878 17.6319Z" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export { Settings };
