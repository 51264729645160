import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Typography, Button, Link } from '../common';
import { TOKEN_STORAGE, TOKEN_STORAGE_TIMESTAMP } from '../../constants';
import { uiSelectors, profileActions } from '../../redux/slices';
import { ProfileForm } from './ProfileForm';

const RegistrationFlow = () => {
  const isMapFullSize = useSelector(uiSelectors.selectMapFullSize) as boolean;
  const referralCode = useSelector(uiSelectors.selectReferralCode) as string;
  const dispatch = useDispatch();

  const resetProfile = () => {
    dispatch(profileActions.clearProfile.base());
    localStorage.removeItem(TOKEN_STORAGE);
    localStorage.removeItem(TOKEN_STORAGE_TIMESTAMP);
  };

  return (
    <div className="mt-5">
      <Typography variant="h2" className="mb-2">Driver Information</Typography>
      <Typography variant="body2" className="mb-3">
        Enter the primary driver&apos;s information - additional drivers can be added at any time after booking.
      </Typography>
      <ProfileForm className="pb-5" referralCode={referralCode}>
        <Typography variant="body2" className="mt-3" style={{ opacity: 0.8 }}>
          <Link onClick={resetProfile}>Wrong phone number? Click here.</Link>
        </Typography>
        <div className="pb-4" />
        <div
          className="d-flex w-100 justify-content-center align-items-center"
          style={{ opacity: isMapFullSize ? 0 : 1 }}
        >
          <Button
            variant="primary"
            className="flex-grow-1 w-md-fit row-b2 m-0"
            type="submit"
          >
            Continue
          </Button>
        </div>
      </ProfileForm>
    </div>
  );
};

export { RegistrationFlow };
