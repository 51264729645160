import React from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import './styles.scss';

import { Button, Link } from '../../common';
import { locationsSelectors } from '../../../redux/slices';
import { Reset } from '../../../assets/icons';

interface IProps {
  onClose: () => void;
  onClear?: () => void;
  className?: string;
}

const FilterButton = ({ onClose, onClear, className }: IProps) => {
  const { isLoading, data: cars } = useSelector(locationsSelectors.selectCars) as any;

  return (
    <div className={cx('d-flex align-items-center justify-content-between flex-grow-1 button-container', className)}>
      <Button
        variant="primary"
        className="w-100 row-x w-md-fit button"
        style={{ alignSelf: 'flex-end' }}
        disabled={isLoading || cars.length === 0}
        onClick={onClose}
        sticky="sm"
      >
        {isLoading ? 'Loading ...' : ''}
        {!isLoading && cars.length === 1 ? 'Show 1 result' : ''}
        {!isLoading && cars.length !== 1 ? `Show ${cars.length} results` : ''}
      </Button>
      {
        onClear && (
          <Link
            className="align-items-center gap-2 d-none d-md-flex"
            onClick={onClear}
          >
            Clear
            <Reset className="ml-2" />
          </Link>
        )
      }
    </div>
  );
};

FilterButton.defaultProps = {
  onClear: undefined,
  className: undefined,
};

export { FilterButton };
