import React from 'react';
import cx from 'classnames';
import { ArrowLeft } from '../../../assets/icons';

import './styles.scss';
import { Typography } from '../Typography';
import { Placeholder } from '../Placeholder';

interface IProps {
  title?: string;
  onGoBack?: (e: any) => void;
  className?: string;
  children?: any;
  showPlaceholder?: boolean;
}

const PageTitle = ({ title, onGoBack, children, className, showPlaceholder }: IProps) => showPlaceholder ? (
  <Placeholder animation className={cx('page-title', className)}>
    <div className="d-flex align-items-center">
      {onGoBack && (
        <ArrowLeft className="back-btn" onClick={onGoBack} />
      )}
      <Placeholder className="m-0 h1" style={{ width: 100 }} />
    </div>
    {children}
  </Placeholder>
) : (
  <div className={cx('page-title', className)}>
    <div className="d-flex align-items-center">
      {onGoBack && (
        <ArrowLeft className="back-btn" onClick={onGoBack} />
      )}
      <Typography variant="h1" className="m-0">{title}</Typography>
    </div>
    {children}
  </div>
);

PageTitle.defaultProps = {
  title: undefined,
  onGoBack: undefined,
  className: undefined,
  children: undefined,
  showPlaceholder: undefined,
};

export { PageTitle };
