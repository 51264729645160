/* eslint-disable */
import cx from 'classnames';

const Charging = ({ className, ...props }: any) => {
  return (
    <svg className={cx(className)} width="19" height="26" viewBox="0 0 19 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M13.25 1L11.5 9.75L18.5 12.375L6.25 25.5L8 16.75L1 14.125L13.25 1Z" stroke="current" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export { Charging };



