// @ts-ignore
/* eslint-disable */

import React from 'react';
import cx from 'classnames';

const ArrowDown = ({ className, ...props }: any) => (
  <svg className={cx('svg-icon', className)} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g>
      <path d="M21.5422 7.8876L11.8922 17.5376L2.24219 7.8876L3.66719 6.4626L11.8922 14.7126L20.1172 6.4626L21.5422 7.8876Z" />
    </g>
  </svg>
);

export { ArrowDown };

