import { useEffect, useState } from 'react';
import { Icon } from '@mui/material';
import Modal from 'react-modal';
import { Button, InputField, Typography } from 'components/common';
import { useFormik } from 'formik';
import { IReservation } from 'types';
import { toast } from 'react-toastify';
import * as api from 'api';
import Dropzone from 'components/common/Dropzone';
import variables from 'assets/scss/variables.scss';

interface IProps {
  visible?: boolean;
  reservation: IReservation;
  onClose?: () => void;
  onSuccess?: (reservation: IReservation) => void;
}

const AddChargingRecord = ({
  visible = false,
  reservation,
  onClose,
  onSuccess,
}: IProps) => {
  const [showModal, setShowModal] = useState(visible);

  useEffect(() => {
    setShowModal(visible);
  }, [visible]);

  useEffect(() => {
    if (!showModal && onClose) {
      onClose();
    }
  }, [showModal]);

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {
      charging_records: [
        {
          location: '',
          start_timestamp: '',
          amount: 0,
        },
      ],
      charging_record_images: [],
    },
    validate: values => {
      const error = {} as any;

      if (values.charging_records.length === 0) {
        error.charging_records = 'At least one charging record is required';
      }

      values.charging_records.forEach((charging_record: any, index: number) => {
        if (!charging_record.location) {
          error.charging_records = error.charging_records || [];
          error.charging_records[index] = {
            ...error.charging_records[index],
            location: true,
          };
        }

        if (!charging_record.start_timestamp) {
          error.charging_records = error.charging_records || [];
          error.charging_records[index] = {
            ...error.charging_records[index],
            start_timestamp: true,
          };
        }

        if (!charging_record.amount || charging_record.amount <= 0) {
          error.charging_records = error.charging_records || [];
          error.charging_records[index] = {
            ...error.charging_records[index],
            amount: true,
          };
        }
      });

      if (values.charging_record_images.length === 0) {
        error.charging_record_images = 'At least one image is required';
      }

      return error;
    },
    onSubmit: async (values) => {
      const payload = {
        charging_records: values.charging_records,
        charging_record_images: values.charging_record_images,
      };

      try {
        const data = await api.updateOwnerReservation({ payload, reservationId: reservation!.id });
        // eslint-disable-next-line no-unused-expressions
        onSuccess && onSuccess(data);
        toast.success('Reservation updated successfully');
        resetForm();
        setShowModal(false);
      } catch (error: any) {
        toast.error(error.response.data.error);
      }
    },
  });

  return (
    <Modal
      ariaHideApp={false}
      isOpen={showModal}
      style={{
        overlay: {
          zIndex: 1000,
        },
        content: {
          zIndex: 1000,
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          padding: '29px',
          maxWidth: '90%',
          minWidth: '300px',
          minHeight: '500px',
          maxHeight: '90%',
        },
      }}
      onRequestClose={() => {
        resetForm();
        setShowModal(false);
        // eslint-disable-next-line no-unused-expressions
        onClose && onClose();
      }}
    >
      <div className="d-flex justify-content-end">
        <Button
          onClick={() => {
            resetForm();
            setShowModal(false);
            // eslint-disable-next-line no-unused-expressions
            onClose && onClose();
          }}
          style={{ width: '25px', height: '25px', padding: 0 }}
        >
          X
        </Button>
      </div>

      <div className="d-flex flex-column w-100 align-items-center">
        <Typography variant="h1">
          Add Charging Records
        </Typography>
      </div>

      <div style={{ height: '100%', overflowY: 'scroll' }}>
        <div style={{ height: '80%' }}>
          <div>
            {values.charging_records.length > 0 && values.charging_records.map((chargingRecord: any, index: number) => (
              <div
                className="d-flex flex-row justify-content-space-between align-items-center gap-2"
                style={{ overflowX: 'scroll' }}
                key={index}
              >
                <div>
                  <InputField
                    className="mb-2"
                    label="Charging Plaza Name"
                    type="text"
                    name={`charging_records.${index}.location`}
                    value={chargingRecord.location}
                    error={
                      touched.charging_records
                      && touched.charging_records[index]?.location
                      && errors.charging_records
                      && (errors.charging_records[index] as any)?.location
                    }
                    onChange={handleChange}
                    required
                    style={{ width: '200px' }}
                  />
                </div>

                <div>
                  <InputField
                    className="mb-2"
                    label="Start Time"
                    type="datetime-local"
                    name={`charging_records.${index}.start_timestamp`}
                    value={chargingRecord.start_timestamp}
                    error={
                      touched.charging_records
                      && touched.charging_records[index]?.start_timestamp
                      && errors.charging_records
                      && (errors.charging_records[index] as any)?.start_timestamp
                    }
                    onChange={handleChange}
                    required
                  />
                </div>

                <div>
                  <InputField
                    className="mb-2"
                    label="Amount"
                    type="number"
                    name={`charging_records.${index}.amount`}
                    value={chargingRecord.amount}
                    error={
                      touched.charging_records
                      && touched.charging_records[index]?.amount
                      && errors.charging_records
                      && (errors.charging_records[index] as any)?.amount
                    }
                    onChange={handleChange}
                    required
                    style={{ width: '125px' }}
                  />
                </div>

                <div>
                  <Icon
                    onClick={() => {
                      const newChargingRecord = {
                        location: '',
                        start_timestamp: '',
                        amount: 0,
                      };
                      setFieldValue(
                        'charging_records',
                        [
                          ...values.charging_records.slice(0, index + 1),
                          newChargingRecord,
                          ...values.charging_records.slice(index + 1),
                        ],
                      );
                    }}
                  >
                    add
                  </Icon>
                </div>
                {
                  values.charging_records.length > 1 && (
                    <div>
                      <Icon
                        onClick={() => {
                          if (values.charging_records.length > 1) {
                            setFieldValue(
                              'charging_records',
                              [...values.charging_records.slice(0, index), ...values.charging_records.slice(index + 1)],
                            );
                          }
                        }}
                      >
                        remove
                      </Icon>
                    </div>
                  )
                }
              </div>
            ))}
          </div>
        </div>

        <div>
          <Dropzone
            accept={{
              'image/png': ['.png'],
              'image/jpeg': ['.jpg', '.jpeg'],
              'application/pdf': ['.pdf'],
            }}
            onImagesChanged={(files: any[]) => {
              setFieldValue('charging_record_images', files);
            }}
            defaultFiles={values.charging_record_images || []}
            convertImgToJpeg
          />

          {
            errors.charging_record_images && (
              <div
                className="pt-2"
                style={{
                  whiteSpace: 'normal',
                  textAlign: 'center',
                  color: variables.red1,
                }}
              >
                {errors.charging_record_images}
              </div>
            )
          }
        </div>

        <div className="d-flex w-100 justify-content-space-around mt-2">
          <Button
            onClick={handleSubmit}
          >
            Set New Charging Records
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AddChargingRecord;
