import React, { useState } from 'react';
import cx from 'classnames';
import moment from 'moment';
import { enGB } from 'date-fns/locale';
import { DateRange, Range } from 'react-date-range';

import 'react-date-range/dist/styles.css'; // main styles
// import 'react-date-range/dist/theme/default.css'; // main styles
import './styles.scss'; // custom theme

import { TIME_PERIODS, TIME_MINUTES, DATES_FORMAT } from '../../../constants';
import { useDeepEffect } from '../../../hooks';
import { IDates } from '../../../types';

import { Filter } from '../Filter';
import { Typography } from '../Typography';
import { PageTitle } from '../PageTitle';
import { InputField } from '../InputField';

interface IProps {
  dates: IDates;
  onChange: (dates: IDates, sendMixpanel: boolean) => void;
  show: boolean;
  onShow: (e: any) => void;
  onClose: (e: any) => void;
  gmtOffset: string;
  format?: string;
  className?: string;
  children?: any;
  [k: string]: any;
}

const TIME_MINUTES_NUMBERS = TIME_MINUTES.map(m => Number(m));

const formatDate = (date: Date | string) => {
  date = new Date(date);
  date.setSeconds(0);
  date.setMilliseconds(0);
  const minutes = date.getMinutes() as number;
  if (!TIME_MINUTES_NUMBERS.includes(minutes)) {
    // @ts-ignore
    const arr = [].concat(TIME_MINUTES_NUMBERS)
      .map((m, ind) => ({ m, ind, d: m - minutes }))
      .sort((a, b) => a.d - b.d)
      .filter(i => i.d > 0);
    if (arr[0]?.ind) {
      date.setMinutes(TIME_MINUTES_NUMBERS[arr[0].ind]);
    } else {
      date.setMinutes(TIME_MINUTES_NUMBERS[0]);
      date.setHours(date.getHours() + 1);
    }
  }
  return date;
};

const DatePicker = ({
  dates,
  onChange,
  format,
  className,
  children,
  show,
  onShow,
  onClose,
  gmtOffset,
  ...props
}: IProps) => {
  const [errMessage, setErrMessage] = useState('');
  const [range, setRange] = useState<IDates>({
    startDate: moment().add(1, 'days').set({ hour: 10, minute: 0, second: 0, millisecond: 0 }).toDate(),
    endDate: moment().add(2, 'days').set({ hour: 10, minute: 0, second: 0, millisecond: 0 }).toDate(),
    gmtOffset,
  });

  const handleChange = (_dates: IDates) => {
    const newDates = { ..._dates };
    newDates.startDate = formatDate(newDates.startDate);
    newDates.endDate = formatDate(newDates.endDate);
    newDates.gmtOffset = gmtOffset;
    if (newDates.endDate.getTime() - newDates.startDate.getTime() < 3600000) {
      setErrMessage('Min 1 hour booking');
    } else {
      setErrMessage('');
    }

    onChange(newDates, true);
    setRange(newDates);
  };

  useDeepEffect(() => {
    handleChange(dates);
  }, [dates]);

  const handleTimeChange = (key: string) => ({ target }: any) => {
    // @ts-ignore
    const newDate = new Date(dates[key]);
    const values = /(\d+):(\d+)(.+)/.exec(target.value) as any;
    if (values[1] === '12') {
      newDate.setHours(parseInt(values[1], 10));
    } else {
      newDate.setHours((values[3].trim().toLowerCase() === 'pm')
        ? 12 + parseInt(values[1], 10)
        : parseInt(values[1], 10));
    }
    newDate.setMinutes(values[2]);
    handleChange({ ...dates, [key]: newDate });
  };

  const handleRangeChange = (ranges: any) => {
    const newDates = { ...ranges.range1 };
    newDates.startDate.setHours(new Date(range.startDate).getHours());
    newDates.startDate.setMinutes(new Date(range.startDate).getMinutes());
    newDates.endDate.setHours(new Date(range.endDate).getHours());
    newDates.endDate.setMinutes(new Date(range.endDate).getMinutes());
    handleChange(newDates);
  };

  return (
    <Filter
      className={cx('pb-2 pt-4 position-relative', className)}
      dropdownClassName="filter-container"
      text={(
        <div className="input-field">
          <div className="label active">Dates</div>
          <Typography variant="body2" inline>
            {`${moment(range.startDate).format(format)} - ${moment(range.endDate).format(format)}`}
          </Typography>
        </div>
      )}
      showDropdownIcon={false}
      show={show}
      onShow={onShow}
      onClose={onClose}
      {...props}
    >
      <div className="d-flex flex-column w-100">
        <PageTitle className="d-md-none mb-2" title="Dates" onGoBack={onClose} />
        <DateRange
          // @ts-ignore
          onChange={handleRangeChange}
          ranges={[range as Range]}
          months={1}
          locale={enGB}
          minDate={new Date()}
          maxDate={moment().add(12, 'months').toDate()}
          direction="vertical"
          scroll={{ enabled: true }}
          showMonthAndYearPickers={false}
          navigatorRenderer={(): any => null}
          showMonthArrow={false}
          showDateDisplay={false}
        />
        <div className="d-flex gap-3 py-4">
          <InputField
            label={moment(range.startDate).format(format)}
            value={moment(range.startDate).format('hh:mm A')}
            type="select"
            onChange={handleTimeChange('startDate')}
          >
            {TIME_PERIODS.map(period => (
              <option key={period} value={period}>{period}</option>
            ))}
          </InputField>
          <InputField
            error={errMessage}
            label={moment(range.endDate).format(format)}
            value={moment(range.endDate).format('hh:mm A')}
            type="select"
            onChange={handleTimeChange('endDate')}
          >
            {TIME_PERIODS.map(period => (
              <option key={period} value={period}>{period}</option>
            ))}
          </InputField>
        </div>
        {children}
      </div>
    </Filter>
  );
};

DatePicker.defaultProps = {
  className: undefined,
  children: undefined,
  format: DATES_FORMAT,
};

export { DatePicker };
