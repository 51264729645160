import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import variables from '../assets/scss/variables.scss';
import { uiActions, uiSelectors } from '../redux/slices';
import { DEFAULT_MAP_HEIGHT } from '../constants';

const MIN_HEIGHT = 88;
const SCROLL_UP_REPEAT = 50;
let scrollingToTopTimes = 0;

const useMapHeight = () => {
  const dispatch = useDispatch();
  const [height, setHeight] = useState<any>(DEFAULT_MAP_HEIGHT);
  const storeHeight = useSelector(state => uiSelectors.selectScrollableData(state, 'map'));

  // const handleResize = () => {
  //   if (window.innerWidth > Number(variables.sizemd)) {
  //     setHeight('100vh');
  //   } else {
  //     setHeight(typeof height === 'number' && height < DEFAULT_MAP_HEIGHT ? height : DEFAULT_MAP_HEIGHT);
  //   }
  // };

  useEffect(() => {
    dispatch(uiActions.setScrollableData.base({ data: height, id: 'map' }));
  }, [height]);

  useEffect(() => {
    if (storeHeight !== height) {
      if (window.innerWidth > Number(variables.sizemd)) {
        setHeight('100vh');
      } else {
        setHeight(storeHeight);
      }
    }
  }, [storeHeight]);

  const handleWheel = (e: any) => {
    return;
    const isMapBox = (
      e?.path?.find?.((d: any) => d?.classList?.contains('map-box'))
      || e?.currentTarget?.classList?.contains('map-box')
    );
    if (window.innerWidth > Number(variables.sizemd)) {
      // setHeight('100vh');
    } else if (!isMapBox) {
      const scrollingToTop = e.wheelDelta ? e.wheelDelta > 0 : e.deltaY < 0;
      const maxHeight = document.documentElement.clientHeight - MIN_HEIGHT;
      let newHeight = height < DEFAULT_MAP_HEIGHT ? height : DEFAULT_MAP_HEIGHT;
      // @ts-ignore
      if (scrollingToTop) {
        // Scroll up
        // @ts-ignore IF element is on the top
        if (document.body.getBoundingClientRect().y === 0) {
          if (scrollingToTopTimes > SCROLL_UP_REPEAT) {
            newHeight = height < DEFAULT_MAP_HEIGHT ? height : maxHeight;
          } else {
            scrollingToTopTimes++;
          }
        }
      }
      if (height !== newHeight) {
        setHeight(newHeight);
      }
    }
  };

  const pStart = { x: 0, y: 0 };
  const pStop = { x: 0, y: 0 };

  const swipeCheck = (e: any) => {
    return;
    const isMapBox = (
      e?.path?.find?.((d: any) => d?.classList?.contains('map-box'))
      || e?.currentTarget?.classList?.contains('map-box')
    );
    if (window.innerWidth > Number(variables.sizemd)) {
      setHeight('100vh');
    } else if (!isMapBox) {
      const changeY = pStart.y - pStop.y;
      const maxHeight = document.documentElement.clientHeight - MIN_HEIGHT;
      let newHeight = height < DEFAULT_MAP_HEIGHT ? height : DEFAULT_MAP_HEIGHT;
      if (changeY < -50 && document.body.getBoundingClientRect().y === 0) {
        newHeight = height < DEFAULT_MAP_HEIGHT ? height : maxHeight;
      }
      if (height !== newHeight) {
        setHeight(newHeight);
      }
    }
  };

  const swipeStart = (e: any) => {
    if (typeof e.targetTouches !== 'undefined') {
      const touch = e.targetTouches[0];
      pStart.x = touch.screenX;
      pStart.y = touch.screenY;
    } else {
      pStart.x = e.screenX;
      pStart.y = e.screenY;
    }
  };

  const swipeEnd = (e: any) => {
    if (typeof e.changedTouches !== 'undefined') {
      const touch = e.changedTouches[0];
      pStop.x = touch.screenX;
      pStop.y = touch.screenY;
    } else {
      pStop.x = e.screenX;
      pStop.y = e.screenY;
    }
    swipeCheck(e);
  };

  useEffect(() => {
    window.addEventListener('touchstart', swipeStart);
    window.addEventListener('touchend', swipeEnd);
    window.addEventListener('wheel', handleWheel);
    return () => {
      window.removeEventListener('touchstart', swipeStart);
      window.removeEventListener('touchend', swipeEnd);
      window.removeEventListener('wheel', handleWheel);
    };
  }, [height]);

  // useEffect(() => {
  //   window.addEventListener('resize', handleResize);
  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);

  return height;
};

export { useMapHeight, DEFAULT_MAP_HEIGHT };
