import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  parsePhoneNumber,
} from 'react-phone-number-input';
import { ToastContainer, toast } from 'react-toastify';

import { LoadingSpinner } from 'components/LoadingSpinner';
import { Button, InputField, InputPhone, Typography } from 'components/common';
import { ownerActions, ownerSelectors } from 'redux/slices';
import { IOwner } from 'types';
import { VALIDATIONS } from '../../../constants';

const Profile = () => {
  const dispatch = useDispatch();
  const owner = useSelector(ownerSelectors.selectOwner) as IOwner;
  const ownerUpdateLoading = useSelector(ownerSelectors.selectOwnerUpdateLoading) as boolean;
  const ownerUpdateError = useSelector(ownerSelectors.selectOwnerUpdateError) as string;
  const ownerUpdateSuccess = useSelector(ownerSelectors.selectOwnerUpdateSuccess) as boolean;

  useEffect(() => {
    if (ownerUpdateSuccess) {
      toast.success('Profile updated successfully!');
    }
  }, [ownerUpdateSuccess]);

  const formikForm = useFormik({
    initialValues: {
      firstname: owner?.firstname,
      lastname: owner?.lastname,
      email: owner?.email,
      phone: owner?.phone,
    },
    validate: vals => {
      const error = {} as any;
      if (String(vals.firstname).length < VALIDATIONS.FIRSTNAME_LENGTH) {
        error.firstname = 'Required';
      }
      if (String(vals.lastname).length < VALIDATIONS.LASTNAME_LENGTH) {
        error.lastname = 'Required';
      }
      if (!VALIDATIONS.EMAIL_REGEXP.test(vals.email)) {
        error.email = 'Invalid email';
      }
      if (!vals.phone || vals.phone.length < 2) {
        error.phone = true;
      }
      if (vals.phone && !isValidPhoneNumber(vals.phone)) {
        error.phone = 'Invalid phone number';
      }

      return error;
    },
    onSubmit: vals => {
      let params = { ...vals };

      if (vals.phone) {
        const phone = parsePhoneNumber(vals.phone) as any;
        formikForm.setFieldValue('phone', phone.number);

        params = {
          ...params,
          phone: phone.number,
        };
      }

      dispatch(ownerActions.updateOwner.base(params));
    },
    enableReinitialize: true,
  });

  if (!owner) return null;

  const handlePhoneChange = (phoneNumber: string) => {
    if (phoneNumber && isValidPhoneNumber(phoneNumber)) {
      formikForm.setFieldValue('phone', formatPhoneNumberIntl(phoneNumber));
    } else {
      formikForm.setFieldValue('phone', phoneNumber);
    }
  };

  return (
    <div
      className="d-flex flex-column align-items-center"
      style={{ padding: '29px' }}
    >
      <ToastContainer autoClose={2500} />

      <div
        className="d-flex flex-column align-items-center"
        style={{ maxWidth: '560px' }}
      >

        {
          ownerUpdateLoading && <LoadingSpinner absolute />
        }

        <div>
          <Typography variant="h2">
            {
              `${owner.firstname} ${owner.lastname}`
            }
          </Typography>

          <div>
            {`Eon Credits: $${owner.credits ? owner.credits / 100 : 0}`}
          </div>

          <Link
            className="w-100"
            to="/owner/referrals"
          >
            Refer a Friend to Earn Credits
          </Link>
        </div>

        <div
          style={{ padding: '29px', paddingTop: '10px' }}
          className="d-flex flex-column gap-3 align-items-center w-100"
        >
          <InputField
            label="First name"
            type="text"
            name="firstname"
            value={formikForm.values.firstname || ''}
            error={formikForm.touched.firstname && formikForm.errors.firstname}
            onChange={formikForm.handleChange}
            required
          />

          <InputField
            label="Last name"
            type="text"
            name="lastname"
            value={formikForm.values.lastname || ''}
            error={formikForm.touched.lastname && formikForm.errors.lastname}
            onChange={formikForm.handleChange}
            required
          />

          <InputField
            label="Email"
            type="text"
            name="email"
            value={formikForm.values.email || ''}
            error={formikForm.touched.email && formikForm.errors.email}
            onChange={formikForm.handleChange}
            required
          />

          <InputPhone
            value={formikForm.values.phone || ''}
            onChange={(val) => handlePhoneChange(val!)}
            style={{ width: '100%' }}
            error={formikForm.errors.phone}
          />

          <Link
            to="/owner/forgot-password"
          >
            Reset password?
          </Link>

          <Button
            variant="primary"
            type="submit"
            onClick={() => {
              dispatch(ownerActions.clearOwnerUpdate.base());
              formikForm.handleSubmit();
            }}
          >
            Update Information
          </Button>

          {
            ownerUpdateError && (
              <div
                className="error-msg pt-2"
                style={{
                  whiteSpace: 'normal',
                  textAlign: 'center',
                  color: 'red',
                }}
              >
                {ownerUpdateError}
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default Profile;
