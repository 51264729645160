import { ArrowRight } from 'assets/icons';
import { Card, Typography } from 'components/common';

const UsedTeslasPrompt = ({
  onClick,
}: {
  onClick: (isTeslaPro: boolean) => void;
}) => {
  return (
    <div>
      <Typography
        variant="h1"
        style={{ lineHeight: '40px' }}
      >
        Have you ever driven a Tesla before
      </Typography>

      <div className="d-flex gap-3 flex-column mt-3">
        <Card
          className="d-flex justify-content-space-between"
          onClick={() => onClick(false)}
        >
          <Typography variant="body2" className="text-secondary mb-0">
            First time
          </Typography>

          <ArrowRight />
        </Card>

        <Card
          className="d-flex justify-content-space-between"
          onClick={() => onClick(false)}
        >
          <Typography variant="body2" className="text-secondary mb-0">
            Once or twice
          </Typography>

          <ArrowRight />
        </Card>

        <Card
          className="d-flex justify-content-space-between"
          onClick={() => onClick(true)}
        >
          <Typography variant="body2" className="text-secondary mb-0">
            Many times
          </Typography>

          <ArrowRight />
        </Card>
      </div>
    </div>
  );
};

export default UsedTeslasPrompt;
