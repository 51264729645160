import './styles.scss';

const Banner = ({
  visible,
  title,
  type,
  body,
}: {
  visible: boolean;
  title: string;
  type: 'error' | 'success';
  body: string;
}) => {
  if (!visible) return null;

  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center banner-container"
    // style={{ width: '21.375rem', height: '21.375rem' }}
    >
      <div className="banner-title">
        {title}
      </div>

      <div>
        <img
          src={type === 'error' ? '/images/red-x-circle.svg' : '/images/green-check-circle.svg'}
          alt={type}
          style={{
            width: '8.5rem',
            height: '8.5rem',
          }}
        />
      </div>

      <div className="banner-body">
        {body}
      </div>
    </div>
  );
};

export default Banner;
