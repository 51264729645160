import React, { useEffect, useRef } from 'react';
import cx from 'classnames';
import './styles.scss';

interface IProps {
  steps: any[];
  className?: string;
  currentStep?: number;
  barHeight?: number;
}

const LinearProgressBar = ({
  className,
  steps,
  currentStep = 0,
  barHeight,
}: IProps) => {
  const progress = (currentStep / (steps.length - 1)) * 100;

  const calculatePercentage = (index: number, length: number): number => {
    if (length === 1) {
      return 100;
    }

    const percentage = (index / (length - 1)) * 100;

    return percentage;
  };

  return (
    <div className={cx('checkin-linear-progress-bar', className)}>
      <div className="checkin-linear-progress-bar-track">
        <div
          className="checkin-linear-progress-bar-fill"
          style={{
            width: '100%',
            backgroundColor: '#D9D9DF',
            height: barHeight && `${barHeight}px`
          }}
        />
        <div
          className="checkin-linear-progress-bar-fill"
          style={{ width: `${Math.min(progress, 100)}%`, height: barHeight && `${barHeight}px` }}
        />
        {
          steps?.map((opt, idx) => (
            <div
              key={idx}
              className={cx(
                'checkin-linear-progress-bar-icon',
                {
                  'checkin-linear-progress-bar-icon--active': currentStep === idx,
                  'checkin-linear-progress-bar-icon--completed': currentStep > idx,
                  'checkin-linear-progress-bar-icon--incompleted': currentStep < idx,
                },
              )}
              style={{
                left: `${calculatePercentage(idx, steps.length)}%`,
              }}
            >
              {
                React.cloneElement(opt.icon, {
                  className: cx(
                    'checkin-linear-progress-bar-icon-svg',
                    {
                      'checkin-linear-progress-bar-icon-svg--active': currentStep === idx,
                      'checkin-linear-progress-bar-icon-svg--completed': currentStep > idx,
                      'checkin-linear-progress-bar-icon-svg--incompleted': currentStep < idx,
                    },
                  ),
                  stroke: currentStep >= idx ? '#1A1A1F' : '#A2A2B2',
                })
              }
            </div>
          ))
        }
      </div>
    </div>
  );
};

LinearProgressBar.defaultProps = {
  className: undefined,
  currentStep: 0,
};

export { LinearProgressBar };
