import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import moment from 'moment';
import axios from 'axios';

import { profileActions, profileSelectors } from '../../redux/slices';

import { IProfile, APIStatus } from '../../types';
import { InputField, InputPhone, Typography, Button } from '../common';
import { AGENCY_TOKEN_STORAGE, DAY_MILLISECONDS, VALIDATIONS } from '../../constants';

interface IProps {
  children?: any;
  className?: string;
  disabled?: boolean;
  onSuccess: (customerId: string, agentId?: string) => void;
}

const CustomerAgencyForm = ({ children, className, disabled, onSuccess }: IProps) => {
  const profile = useSelector(profileSelectors.selectProfile) as IProfile;
  const isProfileNewCustomer = useSelector(profileSelectors.selectIsNewCustomer) as boolean;
  const [submitFormStatus, setSubmitFormStatus] = useState<APIStatus>({
    loading: false,
    success: false,
    error: null,
  });

  const { values, errors, touched, handleSubmit, handleChange, resetForm, setFieldValue } = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
      dob: '',
      phone: '',
      agentId: '',
    },
    validate: value => {
      const error = {} as any;
      if (String(value.firstname).length < VALIDATIONS.FIRSTNAME_LENGTH) {
        error.firstname = true;
      }
      if (String(value.lastname).length < VALIDATIONS.LASTNAME_LENGTH) {
        error.lastname = true;
      }
      if (!VALIDATIONS.EMAIL_REGEXP.test(value.email)) {
        error.email = true;
      }
      if (Math.floor(moment().diff(value.dob) / DAY_MILLISECONDS / 365) < VALIDATIONS.MIN_AGE) {
        error.dob = 'You must be at least 21 years old';
      }
      return error;
    },
    onSubmit: data => {
      if (disabled) return;

      const payload = {
        ...data,
      };

      const api = `${process.env.REACT_APP_API_ENDPOINT}/api/v1`;

      const request = axios.create({
        headers: {
          Authorization: `Bearer ${localStorage.getItem(AGENCY_TOKEN_STORAGE)}`,
        },
      });

      setSubmitFormStatus({ loading: true, success: false, error: null });

      request
        .post(`${api}/agencies/customer/login`, payload)
        .then(res => res.data)
        .then((data) => {
          setSubmitFormStatus({ loading: false, success: true, error: null });
          onSuccess(data.customer.id, values.agentId);
        })
        .catch(error => {
          setSubmitFormStatus({ loading: false, success: false, error });
        });
    },
  });

  useEffect(() => {
    if (profile && (profile.firstname === 'MobileApp' || isProfileNewCustomer)) {
      resetForm();
      setFieldValue('phone', profile.phone);
    }
    if (profile && profile.firstname !== 'MobileApp' && !isProfileNewCustomer) {
      resetForm();
      setFieldValue('firstname', profile.firstname);
      setFieldValue('lastname', profile.lastname);
      setFieldValue('email', profile.email);
      setFieldValue('dob', profile.dob);
      setFieldValue('phone', profile.phone);
    }
  }, [profile]);

  return (
    <form onSubmit={handleSubmit} className={className}>
      <InputField
        className="mb-2"
        label="First name"
        type="text"
        name="firstname"
        value={values.firstname}
        error={touched.firstname && errors.firstname}
        onChange={handleChange}
        disabled={disabled}
        required
      />
      <InputField
        className="mb-2"
        label="Last name"
        type="text"
        name="lastname"
        value={values.lastname}
        error={touched.lastname && errors.lastname}
        onChange={handleChange}
        disabled={disabled}
        required
      />
      <InputField
        className="mb-2"
        label="Email address"
        type="email"
        name="email"
        value={values.email}
        error={touched.email && errors.email}
        onChange={handleChange}
        disabled={disabled}
        required
      />
      <Typography variant="body3" className="mb-2">Phone number</Typography>
      <InputPhone
        value={values.phone}
        onChange={(val) => {
          formatPhoneNumberIntl(val);
          setFieldValue('phone', val);
        }}
        style={{ width: '100%', marginBottom: '0.5rem' }}
        error={touched.phone && errors.phone}
      />
      <InputField
        className="mb-2"
        label="Date of Birth"
        type="date"
        name="dob"
        value={values.dob}
        error={touched.dob && errors.dob}
        onChange={handleChange}
        disabled={disabled}
        required
      />
      <InputField
        className="mb-2"
        label="Agent ID"
        type="text"
        name="agentId"
        value={values.agentId}
        error={touched.agentId && errors.agentId}
        onChange={handleChange}
        disabled={disabled}
        required
      />
      <div
        className="d-flex w-100 justify-content-center align-items-center"
      >
        <Button
          variant="primary"
          className="flex-grow-1 w-md-fit row-b2 m-0"
          type="submit"
        >
          Continue
        </Button>
      </div>
      {children}
    </form>
  );
};

CustomerAgencyForm.defaultProps = {
  children: undefined,
  className: undefined,
  disabled: false,
};

export { CustomerAgencyForm };
