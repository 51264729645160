// @ts-ignore
/* eslint-disable */

import React from 'react';
import cx from 'classnames';

const Range = ({ className, ...props }: any) => (
  <svg className={cx('svg-icon', className)} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g>
      <path d="M8.34531 21.3999C8.07865 21.3999 7.86198 21.3122 7.69531 21.1369C7.52865 20.9622 7.44531 20.7499 7.44531 20.4999V5.32485C7.44531 5.05819 7.52865 4.84152 7.69531 4.67485C7.86198 4.50819 8.07865 4.42485 8.34531 4.42485H10.1453V2.59985H13.8453V4.42485H15.6453C15.912 4.42485 16.1286 4.50819 16.2953 4.67485C16.462 4.84152 16.5453 5.05819 16.5453 5.32485V20.4999C16.5453 20.7499 16.462 20.9622 16.2953 21.1369C16.1286 21.3122 15.912 21.3999 15.6453 21.3999H8.34531ZM8.94531 11.8999H15.0453V5.89985H8.94531V11.8999Z" />
    </g>
  </svg>
);

export { Range };

