import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

import { IProfile, APIStatus, ManualInsuranceStatusEnum } from 'types';
import { profileSelectors, profileActions } from 'redux/slices';
import { LoginFlow } from 'components/Authentication';
import Navbar from 'components/Navbar';
import { Button, Typography } from 'components/common';
import { LoadingSpinner } from 'components/LoadingSpinner';
import * as api from 'api';

interface ICreateSession {
  ignitionToken: string;
  ignitionUri: string;
}

interface IInsuranceStatus {
  is_insurance_connected: boolean;
  is_insurance_valid: boolean;
}

enum InsuranceStatusEnum {
  Complete = 'complete',
  Exit = 'exit',
  Error = 'error',
}

enum InsuranceResultEnum {
  Link = 'link',
  Basic = 'basic',
  Manual = 'manual',
}

interface IInsuranceEvent {
  status: InsuranceStatusEnum;
  authCode: string;
  client: string;
  result: InsuranceResultEnum;
  step: string;
  message: string;
}

const Insurance = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tripId = queryParams.get('reservation_id');

  const [showInsuranceIFrame, setShowInsuranceIFrame] = useState(false);
  const [insuranceIFrameSrc, setInsuranceIFrameSrc] = useState('');
  const profile = useSelector(profileSelectors.selectProfile) as IProfile;
  const profileLoading = useSelector(profileSelectors.selectProfileLoading);
  const [insuranceStatus, setInsuranceStatus] = useState<IInsuranceStatus>();
  const [getInsuranceStatus, setGetInsuranceStatus] = useState<APIStatus>({
    loading: false,
    success: false,
    error: null,
  });
  const [createSessionStatus, setCreateSessionStatus] = useState<APIStatus>({
    loading: false,
    success: false,
    error: null,
  });
  const [connectInsuranceStatus, setConnectInsuranceStatus] = useState<APIStatus>({
    loading: false,
    success: false,
    error: null,
  });
  const [validateInsuranceStatus, setValidateInsuranceStatus] = useState<APIStatus>({
    loading: false,
    success: false,
    error: null,
  });
  const isLoggedIn = !!profile?.id;

  const fetchInsuranceStatus = async () => {
    setGetInsuranceStatus({ loading: true, success: false, error: null });

    try {
      const { data }: { data: IInsuranceStatus } = await api.getInsuranceStatus(Number(tripId));
      setGetInsuranceStatus({ loading: false, success: true, error: null });
      setInsuranceStatus(data);
    } catch (error: any) {
      setGetInsuranceStatus({
        loading: false,
        success: false,
        error: { code: 500, message: error },
      });
      // eslint-disable-next-line no-console
      console.error('Error fetching data:', error);
    }
  };

  const handleInsuranceValidation = () => {
    const validateInsurance = async () => {
      setValidateInsuranceStatus({ loading: true, success: false, error: null });

      try {
        const {
          data: updatedProfile,
          message,
        }: { data: IProfile, message: string } = await api.validateInsuranceAccount(Number(tripId));
        setValidateInsuranceStatus({ loading: false, success: true, error: null });
        toast.success(message || 'Your insurance policy has been verified');
        dispatch(profileActions.setProfile.base(updatedProfile));
        fetchInsuranceStatus();
      } catch (error: any) {
        setValidateInsuranceStatus({
          loading: false,
          success: false,
          error: { code: 500, message: error.response.data.errors.join(' ') },
        });
      }
    };

    validateInsurance();
  };

  useEffect(() => {
    if (tripId) {
      fetchInsuranceStatus();
    }
  }, [tripId]);

  useEffect(() => {
    if (!connectInsuranceStatus.success) return;

    handleInsuranceValidation();
  }, [connectInsuranceStatus.success]);

  const handleConnectInsuranceAccount = (data: IInsuranceEvent) => {
    const createInsuranceSession = async () => {
      setConnectInsuranceStatus({ loading: true, success: false, error: null });

      try {
        const { data: updatedProfile }: { data: IProfile } = await api.connectInsuranceAccount(data.authCode);
        setConnectInsuranceStatus({ loading: false, success: true, error: null });
        dispatch(profileActions.setProfile.base(updatedProfile));
      } catch (error: any) {
        setConnectInsuranceStatus({
          loading: false,
          success: false,
          error: { code: 500, message: error },
        });
        // eslint-disable-next-line no-console
        console.error('Error fetching data:', error);
      }
    };

    createInsuranceSession();
  };

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (!event.origin.includes('axle.insure')) return;

      const data = event.data as IInsuranceEvent;

      console.log(data);

      setShowInsuranceIFrame(false);

      if (data.status === InsuranceStatusEnum.Error) {
        toast.error(data.message);
        return;
      }

      if (data.status === InsuranceStatusEnum.Exit) {
        toast.error('You need to upload your insurance information before you can check in for any trips');
        return;
      }

      if ([InsuranceResultEnum.Manual, InsuranceResultEnum.Link].includes(data.result)) {
        handleConnectInsuranceAccount(data);
      }

      // TODO: no clue what to do about InsuranceResult.Basic
    };

    window.addEventListener('message', handleMessage);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const handleInsuranceInitiation = () => {
    const createInsuranceSession = async () => {
      setCreateSessionStatus({ loading: true, success: false, error: null });

      try {
        const { data }: { data: ICreateSession } = await api.createInsuranceSession();
        setCreateSessionStatus({ loading: false, success: true, error: null });

        const url = new URL(data.ignitionUri);
        url.searchParams.append('origin', window.location.origin);
        const uriWithQueryString = url.toString();

        setInsuranceIFrameSrc(uriWithQueryString);
        setShowInsuranceIFrame(true);
      } catch (error: any) {
        setCreateSessionStatus({
          loading: false,
          success: false,
          error: { code: 500, message: error },
        });
        // eslint-disable-next-line no-console
        console.error('Error fetching data:', error);
      }
    };

    createInsuranceSession();
  };

  if (profileLoading) {
    return (
      <LoadingSpinner absolute />
    );
  }

  if (!isLoggedIn) {
    return (
      <div>
        <Navbar />

        <div
          className="d-flex justify-content-center"
        >
          <div
            className="d-flex justify-content-center align-items-center flex-column"
            style={{
              maxWidth: '500px',
              overflow: 'hidden',
              padding: '0 16px 0 16px',
            }}
          >
            <LoginFlow buttonText="Login" />
          </div>
        </div>
      </div>
    );
  }

  if (!tripId) {
    return null;
  }

  const renderBody = () => {
    if (!profile) return null;

    if (validateInsuranceStatus.error) {
      return (
        <div
          className="d-flex flex-column align-items-center gap-2"
        >
          <div className="text-center">
            {
              validateInsuranceStatus.error.message === 'Insurance only has liability'
                ? (
                  <>
                    <Typography variant="h2">
                      Insurance only has liability
                    </Typography>

                    <Typography>
                      Please contact customer support to add in collision coverage
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography variant="h2">
                      Insurance Insufficient
                    </Typography>

                    <Typography>
                      Unfortunately your insurance is not sufficient to cover this trip,
                      either because it is expired/will expire or does not have the sufficient protections
                      needed to rent this car. Please reach out to customer support if you’d like to add
                      in one of our protection packages to protect your trip
                    </Typography>
                  </>
                )
            }

            <Typography>
              If you continue to have issues, please click on the button below, and
              upload your policy manually by selecting "Other" at the bottom of the
              list of insurance carriers.
            </Typography>

            <Button
              className="btn-primary"
              onClick={handleInsuranceInitiation}
            >
              Reupload policies
            </Button>
          </div>
        </div>
      );
    }

    if (insuranceStatus && !insuranceStatus.is_insurance_connected) {
      return (
        <div
          className="d-flex flex-column align-items-center gap-2"
        >
          <div className="text-center">
            <Typography variant="h2">
              Upload your insurance
            </Typography>

            <Typography>
              If you've selected to use your own insurance policy, you'll need to connect
              your insurance via Axle. This will allow us to verify your
              insurance coverage and ensure you're good to go. Please click
              the button below to get started.
            </Typography>
          </div>

          <Button
            className="btn-primary"
            onClick={handleInsuranceInitiation}
          >
            Connect
          </Button>
        </div>
      );
    }

    if (moment(profile.insurance_liability_verified_until).isBefore(moment())) {
      return (
        <div
          className="d-flex flex-column align-items-center gap-2"
        >
          <div className="text-center">
            <Typography variant="h2">
              Insurance Insufficient
            </Typography>

            <Typography>
              Unfortunately your insurance is not sufficient to cover this trip,
              either because it is expired/will expire or does not have the sufficient protections
              needed to rent this car. Please reach out to customer support if you’d like to add
              in one of our protection packages to protect your trip
            </Typography>
          </div>

          <Button
            className="btn-primary"
            onClick={handleInsuranceInitiation}
          >
            Connect new insurance policy
          </Button>
        </div>
      );
    }

    if (profile.manual_insurance_status === ManualInsuranceStatusEnum.Pending) {
      return (
        <div
          className="d-flex flex-column align-items-center gap-2"
        >
          <div className="text-center">
            <Typography variant="h2">
              Insurance pending review
            </Typography>

            <Typography>
              Your insurance policy is pending verification. Please check back later.
            </Typography>
          </div>
        </div>
      );
    }

    if (insuranceStatus && !insuranceStatus.is_insurance_valid) {
      return (
        <div
          className="d-flex flex-column align-items-center gap-2"
        >
          <div className="text-center">
            <Typography variant="h2">
              Insurance rejected
            </Typography>

            <Typography>
              Your insurance policy is not valid. Please reupload your insurance policy.
            </Typography>
          </div>

          <Button
            className="btn-primary"
            onClick={handleInsuranceInitiation}
          >
            Reupload policies
          </Button>
        </div>
      );
    }

    if (insuranceStatus?.is_insurance_valid) {
      return (
        <div
          className="d-flex flex-column align-items-center gap-2"
        >
          <div className="text-center">
            <Typography variant="h2">
              Insurance verified
            </Typography>

            <Typography>
              Your insurance policy has been verified.
            </Typography>

            <Typography>
              You may close this window.
            </Typography>
          </div>
        </div>
      );
    }

    if (profile.manual_insurance_status === ManualInsuranceStatusEnum.Rejected) {
      return (
        <div
          className="d-flex flex-column align-items-center gap-2"
        >
          <div className="text-center">
            <Typography variant="h2">
              Insurance rejected
            </Typography>

            <Typography>
              Your insurance policy has been rejected. Please reupload your insurance policy.
            </Typography>
          </div>

          <Button
            className="btn-primary"
            onClick={handleInsuranceInitiation}
          >
            Reupload policies
          </Button>
        </div>
      );
    }

    return null;
  };

  return (
    <div>
      <Navbar />

      <ToastContainer autoClose={2500} />

      {
        getInsuranceStatus.loading && (
          <LoadingSpinner absolute>
            <Typography style={{ marginTop: '120px' }}>
              Creating insurance session...
            </Typography>
          </LoadingSpinner>
        )
      }

      {
        createSessionStatus.loading && (
          <LoadingSpinner absolute>
            <Typography style={{ marginTop: '120px' }}>
              Creating insurance session...
            </Typography>
          </LoadingSpinner>
        )
      }

      {
        connectInsuranceStatus.loading && (
          <LoadingSpinner absolute>
            <Typography style={{ marginTop: '120px' }}>
              Connecting insurance connect...
            </Typography>
          </LoadingSpinner>
        )
      }

      {
        validateInsuranceStatus.loading && (
          <LoadingSpinner absolute>
            <Typography style={{ marginTop: '120px' }}>
              Validating insurance policy...
            </Typography>
          </LoadingSpinner>
        )
      }

      {
        showInsuranceIFrame && (
          <iframe
            src={insuranceIFrameSrc}
            title="Insurance Session"
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
            }}
          />
        )
      }

      <div
        className="d-flex justify-content-center mt-4"
      >
        <div
          className="d-flex justify-content-center align-items-center flex-column gap-1"
          style={{
            maxWidth: '500px',
            overflow: 'hidden',
            padding: '0 16px 0 16px',
          }}
        >
          {
            renderBody()
          }

          <div className="mt-3 text-center">
            If you run into any issues,
            please call or text (857) 399-0442 or email us at support@eonrides.com
          </div>
        </div>
      </div>
    </div>
  );
};

export default Insurance;
