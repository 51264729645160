import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import mixpanel from 'mixpanel-browser';

import {
  CARS_OPTIONS,
  DATES,
  DELIVERY_ADDRESSES,
  CAR_TABS_MODE,
} from '../constants';
import { checkoutActions, checkoutSelectors, uiActions, uiSelectors } from '../redux/slices';
import { IStoreCarsSearch, ICar, IExtra, IPurchase, IInsurance } from '../types';
import { checkDiscountCode } from '../api';
import { getPriceWithDiscount } from '../utils';
import { getFormattedDisplayParameterString, getNumDays } from '../helpers/utils';

declare const google: any;

const useCarDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search, hash } = useLocation();
  const { dates, address } = useSelector(uiSelectors.selectCarsSearch) as IStoreCarsSearch;
  const carDetails = useSelector(checkoutSelectors.selectCarDetails) as ICar;
  const extraData = useSelector(checkoutSelectors.selectExtraData) as [IExtra] | [];
  const insurances = useSelector(checkoutSelectors.selectInsurances) as [IInsurance] | [];
  const uiGMTOffset = useSelector(uiSelectors.selectGMTOffset) as string;
  const {
    selectedInsurance, selectedOptions, discountData
  } = useSelector(checkoutSelectors.selectPurchase) as IPurchase;
  const mode = useSelector(uiSelectors.selectCarsTabMode);

  const selectedDays = getNumDays(dates);

  const setSelectedInsurance = (data: any) => {
    dispatch(checkoutActions.setPurchaseData.base({ key: 'selectedInsurance', data }));
  };
  const setSelectedOptions = (data: any) => {
    dispatch(checkoutActions.setPurchaseData.base({ key: 'selectedOptions', data }));
  };
  const setDiscountData = (data: any) => {
    dispatch(checkoutActions.setPurchaseData.base({
      key: 'discountData',
      data: data.clear ? data : { ...discountData, ...data },
    }));
  };

  const setQueryData = (key: string, data: any) => () => {
    const params = new URLSearchParams(search);
    params.set(key, typeof data === 'object' ? data.join(',') : data);
    switch (key) {
      case CARS_OPTIONS.INSURANCE: setSelectedInsurance(data); break;
      case CARS_OPTIONS.OPTIONS: setSelectedOptions(data); break;
      default:
    }
    navigate({ hash, search: `?${params.toString()}` }, { replace: true });
  };

  const redeemDiscount = (e: any) => {
    mixpanel.track('Button Clicked', {
      'Button Name': 'Redeem Discount',
      'Discount Code': discountData.code || '',
    });

    e.preventDefault();
    e.stopPropagation();
    setDiscountData({ ...discountData, isChecking: true, error: null });
    (async () => {
      try {
        const data = await checkDiscountCode(discountData);
        setDiscountData({ ...discountData, isChecking: false, amount: data.amount });
      } catch (err) {
        setDiscountData({ ...discountData, isChecking: false, error: true });
      }
    })();
  };

  const decodeAddresses = async (deliveryTo: string, collectAt: string) => {
    const geocoder = typeof google !== 'undefined' ? new google.maps.Geocoder() : null;
    if (!geocoder) {
      setTimeout(() => decodeAddresses(deliveryTo, collectAt), 200);
    } else {
      const decodeAddresses = await Promise.all([
        geocoder?.geocode({ placeId: deliveryTo }).then((data: any) => data.results[0]),
        geocoder?.geocode({ placeId: collectAt }).then((data: any) => data.results[0])
      ]);
      if (decodeAddresses[0] && decodeAddresses[1]) {
        dispatch(uiActions.setCarsSearch.base({
          address: {
            deliveryTo: {
              placeId: decodeAddresses[0].place_id,
              address: decodeAddresses[0].formatted_address,
              geo: {
                lat: decodeAddresses[0].geometry.location.lat(),
                lng: decodeAddresses[0].geometry.location.lng()
              }
            },
            collectAt: {
              placeId: decodeAddresses[1].place_id,
              address: decodeAddresses[1].formatted_address,
              geo: {
                lat: decodeAddresses[1].geometry.location.lat(),
                lng: decodeAddresses[1].geometry.location.lng()
              }
            }
          }
        }));
      }
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(search);
    const insuranceParam = params.get(CARS_OPTIONS.INSURANCE);
    const optionsParam = (params.get(CARS_OPTIONS.OPTIONS) || '').split(',');
    if (insuranceParam) {
      setSelectedInsurance(Number(insuranceParam));
    } else if (insurances[0]) {
      setQueryData(CARS_OPTIONS.INSURANCE, insurances[0].id)();
    }
    if (optionsParam) {
      setSelectedOptions(optionsParam.filter(f => !!f).map(i => Number(i)));
    }
    if (params.get(DATES.START) && params.get(DATES.END)) {
      const startDateToSet = getFormattedDisplayParameterString(params.get(DATES.START) || (new Date()).toISOString());
      const endDateToSet = getFormattedDisplayParameterString(params.get(DATES.END) || (new Date()).toISOString());
      // Previous dispatch startDate: params.get(DATES.START) ? new Date(params.get(DATES.START)) : dates.startDate
      dispatch(uiActions.setCarsSearch.base({
        dates: {
          // @ts-ignore
          startDate: new Date(startDateToSet),
          // @ts-ignore
          endDate: new Date(endDateToSet),
          gmtOffset: params.get(DATES.GMT_OFFSET) || uiGMTOffset,
        }
      }));
    }
    if (params.get(DELIVERY_ADDRESSES.DELIVERY_TO)) {
      // @ts-ignore
      decodeAddresses(params.get(DELIVERY_ADDRESSES.DELIVERY_TO), params.get(DELIVERY_ADDRESSES.COLLECT_AT));
    }
  }, []);

  const totalPrice = (() => {
    if (!carDetails) return 0;

    let price = getPriceWithDiscount(carDetails, selectedDays, true);
    price += (mode === CAR_TABS_MODE.DELIVERY && address.deliveryTo && address.collectAt ? (
      Math.round(carDetails?.delivery_cost)
      || extraData.find((item: IExtra) => item.title === CARS_OPTIONS.DELIVERY.id)?.amount
      || CARS_OPTIONS.DELIVERY.amount
    ) : 0);

    price += (
      (carDetails?.daily_rate || 0)
      * ((insurances.find((i: IInsurance) => i.id === selectedInsurance)?.percentage || 0) / 100)
      * selectedDays
    );

    // @ts-ignore
    price += extraData.reduce((sum: number, next: IExtra) => sum + (
      // @ts-ignore
      // eslint-disable-next-line
      selectedOptions.includes(next.id) ? (next.recurring ? next.amount * selectedDays : next.amount) : 0
    ), 0);

    price -= (discountData.opened ? (Math.round(discountData.amount) || 0) : 0);

    return price;
  })();

  return {
    totalPrice,
    selectedInsurance,
    setSelectedInsurance,
    selectedOptions,
    setSelectedOptions,
    selectedDays,
    carDetails,
    extraData,
    insurances,
    setQueryData,
    discountData,
    setDiscountData,
    redeemDiscount,
  };
};

export { useCarDetails };
