import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import cx from 'classnames';
import moment from 'moment';
import mixpanel from 'mixpanel-browser';
import Icon from '@mui/material/Icon';

import CopyLink from 'components/common/CopyLink';
import { checkoutSelectors, uiSelectors, uiActions, checkoutActions, profileSelectors } from '../../redux/slices';
import { IReservation, IProfile } from '../../types';
import { pages, routes } from '../../pages';
import { APPSTORE_LINK } from '../../constants';
import { PageTitle, Scrollable, InputField, Typography, Button } from '../common';
import { PoweredBy } from '../PoweredBy';
import { CarImages } from '../Cars/CarImages';

const STEPS = [
  {
    description: `Your deposit will be authorized 24 hours before your trip starts or shortly after verification
    is complete if you're picking up within the next 24 hours.`,
    icon: <Icon>paid</Icon>
  },
  {
    description: `1 hour before your trip, you'll get your car's license plate, code to drive,
    and dedicated rental agent's phone number so you can just get in and go.`,
    icon: <Icon>key</Icon>
  },
  {
    description: `Download the Eon app so you can control your car's connected features straight from the app!
    You don't need the app to access your car, it's just a better experience. 😉`,
    icon: <Icon>smartphone</Icon>
  },
  {
    description: `
      Refer a friend! They'll receive a $35 discount when they book and you'll
      receive $35 in Eon credit when they complete their first trip.
    `,
    icon: <Icon>group</Icon>
  },
];

const ReservationInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const darkMode = useSelector(uiSelectors.selectDarkMode);
  const reservation = useSelector(checkoutSelectors.selectReservation) as IReservation;
  const profile = useSelector(profileSelectors.selectProfile) as IProfile;
  const scrollableData = useSelector(state => uiSelectors.selectScrollableData(state, 'reservation-info'));
  const gmtOffset = reservation.start_time.substring(reservation.start_time.length - 5);

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', () => {
      window.history.pushState(null, document.title, window.location.href);
    });
  }, []);

  const referralLink = useMemo(() => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const PORT = process.env.REACT_APP_PORT;

    if (!profile) return '';

    return `${window.location.protocol}//${BASE_URL}${PORT ? `:${PORT}` : ''}?ref=${profile.referral_code}`;
  }, [profile]);

  const handleNewBooking = () => {
    mixpanel.track('Button Clicked', {
      'Button Name': 'New Booking',
    });

    dispatch(uiActions.clearCarSearch.base());
    dispatch(checkoutActions.clearCheckout.base());

    navigate({
      pathname: routes[pages.home],
      search: '',
      hash: '',
    });
  };

  const handleAppStoreButton = () => {
    mixpanel.track('Button Clicked', {
      'Button Name': 'App Store Download',
    });

    window.open(APPSTORE_LINK, '_blank', 'noreferrer');
  };

  const pickupLocation = reservation.pickup_location;
  const address = reservation.custom_pickup_location
    ? reservation.custom_pickup_location
    : `${pickupLocation.address}, USA`;

  return (
    <div className="d-flex flex-column w-100">
      <PageTitle
        title="You're Booked!"
        className={cx('p-4', { 'box-shadow2': scrollableData?.scrollTop > 0 })}
      />
      <Scrollable id="reservation-info" className="position-relative">
        <div className="mb-3">
          <CarImages
            images={reservation.car.thumbnails}
            className="mb-3"
          />
          <div className="d-flex gap-1 mb-3 w-100">
            <InputField
              label="Pick up"
              value={moment(reservation.start_time)
                .zone(gmtOffset)
                .format('MMM DD hh:mma')}
              readOnly
            />
            <InputField
              label="Drop off"
              value={moment(reservation.end_time)
                .zone(gmtOffset)
                .format('MMM DD hh:mma')}
              readOnly
            />
          </div>
          <Typography variant="body2">
            {`You've booked your ${reservation.car.model_name} ${reservation.car.model_type}!`}
          </Typography>
          <Typography variant="body2">
            {`Address: ${address}`}
          </Typography>
        </div>
        <div className="py-4">
          <Typography variant="h2" className="mb-2">Next Steps</Typography>

          <Typography variant="body2" className="mb-3">
            Woohoo! You&apos;re booked and we&apos;re excited to host you!
          </Typography>

          {STEPS.map((item: any) => (
            <div key={item.description} className="d-flex gap-3 align-items-start mb-3">
              {item.icon}
              <div className="w-fit">
                <Typography variant="body2" className="m-0">{item.description}</Typography>
              </div>
            </div>
          ))}

          {
            referralLink && (
              <CopyLink link={referralLink} />
            )
          }

          <div className="d-flex justify-content-center align-items-center gap-4 mt-4">
            <Button variant="primary" className="" onClick={handleNewBooking}>
              New Booking
            </Button>

            <div onClick={handleAppStoreButton}>
              <img
                src={darkMode ? '/images/app-store-download-white.svg' : '/images/app-store-download-black.svg'}
                alt=""
                style={{ height: 60, width: 150 }}
              />
            </div>
          </div>
        </div>
        <div className="position-relative">
          <div className="d-flex gap-3 justify-content-end" style={{ right: 0, top: 0, height: 120 }}>
            <Typography variant="body3" className="mb-0">
              haha yes
            </Typography>
            <img src="/images/reservation-finish.png" alt="" />
          </div>
        </div>
        <PoweredBy className="mt-5 text-center" />
      </Scrollable>
    </div>
  );
};

export { ReservationInfo };
