import axios, { AxiosRequestConfig } from 'axios';

import { TOKEN_STORAGE, TOKEN_STORAGE_LIVE, TOKEN_STORAGE_TIMESTAMP } from '../../constants';

export const handleAxiosErrors = async (error: any) => {
  if (error?.response?.status === 401) {
    localStorage.removeItem(TOKEN_STORAGE);
    localStorage.removeItem(TOKEN_STORAGE_TIMESTAMP);
  }
  return Promise.reject(error);
};

const getConfig = (config = {} as AxiosRequestConfig) => {
  if (localStorage.getItem(TOKEN_STORAGE_TIMESTAMP)) {
    // @ts-ignore
    if (+new Date() > +new Date(localStorage.getItem(TOKEN_STORAGE_TIMESTAMP)) + TOKEN_STORAGE_LIVE) {
      localStorage.removeItem(TOKEN_STORAGE);
      localStorage.removeItem(TOKEN_STORAGE_TIMESTAMP);
    } else {
      localStorage.setItem(TOKEN_STORAGE_TIMESTAMP, new Date().toISOString());
    }
  }

  const headers = {
    ...config.headers,
    ...(localStorage.getItem(TOKEN_STORAGE) ? { Authorization: `Bearer ${localStorage.getItem(TOKEN_STORAGE)}` } : {})
  };

  return { ...config, headers };
};

const createRequestInstance = (customConfig = {}) => {
  const instance = axios.create({ ...getConfig(), ...customConfig });
  const { get, post, put, delete: del } = instance;

  instance.interceptors.response.use((response: any) => response, handleAxiosErrors);

  instance.get = (url: string, config: AxiosRequestConfig) => (
    get.call(this, url, getConfig(config)) as any
  );
  instance.post = (url: string, data: any, config: AxiosRequestConfig) => (
    post.call(this, url, data, getConfig(config)) as any
  );
  instance.put = (url: string, data: any, config: AxiosRequestConfig) => (
    put.call(this, url, data, getConfig(config)) as any
  );
  instance.delete = (url: string, config: AxiosRequestConfig) => (
    del.call(this, url, getConfig(config)) as any
  );

  return instance;
};

const request = createRequestInstance();
const externalRequest = axios.create({});

export { request, externalRequest };
