import React, { useEffect, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, useLocation } from 'react-router-dom';
import { Wrapper as GoogleMapWrapper, Status as GoogleMapStatus } from '@googlemaps/react-wrapper';
import mixpanel from 'mixpanel-browser';

import { uiActions, profileSelectors } from './redux/slices';
import { useCurrentPosition, useDarkMode, useMapHeight, useScrollTop, usePartnerConfig } from './hooks';
import { pages, routes } from './pages';
import { IProfile } from './types';

import './App.scss';

import { MapWrapper, MapFailure, MapLoading } from './components/MapWrapper';
import { TopBar } from './components/TopBar';
import { Locations } from './components/Locations';
import { Cars } from './components/Cars';
import { Checkout } from './components/Checkout';
import { Reservation } from './components/Reservation';
import { REFERRAL_CODE_KEY } from './constants';

const App = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const profile = useSelector(profileSelectors.selectProfile) as IProfile;

  useEffect(() => {
    if (profile?.id) {
      mixpanel.identify(profile.id.toString());

      mixpanel.people.set({
        $name: `${profile.firstname} ${profile.lastname}`,
        $email: profile.email,
        $phone: profile.phone,
      });
    }
  }, [profile]);

  useEffect(() => {
    dispatch(uiActions.initialize.base());
    const params = new URLSearchParams(search);

    if (params.get(REFERRAL_CODE_KEY) && params.get(REFERRAL_CODE_KEY)?.replaceAll(' ', '') !== '') {
      dispatch(uiActions.setReferralCode.base(params.get(REFERRAL_CODE_KEY)));
    } else {
      dispatch(uiActions.setReferralCode.base(''));
    }
  }, []);

  const height = useMapHeight();
  usePartnerConfig();
  useDarkMode();
  useCurrentPosition();
  useScrollTop();

  return (
    <div className="d-flex flex-column flex-md-row-reverse">
      <div className="app-map" style={{ height }}>
        <GoogleMapWrapper
          apiKey={process.env.REACT_APP_GOOGLE_API_KEY || ''}
          render={(status: GoogleMapStatus): ReactElement => {
            if (status === GoogleMapStatus.FAILURE) {
              return <MapFailure />;
            }
            if (status === GoogleMapStatus.SUCCESS) {
              return <MapWrapper height={height} />;
            }
            return <MapLoading />;
          }}
          libraries={['geometry', 'places']}
          language="en"
          region=".us"
          version="beta"
        />
      </div>
      <div className="app-data">
        <TopBar />
        <Routes>
          {Object.keys(routes).map(key => (
            <Route
              key={key}
              path={routes[key]}
              element={
                {
                  [pages.home]: <Locations />,
                  [pages.city]: <Cars />,
                  [pages.checkout]: <Checkout />,
                  [pages.reservation]: <Reservation />,
                }[key]
              }
            />
          ))}
          <Route path="*" element={<Locations />} />
        </Routes>
      </div>
    </div>
  );
};

export default App;
