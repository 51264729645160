import { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';

import { Button, Typography } from 'components/common';
import { ICar, APIStatus } from 'types';
import { Eon } from 'assets/icons';
import * as api from 'api';

interface Props {
  car?: ICar;
  onSuccess?: (car: ICar) => void;
  style?: any;
  visible?: boolean;
  showBanner?: boolean;
}

const AddVirtualKeyBanner = ({
  car,
  onSuccess,
  style,
  visible = false,
  showBanner = true,
}: Props) => {
  const [showVirtualKeyModal, setShowVirtualKeyModal] = useState(visible);
  const [verifyKeyStatus, setVerifyKeyStatus] = useState<APIStatus>({
    loading: false,
    success: false,
    error: null,
  });

  const [dots, setDots] = useState(0);

  useEffect(() => {
    setShowVirtualKeyModal(visible);
  }, [visible]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDots((prevDots) => (prevDots % 3) + 1);
    }, 500);

    return () => {
      clearInterval(intervalId); // Clean up the interval on unmount
    };
  }, []);

  if (car?.smartcar_virtual_key_connected || car?.is_resla_car) return null;

  const handleVerifyVirtualKey = async () => {
    try {
      setVerifyKeyStatus({ loading: true, success: false, error: null });

      await api.verifyOwnerCarVirtualKey(car!.id);

      setVerifyKeyStatus({ loading: false, success: true, error: null });

      // eslint-disable-next-line no-unused-expressions
      onSuccess && onSuccess(car!);

      toast.success('Virtual key successfully connected');

      setTimeout(() => {
        setShowVirtualKeyModal(false);
      }, 2000);
    } catch (error: any) {
      setVerifyKeyStatus({
        loading: false,
        success: false,
        error: { code: error.response.status, message: error.response.data.error },
      });
      toast.error('Failed to verify virtual key.');
    }
  };

  return (
    <>
      {
        showBanner && (
          <div
            className="d-flex flex-row gap-2"
            style={style}
          >
            <div style={{ fontSize: '20px' }}>
              ⚠️
            </div>
            <div>
              Car is not secured
            </div>
            <Button
              className="m-0 p-0"
              style={{ minWidth: '150px', height: '35px' }}
              onClick={() => setShowVirtualKeyModal(true)}
            >
              Add Digital Key
            </Button>
          </div>
        )
      }

      <Modal
        ariaHideApp={false}
        isOpen={showVirtualKeyModal}
        style={{
          overlay: {
            zIndex: 1000,
          },
          content: {
            zIndex: 1000,
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '29px',
            width: '90%',
            borderRadius: '8px',
            overflow: 'scroll',
            maxHeight: '90%',
          },
        }}
        onRequestClose={() => setShowVirtualKeyModal(false)}
      >
        <div className="d-flex justify-content-end">
          <Button
            onClick={() => setShowVirtualKeyModal(false)}
            style={{ width: '25px', height: '25px', padding: 0 }}
          >
            X
          </Button>
        </div>

        <div className="d-flex flex-column align-items-center gap-3">
          <div>
            <Eon style={{ width: '70px', height: '70px' }} />
          </div>

          <div className="d-flex flex-column gap-3">
            <Typography variant="h1" className="text-center" style={{ lineHeight: '35px' }}>
              How to Create a Virtual Key for Your Tesla and Connect It to Eon
            </Typography>

            <div>
              <Typography variant="h3">
                What is This?
              </Typography>

              <Typography>
                Creating a virtual key for your Tesla allows you to integrate your vehicle seamlessly with Eon’s system.
                This process establishes a secure connection between your car and our platform,
                enabling various features like remote access, tracking, and automated processes
                essential for the Eon rental experience.
              </Typography>
            </div>

            <div>
              <Typography variant="h3">
                Why is This Important?
              </Typography>

              <ul>
                <li>
                  <Typography>
                    Enhanced Control: Provides you with remote management capabilities
                    like automatically locking and unlocking directly from our system -
                    this way you don’t need to be online and available to unlock the car
                    from your Tesla app when verified guests arrive for pickup.
                  </Typography>
                </li>

                <li>
                  <Typography>
                    Increased Security: Highly Recommended -
                    allows you to disable continued used of the car if a guest is violating the terms of service**
                  </Typography>
                </li>

                <li>
                  <Typography>
                    Increased Rentals: Cars that are secured will be ranked higher on the platform than non-secured cars
                  </Typography>
                </li>
              </ul>
            </div>

            <div>
              <Typography variant="h3">
                Risks of Not Linking Your Virtual Key
              </Typography>

              <ul>
                <li>
                  <Typography>
                    Reduced Rental Opportunities: Without a virtual key, your vehicle
                    cannot fully utilize Eon’s automated rental processes, which will
                  </Typography>
                </li>

                <li>
                  <Typography>
                    Decreased Security: Not using a virtual key can increase the risk of
                    unauthorized access to your vehicle. By not creating a digital key,
                    you take on the full responsibility of unauthorized use of your car (see FAQ’s)
                  </Typography>
                </li>

                <li>
                  <Typography>
                    Inefficient Management: Lack of integration can result in more manual processes
                    where you have to lock and unlock the car manually for each rental,
                    making the management of your vehicle less efficient and more time-consuming.
                  </Typography>
                </li>
              </ul>
            </div>

            <div className="d-flex flex-column">
              <Typography variant="h2">
                Connect your Phone as a Key:
              </Typography>

              <ol>
                <li style={{ marginBottom: '10px' }}>
                  Confirm your phone’s general Bluetooth settings are enabled.
                </li>

                <li style={{ marginBottom: '10px' }}>
                  {/* eslint-disable-next-line */}
                  Allow Mobile Access is enabled on the vehicle touchscreen (<b>Controls {'>'} Safety {'>'} Allow Mobile Access</b>).
                </li>

                <li>
                  {/* eslint-disable-next-line */}
                  While inside or near the vehicle, open the Tesla mobile app and touch <b>Set Up Phone Key</b> on the main screen,
                  {/* eslint-disable-next-line */}
                  &nbsp;or navigate to <b>Security {'>'} Set Up Phone Key</b>. Follow the prompts on the mobile app and vehicle touchscreen to set up your phone key.
                </li>
              </ol>

              <div>
                <Typography inline>
                  If you have any additional questions or concerns, please visit
                </Typography>

                <a
                  href="https://smartcar.com/docs/help/oem-integrations/tesla/owners#how-do-i-add-smartcars-virtual-key"
                  target="_blank"
                  rel="noreferrer"
                >
                  {' this link. '}
                </a>
              </div>
            </div>

            <div className="d-flex flex-column">
              <Typography variant="h2">
                Add a Virtual Key:
              </Typography>

              <Button
                style={{ width: '300px' }}
                onClick={() => {
                  window.open('https://www.tesla.com/_ak/eon.rent', '_blank');
                }}
              >
                Create Virtual Key
              </Button>
            </div>

            {
              car && (
                <div className="mt-1 d-flex flex-column gap-2">
                  <Typography variant="h2">
                    Confirm Virtual Key Connection:
                  </Typography>

                  <Button
                    style={{ width: '300px' }}
                    onClick={handleVerifyVirtualKey}
                  >
                    {
                      verifyKeyStatus.loading
                        ? `Working on it${'.'.repeat(dots)}`
                        : 'I added it'
                    }
                  </Button>

                  <Typography style={{ width: '300px' }}>
                    * This may take a few minutes while we verify your key.
                  </Typography>
                </div>
              )
            }

            <div>
              <Typography variant="h2">
                FAQ’s:
              </Typography>

              <ol>
                <li>
                  <Typography>
                    How will my virtual key be used?
                  </Typography>

                  <ol type="a">
                    <li>
                      This key allows eon and your authorized guests to lock and
                      unlock the car via the eon app. It does not allow anyone to drive the key,
                      a physical key will still be necessary to use the car, but this adds a layer
                      of convenience and security.
                    </li>
                  </ol>
                </li>

                <li>
                  <Typography>
                    Can I remove this key after added?
                  </Typography>

                  <ol type="a">
                    <li>
                      yes - while we recommend keeping it on as long as you’re using the eon platform,
                      you can always remove the key by going to your tesla app, going to the security
                      and drivers tab, and removing the eon/smartcar driver profile from your account
                    </li>
                  </ol>
                </li>
              </ol>
            </div>

            <div>
              <Typography>
                Additional questions? text or call our support team at 857-399-0442
              </Typography>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddVirtualKeyBanner;
