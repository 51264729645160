import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import moment from 'moment';

import { Typography } from 'components/common';
import ReservationCard from 'components/Owner/Home/ReservationCard';
import { ownerActions, ownerSelectors, UpcomingReservations, ReservationsToReview } from 'redux/slices';
import { IReservation, IOwner } from 'types';
import { LoadingSpinner } from 'components/LoadingSpinner';

import variables from 'assets/scss/variables.scss';
import AddVirtualKeyBanner from 'components/Owner/Cars/AddVirtualKeyBanner';
import Banner from 'components/common/Banner';
import SmartcarUserDriverBanner from '../Cars/SmartcarUserDriverBanner';

interface ReservationMap {
  [key: string]: IReservation[];
}

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const upcomingReservations = useSelector(ownerSelectors.selectUpcomingReservations) as UpcomingReservations;
  const reservationsToReview = useSelector(ownerSelectors.selectReservationsToReview) as ReservationsToReview;
  const [formattedReservations, setFormattedReservations] = useState<ReservationMap | null>(null);
  const owner = useSelector(ownerSelectors.selectOwner) as IOwner;

  useEffect(() => {
    dispatch(ownerActions.getUpcomingReservations.base({
      page: 1,
      per_page: 10,
    }));

    dispatch(ownerActions.getReservationsToReview.base());
  }, []);

  useEffect(() => {
    const reservations = upcomingReservations.data;

    const sortedRes: ReservationMap = reservations.reduce((acc: ReservationMap, res: IReservation) => {
      const now = moment.tz(moment(), res.pickup_location.timezone);

      const startTime = moment.tz(res.start_time, res.pickup_location.timezone);
      const endTime = moment.tz(res.end_time, res.return_location.timezone);

      if (startTime.isAfter(now)) {
        const key = startTime.format('dddd - MM/DD/YY');
        if (acc[key]) {
          acc[key].push(res);
        } else {
          acc[key] = [res];
        }

        return acc;
      }

      const key = endTime.format('dddd - MM/DD/YY');
      if (acc[key]) {
        acc[key].push(res);
      } else {
        acc[key] = [res];
      }

      return acc;
    }, {});

    setFormattedReservations(sortedRes);
  }, [upcomingReservations]);

  const handleLoadMore = () => {
    if (upcomingReservations.status.loading) return;

    dispatch(ownerActions.getUpcomingReservations.base({
      page: upcomingReservations.page + 1,
      per_page: 10,
    }));
  };

  const renderReservationsToReview = () => {
    return (
      <div
        className="mb-4"
      >
        <div className="mb-3">
          <Typography variant="h3">
            (Review reimbursements, confirm supercharging/tolls, etc.)
          </Typography>

          <div
            style={{
              width: '100%',
              height: '2px',
              backgroundColor: variables.gray3,
            }}
          />
        </div>

        <div className="d-flex flex-column gap-3">
          {
            reservationsToReview.data && reservationsToReview.data
              .map((res: IReservation) => (
                <div key={`${res.id}`}>
                  <ReservationCard
                    reservation={res}
                    onClick={() => {
                      navigate(`/owner/reservations/${res.id}/edit`);
                    }}
                  />
                </div>
              ))
          }
        </div>
      </div>
    );
  };

  const renderReservations = () => {
    if (upcomingReservations.data.length === 0 && upcomingReservations.status.success === true) {
      return (
        <div className="mt-5 d-flex flex-column align-items-center w-100">
          <Typography variant="h1" style={{ color: variables.gray2 }}>
            No Reservations Yet
          </Typography>
          <Typography variant="body1" className="text-center" style={{ color: variables.gray2 }}>
            They will come soon. Stay strong. 🚀
          </Typography>
        </div>
      );
    }

    if (upcomingReservations.data.length === 0 && upcomingReservations.status.loading === true) {
      return (
        <div className="mt-5 d-flex flex-column align-items-center w-100">
          <LoadingSpinner />
        </div>
      );
    }

    if (!formattedReservations) return null;

    return (
      <div className="d-flex flex-column w-100">
        <InfiniteScroll
          pageStart={0}
          loadMore={handleLoadMore}
          hasMore={upcomingReservations.page < upcomingReservations.total_pages}
          loader={<LoadingSpinner key={1} />}
        >
          {
            formattedReservations && Object.keys(formattedReservations)
              .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
              .map((key: string) => {
                const reservations = formattedReservations[key];
                return (
                  <div
                    key={`${key}`}
                    className="mb-4"
                  >
                    <div className="mb-3">
                      <Typography variant="h2">
                        {key}
                      </Typography>

                      <div
                        style={{
                          width: '100%',
                          height: '2px',
                          backgroundColor: variables.gray3,
                        }}
                      />
                    </div>

                    <div className="d-flex flex-column gap-3">
                      {
                        reservations
                          .map((res: IReservation) => (
                            <div key={`${key}${res.id}`}>
                              <div
                                className="d-flex flex-column align-items-center gap-2"
                                style={{ marginBottom: '12px', width: '100%' }}
                              >
                                <SmartcarUserDriverBanner car={res.car} style={{ width: '350px' }} />

                                <AddVirtualKeyBanner
                                  car={res.car}
                                  onSuccess={(car) => {
                                    const data = upcomingReservations.data.map((r) => {
                                      if (r.car.id === car.id) {
                                        return {
                                          ...r,
                                          car: {
                                            ...r.car,
                                            smartcar_virtual_key_connected: true,
                                          },
                                        };
                                      }

                                      return r;
                                    });

                                    const payload = {
                                      ...upcomingReservations,
                                      data,
                                    };

                                    dispatch(ownerActions.setUpcomingReservations.base(payload));
                                  }}
                                  style={{ width: '350px' }}
                                />
                              </div>
                              <ReservationCard
                                reservation={res}
                                onClick={() => {
                                  navigate(`/owner/reservations/${res.id}/edit`);
                                }}
                              />
                            </div>
                          ))
                      }
                    </div>

                  </div>
                );
              })
          }
        </InfiniteScroll>
      </div>
    );
  };

  return (
    <div
      className="w-100 d-flex flex-column align-items-center"
      style={{ padding: '24px' }}
    >
      <div className="w-100" style={{ maxWidth: '560px' }}>
        {
          owner?.referral_code && (
            <Banner
              text={`Refer a friend using code: ${owner.referral_code} and get Eon credit! 🚀`}
              link="/owner/referrals"
            />
          )
        }

        {
          reservationsToReview.data.length > 0 && (
            <div>
              <Typography variant="h1">To Review</Typography>

              {renderReservationsToReview()}
            </div>
          )
        }

        <div>
          <Typography variant="h1">Up Next</Typography>
        </div>

        {
          renderReservations()
        }
      </div>
    </div>
  );
};

export default Home;
