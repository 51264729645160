import React, { useState } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import mixpanel from 'mixpanel-browser';

import { Filter, PageTitle, Card, Link, Typography } from '../common';
import { FilterButton } from './FilterButton';

import { Autopilot, Speed, Model, Range, Reset } from '../../assets/icons';
import { CARS_FILTERS } from '../../constants';

import { uiActions, uiSelectors } from '../../redux/slices';
import { IStoreCarsSearch } from '../../types';

interface IProps {
  isMapFilters?: boolean;
}

const ICONS = {
  [CARS_FILTERS.AUTOPILOT.key]: <Autopilot />,
  [CARS_FILTERS.SPEED.key]: <Speed />,
  [CARS_FILTERS.MODEL.key]: <Model />,
  [CARS_FILTERS.RANGE.key]: <Range />,
};

const CarFilters = ({ isMapFilters }: IProps) => {
  const dispatch = useDispatch();
  const [showFilter, setShowFilter] = useState<any>(null);
  const { filters } = useSelector(uiSelectors.selectCarsSearch) as IStoreCarsSearch;
  const totalSelected = useSelector(uiSelectors.selectCarsSearchTotalFilters) as number;

  const handleOpenFilter = (f: any) => () => setShowFilter(f);
  const handleCloseFilter = () => showFilter ? setShowFilter(null) : {};

  const handleChangeFilter = (filter: string, type: string, value: string) => () => {
    mixpanel.track('Button Clicked', {
      'Button Name': 'Car Filters',
      'Car Filter': value,
      'Car Filter Type': filter,
      'Car Filter Toggle': (filters[filter] && filters[filter][value]) ? 'Off' : 'On',
    });

    dispatch(uiActions.setFilters.base({ filter, type, value }));
  };

  const clearAllFilters = () => {
    dispatch(uiActions.setFilters.base({ type: 'clear' }));
    dispatch(uiActions.clearLocationFilter.base());
  };

  return (
    <div
      style={{ top: 24 }}
      className={cx(
        'z-index-1 flex-column w-100',
        isMapFilters ? {
          'position-absolute': true,
          'd-none': true,
          'd-md-flex': true,
        } : {
          'd-flex': true,
          'd-md-none': true,
        }
      )}
    >
      <div
        className="d-flex gap-2 justify-xl-content-center hidden-scroll w-100 overflow-x-auto overflow-y-hidden"
        style={{ height: 72 }}
      >
        {Object.values(CARS_FILTERS).map(filter => (
          <Filter
            key={filter.key}
            icon={ICONS[filter.key]}
            text={filter.title}
            show={showFilter === filter.title}
            onShow={handleOpenFilter(filter.title)}
            onClose={handleCloseFilter}
            selected={Object.keys(filters[filter.key] || {}).length}
          >
            <div className="d-flex flex-column w-100">
              <PageTitle
                className="d-md-none mb-3"
                onGoBack={handleCloseFilter}
                title={filter.title}
              >
                <Link
                  className="d-flex align-items-center gap-2"
                  onClick={handleChangeFilter(filter.key, filter.type, 'clear')}
                >
                  Clear
                  <Reset className="ml-2" />
                </Link>
              </PageTitle>
              {filter.description && (
                <Typography className="mb-3" variant="body2">{filter.description}</Typography>
              )}
              <div className={cx(filter.className, 'mb-4')} style={filter.style}>
                {filter.options.map(opt => (
                  <Card
                    key={opt.value}
                    type={filter.type}
                    name={filter.title}
                    checked={filters[filter.key]?.[opt.value]}
                    onChange={handleChangeFilter(filter.key, filter.type, opt.value)}
                    className={cx({
                      'pt-2 pb-2': Boolean(opt.image)
                    })}
                  >
                    <Typography
                      className="m-0 d-flex align-items-center justify-content-between"
                      variant={opt.title && opt.description ? 'h3' : 'body2'}
                    >
                      {opt.title}
                      {opt.image && (
                        <span
                          className="img-background"
                          style={{ height: 40, width: 80, backgroundImage: `url('${opt.image}')` }}
                        />
                      )}
                    </Typography>
                    {opt.description && (
                      <Typography className="m-0" variant="body2">{opt.description}</Typography>
                    )}
                  </Card>
                ))}
              </div>
              <FilterButton
                onClose={handleCloseFilter}
                onClear={handleChangeFilter(filter.key, filter.type, 'clear')}
              />
            </div>
          </Filter>
        ))}
      </div>
      {totalSelected > 0 && (
        <div className="w-100 d-flex justify-content-center mb-4">
          <div className="filter p-2" onClick={clearAllFilters}>Clear all filters</div>
        </div>
      )}
    </div>
  );
};

CarFilters.defaultProps = {
  isMapFilters: false,
};

export { CarFilters };
