import React, { useEffect, useRef } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { DEFAULT_GOOGLE_DATA } from '../../constants';
import { uiActions, uiSelectors } from '../../redux/slices';
import { useDeepEffect } from '../../hooks';
import { IMap } from '../../types';
import { pages, routes } from '../../pages';

import { CarFilters, DateFilters } from '../Filters';
import { LocationMarkers, CityMarkers, ReservationMarkers } from './Markers';

declare const google: any;
const allMarkers = [] as any;

const MapWrapper = ({ height }: { height: number }) => {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const map = useSelector(uiSelectors.selectMap) as IMap;
  const darkMode = useSelector(uiSelectors.selectDarkMode);

  useEffect(() => {
    if (ref.current) {
      dispatch(uiActions.setMap.base(
        new google.maps.Map(ref.current, {
          center: DEFAULT_GOOGLE_DATA.center,
          zoom: DEFAULT_GOOGLE_DATA.zoom,
          zoomControl: true,
          disableDefaultUI: true,
        })
      ));
    }
  }, [ref]);

  useDeepEffect(() => {
    if (map) {
      map.setOptions({ styles: darkMode ? DEFAULT_GOOGLE_DATA.darkStyles : DEFAULT_GOOGLE_DATA.lightStyles });
    }
  }, [map, darkMode]);

  return (
    <div className="position-relative w-100">
      <Routes>
        <Route
          path={routes[pages.home]}
          element={(
            <LocationMarkers allMarkers={allMarkers} />
          )}
        />
        <Route
          path={routes[pages.city]}
          element={(
            <>
              <CityMarkers allMarkers={allMarkers} />
              <CarFilters isMapFilters />
              <DateFilters isMapFilters />
            </>
          )}
        />
        <Route
          path={routes[pages.checkout]}
          element={<ReservationMarkers allMarkers={allMarkers} />}
        />
        <Route
          path={routes[pages.reservation]}
          element={<ReservationMarkers allMarkers={allMarkers} />}
        />
      </Routes>
      <div ref={ref} className="map-box" style={{ height }} />
    </div>
  );
};

export { MapWrapper };
