import { useMemo, useEffect, useState, useCallback } from 'react';
import { Accept, useDropzone, FileError, ErrorCode } from 'react-dropzone';
import { Document, Page, pdfjs } from 'react-pdf';
import Compressor from 'compressorjs';

import { Button } from 'components/common/Button';
import { Typography } from '../Typography';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const baseStyle = {
  display: 'flex',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const focusedStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

interface Props {
  multiple?: boolean;
  accept?: Accept;
  defaultFiles: any[];
  placeholder?: string;
  aspectRatio?: number;
  onImagesChanged?: (files: any[]) => void;
  previewStyle?: any;
  maxSize?: number;
  convertImgToJpeg?: boolean;
}

function Dropzone({
  multiple = true,
  accept,
  defaultFiles,
  placeholder = 'Drag and drop some files here, or click to select files',
  aspectRatio,
  onImagesChanged,
  previewStyle,
  maxSize = 8000000, // default is 8 megabytes
  convertImgToJpeg = false,
}: Props) {
  const [acceptedFiles, setAcceptedFiles] = useState(defaultFiles || []) as any[];
  const [rejectedFiles, setRejectedFiles] = useState([]) as any[];

  useEffect(() => {
    setAcceptedFiles(defaultFiles);
  }, [defaultFiles]);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    onImagesChanged && onImagesChanged(acceptedFiles);
  }, [acceptedFiles]);

  const validator = (file: File): FileError | FileError[] | null => {
    if (aspectRatio) {
      const image = new Image();
      image.src = URL.createObjectURL(file);

      const promise = new Promise((resolve, reject) => {
        image.onload = () => {
          const imgAspectRatio = image.width / image.height;

          if (imgAspectRatio !== aspectRatio) {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject({
              code: 'invalid-aspect-ratio',
              message: `Aspect ratio must be ${aspectRatio}`,
            });
          } else {
            resolve(null);
          }
        };

        image.onerror = () => {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject({
            code: 'invalid-file-type',
            message: 'Invalid file type',
          });
        };
      });

      promise
        .then(() => {
          return null;
        })
        .catch((error) => {
          setRejectedFiles([...rejectedFiles, { file, errors: [error] }]);

          return error;
        });
    }

    return null;
  };

  const onDropAccepted = useCallback((newFiles: any[]) => {
    Promise.all(newFiles.map((file: any) => new Promise((resolve, reject) => {
      if (file.type.startsWith('image/')) {
        new Compressor(file, {
          quality: 0.7, // The compression quality, must be a number between 0 and 1
          // png is hard to compress, so convert to jpeg if prop is true
          mimeType: convertImgToJpeg ? 'image/jpeg' : file.type,
          success(result) {
            const compressedFile = new File([result], file.name, {
              type: result.type,
              lastModified: Date.now(),
            });

            resolve(compressedFile);
          },
          error(err) {
            console.log(err.message);
            reject(err);
          },
        });
      } else if (file.type === 'application/pdf') {
        resolve(file);
      }
    }))).then((results) => {
      const newFilesWithPreview: any[] = results.map((file: any) => Object.assign(file, {
        preview: URL.createObjectURL(file),
      }));

      setAcceptedFiles((prevFiles: any[]) => {
        const allFiles = multiple ? [...prevFiles, ...newFilesWithPreview] : newFilesWithPreview;
        return allFiles;
      });
    }).catch((error) => {
      // An error occurred
      console.error(error);
    });
  }, []);

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    multiple,
    accept,
    maxSize,
    onDropAccepted,
    onDropRejected: (rejectedFiles) => {
      setRejectedFiles(rejectedFiles);
    },
    validator,
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => acceptedFiles.forEach((file: any) => URL.revokeObjectURL(file.preview));
  }, []);

  const removeFile = (index: number) => {
    const newFiles = [...acceptedFiles];
    newFiles.splice(index, 1);
    setAcceptedFiles(newFiles);

    // eslint-disable-next-line no-unused-expressions
    onImagesChanged && onImagesChanged(newFiles);

    setRejectedFiles([]);
  };

  const thumbs = acceptedFiles.map((file: any, index: number) => {
    const isPdf = file.type === 'application/pdf';

    return (
      <div key={`${file.name}${index}`} style={{ position: 'relative' }}>
        <div
          style={{
            position: 'absolute',
            top: -10,
            right: -4,
            zIndex: 1,
          }}
        >
          <Button
            onClick={() => removeFile(index)}
            className="m-0 p-0"
            style={{
              boxShadow: 'none',
              backgroundColor: 'white',
              verticalAlign: 'none',
              width: '20px',
              height: '20px',
              padding: '2px',
            }}
          >
            x
          </Button>
        </div>
        {
          isPdf
            ? (
              <div>
                <Document file={file}>
                  <Page
                    pageNumber={1}
                    width={50}
                    scale={1.5}
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                  />
                </Document>
              </div>
            ) : (
              <img
                alt={file.name.substring(0, 10)}
                src={file.preview}
                style={{
                  width: '50px',
                  objectFit: 'contain',
                  ...previewStyle,
                }}
              />
            )
        }
      </div>
    );
  });

  return (
    <>
      <div className="container">
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <p>{placeholder}</p>
        </div>
      </div>

      {
        rejectedFiles.length > 0 && (
          <div
            className="mt-2 d-flex flex-column gap-2 p-2 text-danger"
            style={{
              borderRadius: '8px',
            }}
          >
            <Typography>
              Error
            </Typography>

            {
              rejectedFiles.map(({ file, errors }: any) => (
                <div
                  key={file.name}
                >
                  {`Name: ${file.name}`}

                  <div>
                    {
                      errors.map((e: FileError) => {
                        if (e.code === ErrorCode.FileTooLarge) {
                          return (
                            <div
                              key={e.code}
                            >
                              {
                                `File size is too large, maximum size is ${(maxSize / 1000000).toFixed(1)}MB`
                              }
                            </div>
                          );
                        }

                        return (
                          <div key={e.code}>{e.message}</div>
                        );
                      })
                    }
                  </div>
                </div>
              ))
            }
          </div>
        )
      }

      <div
        className="d-flex flex-row align-items-center gap-3 mt-3 w-100 flex-wrap"
      >
        {thumbs}
      </div>
    </>
  );
}

export default Dropzone;
