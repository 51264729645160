import { useNavigate } from 'react-router-dom';

import { Eon } from 'assets/icons';
import { Button } from 'components/common';
import './styles.scss';

const Navbar = () => {
  const navigate = useNavigate();

  return (
    <div
      className="d-flex justify-content-space-between p-4 pb-3 container w-100"
      style={{
        position: 'sticky',
        top: 0,
        zIndex: 100,
        borderWidth: 0,
      }}
    >
      <Button
        style={{
          boxShadow: 'none',
          padding: 0,
          margin: 0,
          backgroundColor: 'transparent',
          color: 'transparent'
        }}
        onClick={() => navigate('/')}
      >
        <Eon style={{ width: '30px', height: '30px' }} />
      </Button>
    </div>
  );
};

export default Navbar;
