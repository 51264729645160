import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import cx from 'classnames';
import mixpanel from 'mixpanel-browser';
import './styles.scss';

import {
  checkoutActions,
  checkoutSelectors,
  locationsSelectors,
  uiActions,
  uiSelectors,
} from '../../redux/slices';
import { IStoreCars, ICar, IStoreCarsSearch } from '../../types';
import { pages, routes } from '../../pages';
import { CAR_TABS_MODE } from '../../constants';
import { getNumDays } from '../../helpers/utils';

import { PageTitle } from '../common';
import { DateFilters } from '../Filters';

import { CarsDelivery } from './CarsDelivery';
import { CarDetails } from './CarDetails';
import { CarsList } from './CarsList';
import { useLoadCarDetails } from '../../hooks';

const Cars = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const mode = useSelector(uiSelectors.selectCarsTabMode);
  const {
    dates, address, location: filteredLocation
  } = useSelector(uiSelectors.selectCarsSearch) as IStoreCarsSearch;
  const { data } = useSelector(locationsSelectors.selectCars) as IStoreCars;
  const scrollableData = useSelector(state => uiSelectors.selectScrollableData(state, 'cars'));
  const carDetails = useSelector(checkoutSelectors.selectCarDetails);

  const [deliveryInputFocused, setDeliveryInputFocused] = useState<string | null>(null);

  const selectedDays = getNumDays(dates);
  const showCars = (
    mode === CAR_TABS_MODE.PICK_UP
    || (mode === CAR_TABS_MODE.DELIVERY && address.deliveryTo && address.collectAt && !deliveryInputFocused)
  );

  const setCarDetails = (car: ICar | undefined) => dispatch(checkoutActions.setCarDetails.base(car));
  const handleCarClick = (car: ICar | undefined) => {
    const params = new URLSearchParams(search);
    if (car) {
      mixpanel.track('Button Clicked', {
        'Button Name': 'Car Card',
        'Car Color': car.color,
        'Car Model Name': car.model_name,
        'Car Model Type': car.model_type,
        'Car Daily Rate': car.daily_rate,
        'Car Num Seats': car.num_seats,
        'Car Zero To Sixty': car.zero_to_sixty,
        'Car Interior Color': car.interior_color,
        'Car Autopilot Type': car.autopilot_type,
        'Car Battery Range': car.battery_range,
      });
      params.set('car', String(car.id));
    } else {
      params.delete('car');
    }
    navigate({ search: `?${params.toString()}`, hash: `#${mode}` });
    setCarDetails(car);
  };
  const handleGoBack = () => navigate({ pathname: routes[pages.home], search });
  const handleClearLocationFilter = () => dispatch(uiActions.clearLocationFilter.base());
  const handleGoToPickUp = () => {
    const params = new URLSearchParams(search);
    params.delete('car');
    navigate({ search: `?${params.toString()}`, hash: `#${CAR_TABS_MODE.PICK_UP}` }, { replace: true });
    dispatch(uiActions.setCarsTabMode.base(CAR_TABS_MODE.PICK_UP));
    dispatch(uiActions.setNeedsDelivery.base(false));
    dispatch(checkoutActions.clearPurchaseData.base());
  };

  useEffect(() => {
    mixpanel.track('Page Viewed', {
      Page: carDetails ? 'Car Details' : 'Cars List',
    });
  }, [carDetails]);

  useEffect(() => () => dispatch(uiActions.clearCarSearch.base()), []);

  useEffect(() => {
    dispatch(uiActions.setPercentageComplete.base(0));
  }, []);

  useLoadCarDetails();

  return (
    <div
      className={cx(
        'cars-parent-container',
      )}
    >
      <DateFilters className="d-md-none" />
      <div
        className={cx(
          'pt-4',
          'position-relative',
          'd-flex',
          'flex-column',
          'w-100',
          'cars-container',
          { 'd-none d-xl-block': Boolean(carDetails) }
        )}
      >
        <DateFilters className="px-4" />
        {Boolean(filteredLocation) && (
          <PageTitle
            title={data.length === 1 ? '1 Car Available' : `${data.length} Cars Available`}
            className={cx('px-4 pb-4', { 'box-shadow2': scrollableData?.scrollTop > 0 })}
            onGoBack={handleClearLocationFilter}
          />
        )}
        {(!filteredLocation && showCars) && (
          <PageTitle
            title="Select Your Car"
            className={cx('px-4 pb-4', {
              'box-shadow2': scrollableData?.scrollTop > 0 && mode !== CAR_TABS_MODE.DELIVERY
            })}
            onGoBack={mode === CAR_TABS_MODE.PICK_UP ? handleGoBack : handleGoToPickUp}
          />
        )}
        {mode === CAR_TABS_MODE.DELIVERY && (
          <CarsDelivery
            inputFocused={deliveryInputFocused}
            setInputFocused={setDeliveryInputFocused}
            refData={scrollableData}
          />
        )}
        {showCars && (
          <CarsList
            onClick={handleCarClick}
            days={selectedDays}
          />
        )}
      </div>
      {Boolean(carDetails) && (
        <CarDetails
          onGoBack={() => handleCarClick(undefined)}
          days={selectedDays}
          isDelivery={mode === CAR_TABS_MODE.DELIVERY}
        />
      )}
    </div>
  );
};

export { Cars };
