import React from 'react';
import { useSelector } from 'react-redux';
import { LinearProgressBar } from './LinearProgressBar';

import { uiSelectors } from '../../redux/slices';

const icons = [
  { name: 'directions_car', text: 'Select Car' },
  { name: 'account_circle', text: 'Driver Info' },
  { name: 'payments', text: 'Checkout' },
];

const TopBar = () => {
  const percentage = useSelector(uiSelectors.selectPercentageComplete) as number;

  return (
    <div className="d-flex align-items-center justify-content-center mt-5 mb-2">
      <LinearProgressBar
        progress={percentage}
        icons={icons}
        className="mb-4"
        barHeight={2}
      />
    </div>
  );
};

TopBar.defaultProps = {

};

export { TopBar };
