import React from 'react';

import { LoadingSpinner } from '../LoadingSpinner';

const MapLoading = () => (
  <div className="map-box">
    <LoadingSpinner absolute />
  </div>
);

export { MapLoading };
