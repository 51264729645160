import { Icon } from '@mui/material';

import { Typography } from 'components/common';

const DepositWithheld = () => {
  return (
    <div>
      <Typography variant="h2">
        Deposit Withheld
      </Typography>

      <div>
        <Typography>
          Violating the following policies will result in your deposit being withheld.
        </Typography>
      </div>

      <div className="d-flex gap-3">
        <Icon style={{ fontSize: '35px', marginTop: '5px' }}>
          battery_3_bar
        </Icon>

        <div className="d-flex flex-column gap-2">
          <Typography variant="h3" className="m-0">
            No running the battery down to 0
          </Typography>

          <Typography>
            This damages the battery and leaves you stranded.
          </Typography>
        </div>
      </div>

      <div className="d-flex gap-3">
        <Icon style={{ fontSize: '35px', marginTop: '5px' }}>
          smoke_free
        </Icon>

        <div className="d-flex flex-column gap-2">
          <Typography variant="h3" className="m-0">
            No smoking
          </Typography>

          <Typography>
            Including cigarettes, marijuana, vaping or any other substance.
          </Typography>
        </div>
      </div>

      <div className="d-flex gap-3 align-items-center">
        <Icon style={{ fontSize: '35px', marginTop: '5px' }}>
          person
        </Icon>

        <Typography variant="h3" className="m-0">
          No unauthorized drivers
        </Typography>
      </div>
    </div>
  );
};

export default DepositWithheld;
