import { Icon } from '@mui/material';

import { Typography } from 'components/common';

const CriticalInfo = () => {
  return (
    <div>
      <Typography variant="h2">
        Critical info
      </Typography>

      <div className="d-flex gap-3">
        <Icon style={{ fontSize: '40px', marginTop: '5px' }}>
          gavel
        </Icon>

        <div className="d-flex flex-column gap-2">
          <Typography variant="h3" className="m-0">
            Late and overdue
          </Typography>

          <Typography>
            There is a $50 per hour late fee if the vehicle is not returned on time.
            If the car is not returned within 24 hours of when it&apos;s due back,
            it will be reported stolen and you may be subject to criminal charges.
          </Typography>
        </div>
      </div>

      <div className="d-flex gap-3">
        <Icon style={{ fontSize: '40px', marginTop: '5px' }}>
          attach_money
        </Icon>

        <div className="d-flex flex-column gap-2">
          <Typography variant="h3" className="m-0">
            Parking and traffic tickets
          </Typography>

          <Typography>
            If you get a ticket while traveling and don&apos;t pay before your trip ends,
            there&apos;s a $50 fee per unpaid ticket. Pay promptly to avoid extra charges.
          </Typography>

          <Typography variant="h3" className="m-0">
            Tolls
          </Typography>

          <Typography>
            All tolls are calculated automatically and incur a $1.50 admin fee per toll.
            If you pay in cash, make sure to take photos of any receipts so we can reimburse you.
          </Typography>

          <Typography>
            Toll passes are already installed on the car.
            Do not install your own, otherwise you'll be doubly charged for each toll you pass through.
          </Typography>
        </div>
      </div>

      <div className="d-flex gap-3">
        <Icon style={{ fontSize: '40px', marginTop: '5px' }}>
          savings
        </Icon>

        <div className="d-flex flex-column gap-2">
          <Typography variant="h3" className="m-0">
            Deposits
          </Typography>

          <Typography>
            Deposits take 5-10 business days after the end of your trip to be returned
            to your account. This may take longer if you have outstanding or
            unpaid balances.
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default CriticalInfo;
