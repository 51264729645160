import { Link } from 'react-router-dom';

interface Props {
  text: string;
  link?: string;
}

const Banner = ({
  text,
  link,
}: Props) => {
  if (link) {
    return (
      <Link
        className="w-100"
        to={link}
      >
        <div
          className="w-100"
          style={{
            padding: '10px',
            backgroundColor: '#f5f5f5',
            color: '#333',
            textAlign: 'center',
            marginBottom: '20px',
            borderRadius: '5px',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
          }}
        >
          {text}
        </div>
      </Link>
    );
  }

  return (
    <div
      className="w-100"
      style={{
        padding: '20px',
        backgroundColor: '#f5f5f5',
        color: '#333',
        textAlign: 'center',
        marginBottom: '20px',
        borderRadius: '5px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
      }}
    >
      {text}
    </div>
  );
};

export default Banner;
