import React, { ReactElement } from 'react';
import cx from 'classnames';

import { Typography } from '../Typography';

interface IProps {
  text: string | ReactElement;
  icon?: JSX.Element;
  className?: string;
  variant?: 'h1' | 'h2' | 'h3' | 'body1' | 'body2' | 'body3';
}

const Chip = ({ icon, text, className, variant = 'body3' }: IProps) => (
  <div className={cx('chip', className)}>
    {icon}
    <Typography inline variant={variant}>{text}</Typography>
  </div>
);

Chip.defaultProps = {
  icon: undefined,
  className: undefined,
};

export { Chip };
