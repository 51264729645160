import { all, call, put, select, takeLatest, take } from 'redux-saga/effects';
import * as api from '../../api';
import { locationsActions, uiSelectors, partnerSelectors } from '../slices';

export function* loadLocationsFlow({ payload }: any): Generator {
  try {
    const data = yield call(api.getAllLocations, {
      partner: payload?.portalPartner,
    });
    yield put(locationsActions.loadLocations.success(data));
  } catch (err) {
    yield put(locationsActions.loadLocations.failed(err));
  }
}

export function* watchPortalPartner(): Generator {
  let previousPortalPartner = yield select(partnerSelectors.selectPartner);
  while (true) {
    yield take();
    const portalPartner: any = yield select(partnerSelectors.selectPartner);
    if (portalPartner !== previousPortalPartner && portalPartner.data?.url_slug) {
      yield call(loadLocationsFlow, { payload: { portalPartner: portalPartner.data?.url_slug } });
      previousPortalPartner = portalPartner;
    }
  }
}

export function* loadAvailableCarsFlow(): Generator {
  try {
    const carsSearch: any = yield select(uiSelectors.selectCarsSearch);
    const needsDelivery: any = yield select(uiSelectors.selectNeedsDelivery);
    const portalPartner: any = yield select(partnerSelectors.selectPartner);
    if (carsSearch.city && carsSearch?.dates?.startDate) {
      const data = yield call(api.getAvailableCars, {
        city: carsSearch.city,
        start: carsSearch?.dates?.startDate?.toISOString(),
        end: carsSearch?.dates?.endDate?.toISOString(),
        delivery_address: carsSearch?.address?.deliveryTo?.address,
        return_address: carsSearch?.address?.collectAt?.address,
        delivery: needsDelivery,
        portal_partner: portalPartner.data?.url_slug,
      });
      yield put(locationsActions.loadAvailableCars.success(data));
    } else {
      yield put(locationsActions.loadAvailableCars.success({ cars: [] }));
    }
  } catch (err) {
    yield put(locationsActions.loadAvailableCars.failed(err));
  }
}

export default function* root() {
  yield all([
    watchPortalPartner(),
    takeLatest(locationsActions.loadLocations.types.BASE, loadLocationsFlow),
    takeLatest(locationsActions.loadAvailableCars.types.BASE, loadAvailableCarsFlow)
  ]);
}
