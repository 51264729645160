// @ts-ignore
/* eslint-disable */

import React from 'react';
import cx from 'classnames';

const Model = ({ className, ...props }: any) => (
  <svg className={cx('svg-icon', className)} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g>
      <path d="M5.625 18.5V19.75C5.625 19.9667 5.55 20.146 5.4 20.288C5.25 20.4293 5.075 20.5 4.875 20.5H4.25C4.03333 20.5 3.85433 20.4293 3.713 20.288C3.571 20.146 3.5 19.9667 3.5 19.75V12.075L5.55 6.25C5.61667 6.01667 5.75 5.83333 5.95 5.7C6.15 5.56667 6.375 5.5 6.625 5.5H17.425C17.6583 5.5 17.8707 5.571 18.062 5.713C18.254 5.85433 18.3833 6.03333 18.45 6.25L20.5 12.075V19.75C20.5 19.9667 20.4293 20.146 20.288 20.288C20.146 20.4293 19.9667 20.5 19.75 20.5H19.125C18.925 20.5 18.75 20.4293 18.6 20.288C18.45 20.146 18.375 19.9667 18.375 19.75V18.5H5.625ZM5.6 10.575H18.4L17.125 7H6.875L5.6 10.575ZM7.475 15.85C7.825 15.85 8.129 15.7207 8.387 15.462C8.64567 15.204 8.775 14.8917 8.775 14.525C8.775 14.175 8.64567 13.871 8.387 13.613C8.129 13.3543 7.81667 13.225 7.45 13.225C7.1 13.225 6.796 13.3543 6.538 13.613C6.27933 13.871 6.15 14.1833 6.15 14.55C6.15 14.9 6.27933 15.204 6.538 15.462C6.796 15.7207 7.10833 15.85 7.475 15.85ZM16.55 15.85C16.9 15.85 17.204 15.7207 17.462 15.462C17.7207 15.204 17.85 14.8917 17.85 14.525C17.85 14.175 17.7207 13.871 17.462 13.613C17.204 13.3543 16.8917 13.225 16.525 13.225C16.175 13.225 15.871 13.3543 15.613 13.613C15.3543 13.871 15.225 14.1833 15.225 14.55C15.225 14.9 15.3543 15.204 15.613 15.462C15.871 15.7207 16.1833 15.85 16.55 15.85ZM5 17H19V12.075H5V17Z" />
    </g>
  </svg>
);

export { Model };
