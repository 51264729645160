import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { uiActions, uiSelectors } from '../redux/slices';

const useDarkMode = () => {
  const dispatch = useDispatch();
  const darkMode = useSelector(uiSelectors.selectDarkMode);

  const handleChangeMode = ({ matches }: MediaQueryListEvent) => {
    dispatch(uiActions.setDarkMode.base(matches));
  };

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('dark-theme');
    } else {
      document.body.classList.remove('dark-theme');
    }
  }, [darkMode]);

  useEffect(() => {
    handleChangeMode({
      matches: window.matchMedia('(prefers-color-scheme: dark)').matches
    } as MediaQueryListEvent);
    window.matchMedia('(prefers-color-scheme: dark)').onchange = handleChangeMode;
  }, []);
};

export { useDarkMode };
