import { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import cx from 'classnames';
import { Check, Extend } from 'assets/icons';
import './styles.scss';

declare const window: any;

const ConfirmSelectCard = ({
  mediaPath,
  body,
  onClick,
  active = false,
  enableEnlarge = true,
  footer = (
    <>
      Click to enlarge

      <Extend stroke="#76CCCC" />
    </>
  ),
}: {
  mediaPath: string;
  body: any;
  onClick: (isActive: boolean) => void;
  active?: boolean;
  enableEnlarge?: boolean;
  footer?: React.ReactNode;
}) => {
  const [isActive, setIsActive] = useState(active);
  const [showEnlarged, setShowEnlarged] = useState(false);
  const containerRef = useRef(null);
  const playerRef = useRef<YT.Player | null>(null);

  useEffect(() => {
    if (mediaPath.includes('youtube.com') && window.YT) {
      playerRef.current = new window.YT.Player('player', {
        videoId: '-FeMwPUAOLM',
        height: '100%',
        width: '100%',
      });
    }
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (
        !entries[0].isIntersecting
        && playerRef.current
        && playerRef.current.getPlayerState
        && playerRef.current.getPlayerState() === YT.PlayerState.PLAYING
      ) {
        playerRef.current.stopVideo();
      }
    });

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    // Clean up on unmount
    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    setIsActive(active);
  }, [active]);

  return (
    <>
      <Modal
        ariaHideApp={false}
        isOpen={showEnlarged}
        style={{
          overlay: {
            zIndex: 1000,
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '29px',
            width: '100%',
            height: '100%',
            backgroundColor: 'black',
          },
        }}
        onRequestClose={() => setShowEnlarged(false)}
      >
        <div
          style={{ width: '100%', height: '100%' }}
          className="d-flex flex-column justify-content-space-between"
        >
          <div
            style={{
              color: '#FFF',
              fontSize: '0.875rem',
              fontFamily: 'neue-haas-grotesk-display',
              width: '12.625rem',
            }}
          >
            {body}
          </div>

          <img
            src={`${mediaPath}`}
            alt="yoyo"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
          />

          <div
            className="d-flex flex-row align-items-center justify-content-space-between w-100"
            style={{ color: 'white' }}
            onClick={() => {
              setShowEnlarged(false);
            }}
          >
            <div
              style={{
                color: '#FFF',
                fontSize: '0.875rem',
                fontFamily: 'neue-haas-grotesk-display',
              }}
            >
              Click to reduce
            </div>

            <Extend stroke="#AFAFAF" />
          </div>
        </div>
      </Modal>

      <div
        ref={containerRef}
        className="confirm-select-card"
        style={{
          position: 'relative',
        }}
        onClick={() => {
          setIsActive(!isActive);
          onClick(!isActive);
        }}
      >
        <div style={{ maxHeight: '12.5rem', minHeight: '5rem', width: '100%' }}>
          {
            // eslint-disable-next-line no-nested-ternary
            mediaPath !== ''
              ? (
                mediaPath.includes('youtube.com')
                  ? (
                    <div id="player" />
                  ) : (
                    <img
                      src={`${mediaPath}`}
                      alt="yo"
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        borderTopRightRadius: '0.5rem',
                        borderTopLeftRadius: '0.5rem',
                      }}
                    />
                  )
              ) : (
                <div
                  style={{
                    backgroundColor: '#1A1A1F',
                    height: '100%',
                    width: '100%',
                    borderTopRightRadius: '0.5rem',
                    borderTopLeftRadius: '0.5rem',
                  }}
                />
              )
          }

          <div
            className="d-flex align-items-center justify-content-center"
            style={{
              position: 'absolute',
              right: '1rem',
              top: '1rem',
              backgroundColor: 'black',
              width: '3.1rem',
              height: '3.1rem',
              borderRadius: '3.1rem',
            }}
          >
            <div
              style={{
                width: '2rem',
                height: '2rem',
                borderColor: isActive ? '#06C167' : 'white',
                borderWidth: '0.15rem',
                borderRadius: '2rem',
                borderStyle: 'solid',
              }}
            >
              {isActive && <Check stroke="#06C167" strokeWidth="1.3px" />}
            </div>
          </div>
        </div>

        <div
          className={cx(
            'confirm-select-card__textbox-container',
          )}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div
            className={cx(
              'confirm-select-card__textbox-container-body',
            )}
          >
            {
              body
            }
          </div>

          <div
            className={cx(
              'confirm-select-card__textbox-container-footer',
            )}
            onClick={() => {
              if (enableEnlarge) setShowEnlarged(true);
            }}
          >
            <div
              className="d-flex flex-row align-items-center gap-2"
              style={{
                color: '#76CCCC',
                alignSelf: 'flex-end',
                fontSize: '0.75rem',
                fontFamily: 'neue-haas-grotesk-display',
                fontWeight: 500,
              }}
            >
              {
                footer
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmSelectCard;
