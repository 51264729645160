import { useState } from 'react';
import { useFormik } from 'formik';
import { InputField } from 'components/common';
import * as api from 'api';
import { APIStatus } from 'types';
import { LoadingSpinner } from 'components/LoadingSpinner';

const UserForm = ({
  onSubmit,
}: {
  onSubmit: () => void;
}) => {
  const [submitFormStatus, setSubmitFormStatus] = useState<APIStatus>({
    loading: false,
    success: false,
    error: null,
  });

  const formikForm = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      signature: '',
    },
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    validate: vals => {
      const error = {} as any;

      if (vals.firstname === '') {
        error.firstname = 'Required';
      }

      if (vals.lastname === '') {
        error.lastname = 'Required';
      }

      if (vals.signature === '') {
        error.signature = 'Required';
      }

      return error;
    },
    onSubmit: async vals => {
      setSubmitFormStatus({ loading: true, success: false, error: null });

      try {
        await api.completedCarInstructions(vals);
        setSubmitFormStatus({ loading: false, success: true, error: null });

        onSubmit();
      } catch (error: any) {
        setSubmitFormStatus({
          loading: false,
          success: false,
          error: { code: error.response.status, message: error.response.data.error },
        });
      }
    },
  });

  if (submitFormStatus.loading) {
    return (
      <LoadingSpinner />
    );
  }

  return (
    <div>
      <div
        style={{
          width: '21.375rem',
          height: '21.375rem',
          borderRadius: '0.5rem',
          border: '2px solid #D9D9DF',
          background: '#F4F4F4',
          padding: '1.5rem 1.875rem',
        }}
      >
        <div
          style={{
            fontFamily: 'neue-haas-grotesk-display',
            color: '#1A1A1F',
            fontWeight: 700,
          }}
        >
          Please fill out the form to complete the process.
        </div>

        <div className="d-flex flex-column gap-3 mt-4">
          <InputField
            label="First name*"
            type="text"
            name="firstname"
            value={formikForm.values.firstname}
            onChange={formikForm.handleChange}
            required
            style={{
              backgroundColor: 'white',
            }}
          />

          <InputField
            label="Last name*"
            type="text"
            name="lastname"
            value={formikForm.values.lastname}
            onChange={formikForm.handleChange}
            required
            style={{
              backgroundColor: 'white',
            }}
          />

          <InputField
            label="Signature*"
            type="text"
            name="signature"
            value={formikForm.values.signature}
            onChange={formikForm.handleChange}
            required
            style={{
              backgroundColor: 'white',
            }}
          />
        </div>
      </div>

      <button
        className="btn btn-primary btn-lg w-100 mt-3"
        style={{
          backgroundColor: formikForm.isValid ? '#1A1A1F' : '#D9D9DF',
          color: 'white',
          fontFamily: 'sf-pro',
          lineHeight: '1.5rem',
          fontSize: '1.125rem',
          height: '3.5rem',
        }}
        disabled={!formikForm.isValid}
        onClick={() => {
          formikForm.handleSubmit();
        }}
      >
        Submit
      </button>
    </div>
  );
};

export default UserForm;
