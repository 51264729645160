import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import './styles.scss';
import mixpanel from 'mixpanel-browser';

import { checkoutSelectors, locationsSelectors } from '../../../redux/slices';
import { IStoreCars, ICar, ISortFilters, SortValue, SortableFields } from '../../../types';

import { Reset } from '../../../assets/icons';
import { Filter, Card, Link, Scrollable, Typography } from '../../common';
import { CarFilters } from '../../Filters';
import { CarCard } from '../CarCard';
import { PoweredBy } from '../../PoweredBy';

const sortFilters: ISortFilters[] = [
  {
    id: 1,
    field: SortableFields.daily_rate,
    value: SortValue.ASC,
    title: 'Price: low to high'
  },
  {
    id: 2,
    field: SortableFields.daily_rate,
    value: SortValue.DESC,
    title: 'Price: high to low'
  },
];

interface IProps {
  onClick: (car: ICar) => void;
  days: number;
}

const CarsList = ({ onClick, days }: IProps) => {
  const [sortShow, setSortShow] = useState(false);
  const [selectedSort, setSelectedSort] = useState<ISortFilters | null>(sortFilters[0]);
  const { isLoading, data } = useSelector(locationsSelectors.selectCars) as IStoreCars;
  const carDetails = useSelector(checkoutSelectors.selectCarDetails) as ICar;

  const onSortClear = () => {
    setSelectedSort(null);
    setSortShow(false);
  };

  const handleSelectedSort = (sortFilter: ISortFilters) => {
    mixpanel.track('Button Clicked', {
      'Button Name': 'Car Price Sort',
      'Car Price Sort': sortFilter.value,
    });

    setSelectedSort(sortFilter);
  };

  const sortedCars = useMemo(() => {
    if (selectedSort) {
      return [...data].sort((firstEl, secondEl) => {
        if (selectedSort.value === SortValue.ASC) {
          return firstEl[selectedSort.field] - secondEl[selectedSort.field];
        }
        return secondEl[selectedSort.field] - firstEl[selectedSort.field];
      });
    }
    return data;
  }, [data, selectedSort]);

  return (
    <Scrollable id="cars" className="cars-list-scroll mb-5">
      <CarFilters />
      {isLoading && (
        <>
          <CarCard showPlaceholder car={{} as ICar} />
          <CarCard showPlaceholder car={{} as ICar} />
        </>
      )}
      {
        !isLoading && !data.length && (
          <Typography variant="body1" className="text-center mt-5">
            No available cars for these dates &#128532;
            <small>Please try another date or location.</small>
          </Typography>
        )
      }
      {
        !isLoading && !!data.length && (
          <>
            <Filter
              className="mb-4"
              text={`Sort By${selectedSort?.title ? `: ${selectedSort.title}` : ''}`}
              show={sortShow}
              onShow={() => setSortShow(true)}
              onClose={() => setSortShow(false)}
              selected={0}
            >
              <div className="d-flex flex-column w-100 gap-2">
                {sortFilters.map(el => (
                  <Card
                    key={el.value}
                    type="radio"
                    name={el.title}
                    checked={selectedSort?.id === el.id}
                    onChange={() => handleSelectedSort(el)}
                  >
                    <Typography
                      className="m-0 d-flex align-items-center justify-content-between"
                      variant="h3"
                    >
                      {el.title}
                    </Typography>
                  </Card>
                ))}
                <div className="d-flex justify-content-end mt-2">
                  <Link
                    className="align-items-center gap-2 d-md-flex mb-3"
                    onClick={onSortClear}
                  >
                    Clear
                    <Reset className="ml-2" />
                  </Link>
                </div>
              </div>
            </Filter>

            {sortedCars.map(item => (
              <CarCard
                key={item.id}
                car={item}
                days={days}
                onClick={() => onClick(item)}
                // disabled={carDetails && carDetails.id !== item.id}
                className={carDetails?.id === item.id ? 'focus' : undefined}
              />
            ))}
          </>
        )
      }
      <PoweredBy className="mb-5" />
    </Scrollable>
  );
};

export { CarsList };
