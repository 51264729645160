import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import { Tabs, DatePicker } from '../common';
import { FilterButton } from './FilterButton';
import { CAR_TABS_MODE, DATES, REFERRAL_CODE_KEY } from '../../constants';
import { IDates, IDisplayDates, IStoreCarsSearch, ICar } from '../../types';
import {
  checkoutActions,
  checkoutSelectors,
  locationsActions,
  uiActions,
  uiSelectors,
} from '../../redux/slices';
import { getFormattedDateStringFromInput } from '../../helpers/utils';

interface IProps {
  isMapFilters?: boolean;
  className?: string;
}

export const FILTER_TABS = [
  { value: CAR_TABS_MODE.PICK_UP, label: 'Pick Up' },
  { value: CAR_TABS_MODE.DELIVERY, label: 'Delivery' },
];

const DateFilters = ({ isMapFilters, className }: IProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search, hash } = useLocation();
  const tabMode = useSelector(uiSelectors.selectCarsTabMode) as string;
  const displayDates = useSelector(uiSelectors.selectDisplayDates) as IDisplayDates;
  const { dates } = useSelector(uiSelectors.selectCarsSearch) as IStoreCarsSearch;
  const uiGMTOffset = useSelector(uiSelectors.selectGMTOffset) as string;
  const carDetails = useSelector(checkoutSelectors.selectCarDetails) as ICar;
  const [showDatePicker, setShowDatePicker] = useState<boolean>(!carDetails);
  const handleOpenDatePicker = () => setShowDatePicker(true);
  const handleCloseDatePicker = () => setShowDatePicker(false);

  const displayDatesObject = {
    startDate: displayDates.startDT || dates.startDate,
    endDate: displayDates.endDT || dates.endDate,
    gmtOffset: uiGMTOffset || dates.gmtOffset,
  };

  const handleTabChange = (tab: string) => {
    mixpanel.track('Button Clicked', {
      'Button Name': 'Pickup/Delivery Tabs',
      'Pickup/Delivery Tab': tab,
    });

    dispatch(uiActions.clearAddress.base());
    const params = new URLSearchParams(search);
    params.delete('car');
    navigate({ search: `?${params.toString()}`, hash: `#${tab}` }, { replace: true });
    dispatch(locationsActions.loadAvailableCars.base());
    dispatch(uiActions.setCarsTabMode.base(tab));
    dispatch(uiActions.setNeedsDelivery.base(tab === CAR_TABS_MODE.DELIVERY));
    dispatch(checkoutActions.clearPurchaseData.base());
  };

  const initDates = (newDates: IDates) => {
    const params = new URLSearchParams(search);

    params.set(DATES.START, getFormattedDateStringFromInput(
      new Date(newDates.startDate)
    ));
    params.set(DATES.END, getFormattedDateStringFromInput(
      new Date(newDates.endDate)
    ));
    const newDisplayDates = {
      startDT: getFormattedDateStringFromInput(new Date(newDates.startDate)),
      endDT: getFormattedDateStringFromInput(new Date(newDates.endDate)),
      gmtOffset: params.get(DATES.GMT_OFFSET) || uiGMTOffset,
    };
    dispatch(uiActions.setDisplayDates.base(newDisplayDates));
    dispatch(uiActions.setCarsSearch.base({ dates: newDates }));
    if (uiGMTOffset !== '-0000') {
      params.set(DATES.GMT_OFFSET, uiGMTOffset);
    }

    navigate({ search: `?${params.toString()}`, hash }, { replace: true });
  };

  const areDatesEqual = (oldDate: IDates, newDate: IDates): boolean => {
    if (
      oldDate.startDate instanceof Date
      && oldDate.endDate instanceof Date
      && newDate.startDate instanceof Date
      && newDate.endDate instanceof Date
    ) {
      const equalDates = oldDate.startDate.getTime() === newDate.startDate.getTime()
        && oldDate.endDate.getTime() === newDate.endDate.getTime();

      const equalGMT = oldDate.gmtOffset === newDate.gmtOffset;

      return equalDates && equalGMT;
    }

    return false;
  };

  const handleDatesChange = (newDates: IDates) => {
    const params = new URLSearchParams(search);
    if (
      !areDatesEqual(dates, newDates)
      && dates.gmtOffset
      && newDates.gmtOffset
    ) {
      params.set(DATES.START, getFormattedDateStringFromInput(
        new Date(newDates.startDate)
      ));
      params.set(DATES.END, getFormattedDateStringFromInput(
        new Date(newDates.endDate)
      ));
      const newDisplayDates = {
        startDT: getFormattedDateStringFromInput(new Date(newDates.startDate)),
        endDT: getFormattedDateStringFromInput(new Date(newDates.endDate)),
        gmtOffset: params.get(DATES.GMT_OFFSET) || uiGMTOffset,
      };
      dispatch(uiActions.setDisplayDates.base(newDisplayDates));
      dispatch(uiActions.setCarsSearch.base({ dates: newDates }));
      if (uiGMTOffset !== '-0000') {
        params.set(DATES.GMT_OFFSET, uiGMTOffset);
      }
      dispatch(locationsActions.loadAvailableCars.base());
      params.delete('car');
      dispatch(checkoutActions.setCarDetails.base(null));
      navigate({ search: `?${params.toString()}`, hash }, { replace: true });

      mixpanel.track('Button Clicked', {
        'Button Name': 'Trip Start/End Date',
        'Trip Start Date': getFormattedDateStringFromInput(new Date(newDates.startDate)),
        'Trip End Date': getFormattedDateStringFromInput(new Date(newDates.endDate)),
      });
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(search);
    let startDateToUse = new Date();
    let endDateToUse = new Date();
    startDateToUse.setDate(startDateToUse.getDate() + 1);
    endDateToUse.setDate(endDateToUse.getDate() + 2);
    if (params.get(DATES.START)) {
      startDateToUse = new Date(getFormattedDateStringFromInput(
        new Date(params.get(DATES.START) || new Date(dates.startDate))
      ));
    } else {
      startDateToUse = new Date(dates.startDate);
    }
    if (params.get(DATES.END)) {
      endDateToUse = new Date(getFormattedDateStringFromInput(
        new Date(params.get(DATES.END) || new Date(dates.endDate))
      ));
    } else {
      endDateToUse = new Date(dates.endDate);
    }

    if (params.get(REFERRAL_CODE_KEY) && params.get(REFERRAL_CODE_KEY)?.replaceAll(' ', '') !== '') {
      dispatch(uiActions.setReferralCode.base(params.get(REFERRAL_CODE_KEY)));
    } else {
      dispatch(uiActions.setReferralCode.base(''));
    }
    initDates(
      {
        // @ts-ignore
        startDate: startDateToUse,
        // @ts-ignore
        endDate: endDateToUse,
        // @ts-ignore
        gmtOffset: params.get(DATES.GMT_OFFSET) || uiGMTOffset,
      },
    );
  }, []);

  return (
    <div
      style={{ top: 24 }}
      className={cx(
        'gap-2 mb-3 z-index-10 hidden-scroll',
        className,
        isMapFilters ? {
          'position-absolute': true,
          'd-flex': true,
          'd-md-none': true,
          'justify-content-center': true,
          'overflow-x-auto': true,
          'w-100': true,
        } : {
          'd-none': true,
          'd-md-flex': true,
        }
      )}
    >
      <Tabs
        options={FILTER_TABS}
        value={tabMode}
        onChange={handleTabChange}
      />
      <DatePicker
        dates={displayDatesObject}
        className="flex-md-grow-1"
        onChange={handleDatesChange}
        show={showDatePicker}
        onShow={handleOpenDatePicker}
        onClose={handleCloseDatePicker}
        gmtOffset={uiGMTOffset || dates.gmtOffset!}
      >
        <FilterButton onClose={handleCloseDatePicker} />
      </DatePicker>
    </div>
  );
};

DateFilters.defaultProps = {
  isMapFilters: false,
  className: undefined,
};

export { DateFilters };
