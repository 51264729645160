import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getPartnerConfig } from 'api';
import { partnerActions, partnerSelectors, IPartnerState } from 'redux/slices';

const usePartnerConfig = () => {
  const dispatch = useDispatch();
  const [partnerSlug, setPartnerSlug] = useState('');
  const partner = useSelector(partnerSelectors.selectPartner) as IPartnerState;
  const subdomain = window.location.hostname.replace(process.env.REACT_APP_BASE_URL!, '').replace('.', '');

  useEffect(() => {
    if (subdomain === 'agent') return;

    setPartnerSlug(subdomain);
  }, [subdomain]);

  const fetchPartnerConfig = async () => {
    if (partner.isLoading) return;

    dispatch(partnerActions.loadPartner.base());

    try {
      const data = await getPartnerConfig(partnerSlug);

      dispatch(partnerActions.loadPartner.success({ partner: data }));

      if (data.title) {
        document.querySelector('title')!.innerHTML = `${data.title} - Powered by Eon`;
      }
      if (data.description) {
        document.querySelector('meta[name="description"]')!.setAttribute('content', data.description);
      }
      if (data.keywords) {
        document.querySelector('meta[name="keywords"]')!.setAttribute('content', data.keywords);
      }
    } catch (err) {
      console.error(err);
      dispatch(partnerActions.loadPartner.failed());

      const BASE_URL = process.env.REACT_APP_BASE_URL;
      const PORT = process.env.REACT_APP_PORT;
      window.location.href = `${window.location.protocol}//${BASE_URL}${PORT ? `:${PORT}` : ''}`;
    }
  };

  useEffect(() => {
    if (partnerSlug && partner.data?.url_slug === partnerSlug) {
      return;
    }

    if (partnerSlug) {
      fetchPartnerConfig();
    }
  }, [partnerSlug, partner]);

  return partner;
};

export { usePartnerConfig };
