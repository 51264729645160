import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import Slider from 'react-slick';
import Zoom from 'react-medium-image-zoom';
import { ToastContainer, toast } from 'react-toastify';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-medium-image-zoom/dist/styles.css';
import './styles.scss';

import { IProfile, IReservation, IImage, APIStatus } from 'types';
import { profileSelectors } from 'redux/slices';
import { LoginFlow } from 'components/Authentication';
import Navbar from 'components/Navbar';
import { Button, Typography } from 'components/common';
import { LoadingSpinner } from 'components/LoadingSpinner';
import * as api from 'api';
import Dropzone from 'components/common/Dropzone';

const TripPhotos = () => {
  const [reservation, setReservation] = useState<IReservation>();
  const profile = useSelector(profileSelectors.selectProfile) as IProfile;
  const profileLoading = useSelector(profileSelectors.selectProfileLoading);
  const { id } = useParams();
  const [getTripStatus, setGetTripStatus] = useState<APIStatus>({
    loading: false,
    success: false,
    error: null,
  });
  const [imagesToUpload, setImagesToUpload] = useState<File[]>();
  const [updateTripStatus, setUpdateTripStatus] = useState<APIStatus>({
    loading: false,
    success: false,
    error: null,
  });

  const isLoggedIn = !!profile?.id;

  useEffect(() => {
    if (!id || !isLoggedIn) return;

    const fetchTrip = async () => {
      setGetTripStatus({ loading: true, success: false, error: null });

      try {
        const data = await api.getReservation(id);
        setReservation(data);
        setGetTripStatus({ loading: false, success: true, error: null });
      } catch (error: any) {
        setGetTripStatus({
          loading: false,
          success: false,
          error: { code: 500, message: error },
        });
        // eslint-disable-next-line no-console
        console.error('Error fetching data:', error);
      }
    };

    fetchTrip();
  }, [id, isLoggedIn]);

  const handleImagesChanged = (files: any[]) => {
    if (!reservation) return;

    const MAX_FILES = 40;

    if ((reservation.car_images.length + files.length) >= MAX_FILES) {
      // eslint-disable-next-line no-alert
      alert(`You can only upload a maximum of ${MAX_FILES} files`);
      return;
    }

    setImagesToUpload(files);
  };

  if (profileLoading || getTripStatus.loading) {
    return (
      <LoadingSpinner absolute />
    );
  }

  const handlePhotosUpload = async () => {
    try {
      setUpdateTripStatus({ loading: true, success: false, error: null });

      const payload = {
        car_images: imagesToUpload,
      };

      const data = await api.updateReservation({ payload, reservationId: reservation!.id });
      setUpdateTripStatus({ loading: false, success: true, error: null });
      setReservation(data);
      setImagesToUpload([]);
      toast.success('Photos uploaded successfully');
    } catch (error: any) {
      console.error('error', error);

      toast.success('Error uploading photos. Please try again.');

      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }

      setUpdateTripStatus({
        loading: false,
        success: false,
        error: { code: error.response.status, message: error.response.data.error },
      });
      // eslint-disable-next-line no-console
      console.error('Error fetching data:', error);
    }
  };

  if (!isLoggedIn) {
    return (
      <div>
        <Navbar />

        <div
          className="d-flex justify-content-center"
        >
          <div
            className="d-flex justify-content-center align-items-center flex-column"
            style={{
              maxWidth: '500px',
              overflow: 'hidden',
              padding: '0 16px 0 16px',
            }}
          >
            <LoginFlow buttonText="Login" />
          </div>
        </div>
      </div>
    );
  }

  if (!reservation) {
    return null;
  }

  return (
    <div>
      <Navbar />

      <ToastContainer autoClose={2500} />

      {
        (
          getTripStatus.loading
          || updateTripStatus.loading
        ) && (
          <LoadingSpinner absolute />
        )
      }

      <div
        className="d-flex justify-content-center mt-4"
      >
        <div
          className="d-flex justify-content-center align-items-center flex-column gap-4"
          style={{
            maxWidth: '500px',
            overflow: 'hidden',
            padding: '0 16px 0 16px',
          }}
        >
          <div
            className="d-flex flex-column w-100"
          >
            <div>
              <Typography variant="h2">
                {`Trip ID #${reservation.id}`}
              </Typography>
            </div>

            <div className="d-flex flex-column gap-1">
              <Typography>
                {
                  `Location: ${reservation.pickup_location.address}`
                }
              </Typography>
              <Typography>
                {
                  `
                  From:
                  ${moment.tz(reservation.start_time, reservation.pickup_location.timezone).format('MM/DD/YY, h:mma z')}
                  `
                }
              </Typography>
              <Typography>
                {
                  `
                  To:
                  ${moment.tz(reservation.end_time, reservation.pickup_location.timezone).format('MM/DD/YY, h:mma z')}
                  `
                }
              </Typography>
              <Typography>
                {
                  `Car Model: ${reservation.car.model_name} ${reservation.car.model_type}`
                }
              </Typography>
              <Typography>
                {
                  `Car License Plate: ${reservation.car.license_plate}`
                }
              </Typography>
            </div>
          </div>

          <div className="w-100">
            <Typography variant="h2">
              Upload Photos
            </Typography>

            <Typography>
              Upload photos of the car to confirm its condition. (40 photos max)
            </Typography>

            <Dropzone
              accept={{
                'image/png': ['.png'],
                'image/jpeg': ['.jpg', '.jpeg'],
              }}
              multiple
              onImagesChanged={handleImagesChanged}
              defaultFiles={imagesToUpload || []}
              previewStyle={{
                width: '100px',
              }}
              convertImgToJpeg
            />

            {
              imagesToUpload && imagesToUpload?.length > 0 && (
                <Button
                  onClick={handlePhotosUpload}
                  className="mb-3 mt-3"
                >
                  Submit new photos
                </Button>
              )
            }
          </div>

          {
            reservation.car_images && reservation.car_images.length > 0 && (
              <div
                className="mb-5"
                style={{ maxWidth: '90%' }}
              >
                <Typography variant="h2">
                  Saved Photos
                </Typography>

                <Slider
                  {
                  ...{
                    dots: true,
                    infinite: reservation.car_images.length > 3,
                    speed: 500,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    lazyLoad: 'anticipated',
                  }
                  }
                >
                  {
                    reservation.car_images.map((image: IImage) => (
                      <div
                        key={image.photo}
                      >
                        <Zoom>
                          <img
                            src={image.photo}
                            alt={image.photo}
                            style={{
                              width: '200px',
                              objectFit: 'contain',
                              margin: '0 auto',
                            }}
                          />
                        </Zoom>
                        <div className="mt-2">
                          {
                            moment(image.created_at)
                              .tz(reservation.pickup_location.timezone)
                              .format('MM/DD/YYYY HH:mm:ss z')
                          }
                        </div>
                      </div>
                    ))
                  }
                </Slider>
              </div>
            )
          }

        </div>
      </div>
    </div>
  );
};

export default TripPhotos;
