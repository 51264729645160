import React, { useState } from 'react';
import moment from 'moment';

import { Typography } from '../Typography';
import { DatePicker } from '../DatePicker';
import { IDates } from '../../../types';

const DatePickers = () => {
  const [show, setShow] = useState<boolean>(false);
  const [dates, setDates] = useState<IDates>({
    startDate: moment().toDate(),
    endDate: moment().add(1, 'days').toDate(),
    gmtOffset: '-0000',
  });

  return (
    <div>
      <Typography variant="h1">Inputs</Typography>
      <Typography variant="h1" className="mt-5">Date Picker</Typography>
      <DatePicker
        dates={dates}
        onChange={setDates}
        show={show}
        onShow={() => setShow(true)}
        onClose={() => setShow(false)}
        gmtOffset={dates.gmtOffset!}
      />
      <code>
        {'<DatePicker dates={dates} onChange={setDates} show={show} onShow={() => {}} onClose={() => {}} /> />'}
        <br />
        Props:
        <ul>
          <li>{'dates: IDates = { startDate: number|Date, endDate:number|Date }'}</li>
          <li>{'onChange: (dates: IDates) => void;'}</li>
          <li>show: boolean;</li>
          <li>{'onShow: (e: any) => void;'}</li>
          <li>{'onClose: (e: any) => void;'}</li>
          <li>format?: string; - shows how date should be format using moment.js</li>
          <li>className?: string;</li>
          <li>children?: any;</li>
        </ul>
      </code>
    </div>
  );
};

export { DatePickers };
