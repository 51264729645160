import React from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import { getBackgroundImageUri } from 'helpers/cars';
import { CAR_TABS_MODE } from '../../constants';
import { uiSelectors } from '../../redux/slices';
import { Card, Placeholder, Typography } from '../common';
import { ICar } from '../../types';
import { CarFeatures } from './CarFeatures';
import { getPriceWithDiscount, getPriceWithDelivery } from '../../utils';

interface IProps {
  car: ICar;
  days?: number;
  onClick?: () => void;
  showPlaceholder?: boolean;
  disabled?: boolean;
  className?: string;
}

const CarCard = ({ car, days, onClick, className, disabled, showPlaceholder }: IProps) => {
  const backgroundImage = getBackgroundImageUri(car);
  const mode = useSelector(uiSelectors.selectCarsTabMode);

  return (
    showPlaceholder ? (
      <Card radius="large" showPlaceholder className={cx(className, 'mb-3')}>
        <div className="d-flex justify-content-between align-items-end">
          <Placeholder className="h2 mb-2" style={{ width: 70 }} />
          <Placeholder className="h3 mb-1" style={{ width: 50 }} />
        </div>
        <div className="d-flex justify-content-between mb-1 text-body3">
          <Placeholder style={{ width: 50 }} />
          <Placeholder style={{ width: 30 }} />
        </div>
        <Placeholder className="img-background mb-2" style={{ height: 100 }} />
        <CarFeatures />
      </Card>
    ) : (
      <Card
        type="button"
        radius="large"
        disabled={disabled || !car.is_available}
        className={cx(className, 'mb-3')}
        onClick={onClick}
      >
        <div className="d-flex justify-content-between">
          <div>
            <Typography variant="h2" className="m-0">{car.model_name}</Typography>

            <Typography variant="body3" className="d-flex justify-content-between m-0">
              <span>{car.model_type}</span>
            </Typography>
          </div>

          <div className="mt-1 d-flex flex-column">
            <Typography variant="h3" className="m-0">
              {car.daily_rate !== getPriceWithDiscount(car, Number(days || 1)) ? (
                <>
                  <span className="text-decoration-line-through me-1" style={{ fontSize: 12 }}>
                    {car.daily_rate}
                  </span>
                  <span className="text-danger">
                    {`$${getPriceWithDiscount(car, Number(days || 1))} / day`}
                  </span>
                </>
              ) : (
                `$${getPriceWithDiscount(car, Number(days || 1))} / day`
              )}
            </Typography>

            <Typography variant="body3" className="align-self-end">
              <span>
                {
                  mode === CAR_TABS_MODE.DELIVERY
                    ? `$${getPriceWithDelivery(car, Number(days || 1), true)} / total`
                    : `$${getPriceWithDiscount(car, Number(days || 1), true)} / total`
                }
              </span>
            </Typography>
          </div>
        </div>
        <div
          className="img-background contain mb-2"
          style={{
            height: 100,
            opacity: (disabled || !car.is_available) ? 0.5 : 1,
            backgroundImage: `url(${backgroundImage})`,
          }}
        />
        <CarFeatures car={car} disabled={disabled || !car.is_available} />
      </Card>
    )
  );
};

CarCard.defaultProps = {
  days: 1,
  onClick: undefined,
  showPlaceholder: undefined,
  disabled: undefined,
  className: undefined,
};

export { CarCard };
