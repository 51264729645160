import { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Routes, Route, Navigate, useLocation, Outlet } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

import Navbar from 'components/Owner/Navbar';
import Login from 'components/Owner/Login';
import SignupFlow from 'components/Owner/SignupFlow';
import ForgotPassword from 'components/Owner/ForgotPassword';
import ForgotPasswordConfirm from 'components/Owner/ForgotPassword/ForgotPasswordConfirm';
import Home from 'components/Owner/Home';
import Cars from 'components/Owner/Cars';
import EditCar from 'components/Owner/Cars/EditCar';
import ConnectCars from 'components/Owner/ConnectCars';
import Calendar from 'components/Owner/Calendar';
import ReservationsDashboard from 'components/Owner/Reservations';
// import CreateReservation from 'components/Owner/Reservations/CreateReservation';
import EditReservation from 'components/Owner/Reservations/EditReservation';
import Payouts from 'components/Owner/Payouts';
import Payout from 'components/Owner/Payouts/Payout';
import Profile from 'components/Owner/Profile';
import ReportBug from 'components/ReportBug';
import Partner from 'components/Owner/Partner';
import Referrals from 'components/Owner/Referrals';

import { usePartnerConfig } from 'hooks';
import { ownerActions, ownerSelectors } from './redux/slices';
// import { useDarkMode } from './hooks';
import { IOwner } from './types';
import { OWNER_TOKEN_STORAGE } from './constants';

import 'App.scss';

function RequireAuth({ children }: { children?: any }) {
  const owner = useSelector(ownerSelectors.selectOwner, shallowEqual) as IOwner;
  const location = useLocation();

  if (!owner && !localStorage.getItem(OWNER_TOKEN_STORAGE)) {
    return <Navigate to="/owner/login" state={{ from: location }} />;
  }

  if (!location.pathname.includes('owner/signup/flow') && owner && !owner.setup_complete) {
    return <Navigate to="/owner/signup/flow" state={{ from: location }} />;
  }

  return children || <Outlet />;
}

const OwnerRouter = () => {
  const dispatch = useDispatch();
  const owner = useSelector(ownerSelectors.selectOwner) as IOwner;

  useEffect(() => {
    if (owner?.id) {
      mixpanel.identify(owner.id.toString());

      mixpanel.people.set({
        $name: owner.firstname && owner.lastname && `${owner.firstname} ${owner.lastname}`,
        $email: owner.email,
        $phone: owner.phone,
      });
    }
  }, [owner]);

  useEffect(() => {
    if (localStorage.getItem(OWNER_TOKEN_STORAGE) && !owner) {
      dispatch(ownerActions.getOwner.base());
    }
  }, [owner]);

  // useDarkMode();
  usePartnerConfig();

  return (
    <>
      <Navbar />
      <Routes>
        <Route path="signup" element={<Login showAsSignup />} />
        <Route path="login" element={<Login />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="forgot-password-confirm" element={<ForgotPasswordConfirm />} />
        <Route path="report-bug" element={<ReportBug />} />
        <Route path="cars/connect" element={<ConnectCars />} />

        <Route
          element={<RequireAuth />}
        >
          <Route path="signup/flow" element={<SignupFlow />} />
          <Route path="" element={<Home />} />
          <Route path="home" element={<Home />} />
          <Route path="cars" element={<Cars />} />
          <Route path="cars/:carId/edit" element={<EditCar />} />
          <Route path="calendar" element={<Calendar />} />
          <Route path="reservations" element={<ReservationsDashboard />} />
          {/* <Route path="reservations/create" element={<CreateReservation />} /> */}
          <Route path="reservations/:reservationId/edit" element={<EditReservation />} />
          <Route path="payouts" element={<Payouts />} />
          <Route path="payouts/:payoutId" element={<Payout />} />
          <Route path="partner" element={<Partner />} />
          <Route path="profile" element={<Profile />} />
          <Route path="referrals" element={<Referrals />} />
        </Route>
      </Routes>
    </>
  );
};

export default OwnerRouter;
