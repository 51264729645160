import cx from 'classnames';
import './styles.scss';

const SelectCard = ({
  mediaPath,
  title,
  subTitle,
  body,
  isActive,
  onClick,
}: {
  mediaPath: string;
  title: string;
  subTitle: string;
  body: string;
  isActive: boolean;
  onClick: () => void;
}) => {
  return (
    <div
      className="select-card"
      onClick={onClick}
      style={{
        background: `url(${mediaPath}) lightgray -74.141px -33.26px / 194.34% 109.503% no-repeat`,
      }}
    >
      <div
        className={cx(
          'select-card__textbox-container',
          isActive && 'active',
        )}
      >
        <div
          className={cx(
            'select-card__textbox-container-title',
            isActive && 'active',
          )}
        >
          {title}
          <span style={{ fontWeight: 400 }}>{subTitle}</span>
        </div>

        <div
          className={cx(
            'select-card__textbox-container-body',
            isActive && 'active',
          )}
        >
          {body}
        </div>
      </div>
    </div>
  );
};

export default SelectCard;
