import { all, put, call, takeLatest, select } from 'redux-saga/effects';
import axios from 'axios';

import * as api from '../../api';
import { AGENCY_TOKEN_STORAGE, CAR_TABS_MODE } from '../../constants';
import { checkoutActions, checkoutSelectors, uiSelectors, partnerSelectors } from '../slices';
import { pages, routes } from '../../pages';
import { dataURLtoFile, getConvertedDateWithOffset } from '../../helpers/utils';

export function* loadExtraFlow(): Generator {
  try {
    const extra: any = yield select(checkoutSelectors.selectExtraData);
    if (!extra.length) {
      const data: any = yield call(api.getCarExtra);
      yield put(checkoutActions.loadExtraData.success(data));
    }
  } catch (err) {
    yield put(checkoutActions.loadExtraData.failed(err));
  }
}

export function* loadInsurancesFlow(): Generator {
  try {
    const insurances: any = yield select(checkoutSelectors.selectInsurances);
    if (!insurances.length) {
      const data: any = yield call(api.getInsurances);
      yield put(checkoutActions.loadInsurances.success(data));
    }
  } catch (err) {
    yield put(checkoutActions.loadInsurances.failed(err));
  }
}

export function* createReservationFlow({ payload: { navigate, search, customerId, agentId } }: any): Generator {
  try {
    const carDetails: any = yield select(checkoutSelectors.selectCarDetails);
    const purchase: any = yield select(checkoutSelectors.selectPurchase);
    const carsSearch: any = yield select(uiSelectors.selectCarsSearch);
    const portalPartner: any = yield select(partnerSelectors.selectPartner);
    const referralCode: any = yield select(uiSelectors.selectReferralCode);
    const mode: any = yield select(uiSelectors.selectCarsTabMode);

    const payload = {
      car_id: carDetails.id,
      insurance_id: purchase.selectedInsurance,
      extra_ids: purchase.selectedOptions,
      status: 'pending',
      end_time: getConvertedDateWithOffset(
        carsSearch.dates.endDate,
        carDetails.default_location.gmt_offset
      ).toString(),
      start_time: getConvertedDateWithOffset(
        carsSearch.dates.startDate,
        carDetails.default_location.gmt_offset
      ).toString(),
      partner: portalPartner?.data?.url_slug,
      ref: referralCode,
      discount_code: purchase.discountData.code,
      ...(mode === CAR_TABS_MODE.DELIVERY ? {
        custom_pickup_location: carsSearch.address.deliveryTo?.address,
        custom_return_location: carsSearch.address.collectAt?.address,
      } : {}),
      customer_id: customerId,
      agent_id: agentId,
    };

    let data: any;
    if (customerId) {
      const api = `${process.env.REACT_APP_API_ENDPOINT}/api/v1`;

      const request = axios.create({
        headers: {
          Authorization: `Bearer ${localStorage.getItem(AGENCY_TOKEN_STORAGE)}`,
        },
      });

      data = yield request
        .post(`${api}/agencies/reservations/new`, payload)
        .then((res: any) => res.data);
    } else {
      data = yield call(api.createReservation, payload);
    }

    navigate({ pathname: routes[pages.reservation].replace(':id', data.reservation_id), search });
    yield put(checkoutActions.createReservation.success(data));
    yield put(checkoutActions.setCheckoutData.base({
      key: 'stripePaymentIntentSecret',
      data: data.stripe_payment_intent_client_secret,
    }));
  } catch (err) {
    yield put(checkoutActions.createReservation.failed(err));
  }
}

export function* loadReservationFlow({ payload: { id, navigate, search, fromAgency } }: any): Generator {
  try {
    let data: any;
    if (fromAgency) {
      const api = `${process.env.REACT_APP_API_ENDPOINT}/api/v1`;

      const request = axios.create({
        headers: {
          Authorization: `Bearer ${localStorage.getItem(AGENCY_TOKEN_STORAGE)}`,
        },
      });

      data = yield request
        .get(`${api}/agencies/reservations/${id}`)
        .then((res: any) => res.data);
    } else {
      data = yield call(api.getReservation, id);
    }

    yield put(checkoutActions.loadReservation.success(data));
  } catch (err) {
    yield put(checkoutActions.loadReservation.failed(err));
    navigate({ pathname: routes[pages.home], search });
  }
}

export function* cancelReservationFlow({ payload: { id } }: any): Generator {
  try {
    const data: any = yield call(api.cancelReservation, id);
    yield put(checkoutActions.cancelReservation.success(data));
  } catch (err) {
    // eslint-disable-next-line
    console.error(err);
    // TODO: when API will be ready -> remove comment
    // yield put(checkoutActions.cancelReservation.failed(err));
  }
}

export function* finalizeReservationFlow({ payload: { id, usedCredits, fromAgency } }: any): Generator {
  try {
    const checkout: any = yield select(checkoutSelectors.selectCheckout);
    const formData = new FormData();
    formData.append('contract_signature_image', dataURLtoFile(checkout.signature, `contract_signature-${id}.png`));
    formData.append('contract_initial_image', dataURLtoFile(checkout.signature, `contract_initial-${id}.png`));
    formData.append('reservation_id', id);
    formData.append('used_credits', usedCredits);

    let data: any;
    if (fromAgency) {
      const api = `${process.env.REACT_APP_API_ENDPOINT}/api/v1`;

      const request = axios.create({
        headers: {
          Authorization: `Bearer ${localStorage.getItem(AGENCY_TOKEN_STORAGE)}`,
        },
      });

      data = yield request
        .post(`${api}/agencies/reservations/finalize`, formData)
        .then((res: any) => res.data);
    } else {
      data = yield call(api.finalizeReservation, formData);
    }

    yield put(checkoutActions.finalizeReservation.success(data));
  } catch (err) {
    yield put(checkoutActions.finalizeReservation.failed(err));
  }
}

export default function* root() {
  yield all([
    takeLatest(checkoutActions.loadExtraData.types.BASE, loadExtraFlow),
    takeLatest(checkoutActions.loadInsurances.types.BASE, loadInsurancesFlow),
    takeLatest(checkoutActions.createReservation.types.BASE, createReservationFlow),
    takeLatest(checkoutActions.loadReservation.types.BASE, loadReservationFlow),
    takeLatest(checkoutActions.cancelReservation.types.BASE, cancelReservationFlow),
    takeLatest(checkoutActions.finalizeReservation.types.BASE, finalizeReservationFlow),
  ]);
}
