import React from 'react';
import cx from 'classnames';
import './styles.scss';
import Icon from '@mui/material/Icon';

interface IProps {
  className?: string;
  progress: number;
  icons?: any[];
  barHeight?: number;
}

const LinearProgressBar = ({ className, progress, icons, barHeight }: IProps) => {
  const calculatePercentage = (index: number, length: number): number => {
    if (length === 1) {
      return 100;
    }

    const percentage = (index / (length - 1)) * 100;

    return percentage;
  };

  return (
    <div className={cx('linear-progress-bar', className)}>
      <div className="linear-progress-bar-track">
        <div
          className="linear-progress-bar-fill"
          style={{ width: '100%', backgroundColor: 'grey', height: barHeight && `${barHeight}px` }}
        />
        <div
          className="linear-progress-bar-fill"
          style={{ width: `${progress}%`, height: barHeight && `${barHeight}px` }}
        />
        {
          icons?.map((icon, idx) => (
            <div
              key={idx}
              className={cx(
                'linear-progress-bar-icon',
                { 'linear-progress-bar-icon--active': progress >= calculatePercentage(idx, icons.length) },
              )}
              style={{
                left: `${calculatePercentage(idx, icons.length)}%`,
              }}
            >
              <Icon>{icon.name}</Icon>
              <div
                className="icon-text"
                style={{
                  position: 'absolute',
                  top: 55,
                  textAlign: 'center',
                  lineHeight: 1.3,
                  fontSize: 14,
                  width: 78,
                }}
              >
                {icon.text}
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
};

LinearProgressBar.defaultProps = {
  className: undefined,
  icons: [],
};

export { LinearProgressBar };
