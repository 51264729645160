// @ts-ignore
/* eslint-disable */

import React from 'react';
import cx from 'classnames';

const Seats = ({ className, ...props }: any) => (
  <svg className={cx('svg-icon', className)} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g>
      <path d="M8.20625 6.29985C7.68958 6.29985 7.25225 6.11652 6.89425 5.74985C6.53558 5.38319 6.35625 4.94985 6.35625 4.44985C6.35625 3.93319 6.53558 3.49552 6.89425 3.13685C7.25225 2.77885 7.68958 2.59985 8.20625 2.59985C8.70625 2.59985 9.13958 2.77885 9.50625 3.13685C9.87292 3.49552 10.0563 3.93319 10.0563 4.44985C10.0563 4.94985 9.87292 5.38319 9.50625 5.74985C9.13958 6.11652 8.70625 6.29985 8.20625 6.29985ZM14.0063 19.4999H7.80625C7.30625 19.4999 6.84792 19.3292 6.43125 18.9879C6.01458 18.6459 5.75625 18.2165 5.65625 17.6999L3.53125 6.99985H5.05625L7.20625 17.6999C7.22292 17.7665 7.26892 17.8332 7.34425 17.8999C7.41892 17.9665 7.48958 17.9999 7.55625 17.9999H14.0063V19.4999ZM19.3062 21.6999L16.5562 16.9999H9.48125C9.06458 16.9999 8.70192 16.8709 8.39325 16.6129C8.08525 16.3542 7.88125 16.0165 7.78125 15.5999L6.65625 10.1249C6.48958 9.39152 6.66058 8.74985 7.16925 8.19985C7.67725 7.64985 8.28125 7.37485 8.98125 7.37485C9.49792 7.37485 9.97292 7.52885 10.4062 7.83685C10.8396 8.14552 11.1229 8.57485 11.2562 9.12485L12.4062 14.4999H15.8813C16.1813 14.4999 16.4646 14.5792 16.7313 14.7379C16.9979 14.8959 17.2146 15.1082 17.3813 15.3749L20.6063 20.9499L19.3062 21.6999Z" />
    </g>
  </svg>
);

export { Seats };
