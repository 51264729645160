import React, { useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { useDispatch, useSelector } from 'react-redux';
import mixpanel from 'mixpanel-browser';
import './styles.scss';

import variables from '../../../assets/scss/variables.scss';

import { Typography, Card, Link } from '../../common';
import { checkoutActions, checkoutSelectors } from '../../../redux/slices';
import { ICheckout } from '../../../types';

interface IProps {
  className?: string;
}

const Signature = ({ className }: IProps) => {
  const dispatch = useDispatch();
  const [sigCanvas, setSigCanvas] = useState<any>();
  const { signature } = useSelector(checkoutSelectors.selectCheckout) as ICheckout;

  const setSignature = (data: string | null) => {
    dispatch(checkoutActions.setCheckoutData.base({ key: 'signature', data }));
  };

  const handleClear = () => {
    mixpanel.track('Cleared Signature');
    sigCanvas?.clear?.();
    setSignature(null);
  };

  const handleSave = () => {
    mixpanel.track('Finished Signature');
    setSignature(sigCanvas?.toDataURL?.());
  };

  return (
    <div className={className}>
      <Typography variant="h2">Sign To Agree</Typography>
      <Card radius="small" className="card-signature-container">
        <SignatureCanvas
          ref={(ref: any) => setSigCanvas(ref)}
          penColor={variables.blackprimary}
          backgroundColor="#9EC7E6"
          onEnd={handleSave}
          minWidth={1.5}
          maxWidth={1.5}
          dotSize={1.5}
          canvasProps={{
            className: 'signature',
          }}
          clearOnResize={false}
        />
        <div className="d-flex gap-3 align-items-center pt-2">
          <Typography variant="body2" className="m-0 flex-grow-1">
            Sign above to agree with the
            <br />
            terms & conditions.
          </Typography>
          {signature && (
            <Link onClick={handleClear}>Clear</Link>
          )}
        </div>
      </Card>
    </div>
  );
};

Signature.defaultProps = {
  className: undefined,
};

export { Signature };
