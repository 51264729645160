import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import { useFormik } from 'formik';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
// @ts-ignore
import AppleSignin from 'react-apple-signin-auth';

import { Eon } from 'assets/icons';
import { InputField, Typography, Button } from 'components/common';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { ownerActions, ownerSelectors } from 'redux/slices';
import { VALIDATIONS } from '../../../constants';

interface Props {
  showAsSignup?: boolean;
}

const Login = ({ showAsSignup = false }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const loginLoading = useSelector(ownerSelectors.selectLoginLoading) as boolean;
  const loginSuccess = useSelector(ownerSelectors.selectLoginSuccess) as boolean;
  const loginError = useSelector(ownerSelectors.selectLoginError) as string;

  useEffect(() => {
    if (loginSuccess) {
      navigate(location.state?.from || '/owner/cars');
    }
  }, [loginSuccess]);

  const formikLogin = useFormik({
    initialValues: {
      email: '',
      password: '',
      googleCredential: '',
      appleCredentials: {},
    },
    validateOnChange: true,
    validateOnBlur: true,
    validate: vals => {
      const error = {} as any;
      if (!vals.googleCredential && Object.keys(vals.appleCredentials).length === 0) {
        if (!VALIDATIONS.EMAIL_REGEXP.test(vals.email)) {
          error.email = 'Invalid email address';
        }

        if (vals.password.length < VALIDATIONS.PASSWORD_LENGTH) {
          error.password = 'Password must be at least 8 characters';
        }
      }

      return error;
    },
    onSubmit: vals => {
      const queryParams = new URLSearchParams(location.search);
      const referralCode = queryParams.get('ref');

      const payload = {
        ...vals,
        email: vals.email.trim().toLowerCase(),
        referralCode,
      };

      dispatch(ownerActions.login.base(payload));
    }
  });

  const onGoogleSuccess = async (credentialResponse: CredentialResponse) => {
    await formikLogin.setFieldValue('googleCredential', credentialResponse.credential);

    setTimeout(() => formikLogin.handleSubmit(), 500);
  };

  const onAppleSuccess = async (response: any) => {
    await formikLogin.setFieldValue('appleCredentials', response);

    setTimeout(() => formikLogin.handleSubmit(), 500);
  };

  return (
    <div
      className="d-flex flex-column align-items-center"
    >
      <ToastContainer autoClose={2500} position="top-center" />

      {
        loginLoading && <LoadingSpinner absolute />
      }

      <div style={{ marginTop: '40px' }}>
        <Eon style={{ width: '60px', height: '60px' }} />
      </div>

      <div className="mt-3">
        <Typography variant="h2">
          {
            showAsSignup
              ? 'Welcome!'
              : 'Welcome Back!'
          }
        </Typography>
      </div>

      <div className="mt-3 d-flex flex-column align-items-center">
        <Typography>
          {
            showAsSignup
              ? 'Hi there 👋'
              : "It's great to see you 👋"
          }
        </Typography>
        <Typography>
          {
            showAsSignup
              ? 'Create an account and join Eon'
              : 'Log in to your account below'
          }
        </Typography>
      </div>

      <div className="d-flex flex-column gap-3 mt-3">
        <GoogleLogin
          useOneTap
          onSuccess={onGoogleSuccess}
          onError={() => {
            toast.error(
              'Login failed. Please try again.',
            );
          }}
        />

        <AppleSignin
          authOptions={{
            clientId: process.env.REACT_APP_APPLE_CLIENT_ID!,
            redirectURI: process.env.REACT_APP_APPLE_REDIRECT_URI!,
            scope: 'email name',
            state: 'state',
            nonce: 'nonce',
            usePopup: true,
          }}
          uiType="dark"
          className="apple-auth-btn"
          noDefaultStyle={false}
          buttonExtraChildren="Continue with Apple"
          onSuccess={onAppleSuccess}
          onError={() => toast.error('Login failed. Please try again.')}
          skipScript={false}
        />
      </div>

      <div className="mt-3">
        <Typography>Or continue with email</Typography>
      </div>

      <div className="mt-3 d-flex flex-column align-items-center gap-2" style={{ width: '300px' }}>
        <InputField
          className="mb-2"
          label="Email"
          type="email"
          value={formikLogin.values.email}
          error={formikLogin.touched.email && formikLogin.errors.email}
          onChange={({ target: { value } }: any) => {
            formikLogin.setFieldValue('email', value);
          }}
          required
        />
        <InputField
          className="mb-2"
          label="Password"
          type="password"
          name="password"
          value={formikLogin.values.password}
          error={formikLogin.touched.password && formikLogin.errors.password}
          onChange={({ target: { value } }: any) => {
            formikLogin.setFieldValue('password', value);
          }}
          required
        />

        <Button
          variant="primary"
          className="flex-grow-1 w-100"
          type="submit"
          onClick={() => formikLogin.handleSubmit()}
        >
          Continue
        </Button>

        {
          loginError && (
            <div
              className="error-msg pt-2"
              style={{
                whiteSpace: 'normal',
                textAlign: 'center',
                color: 'red',
              }}
            >
              {loginError}
            </div>
          )
        }
      </div>

      <div className="d-flex flex-column align-items-center mt-3">
        {
          !showAsSignup && (
            <Link
              to="/owner/forgot-password"
              style={{ textDecoration: 'none' }}
              className="mb-3"
            >
              Forget your password?
            </Link>
          )
        }

        <Typography>
          {
            showAsSignup
              ? 'Already have an account?'
              : "Don't have an account yet?"
          }
        </Typography>

        {
          showAsSignup
            ? (
              <Link
                to="/owner/login"
                style={{ textDecoration: 'none' }}
              >
                Log in
              </Link>
            ) : (
              <Link
                to="/owner/signup"
                style={{ textDecoration: 'none' }}
              >
                Sign up for free
              </Link>
            )
        }
      </div>
    </div>
  );
};

export default Login;
