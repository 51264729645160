import { IReservation } from 'types';

export const getResColor = (res: IReservation): string => {
  if (res.is_block) return 'gray';

  switch (res.status) {
    case 'open': return '#0085ba';
    case 'rental': return '#00c965';
    case 'confirmed': return '#00bfb9';
    case 'completed': return '#1b4a00';
    case 'verified': return '#9c9a00';
    case 'blocked': return '#ab0300';
    case 'cleaning': return '#4e0082';
    case 'late': return '#ad3700';
    case 'pending': return '#888888';
    case 'cancelled': return '#1f1f1f';
    case 'billable': return '#FFA500';
    default: return '#1f1f1f';
  }
};
