import * as FullStory from '@fullstory/browser';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import mixpanel from 'mixpanel-browser';

import { Card, Typography, Link } from '../common';
import { ArrowDown, ArrowUp, Account } from '../../assets/icons';
import { IProfile } from '../../types';
import { profileActions, profileSelectors } from '../../redux/slices';
import { SUPPORT_PHONE } from '../../constants';

import { ProfileForm } from '../Authentication';

interface IPops {
  className?: string;
  callback?: () => void;
}

const CurrentProfile = ({ className, callback }: IPops) => {
  const dispatch = useDispatch();
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const profile = useSelector(profileSelectors.selectProfile) as IProfile;

  const handleLogout = () => {
    if (typeof callback !== 'undefined') {
      callback();
    }
    dispatch(profileActions.logout.base());
    mixpanel.reset();
  };

  useEffect(() => {
    if (!profile) {
      dispatch(profileActions.getProfile.base());
    } else {
      FullStory.identify(String(profile.id), {
        email: profile.email,
      });
    }
  }, [profile]);

  if (!profile) {
    return null;
  }

  return (
    <Card
      type="button"
      className={cx('d-flex flex-column gap-3 cursor-default focus-disable active-disable', className, {
        hover: showDetails,
        'border-dark': showDetails,
        'hover-disabled': !showDetails
      })}
    >
      <div
        className="d-flex gap-3 w-100 align-items-center cursor-pointer"
        onClick={() => setShowDetails(!showDetails)}
      >
        <Account />
        <Typography variant="body2" className="flex-grow-1 m-0">
          {profile.firstname !== 'MobileApp' ? `${profile.firstname} ${profile.lastname}` : 'Personal data'}
        </Typography>
        {showDetails ? <ArrowUp className="icon-small" /> : <ArrowDown className="icon-small" />}
      </div>
      {showDetails && (
        <div className="w-100">
          <ProfileForm disabled={profile.firstname !== 'MobileApp'}>
            <Typography variant="body2">
              <span>Not you? </span>
              <Link onClick={handleLogout}>Continue as guest.</Link>
              <br />
              {profile.firstname !== 'MobileApp'
                && (
                  <small>
                    Need to update your info?&nbsp;
                    Call us at&nbsp;
                    <a href={`tel:${SUPPORT_PHONE}`}>{SUPPORT_PHONE}</a>
                  </small>
                )}
            </Typography>
          </ProfileForm>
        </div>
      )}
    </Card>
  );
};

CurrentProfile.defaultProps = {
  className: undefined,
  callback: undefined,
};

export { CurrentProfile };
