import { useEffect, useState } from 'react';
import { Icon } from '@mui/material';
import { Button } from '../Button';
import './styles.scss';

const CopyLink = ({
  link,
}: {
  link: string;
}) => {
  const [showCheck, setShowCheck] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (showCheck) {
      timer = setTimeout(() => {
        setShowCheck(false);
      }, 1000);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [showCheck]);

  return (
    <div
      className="d-flex flex-row justify-content-space-between align-items-center container w-100 gap-2"
      style={{
        borderRadius: 8,
        height: 48,
      }}
    >
      <div
        style={{
          paddingLeft: '10px',
          overflowX: 'scroll',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          whiteSpace: 'nowrap',
        }}
        className="hide-scrollbar"
      >
        {link}
      </div>

      <Button
        style={{ height: '100%' }}
        onClick={() => {
          navigator.clipboard.writeText(link);
          setShowCheck(true);
        }}
      >
        {
          showCheck
            ? (
              <Icon style={{ color: 'green' }}>check</Icon>
            ) : (
              <Icon>content_copy</Icon>
            )
        }
      </Button>
    </div>
  );
};

export default CopyLink;
