// @ts-ignore
/* eslint-disable */

import React from 'react';
import cx from 'classnames';

const EventBusy = ({ className, ...props }: any) => (
  <svg className={cx('svg-icon', className)} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g>
      <path d="M9.7 18.225L8.65 17.175L10.95 14.875L8.65 12.575L9.7 11.525L12 13.825L14.3 11.525L15.35 12.575L13.05 14.875L15.35 17.175L14.3 18.225L12 15.925L9.7 18.225ZM5.3 21.5C4.8 21.5 4.375 21.325 4.025 20.975C3.675 20.625 3.5 20.2 3.5 19.7V6.3C3.5 5.8 3.675 5.375 4.025 5.025C4.375 4.675 4.8 4.5 5.3 4.5H6.7V2.375H8.225V4.5H15.8V2.375H17.3V4.5H18.7C19.2 4.5 19.625 4.675 19.975 5.025C20.325 5.375 20.5 5.8 20.5 6.3V19.7C20.5 20.2 20.325 20.625 19.975 20.975C19.625 21.325 19.2 21.5 18.7 21.5H5.3ZM5.3 20H18.7C18.7667 20 18.8333 19.9667 18.9 19.9C18.9667 19.8333 19 19.7667 19 19.7V10.3H5V19.7C5 19.7667 5.03333 19.8333 5.1 19.9C5.16667 19.9667 5.23333 20 5.3 20ZM5 8.8H19V6.3C19 6.23333 18.9667 6.16667 18.9 6.1C18.8333 6.03333 18.7667 6 18.7 6H5.3C5.23333 6 5.16667 6.03333 5.1 6.1C5.03333 6.16667 5 6.23333 5 6.3V8.8Z" />
    </g>
  </svg>
)

export { EventBusy };
