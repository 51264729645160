// @ts-ignore
/* eslint-disable */

import React from 'react';
import cx from 'classnames';

const Autopilot = ({ className, ...props }: any) => (
  <svg className={cx('svg-icon', className)} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g>
      <path d="M4.5 14.5C3.8 14.5 3.20833 14.2583 2.725 13.775C2.24167 13.2917 2 12.7 2 12C2 11.3 2.24167 10.7083 2.725 10.225C3.20833 9.74167 3.8 9.5 4.5 9.5V7C4.5 6.58333 4.646 6.22933 4.938 5.938C5.22933 5.646 5.58333 5.5 6 5.5H9.5C9.5 4.8 9.74167 4.20833 10.225 3.725C10.7083 3.24167 11.3 3 12 3C12.7 3 13.2917 3.24167 13.775 3.725C14.2583 4.20833 14.5 4.8 14.5 5.5H18C18.4167 5.5 18.7707 5.646 19.062 5.938C19.354 6.22933 19.5 6.58333 19.5 7V9.5C20.2 9.5 20.7917 9.74167 21.275 10.225C21.7583 10.7083 22 11.3 22 12C22 12.7 21.7583 13.2917 21.275 13.775C20.7917 14.2583 20.2 14.5 19.5 14.5V19C19.5 19.4167 19.354 19.7707 19.062 20.062C18.7707 20.354 18.4167 20.5 18 20.5H6C5.58333 20.5 5.22933 20.354 4.938 20.062C4.646 19.7707 4.5 19.4167 4.5 19V14.5ZM9 12.75C9.35 12.75 9.64567 12.629 9.887 12.387C10.129 12.1457 10.25 11.85 10.25 11.5C10.25 11.15 10.129 10.8543 9.887 10.613C9.64567 10.371 9.35 10.25 9 10.25C8.65 10.25 8.35433 10.371 8.113 10.613C7.871 10.8543 7.75 11.15 7.75 11.5C7.75 11.85 7.871 12.1457 8.113 12.387C8.35433 12.629 8.65 12.75 9 12.75ZM15 12.75C15.35 12.75 15.6457 12.629 15.887 12.387C16.129 12.1457 16.25 11.85 16.25 11.5C16.25 11.15 16.129 10.8543 15.887 10.613C15.6457 10.371 15.35 10.25 15 10.25C14.65 10.25 14.3543 10.371 14.113 10.613C13.871 10.8543 13.75 11.15 13.75 11.5C13.75 11.85 13.871 12.1457 14.113 12.387C14.3543 12.629 14.65 12.75 15 12.75ZM8.25 16.75H15.75V15.25H8.25V16.75ZM6 19H18V7H6V19Z" />
    </g>
  </svg>
);

export { Autopilot };
