import React, { ReactElement, useEffect, useState, forwardRef } from 'react';
import cx from 'classnames';

interface IProps {
  label: string;
  value?: string | number;
  error?: string | boolean;
  className?: string;
  type?: string;
  children?: any;
  inputClassName?: string;
  disabled?: boolean;
  readOnly?: boolean;
  startIcon?: ReactElement;
  endIcon?: ReactElement;
  onClick?: (e: any) => void;
  onChange?: (e: any) => void;
  [k: string]: any;
}

// eslint-disable-next-line
const InputField = forwardRef(function InputField(
  {
    label, value, onClick, onChange, startIcon, endIcon, disabled, error,
    className, inputClassName, type, children, readOnly, ...props
  }: IProps,
  ref: React.Ref<HTMLInputElement> | any,
) {
  const [_value, setValue] = useState<string | number | undefined>('');
  const tag = type === 'select' ? 'select' : 'input';

  useEffect(() => {
    setValue(value);
  }, [value]);

  const handleChange = (e: any) => {
    setValue(e.target.value);
    if (typeof onChange === 'function') {
      onChange(e);
    }
  };

  return (
    <div
      className={cx('input-field', className, {
        readonly: Boolean(readOnly),
        disabled: Boolean(disabled),
        error: Boolean(error),
      })}
      onClick={onClick}
      ref={ref}
    >
      <div className="group">
        {React.createElement(tag, {
          id: `input-field-${label}`,
          className: cx('input', inputClassName, {
            'has-start': Boolean(startIcon),
            'has-end': Boolean(endIcon),
          }),
          type,
          value: _value,
          onChange: handleChange,
          disabled,
          readOnly,
          placeholder: label,
          ...props
        }, children)}
        <label htmlFor={`input-field-${label}`} className="label">
          {label}
        </label>
        {startIcon && React.cloneElement(startIcon, { className: cx('icon start', startIcon.props.className) })}
        {endIcon && React.cloneElement(endIcon, { className: cx('icon end', endIcon.props.className) })}
      </div>
      {typeof error === 'string' && error && (
        <div
          className="error-msg pt-2"
          style={{
            whiteSpace: 'normal',
            textAlign: 'center',
          }}
        >
          {error}
        </div>
      )}
    </div>
  );
});

InputField.defaultProps = {
  value: undefined,
  error: undefined,
  disabled: undefined,
  readOnly: undefined,
  className: undefined,
  inputClassName: undefined,
  startIcon: undefined,
  endIcon: undefined,
  onClick: undefined,
  onChange: undefined,
  type: undefined,
  children: undefined,
};

export { InputField };
