import { useEffect, useState } from 'react';
import * as api from 'api';

import { IReservation } from 'types';
import { Button } from 'components/common';
import { LinearProgressBar } from 'components/TopBar/LinearProgressBar';
import { ArrowLeft } from 'assets/icons';
import DepositWithheld from './DepositWithheld';
import CriticalInfo from './CriticalInfo';
import UsedTeslasPrompt from './UsedTeslasPrompt';
import DynamicInstruction from './DynamicInstruction';
import DynamicQuestion from './DynamicQuestion';

interface ITutorial {
  instructions: [{
    id: number;
    image: string,
    instruction: string
  }];
  questions: [{
    correct_answers: string[],
    id: string;
    options: [{ id: string; value: string }],
    question: string,
  }];
}

interface IProps {
  reservation: IReservation;
  onComplete: () => void;
}

const Tutorial = ({
  reservation,
  onComplete,
}: IProps) => {
  const [tutorialSteps, setTutorialSteps] = useState<any[]>([]);
  const [instructionStep, setInstructionStep] = useState(0);

  useEffect(() => {
    if (tutorialSteps.length > 0 && instructionStep === tutorialSteps.length) {
      onComplete();
    }
  }, [tutorialSteps.length, instructionStep]);

  const createTutorialSteps = (tutorial: ITutorial) => {
    if (!tutorial) return;

    const tutorialSteps: any[] = [
      {
        element: (
          <UsedTeslasPrompt
            onClick={(isTeslaPro: boolean) => {
              if (isTeslaPro) {
                setInstructionStep(tutorial.instructions.length + 1);
              } else {
                setInstructionStep(instructionStep + 1);
              }
            }}
          />
        ),
      },
      ...tutorial.instructions.map((instruction) => ({
        showUnderstandButton: true,
        element: (
          <DynamicInstruction
            key={instruction.id}
            instruction={instruction}
          />
        ),
      })),
      {
        showUnderstandButton: true,
        element: <DepositWithheld />
      },
      {
        showUnderstandButton: true,
        element: <CriticalInfo />
      },
      ...tutorial.questions.map((question) => ({
        element: (
          <DynamicQuestion
            key={question.id}
            question={question}
            onClick={() => setInstructionStep(prevStep => prevStep + 1)}
            onResetClick={() => setInstructionStep(1)}
          />
        ),
      })),
    ];

    setTutorialSteps(tutorialSteps);
  };

  useEffect(() => {
    if (!reservation || tutorialSteps.length > 0) return;

    const fetchTrip = async () => {
      try {
        const data = await api.getCheckinInstructions(reservation.id);
        createTutorialSteps(data);
      } catch (error: any) {
        // eslint-disable-next-line no-console
        console.error('Error fetching data:', error);
      }
    };

    fetchTrip();
  }, [reservation]);

  if (tutorialSteps.length === 0) return null;

  if (!tutorialSteps[instructionStep]?.element) return null;

  return (
    <div
      className="d-flex align-items-center flex-column gap-2"
    >
      <div
        className="w-100 d-flex justify-content-center"
      >
        <ArrowLeft
          onClick={() => {
            if (instructionStep === 0) {
              return;
            }

            setInstructionStep(instructionStep - 1);
          }}
        />

        <LinearProgressBar
          progress={(instructionStep / (tutorialSteps.length - 1)) * 100}
          barHeight={8}
        />
      </div>

      <div
        className="d-flex flex-column gap-3"
        style={{ padding: '16px' }}
      >
        {
          tutorialSteps[instructionStep].element
        }

        {
          tutorialSteps[instructionStep]?.showUnderstandButton && (
            <Button
              className="btn-primary"
              style={{ width: '100%' }}
              onClick={() => {
                setInstructionStep(instructionStep + 1);
              }}
            >
              I understand
            </Button>
          )
        }
      </div>
    </div>
  );
};

export default Tutorial;
