import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import moment from 'moment';
import { debounce } from 'lodash';
// import { Icon } from '@mui/material';

import { Search } from 'assets/icons';
import { InputField } from 'components/common';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { ownerActions, ownerSelectors, Reservations } from 'redux/slices';
import { IOwner, IReservation } from 'types';

import './styles.scss';

const ReservationsDashboard = () => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');

  const owner = useSelector(ownerSelectors.selectOwner) as IOwner;
  const reservations = useSelector(ownerSelectors.selectReservations) as Reservations;

  const isSuperAdmin: boolean = owner?.is_superadmin;

  const debouncedSearch = useCallback(
    debounce((searchText) => {
      dispatch(ownerActions.getReservations.base({
        page: 1,
        per_page: 10,
        search: searchText,
      }));
    }, 500),
    []
  );

  useEffect(() => {
    debouncedSearch(searchText);
  }, [searchText, debouncedSearch]);

  const handleLoadMore = () => {
    if (reservations.status.loading) return;

    dispatch(ownerActions.getReservations.base({
      page: reservations.page + 1,
      per_page: 10,
      search: searchText,
    }));
  };

  // const handleNewReservation = () => {
  //   navigate('/owner/reservations/create');
  // };

  return (
    <div style={{ padding: '24px', overflow: 'scroll' }}>
      {
        reservations.status.loading && reservations.page === 1 && (
          <LoadingSpinner absolute />
        )
      }

      <div
        className="d-flex flex-row justify-content-space-between align-items-center"
        style={{ width: '250px' }}
      >
        <InputField
          label="Search Trips"
          type="text"
          name="Search Trips"
          value={searchText}
          onChange={(e: any) => setSearchText(e.target.value)}
          required
          startIcon={<Search />}
          style={{ width: '95%' }}
        />

        {/* <Icon onClick={handleNewReservation}>add</Icon> */}
      </div>

      <InfiniteScroll
        pageStart={0}
        loadMore={handleLoadMore}
        hasMore={reservations.page < reservations.total_pages}
        loader={<LoadingSpinner key={1} />}
      >
        <table style={{ width: '100%' }}>
          <thead>
            <tr>
              <th>ID</th>
              <th>Plate</th>
              <th>Customer</th>
              <th>Status</th>
              <th>Pickup/Dropoff Time</th>
              <th>Location</th>
            </tr>
          </thead>

          <tbody>
            {
              reservations.data?.map((reservation: IReservation) => (
                <tr key={reservation.id}>
                  <td>
                    {
                      // eslint-disable-next-line no-nested-ternary
                      isSuperAdmin
                        ? <Link to={`/owner/reservations/${reservation.id}/edit`}>{reservation.id}</Link>
                        : owner?.is_fully_managed
                          ? reservation.id
                          : <Link to={`/owner/reservations/${reservation.id}/edit`}>{reservation.id}</Link>
                    }
                  </td>
                  <td>
                    {
                      isSuperAdmin
                        ? <Link to={`/owner/cars/${reservation.car.id}/edit`}>{reservation.car.license_plate}</Link>
                        : reservation.car.license_plate
                    }
                  </td>
                  <td>
                    {
                      isSuperAdmin
                        ? <a href={`https://fastfleet.me/customers/${reservation.customer_id}`}>{reservation.customer_name}</a>
                        : reservation.customer_name
                    }
                  </td>
                  <td>
                    {reservation.status}
                  </td>
                  <td>
                    <div>
                      <div className="mb-2">
                        {
                          moment.tz(
                            reservation.start_time,
                            reservation.pickup_location.timezone,
                          ).format('MM/DD/YY, h:mma z')
                        }
                      </div>
                      <div>
                        {
                          moment.tz(
                            reservation.end_time,
                            reservation.return_location.timezone,
                          ).format('MM/DD/YY, h:mma z')
                        }
                      </div>
                    </div>
                  </td>
                  <td>
                    {reservation.pickup_location.address}
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </InfiniteScroll>
    </div>
  );
};

export default ReservationsDashboard;
