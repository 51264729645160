import { Typography } from 'components/common';

interface IInstruction {
  id: number;
  image: string,
  instruction: string
}

const DynamicInstruction = ({
  instruction,
}: {
  instruction: IInstruction;
}) => {
  return (
    <div
      className="d-flex flex-column gap-2"
    >
      <Typography
        variant="h2"
      >
        Check in
      </Typography>

      {
        instruction.image && (
          instruction.image.includes('youtube.com') ? (
            <iframe
              width="100%"
              height="175"
              src={instruction.image}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          ) : (
            <img
              src={instruction.image}
              alt="instruction"
              style={{
                width: 'auto',
                height: '175px',
                objectFit: 'contain',
              }}
            />
          )
        )
      }

      <Typography
        variant="body2"
      >
        {instruction.instruction}
      </Typography>
    </div>
  );
};

export default DynamicInstruction;
