// @ts-ignore
/* eslint-disable */

import React from 'react';
import cx from 'classnames';

const Speed = ({ className, ...props }: any) => (
  <svg className={cx('svg-icon', className)} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g>
      <path d="M10.6 15.35C10.95 15.7 11.4083 15.8667 11.975 15.85C12.5417 15.8333 12.95 15.6333 13.2 15.25L18.625 7.375L10.75 12.8C10.3667 13.05 10.1583 13.45 10.125 14C10.0917 14.55 10.25 15 10.6 15.35ZM5.1 19.5C4.83333 19.5 4.58333 19.4373 4.35 19.312C4.11667 19.1873 3.925 19 3.775 18.75C3.39167 18.0833 3.08333 17.3583 2.85 16.575C2.61667 15.7917 2.5 14.9333 2.5 14C2.5 12.7 2.74567 11.475 3.237 10.325C3.729 9.175 4.40433 8.16667 5.263 7.3C6.121 6.43333 7.12933 5.75 8.288 5.25C9.446 4.75 10.6833 4.5 12 4.5C12.95 4.5 13.8417 4.625 14.675 4.875C15.5083 5.125 16.3083 5.5 17.075 6L15.675 6.925C15.1083 6.625 14.5167 6.39567 13.9 6.237C13.2833 6.079 12.65 6 12 6C9.78333 6 7.896 6.779 6.338 8.337C4.77933 9.89567 4 11.7833 4 14C4 14.7 4.09567 15.3917 4.287 16.075C4.479 16.7583 4.75 17.4 5.1 18H18.9C19.2833 17.3667 19.5627 16.7083 19.738 16.025C19.9127 15.3417 20 14.6333 20 13.9C20 13.3 19.925 12.6917 19.775 12.075C19.625 11.4583 19.4 10.875 19.1 10.325L20 8.925C20.5 9.725 20.871 10.529 21.113 11.337C21.3543 12.1457 21.4833 12.9917 21.5 13.875C21.5167 14.775 21.4167 15.6293 21.2 16.438C20.9833 17.246 20.6583 18.0333 20.225 18.8C20.0917 19.0167 19.9043 19.1873 19.663 19.312C19.421 19.4373 19.1667 19.5 18.9 19.5H5.1Z" />
    </g>
  </svg>
);

export { Speed };

