import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { uiActions, uiSelectors } from '../../../redux/slices';
import { useDarkMode } from '../../../hooks';

import { Button } from '../Button';
import { Link } from '../Link';

import { Colors } from './Colors';
import { Typography } from './Typography';
import { Icons } from './Icons';
import { Buttons } from './Buttons';
import { Inputs } from './Inputs';
import { DatePickers } from './DatePickers';
import { Filters } from './Filters';
import { Cards } from './Cards';

const sections = [
  { path: 'colors', title: 'Colors', component: <Colors /> },
  { path: 'typography', title: 'Typography', component: <Typography /> },
  { path: 'icons', title: 'Icons', component: <Icons /> },
  { path: 'buttons', title: 'Buttons', component: <Buttons /> },
  { path: 'inputs', title: 'Inputs', component: <Inputs /> },
  { path: 'date-pickers', title: 'Date Pickers', component: <DatePickers /> },
  { path: 'filters', title: 'Filters', component: <Filters /> },
  { path: 'cards', title: 'Cards', component: <Cards /> },
];

const BrandBook = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const darkMode = useSelector(uiSelectors.selectDarkMode);

  const handleChangeTheme = () => {
    dispatch(uiActions.setDarkMode.base(!darkMode));
  };

  useDarkMode();

  return (
    <div className="py-2">
      <h1 className="d-flex justify-content-between px-5">
        Brand Book
        <Button onClick={handleChangeTheme}>{`Theme: ${darkMode ? 'dark' : 'light'}`}</Button>
      </h1>
      <div className="d-flex gap-3 px-5 flex-wrap">
        {sections.map(section => (
          <Link key={section.path} to={section.path} active={pathname.includes(section.path)}>{section.title}</Link>
        ))}
      </div>
      <div className="px-5 py-5">
        <Routes>
          {sections.map(section => (
            <Route key={section.path} path={section.path} element={section.component} />
          ))}
        </Routes>
      </div>
    </div>
  );
};

export { BrandBook };
