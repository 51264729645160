import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import mixpanel from 'mixpanel-browser';
import './styles.scss';
import Icon from '@mui/material/Icon';

import { PageTitle, Typography, Scrollable } from '../common';
import { CustomerAgencyForm } from './CustomerAgencyForm';
import { useCarDetails, useDeepEffect, useLoadCarDetails } from '../../hooks';
import { Lock } from '../../assets/icons';

import { pages, routes } from '../../pages';
import {
  IDisplayDates,
  IExtra,
  IInsurance,
  ILocation,
  IProfile,
  IReservation,
  IStoreCarsSearch,
  ICar,
} from '../../types';
import { CARS_OPTIONS, DATES, DELIVERY_ADDRESSES, PARTNER_KEY } from '../../constants';
import {
  checkoutActions, checkoutSelectors,
  profileSelectors, uiSelectors, uiActions, locationsSelectors,
} from '../../redux/slices';

import { CarImages } from '../Cars/CarImages';
import { SelectedOptions } from './SelectedOptions';
import { LoginFlow, RegistrationFlow } from '../Authentication';
import { LoadingSpinner } from '../LoadingSpinner';
import { getDiscountPrice } from '../../utils';
import { PoweredBy } from '../PoweredBy';
import { getFormattedDateStringFromInput, getFormattedDisplayParameterString, getNumDays } from '../../helpers/utils';

const STEPS = {
  LOGIN: 'LOGIN',
  REGISTRATION: 'REGISTRATION',
  REDIRECT: 'REDIRECT',
};

const WORKS = [
  {
    description: "After booking, you'll get a link to upload your driver's license.",
    icon: <Icon>perm_identity</Icon>
  },
  {
    description: `1 hour before your trip, you'll get your car's license plate,
    code to drive, and dedicated rental agent's phone number.`,
    icon: <Icon>key</Icon>
  },
  {
    description: `After booking you can download the Eon app where you'll be able to control
    your car's features from the app's control panel.`,
    icon: <Icon>smartphone</Icon>
  }
];

const Checkout = () => {
  const dispatch = useDispatch();
  const { city } = useParams();
  const navigate = useNavigate();
  const { hash, search } = useLocation();
  const [step, setStep] = useState<string>(STEPS.LOGIN);
  const [agentId, setAgentId] = useState<string>('');
  const [customerId, setCustomerId] = useState<string>('');

  const scrollableData = useSelector(state => uiSelectors.selectScrollableData(state, 'checkout'));
  const profile = useSelector(profileSelectors.selectProfile) as IProfile;
  const isNewCustomer = useSelector(profileSelectors.selectIsNewCustomer) as IProfile;
  const { dates, address } = useSelector(uiSelectors.selectCarsSearch) as IStoreCarsSearch;
  const reservation = useSelector(checkoutSelectors.selectReservation) as IReservation;
  const displayDates = useSelector(uiSelectors.selectDisplayDates) as IDisplayDates;
  const uiGMTOffset = useSelector(uiSelectors.selectGMTOffset) as string;
  const car = useSelector(checkoutSelectors.selectCarDetails) as ICar;
  const location = useSelector(state => locationsSelectors.selectLocationById(state, Number(city))) as ILocation;

  const selectedDays = getNumDays(dates);

  const params = new URLSearchParams(search);
  const datesToShow = {
    startDate: getFormattedDateStringFromInput(new Date(params.get(DATES.START) || dates.startDate)),
    endDate: getFormattedDateStringFromInput(new Date(params.get(DATES.END) || dates.endDate)),
    gmtOffset: uiGMTOffset,
  };

  const tempDateString = new Date().toString().substring(28, 33);
  if (tempDateString !== uiGMTOffset) {
    datesToShow.startDate = getFormattedDisplayParameterString(datesToShow.startDate, tempDateString);
    datesToShow.endDate = getFormattedDisplayParameterString(datesToShow.endDate, tempDateString);
  }

  const handleGoBack = () => {
    params.delete(DELIVERY_ADDRESSES.DELIVERY_TO);
    params.delete(DELIVERY_ADDRESSES.COLLECT_AT);
    if (car) {
      params.set('car', String(car.id));
    }

    navigate({
      hash,
      search: params.toString(),
      pathname: routes[pages.city].replace(':city', String(location.id))
    });
  };

  useLoadCarDetails();

  const {
    carDetails,
    extraData,
    insurances,
    totalPrice,
    selectedOptions,
    selectedInsurance,
    discountData,
  } = useCarDetails();

  useEffect(() => {
    if (params.has(DATES.START)) {
      params.set(DATES.START, getFormattedDateStringFromInput(new Date(params.get(DATES.START) || dates.startDate)));
    } else {
      params.set(DATES.START, getFormattedDateStringFromInput(
        displayDates.startDT || new Date(dates.startDate),
      ));
    }
    if (params.has(DATES.END)) {
      params.set(DATES.END, getFormattedDateStringFromInput(new Date(params.get(DATES.END) || dates.endDate)));
    } else {
      params.set(DATES.END, getFormattedDateStringFromInput(
        displayDates.endDT || new Date(dates.endDate),
      ));
    }
  }, []);

  useEffect(() => {
    if ([STEPS.LOGIN, STEPS.REGISTRATION].includes(step)) {
      dispatch(uiActions.setPercentageComplete.base(50));
    }

    if (step) {
      mixpanel.track('Page Viewed', {
        Page: step,
      });
    }
  }, [step]);

  useDeepEffect(() => {
    const params = new URLSearchParams(search);

    let finalParamString = params.get(PARTNER_KEY) ? `?${PARTNER_KEY}=${params.get(PARTNER_KEY)}` : '';
    if (city) {
      finalParamString += `&city=${String(city)}`;
    }

    if (customerId) {
      setStep(STEPS.REDIRECT);

      if (
        (params.get(DELIVERY_ADDRESSES.DELIVERY_TO) && address.deliveryTo)
        || !params.get(DELIVERY_ADDRESSES.DELIVERY_TO)
      ) {
        dispatch(checkoutActions.createReservation.base({
          navigate,
          search: finalParamString,
          customerId,
          agentId,
        }));
      }

      return;
    }

    if (!profile) {
      setStep(STEPS.LOGIN);
    } else if (isNewCustomer) {
      setStep(STEPS.REGISTRATION);
    } else {
      setStep(STEPS.REDIRECT);

      if (
        (params.get(DELIVERY_ADDRESSES.DELIVERY_TO) && address.deliveryTo)
        || !params.get(DELIVERY_ADDRESSES.DELIVERY_TO)
      ) {
        dispatch(checkoutActions.createReservation.base({
          navigate,
          search: finalParamString,
        }));
      }
    }
  }, [profile, isNewCustomer, address, customerId]);

  const insuranceData = useMemo(() => (
    insurances.find((i: IInsurance) => i.id === selectedInsurance)
  ), [selectedInsurance, insurances]);

  const extraOptionsData = useMemo(() => (
    extraData.filter((i: any) => selectedOptions.includes(i.id as never))
  ), [selectedOptions, extraData]);

  const deliveryData = useMemo(() => (
    extraData.find((item: IExtra) => item.title === CARS_OPTIONS.DELIVERY.id)
  ), [extraData]);

  if (!carDetails) {
    return null;
  }

  const subdomain = window.location.hostname.replace(process.env.REACT_APP_BASE_URL!, '').replace('.', '');
  if (subdomain === 'agent') {
    return (
      <div className="checkout-container vh-100 overflow-y-scroll mt-2 mb-3" style={{ minWidth: '350px' }}>
        <PageTitle
          title="Checkout"
          onGoBack={handleGoBack}
          className={cx('p-3', { 'box-shadow2': scrollableData?.scrollTop > 0 })}
        />

        <div className="d-flex flex-column gap-2 ps-3 px-3">
          <CustomerAgencyForm
            onSuccess={(customerId: string, agentId?: string) => {
              setCustomerId(customerId);

              if (agentId) {
                setAgentId(agentId);
              }
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="checkout-container w-100 vh-100 overflow-y-scroll">
      <PageTitle
        title="Checkout"
        onGoBack={handleGoBack}
        className={cx('p-4', { 'box-shadow2': scrollableData?.scrollTop > 0 })}
      >
        {profile && (
          <div className="d-flex align-items-center gap-2">
            <Typography variant="body2" inline>Secure</Typography>
            <Lock />
          </div>
        )}
      </PageTitle>
      <Scrollable id="checkout" className="position-relative">
        <div className="pb-4 d-flex flex-column gap-2">
          <SelectedOptions
            onGoBack={handleGoBack}
            insuranceData={insuranceData}
            extraData={extraOptionsData}
            deliveryData={deliveryData}
            carDetails={carDetails}
            totalPrice={totalPrice}
            totalDeliveryCost={(reservation) ? reservation.total_delivery_cost
              : deliveryData?.amount || 0}
            discount={
              discountData?.amount
              || getDiscountPrice(
                carDetails,
                selectedDays,
                true,
              )
              || 0
            }
            location={car?.default_location}
            dates={datesToShow}
            address={{
              deliveryTo: address.deliveryTo?.address,
              collectAt: address.collectAt?.address,
            }}
          />
        </div>
        <CarImages className="mb-3" images={carDetails.thumbnails} />
        {
          step === STEPS.LOGIN && (
            <>
              <Typography variant="h2">How It Works</Typography>
              {WORKS.map((item: any) => (
                <div key={item.description} className="d-flex gap-3 align-items-start mb-3">
                  {item.icon}
                  <div className="w-fit">
                    <Typography variant="body2" className="m-0">{item.description}</Typography>
                  </div>
                </div>
              ))}
            </>
          )
        }
        {step === STEPS.LOGIN && <LoginFlow buttonText="Continue" />}
        {step === STEPS.REGISTRATION && <RegistrationFlow />}
        {step === STEPS.REDIRECT && <LoadingSpinner className="mt-5" />}
        <PoweredBy className="mt-3" />
        <div style={{ paddingBottom: 20 }} />
      </Scrollable>
    </div>
  );
};

export { Checkout };
