import React, { useMemo } from 'react';

import { Chip, Placeholder } from '../common';
import {
  Autopilot, Range, Seats, Speed, ColorRed, ColorBlack, ColorBlue, ColorGray, ColorWhite
} from '../../assets/icons';
import { ICar } from '../../types';

interface IProps {
  car?: ICar;
  disabled?: boolean;
}

const CarFeatures = ({ car, disabled }: IProps) => {
  const colorMap: Record<string, JSX.Element> = useMemo(() => ({
    black: <ColorBlack style={{ opacity: disabled ? 0.5 : 1 }} />,
    blue: <ColorBlue style={{ opacity: disabled ? 0.5 : 1 }} />,
    gray: <ColorGray style={{ opacity: disabled ? 0.5 : 1 }} />,
    red: <ColorRed style={{ opacity: disabled ? 0.5 : 1 }} />,
    white: <ColorWhite style={{ opacity: disabled ? 0.5 : 1 }} />,
  }), [disabled]);

  return (
    <div>
      {
        !car ? (
          <>
            <div className="d-flex gap-2 mb-2">
              <Chip className="flex-grow-1" icon={<Autopilot />} text={<Placeholder style={{ width: 20 }} />} />
              <Chip className="flex-grow-1" icon={<Speed />} text={<Placeholder style={{ width: 20 }} />} />
            </div>
            <div className="d-flex gap-2">
              <Chip className="flex-grow-1" icon={<Range />} text={<Placeholder style={{ width: 20 }} />} />
              <Chip className="flex-grow-1" icon={<Seats />} text={<Placeholder style={{ width: 20 }} />} />
              <Chip
                className="flex-grow-1"
                icon={<Placeholder className="me-2" style={{ width: 16 }} />}
                text="Interior"
              />
            </div>
          </>
        ) : (
          <>
            <div className="d-flex gap-2 mb-2">
              <Chip className="flex-grow-1" icon={<Autopilot />} text={car.autopilot_type} />
              <Chip className="flex-grow-1" icon={<Speed />} text={`0-60 in ${car.zero_to_sixty}s`} />
            </div>
            <div className="d-flex gap-2">
              <Chip className="flex-grow-1" icon={<Range />} text={`${car.battery_range} miles`} />
              <Chip className="flex-grow-1" icon={<Seats />} text={`${car.num_seats} seats`} />
              <Chip className="flex-grow-1" icon={colorMap[car.interior_color]} text="Interior" />
            </div>
          </>
        )
      }
    </div>
  );
};

CarFeatures.defaultProps = {
  disabled: undefined,
  car: undefined,
};

export { CarFeatures };
