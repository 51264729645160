// @ts-ignore
/* eslint-disable */

import React from 'react';
import cx from 'classnames';

const EyeInvisible = ({ className, ...props }: any) => (
  <svg className={cx('svg-icon', className)} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g>
      <path d="M15.7748 12.975L14.6498 11.85C14.7998 11.0167 14.5625 10.271 13.9378 9.61299C13.3125 8.95432 12.5498 8.69999 11.6498 8.84999L10.5248 7.72499C10.7581 7.62499 10.9915 7.54999 11.2248 7.49999C11.4581 7.44999 11.7165 7.42499 11.9998 7.42499C13.1331 7.42499 14.0955 7.82099 14.8868 8.61299C15.6788 9.40432 16.0748 10.3667 16.0748 11.5C16.0748 11.7833 16.0498 12.0457 15.9998 12.287C15.9498 12.529 15.8748 12.7583 15.7748 12.975ZM18.9498 16.075L17.8498 15.05C18.4831 14.5667 19.0458 14.0373 19.5378 13.462C20.0291 12.8873 20.4498 12.2333 20.7998 11.5C19.9665 9.81665 18.7705 8.47899 17.2118 7.48699C15.6538 6.49565 13.9165 5.99999 11.9998 5.99999C11.5165 5.99999 11.0415 6.03332 10.5748 6.09999C10.1081 6.16665 9.6498 6.26665 9.1998 6.39999L8.0248 5.22499C8.65814 4.97499 9.30414 4.79165 9.9628 4.67499C10.6208 4.55832 11.2998 4.49999 11.9998 4.49999C14.3498 4.49999 16.4665 5.14599 18.3498 6.43799C20.2331 7.72932 21.5998 9.41665 22.4498 11.5C22.0831 12.4 21.6081 13.2373 21.0248 14.012C20.4415 14.7873 19.7498 15.475 18.9498 16.075ZM19.7498 21.875L15.7248 17.85C15.2081 18.05 14.6375 18.2083 14.0128 18.325C13.3875 18.4417 12.7165 18.5 11.9998 18.5C9.64981 18.5 7.53714 17.854 5.66181 16.562C3.78714 15.2707 2.41647 13.5833 1.5498 11.5C1.91647 10.6167 2.39147 9.79165 2.9748 9.02499C3.55814 8.25832 4.1998 7.59999 4.8998 7.04999L2.1248 4.24999L3.1748 3.17499L20.8248 20.825L19.7498 21.875ZM5.9498 8.09999C5.41647 8.51665 4.90414 9.02099 4.4128 9.61299C3.9208 10.2043 3.51647 10.8333 3.1998 11.5C4.03314 13.1833 5.22881 14.5207 6.78681 15.512C8.34547 16.504 10.0831 17 11.9998 17C12.4498 17 12.8998 16.9623 13.3498 16.887C13.7998 16.8123 14.1831 16.7333 14.4998 16.65L13.2498 15.35C13.0665 15.4167 12.8665 15.4707 12.6498 15.512C12.4331 15.554 12.2165 15.575 11.9998 15.575C10.8665 15.575 9.90414 15.179 9.1128 14.387C8.3208 13.5957 7.9248 12.6333 7.9248 11.5C7.9248 11.3 7.9458 11.0873 7.9878 10.862C8.02914 10.6373 8.08314 10.4333 8.1498 10.25L5.9498 8.09999Z" />
    </g>
  </svg>
);

export { EyeInvisible };
