// @ts-ignore
/* eslint-disable */

import React from 'react';
import cx from 'classnames';

const ArrowRight = ({ className, ...props }: any) => (
  <svg className={cx('svg-icon', className)} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g>
      <path d="M7.77676 2.34998L17.4268 12L7.77676 21.65L6.35176 20.225L14.6018 12L6.35176 3.77498L7.77676 2.34998Z"/>
    </g>
  </svg>
);

export { ArrowRight };

