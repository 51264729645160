// @ts-ignore
/* eslint-disable */

import React from 'react';
import cx from 'classnames';

const Plus = ({ className, ...props }: any) => (
  <svg className={cx('svg-icon', className)} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g>
      <path d="M11.25 18.75V12.75H5.25V11.25H11.25V5.25H12.75V11.25H18.75V12.75H12.75V18.75H11.25Z" />
    </g>
  </svg>
);

export { Plus };
