import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import { toast, ToastContainer } from 'react-toastify';
import { useFormik } from 'formik';
import { Icon } from '@mui/material';

import * as api from 'api';
import { APIStatus, IPartner } from 'types';
import { Typography, Button, InputField } from 'components/common';
import Dropzone from 'components/common/Dropzone';
import { ownerActions, ownerSelectors } from 'redux/slices';
import variables from 'assets/scss/variables.scss';
import { PoweredBy } from 'components/PoweredBy';

import './styles.scss';

const Partner = () => {
  const dispatch = useDispatch();
  const [keyword, setKeyword] = useState<string>('');
  const partner = useSelector(ownerSelectors.selectPartner) as IPartner;

  const [upsertPartnerStatus, setUpsertParterStatus] = useState<APIStatus>({
    loading: false,
    success: false,
    error: null,
  });

  useEffect(() => {
    const fetchPartner = async () => {
      try {
        const data = await api.getPartner();
        dispatch(ownerActions.updatePartner.success(data));
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching data:', error);
      }
    };

    fetchPartner();
  }, []);

  const urlToFile = async (url: string, filename: string) => {
    const response = await fetch(url);
    const data = await response.blob();
    const file = new File([data], filename, { type: data.type }) as File & { preview?: string };
    file.preview = URL.createObjectURL(file);
    return file;
  };

  const formikForm = useFormik({
    initialValues: {
      name: partner?.name || '',
      url_slug: partner?.url_slug || '',
      description: partner?.description || '',
      keywords: partner?.keywords || [],
      dark_logo: null,
      light_logo: null,
    },
    validateOnChange: true,
    validateOnBlur: true,
    validate: vals => {
      const error = {} as any;

      if (!vals.name) {
        error.name = 'Required';
      }

      if (!vals.url_slug) {
        error.url_slug = 'Required';
      }

      return error;
    },
    onSubmit: async vals => {
      const payload = {
        ...vals,
      };

      setUpsertParterStatus({ loading: true, success: false, error: null });

      try {
        const data = await api.updatePartner(payload);
        setUpsertParterStatus({ loading: false, success: true, error: null });
        dispatch(ownerActions.updatePartner.success(data));
        toast.success('Update successful');
      } catch (error: any) {
        setUpsertParterStatus({
          loading: false,
          success: false,
          error: { code: error.response.status, message: error.response.data.error },
        });
        toast.error(error.response.data.error);
      }
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (!partner) return;

    const fetchLogo = async () => {
      if (partner.dark_logo) {
        const darkLogo = await urlToFile(partner.dark_logo, 'dark_logo');
        formikForm.setFieldValue('dark_logo', darkLogo);
      }

      if (partner.light_logo) {
        const lightLogo = await urlToFile(partner.light_logo, 'light_logo');
        formikForm.setFieldValue('light_logo', lightLogo);
      }
    };

    fetchLogo();
  }, [partner?.dark_logo, partner?.light_logo]);

  const partnerURL = useMemo(() => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const PORT = process.env.REACT_APP_PORT;

    return `${window.location.protocol}//${formikForm.values.url_slug}.${BASE_URL}${PORT ? `:${PORT}` : ''}`;
  }, [formikForm.values.url_slug]);

  const handleDarkLogoChanged = (files: any[]) => {
    formikForm.setFieldValue('dark_logo', files[0]);
  };

  const handleLightLogoChanged = (files: any[]) => {
    formikForm.setFieldValue('light_logo', files[0]);
  };

  const defaultDarkLogoFiles = useMemo(() => (
    formikForm.values.dark_logo
      ? [formikForm.values.dark_logo]
      : []
  ), [formikForm.values.dark_logo]);

  const defaultLightLogoFiles = useMemo(() => (
    formikForm.values.light_logo
      ? [formikForm.values.light_logo]
      : []
  ), [formikForm.values.light_logo]);

  return (
    <div
      style={{ padding: '29px', paddingTop: '10px' }}
      className="d-flex flex-column align-items-center"
    >
      <ToastContainer autoClose={2500} />

      <div
        style={{ maxWidth: '350px' }}
        className="d-flex flex-column gap-3 align-items-center"
      >
        <Typography variant="h2" className="mt-3">
          Your Booking Portal
        </Typography>

        <Typography className="text-center">
          Hey there! 👋 Welcome to your Eon Partner Booking Portal! 🚗✨
          It’s your exclusive link to book your cars directly for your guests.
          And guess what? You pocket an extra 10% on each rental when you use it.🌟
          Customize it with your brand for that extra wow factor! It will only show
          your cars and won’t mess with your other Eon bookings. Happy hosting! 🎉
        </Typography>

        <div
          className="d-flex align-items-center gap-2 w-100"
        >
          <InputField
            label="Brand name"
            type="text"
            name="name"
            value={formikForm.values.name || ''}
            error={formikForm.touched.name && formikForm.errors.name}
            onChange={formikForm.handleChange}
            required
          />

          <Icon
            data-tooltip-id="brand-name-tooltip-multiline"
            data-tooltip-html={`
              Will appear in emails and SMS messages to your customers`}
          >
            info
          </Icon>

          <Tooltip id="brand-name-tooltip-multiline" />
        </div>

        <div
          className="d-flex align-items-center gap-2 w-100"
        >
          <InputField
            label="Subdomain"
            type="text"
            name="url_slug"
            value={formikForm.values.url_slug || ''}
            error={formikForm.touched.url_slug && formikForm.errors.url_slug}
            onChange={formikForm.handleChange}
            required
          />

          <Icon
            data-tooltip-id="url-slug-tooltip-multiline"
            data-tooltip-html={`
              Will appear as your subdomain ex. toms-cars.eon.rent`}
          >
            info
          </Icon>

          <Tooltip id="url-slug-tooltip-multiline" />
        </div>

        <div
          className="d-flex align-items-center gap-2 w-100"
        >
          <InputField
            label="Description"
            type="text"
            name="description"
            value={formikForm.values.description || ''}
            error={formikForm.touched.description && formikForm.errors.description}
            onChange={formikForm.handleChange}
            required
          />
          <Icon
            data-tooltip-id="description-tooltip-multiline"
            data-tooltip-html={`
              Used for SEO purposes`}
          >
            info
          </Icon>

          <Tooltip id="description-tooltip-multiline" />
        </div>

        <div className="w-100 d-flex flex-column">
          <div
            className="d-flex align-items-center gap-2 w-100"
          >
            <InputField
              label="Keywords"
              type="text"
              name="keyword"
              value={keyword}
              onChange={(e: any) => setKeyword(e.target.value)}
              onKeyDown={(e: any) => {
                if (e.key === 'Enter') {
                  formikForm.setFieldValue('keywords', [...formikForm.values.keywords, keyword]);
                  setKeyword('');
                }
              }}
              required
            />

            <Icon
              data-tooltip-id="keywords-tooltip-multiline"
              data-tooltip-html={`
              Used for SEO purposes`}
            >
              info
            </Icon>

            <Tooltip id="keywords-tooltip-multiline" />
          </div>

          {
            formikForm.values.keywords.length > 0 && (
              <div className="d-flex flex-row align-items-center gap-2 mt-2 w-100 flex-wrap">
                {
                  formikForm.values.keywords.map((keyword: string, index: number) => (
                    <div
                      key={`${keyword}${index}`}
                      className="d-flex flex-column flex-wrap"
                      style={{
                        backgroundColor: variables.gray4,
                        borderRadius: '8px',
                        padding: '0px 20px 0px 20px',
                        position: 'relative',
                      }}
                    >
                      <div className="align-self-end">
                        <Button
                          className="m-0 p-0 align-self-end"
                          style={{
                            boxShadow: 'none',
                            backgroundColor: 'transparent',
                            height: '10px',
                            width: '10px',
                            position: 'absolute',
                            top: -4,
                            right: 5,
                          }}
                          onClick={() => {
                            const newKeywords = [...formikForm.values.keywords];
                            newKeywords.splice(index, 1);
                            formikForm.setFieldValue('keywords', newKeywords);
                          }}
                        >
                          x
                        </Button>
                      </div>

                      <Typography className="text-center pt-2">
                        {keyword}
                      </Typography>
                    </div>
                  ))
                }
              </div>
            )
          }
        </div>

        <div className="d-flex flex-column w-100">
          <div>
            <Typography>
              Dark Logo
            </Typography>

            <Typography>
              PNGs only & use 1:1 width to height ratio e.g. 100x100
            </Typography>
          </div>

          <Dropzone
            accept={{
              'image/png': ['.png'],
            }}
            multiple={false}
            defaultFiles={defaultDarkLogoFiles}
            placeholder="Drag and drop your logo here"
            onImagesChanged={handleDarkLogoChanged}
            aspectRatio={1}
          />
        </div>

        <div className="d-flex flex-column w-100">
          <div>
            <Typography>
              Light Logo
            </Typography>

            <Typography>
              PNGs only & use 1:1 width to height ratio e.g. 100x100
            </Typography>
          </div>

          <Dropzone
            accept={{
              'image/png': ['.png'],
            }}
            multiple={false}
            defaultFiles={defaultLightLogoFiles}
            placeholder="Drag and drop your logo here"
            onImagesChanged={handleLightLogoChanged}
            aspectRatio={1}
          />
        </div>

        <div className="d-flex flex-column w-100">
          <Typography>
            Preview
          </Typography>

          <a
            href={partnerURL}
            target="_blank"
            rel="noreferrer"
            style={{ fontWeight: 500, fontSize: 18 }}
          >
            {partnerURL}
          </a>

          <div
            className="d-flex flex-column gap-2 mt-4"
            style={{
              borderRadius: '8px',
              padding: '20px',
              marginTop: '10px',
              border: '1px solid black',
            }}
          >
            <Typography>
              Branding w/ Dark Logo
            </Typography>

            <div className="d-flex flex-column align-items-center">
              {
                formikForm.values.dark_logo && (
                  <img
                    src={(formikForm.values.dark_logo as any)?.preview}
                    alt="dark-logo"
                    style={{
                      width: '70px',
                      objectFit: 'contain',
                    }}
                  />
                )
              }

              <PoweredBy />
            </div>
          </div>

          <div
            className="d-flex flex-column gap-2"
            style={{
              borderRadius: '8px',
              padding: '20px',
              marginTop: '10px',
              border: '1px solid black',
              backgroundColor: 'black',
              color: 'white',
            }}
          >
            <Typography>
              Branding w/ Light Logo
            </Typography>

            <div className="d-flex flex-column align-items-center">
              {
                formikForm.values.light_logo && (
                  <img
                    src={(formikForm.values.light_logo as any)?.preview}
                    alt="light-logo"
                    style={{
                      width: '70px',
                      objectFit: 'contain',
                    }}
                  />
                )
              }

              <PoweredBy eonLogoClassName="white-eon-logo" />
            </div>
          </div>

          <div
            style={{
              backgroundColor: variables.gray4,
              borderRadius: '8px',
              padding: '20px',
              marginTop: '10px',
            }}
          >
            <Typography>
              Email
            </Typography>

            <Typography>
              {`Subject: Your ${formikForm.values.name} Reservation.`}
            </Typography>

            <Typography>
              Body: Thank you for booking with us!
            </Typography>
          </div>

          <div
            style={{
              backgroundColor: variables.gray4,
              borderRadius: '8px',
              padding: '20px',
              marginTop: '10px',
            }}
          >
            <Typography>
              SMS
            </Typography>

            <Typography>
              {`Text: Your ${formikForm.values.name} Reservation...`}
            </Typography>
          </div>
        </div>

        <Button
          variant="primary"
          type="submit"
          onClick={formikForm.handleSubmit}
        >
          Update
        </Button>
      </div>
    </div>
  );
};

export default Partner;
