import * as FullStory from '@fullstory/browser';
import { Suspense, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
  useNavigate,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import mixpanel from 'mixpanel-browser';
import { GoogleOAuthProvider } from '@react-oauth/google';

import ScrollToTop from 'components/common/ScrollToTop';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { store } from './redux/store';
import { LoadingSpinner } from './components/LoadingSpinner';
import OwnerRouter from './OwnerRouter';
import { BrandBook } from './components/common';
import Checkin from './components/Checkin';
import TripPhotos from './components/TripPhotos';
import Insurance from './components/Insurance';
import VerifyPaymentUser from './components/VerifyPaymentUser';
import CarInstructions from './components/CarInstructions';

import './assets/scss/index.scss';

const root = createRoot(document.getElementById('root')!);

mixpanel.init(
  process.env.REACT_APP_MIXPANEL_TOKEN!,
  {
    debug: process.env.REACT_APP_NODE_ENV !== 'production',
    persistence: 'localStorage',
    ignore_dnt: true,
  },
);

FullStory.init({
  orgId: process.env.REACT_APP_FULL_STORY_ORG_ID!,
  devMode: process.env.REACT_APP_NODE_ENV !== 'production',
});

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_NODE_ENV,
  enabled: ['staging', 'production'].includes(process.env.REACT_APP_NODE_ENV!),
  integrations: [
    new Sentry.BrowserTracing({
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay()
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 0.5,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/fastfleet\.me\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const RedirectToOwner = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const newPath = window.location.pathname.replace('/owners', '/owner');
    navigate(newPath);
  }, [navigate]);

  return null;
};

root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID!}>
    <Suspense fallback={<LoadingSpinner absolute />}>
      <Provider store={store}>
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            <Route path="/brandbook/*" element={<BrandBook />} />
            <Route path="*" element={<App />} />
            <Route path="/owner/*" element={<OwnerRouter />} />
            <Route path="/owners/*" element={<RedirectToOwner />} />
            <Route path="/checkin" element={<Checkin />} />
            <Route path="/trips/:id/photos" element={<TripPhotos />} />
            <Route path="/insurance" element={<Insurance />} />
            <Route path="/verify-payment" element={<VerifyPaymentUser />} />
            <Route path="/instructions/model-y" element={<CarInstructions />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </Suspense>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
