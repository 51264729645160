import variables from 'assets/scss/variables.scss';

interface Props {
  value: string | undefined;
  type?: 'text' | 'checkbox' | 'textbox' | 'date';
  name?: string;
  placeholder?: string;
  onChange?: (e: any) => void;
  style?: any;
  containerStyle?: any;
  disabled?: boolean;
}

const Input = ({
  value,
  type = 'text',
  name,
  placeholder,
  onChange,
  style,
  containerStyle,
  disabled = false,
}: Props) => {
  if (type === 'checkbox') {
    return (
      <div
        className="d-flex flex-row w-100"
        style={{
          borderRadius: '8px',
          backgroundColor: variables.gray5,
          color: variables.blacksecondary,
          padding: '16px',
          fontSize: '16px',
          borderWidth: '0px',
          display: 'inline-block',
          width: '100%',
          ...containerStyle,
        }}
      >
        <input
          type="checkbox"
          value={value}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          style={{ width: '20%', ...style }}
          checked={value === 'true'}
          disabled={disabled}
        />

        <div style={{ color: variables.blacksecondary }}>
          {placeholder}
        </div>
      </div>
    );
  }

  if (type === 'textbox') {
    return (
      <textarea
        value={value}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        className="d-flex"
        style={{
          borderRadius: '8px',
          backgroundColor: variables.gray5,
          color: variables.blacksecondary,
          padding: '16px',
          fontSize: '16px',
          borderWidth: '0px',
          width: '100%',
          ...style,
        }}
        disabled={disabled}
      />
    );
  }

  return (
    <div>
      <label htmlFor={name} className="label">
        {placeholder}
      </label>
      <input
        type={type}
        value={value}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        className="d-flex w-100"
        style={{
          borderRadius: '8px',
          backgroundColor: variables.gray5,
          color: variables.blacksecondary,
          padding: '16px',
          fontSize: '16px',
          borderWidth: '0px',
          width: '100%',
          ...style,
        }}
      />
    </div>
  );
};

export { Input };
