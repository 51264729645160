// @ts-ignore
/* eslint-disable */

import React from 'react';
import cx from 'classnames';

const Card = ({ className, ...props }: any) => (
  <svg className={cx('svg-icon', className)} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g>
      <path d="M4.3 19.5C3.8 19.5 3.375 19.325 3.025 18.975C2.675 18.625 2.5 18.2 2.5 17.7V6.3C2.5 5.8 2.675 5.375 3.025 5.025C3.375 4.675 3.8 4.5 4.3 4.5H19.7C20.2 4.5 20.625 4.675 20.975 5.025C21.325 5.375 21.5 5.8 21.5 6.3V17.7C21.5 18.2 21.325 18.625 20.975 18.975C20.625 19.325 20.2 19.5 19.7 19.5H4.3ZM4 8.4H20V6.3C20 6.23333 19.9667 6.16667 19.9 6.1C19.8333 6.03333 19.7667 6 19.7 6H4.3C4.23333 6 4.16667 6.03333 4.1 6.1C4.03333 6.16667 4 6.23333 4 6.3V8.4ZM4 11.6V17.7C4 17.7667 4.03333 17.8333 4.1 17.9C4.16667 17.9667 4.23333 18 4.3 18H19.7C19.7667 18 19.8333 17.9667 19.9 17.9C19.9667 17.8333 20 17.7667 20 17.7V11.6H4Z" />
    </g>
  </svg>
);

export { Card };
