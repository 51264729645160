import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  checkoutActions,
  checkoutSelectors,
  locationsActions,
  locationsSelectors,
  uiActions,
  partnerSelectors,
  IPartnerState,
} from '../redux/slices';

import { useDeepEffect } from '../hooks';
import { ICar, ILocation, IStoreCars, IStoreLocations } from '../types';
import { pages, routes } from '../pages';

const useLoadCarDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { city } = useParams();
  const { search } = useLocation();
  const { isLoading } = useSelector(locationsSelectors.selectLocations) as IStoreLocations;
  const location = useSelector(state => locationsSelectors.selectLocationById(state, Number(city))) as ILocation;
  const { data } = useSelector(locationsSelectors.selectCars) as IStoreCars;
  const carDetails = useSelector(checkoutSelectors.selectCarDetails) as ICar;
  const partner = useSelector(partnerSelectors.selectPartner) as IPartnerState;

  useDeepEffect(() => {
    if (location) {
      dispatch(uiActions.setGMTOffset.base(location.gmt_offset));
      dispatch(uiActions.setCarsSearch.base({ city: location.city }));
      dispatch(locationsActions.loadAvailableCars.base());
    }
  }, [location, partner]);

  useDeepEffect(() => {
    if (!isLoading && !location) {
      navigate({ pathname: routes[pages.home], search });
    }
  }, [location, isLoading]);

  useDeepEffect(() => {
    if (search) {
      const params = new URLSearchParams(search);
      if ((carDetails && !params.get('car')) || (!carDetails && params.get('car'))) {
        dispatch(checkoutActions.setCarDetails.base(data.find((c: any) => c.id === Number(params.get('car')))));
      }
    }
  }, [search, carDetails, data]);
};

export { useLoadCarDetails };
