import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import Modal from 'react-modal';
import {
  parsePhoneNumber,
  isValidPhoneNumber,
  formatPhoneNumberIntl,
} from 'react-phone-number-input';
import { ToastContainer, toast } from 'react-toastify';

import * as api from 'api';
import { ownerActions, ownerSelectors } from 'redux/slices';
import { Typography, Button, InputField, InputPhone } from 'components/common';
import { APIStatus, IOwner } from 'types';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { VALIDATIONS } from '../../../../../constants';

interface Props {
  isOpen?: boolean;
  onClose?: () => void;
}

const AddCleanerForm = ({
  isOpen = false,
  onClose,
}: Props) => {
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(isOpen);
  const owner = useSelector(ownerSelectors.selectOwner) as IOwner;
  const [createCleanerStatus, setCreateCleanerStatus] = useState<APIStatus>({
    loading: false,
    success: false,
    error: null,
  });

  useEffect(() => {
    setIsVisible(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (!isVisible && onClose) {
      onClose();
    }
  }, [isVisible]);

  const formikAddCleanerForm = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      dob: '',
    },
    validateOnChange: true,
    validateOnBlur: true,
    validate: vals => {
      const error = {} as any;

      if (String(vals.firstname).length < VALIDATIONS.FIRSTNAME_LENGTH) {
        error.firstname = 'Required';
      }

      if (String(vals.lastname).length < VALIDATIONS.LASTNAME_LENGTH) {
        error.firstname = 'Required';
      }

      if (!VALIDATIONS.EMAIL_REGEXP.test(vals.email)) {
        error.email = 'Invalid email';
      }

      if (!vals.phone || vals.phone.length < 2) {
        error.phone = 'Required';
      }

      if (vals.phone && !isValidPhoneNumber(vals.phone)) {
        error.phone = 'Invalid phone number';
      }

      if (!vals.dob) {
        error.dob = 'Required';
      }

      return error;
    },
    onSubmit: async vals => {
      const phone = parsePhoneNumber(vals.phone) as any;
      formikAddCleanerForm.setFieldValue('phone', phone.number);

      const payload = {
        ...vals,
        phone: phone.number,
        creator_id: owner.id,
      };

      setCreateCleanerStatus({ loading: true, success: false, error: null });

      try {
        const data = await api.createOwnerCleaner(payload);
        dispatch(ownerActions.createCleaner.success(data));
        setCreateCleanerStatus({ loading: false, success: true, error: null });
        toast.success('Cleaner created successfully');
        setIsVisible(false);
      } catch (error: any) {
        setCreateCleanerStatus({
          loading: false,
          success: false,
          error: { code: error.response.status, message: error.response.data.error },
        });
        toast.error(error.response.data.error);
      }
    },
  });

  const handlePhoneChange = (phoneNumber: string) => {
    if (phoneNumber && isValidPhoneNumber(phoneNumber)) {
      formikAddCleanerForm.setFieldValue('phone', formatPhoneNumberIntl(phoneNumber));
    } else {
      formikAddCleanerForm.setFieldValue('phone', phoneNumber);
    }
  };

  const handleClose = () => {
    // eslint-disable-next-line no-unused-expressions
    onClose && onClose();
    setIsVisible(false);
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isVisible}
      style={{
        overlay: {
          zIndex: 1000,
        },
        content: {
          width: '400px',
          zIndex: 1000,
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          padding: '29px',
        },
      }}
      onRequestClose={handleClose}
    >
      {
        createCleanerStatus.loading && (
          <LoadingSpinner absolute />
        )
      }

      <ToastContainer autoClose={2500} />

      <div className="d-flex justify-content-end">
        <Button
          onClick={handleClose}
          style={{ width: '25px', height: '25px', padding: 0 }}
        >
          X
        </Button>
      </div>

      <div className="d-flex flex-column gap-3 mt-4 align-items-center">
        <Typography variant="h2">
          Add New Cleaner
        </Typography>

        <InputField
          label="First Name"
          type="text"
          name="firstname"
          value={formikAddCleanerForm.values.firstname}
          onChange={formikAddCleanerForm.handleChange}
          error={formikAddCleanerForm.touched.firstname && formikAddCleanerForm.errors.firstname}
          required
        />

        <InputField
          label="Last Name"
          type="text"
          name="lastname"
          value={formikAddCleanerForm.values.lastname}
          onChange={formikAddCleanerForm.handleChange}
          error={formikAddCleanerForm.touched.lastname && formikAddCleanerForm.errors.lastname}
          required
        />

        <InputField
          label="Email"
          type="text"
          name="email"
          value={formikAddCleanerForm.values.email}
          onChange={formikAddCleanerForm.handleChange}
          error={formikAddCleanerForm.touched.email && formikAddCleanerForm.errors.email}
          required
        />

        <InputField
          value={formikAddCleanerForm.values.dob}
          type="date"
          name="dob"
          label="Date of Birth"
          placeholder="Date of Birth"
          onChange={formikAddCleanerForm.handleChange}
          error={formikAddCleanerForm.touched.dob && formikAddCleanerForm.errors.dob}
        />

        <div className="w-100">
          <Typography>
            Phone Number
          </Typography>

          <InputPhone
            value={formikAddCleanerForm.values.phone}
            onChange={(val) => handlePhoneChange(val)}
            style={{ width: '100%' }}
            error={formikAddCleanerForm.touched.phone && formikAddCleanerForm.errors.phone}
          />
        </div>

        <Button
          onClick={formikAddCleanerForm.handleSubmit}
        >
          Create
        </Button>
      </div>
    </Modal>
  );
};

export default AddCleanerForm;
