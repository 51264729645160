// @ts-ignore
/* eslint-disable */

import React from 'react';
import cx from 'classnames';

const Reset = ({ className, ...props }: any) => (
  <svg className={cx('svg-icon', className)} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g>
      <path d="M11 20.425C9.11667 20.1917 7.56267 19.3707 6.338 17.962C5.11267 16.554 4.5 14.9 4.5 13C4.5 11.9833 4.7 11.0083 5.1 10.075C5.5 9.14166 6.06667 8.325 6.8 7.625L7.85 8.7C7.23333 9.25 6.771 9.89567 6.463 10.637C6.15433 11.379 6 12.1667 6 13C6 14.4667 6.46667 15.7583 7.4 16.875C8.33333 17.9917 9.53333 18.675 11 18.925V20.425ZM13 20.45V18.95C14.45 18.65 15.6457 17.95 16.587 16.85C17.529 15.75 18 14.4667 18 13C18 11.3333 17.4167 9.91667 16.25 8.75C15.0833 7.58333 13.6667 7 12 7H11.65L13 8.35L11.95 9.4L8.8 6.25L11.95 3.1L13 4.15L11.65 5.5H12C14.1 5.5 15.875 6.225 17.325 7.675C18.775 9.125 19.5 10.9 19.5 13C19.5 14.8833 18.8833 16.525 17.65 17.925C16.4167 19.325 14.8667 20.1667 13 20.45Z" />
    </g>
  </svg>
);

export { Reset };

