import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ClickAwayListener from 'react-click-away-listener';

import Icon from '@mui/material/Icon';

import { ownerActions, ownerSelectors } from 'redux/slices';
import { IOwner } from 'types';

import './styles.scss';

interface Props {
  className?: string;
}

const Hamburger = ({
  className,
}: Props) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const owner = useSelector(ownerSelectors.selectOwner) as IOwner;

  const isLoggedIn = owner?.id !== undefined;
  const isLoggedInAndSetupComplete = owner?.setup_complete;

  const closeMenu = () => {
    setIsOpen(false);
  };

  const renderLinks = () => {
    if (isLoggedInAndSetupComplete) {
      return (
        <>
          <Link
            to="/owner/home"
            className="menu-item"
            onClick={closeMenu}
          >
            Home
          </Link>
          <Link
            to="/owner/cars"
            className="menu-item"
            onClick={closeMenu}
          >
            Cars
          </Link>
          <Link
            to="/owner/calendar"
            className="menu-item"
            onClick={closeMenu}
          >
            Calendar
          </Link>
          <Link
            to="/owner/reservations"
            className="menu-item"
            onClick={closeMenu}
          >
            Reservations
          </Link>
          <Link
            to="/owner/payouts"
            className="menu-item"
            onClick={closeMenu}
          >
            Payouts
          </Link>
          <Link
            to="/owner/partner"
            className="menu-item"
            onClick={closeMenu}
          >
            Booking Portal
          </Link>
          <Link
            to="/owner/profile"
            className="menu-item"
            onClick={closeMenu}
          >
            Settings
          </Link>
          <Link
            to="/owner/referrals"
            className="menu-item"
            onClick={closeMenu}
          >
            Refer a Friend
          </Link>
          <a
            className="menu-item"
            href="https://eonrides.notion.site/FAQ-30874a0bcebc4894819b5ecf62cf6b4f?pvs=4"
            target="_blank"
            rel="noreferrer"
          >
            FAQ
          </a>
          <Link
            to="/owner/report-bug"
            className="menu-item"
            onClick={closeMenu}
          >
            Report Issue
          </Link>
          <a
            className="menu-item"
            onClick={() => {
              setIsOpen(false);
              dispatch(ownerActions.logout.base());
            }}
          >
            Log out
          </a>
        </>
      );
    }

    if (isLoggedIn) {
      return (
        <>
          <a
            className="menu-item"
            href="https://eonrides.notion.site/FAQ-30874a0bcebc4894819b5ecf62cf6b4f?pvs=4"
            target="_blank"
            rel="noreferrer"
          >
            FAQ
          </a>
          <Link
            to="/owner/report-bug"
            className="menu-item"
            onClick={closeMenu}
          >
            Report Issue
          </Link>
          <a
            className="menu-item"
            onClick={() => {
              setIsOpen(false);
              dispatch(ownerActions.logout.base());
            }}
          >
            Log out
          </a>
        </>
      );
    }

    return (
      <>
        <Link
          to="/owner/login"
          className="menu-item"
          onClick={closeMenu}
        >
          Log in
        </Link>
        <Link
          to="/owner/signup"
          className="menu-item"
          onClick={closeMenu}
        >
          Sign up
        </Link>
        <a
          className="menu-item"
          href="https://eonrides.notion.site/FAQ-30874a0bcebc4894819b5ecf62cf6b4f?pvs=4"
          target="_blank"
          rel="noreferrer"
        >
          FAQ
        </a>
        <Link
          to="/owner/report-bug"
          className="menu-item"
          onClick={closeMenu}
        >
          Report Issue
        </Link>
      </>
    );
  };

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <div className={className}>
        <Icon className="menu-icon" onClick={() => setIsOpen(!isOpen)}>menu</Icon>

        {
          isOpen && (
            <div>
              <div className="menu">
                {renderLinks()}
              </div>
            </div>
          )
        }
      </div>
    </ClickAwayListener>
  );
};

export default Hamburger;
