// @ts-ignore
/* eslint-disable */

import React from 'react';
import cx from 'classnames';

const Close = ({ className, ...props }: any) => (
  <svg className={cx('svg-icon', className)} width="24" height="24" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="m289.94 256l95-95A24 24 0 0 0 351 127l-95 95l-95-95a24 24 0 0 0-34 34l95 95l-95 95a24 24 0 1 0 34 34l95-95l95 95a24 24 0 0 0 34-34Z" />
  </svg>
);

export { Close };
