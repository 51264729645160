const DoneCard = () => {
  return (
    <div
      className="d-flex flex-column text-center"
      style={{
        width: '21.375rem',
        height: '21.375rem',
        border: '2px solid #D9D9DF',
        borderRadius: '0.5rem',
        background: '#000',
        gap: '1.25rem',
        padding: '2.25rem 0rem',

        backgroundImage: 'url("/images/cybertruck-funride.gif")',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
    >
      <div className="text-center w-100 d-flex flex-column align-items-center gap-2">
        <div
          style={{
            color: '#FFFFFF',
            fontFamily: 'neue-haas-grotesk-display',
            fontSize: '1.5rem',
            fontWeight: 700,
          }}
        >
          Done!
        </div>

        <div
          style={{
            color: '#FFFFFF',
            fontFamily: 'neue-haas-grotesk-display',
            fontSize: '1rem',
            fontWeight: 400,
            width: '18.875rem',
          }}
        >
          You're all set, enjoy your ride!
        </div>
      </div>
    </div>
  );
};

export default DoneCard;
