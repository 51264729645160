import { ICar } from 'types';

export const getBackgroundImageUri = (car: ICar): string => {
  const carModel = car.model_name?.replace(' ', '_')?.toLowerCase();
  const carColor = car.color?.toLowerCase();

  if (!carModel || !carColor) {
    return '/images/cars/model_unknown.png';
  }

  const backgroundImageURI = `/images/cars/${carModel}_${carColor}.png`;

  return backgroundImageURI;
};
