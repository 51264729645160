import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import { Eon } from 'assets/icons';
import { partnerSelectors, IPartnerState, uiSelectors } from 'redux/slices';

interface IProps {
  className?: string;
  absolute?: boolean;
  eonLogoClassName?: string;
}

const PoweredBy = ({ className, absolute, eonLogoClassName }: IProps) => {
  const partner = useSelector(partnerSelectors.selectPartner) as IPartnerState;
  const darkMode = useSelector(uiSelectors.selectDarkMode);
  const partnerLogo = useMemo(() => {
    if (darkMode && partner.data?.light_logo) {
      return partner.data.light_logo;
    }

    if (!darkMode && partner.data?.dark_logo) {
      return partner.data.dark_logo;
    }

    return null;
  }, [partner]);

  return (
    <div
      className={cx(
        'd-flex flex-column align-items-center justify-content-center w-100 p-3',
        className,
        {
          'position-absolute': Boolean(absolute)
        }
      )}
      style={{ bottom: 0, left: 0, maxHeight: 70 }}
    >
      {partnerLogo && (
        <img
          alt="logo"
          src={partnerLogo}
          className="mt-5"
          style={{
            width: '90px',
            objectFit: 'contain',
            marginBottom: '10px',
          }}
        />
      )}
      <div className="d-flex gap-1 pb-1">
        <Eon className={eonLogoClassName} />
        <span>Powered by Eon</span>
      </div>
    </div>
  );
};

PoweredBy.defaultProps = {
  className: undefined,
  absolute: false,
  eonLogoClassName: undefined,
};

export { PoweredBy };
