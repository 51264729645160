import moment from 'moment';
import { createSlice } from '../../helpers/store';
import { IMapLocation, IStoreCarsSearch } from '../../types';
import { CAR_TABS_MODE, CARD_TYPES, DEFAULT_MAP_HEIGHT, DELIVERY_ADDRESSES } from '../../constants';

interface IState {
  darkMode: boolean;
  map: any;
  referralCode: string;
  currentPosition: IMapLocation | null;
  scrollTop: any;
  scrollableElements: any;
  carsTabMode: string;
  carsSearch: IStoreCarsSearch;
  needsDelivery: boolean;
  gmtOffset: string;
  displayDates: {
    startDT: Date;
    endDT: Date;
  };
  percentageComplete: number;
}

const initialState: IState = {
  displayDates: {
    startDT: moment().add(1, 'days').set({ hour: 10, minute: 0, second: 0, millisecond: 0 }).toDate(),
    endDT: moment().add(2, 'days').set({ hour: 10, minute: 0, second: 0, millisecond: 0 }).toDate(),
  },
  referralCode: '',
  darkMode: false,
  map: null,
  currentPosition: null,
  scrollTop: {},
  scrollableElements: {},
  carsTabMode: CAR_TABS_MODE.PICK_UP,
  carsSearch: {
    city: '',
    location: null,
    filters: {},
    dates: {
      startDate: moment().add(1, 'days').set({ hour: 10, minute: 0, second: 0, millisecond: 0 }).toDate(),
      endDate: moment().add(2, 'days').set({ hour: 10, minute: 0, second: 0, millisecond: 0 }).toDate(),
      gmtOffset: null,
    },
    address: {
      deliveryTo: null,
      collectAt: null
    }
  },
  needsDelivery: false,
  gmtOffset: '-0000',
  percentageComplete: 0,
};

const { actions, selectors, reducer } = createSlice({
  prefix: 'ui',
  initialState,

  selectors: {
    selectDarkMode: (state: IState) => state.darkMode,
    selectMap: (state: IState) => state.map,
    selectCurrentPosition: (state: IState) => state.currentPosition,
    selectScrollTop: (state: IState, _: any, k: string) => state.scrollTop[k] || 0,
    selectScrollableData: (state: IState, _: any, k: string) => state.scrollableElements[k],
    selectMapFullSize: (state: IState) => state.scrollableElements.map > DEFAULT_MAP_HEIGHT,
    selectCarsTabMode: (state: IState) => state.carsTabMode,
    selectCarsSearch: (state: IState) => state.carsSearch,
    selectNeedsDelivery: (state: IState) => state.needsDelivery,
    selectGMTOffset: (state: IState) => state.gmtOffset,
    selectReferralCode: (state: IState) => state.referralCode,
    selectDisplayDates: (state: IState) => state.displayDates,
    selectCarsSearchTotalFilters: (state: IState) => Object.values(state.carsSearch.filters)
      .reduce((sum: number, next: any) => sum + Object.keys(next).length, 0) + (state.carsSearch.location ? 1 : 0),
    selectPercentageComplete: (state: IState) => state.percentageComplete,
  },

  reducers: {
    initialize: {},
    setDarkMode: {
      base: (state: IState, { payload }: any) => {
        state.darkMode = payload;
      },
    },
    setMap: {
      base: (state: IState, { payload }: any) => {
        state.map = payload;
      }
    },
    setCurrentPosition: {
      base: (state: IState, { payload }: any) => {
        state.currentPosition = payload;
      }
    },
    setScrollTop: {
      base: (state: IState, { payload }: any) => {
        state.scrollTop[payload.page] = payload.height;
      }
    },
    setCarsTabMode: {
      base: (state: IState, { payload }: any) => {
        state.carsTabMode = payload;
        state.carsSearch = { ...state.carsSearch };
      }
    },
    setReferralCode: {
      base: (state: IState, { payload }: any) => {
        state.referralCode = payload;
      }
    },
    setCarsSearch: {
      base: (state: IState, { payload }: any) => {
        state.carsSearch = { ...state.carsSearch, ...payload };
      }
    },
    setFilters: {
      base: (state: IState, { payload: { filter, type, value } }: any) => {
        if (!state.carsSearch.filters[filter] && filter) {
          state.carsSearch.filters[filter] = {};
        }
        if (type === 'clear') {
          state.carsSearch.filters = {};
        }
        if (type === CARD_TYPES.CHECKBOX) {
          if (value === 'clear') {
            state.carsSearch.filters[filter] = {};
          } else if (state.carsSearch.filters[filter][value]) {
            delete state.carsSearch.filters[filter][value];
          } else {
            state.carsSearch.filters[filter][value] = true;
          }
        }
        if (type === CARD_TYPES.RADIO) {
          state.carsSearch.filters[filter] = value === 'clear' ? {} : { [value]: true };
        }
      }
    },
    clearAddress: {
      base: (state: IState) => {
        state.carsSearch.address = initialState.carsSearch.address;
      }
    },
    setAddress: {
      base: (state: IState, { payload: { key, address } }: any) => {
        // @ts-ignore
        state.carsSearch.address[key] = address;
        // @ts-ignore
        if (key === DELIVERY_ADDRESSES.DELIVERY_TO && !state.carsSearch.address[DELIVERY_ADDRESSES.COLLECT_AT]) {
          // @ts-ignore
          state.carsSearch.address[DELIVERY_ADDRESSES.COLLECT_AT] = address;
        }
      }
    },
    clearLocationFilter: {
      base: (state: IState) => {
        state.carsSearch.location = null;
      }
    },
    setLocationFilter: {
      base: (state: IState, { payload: { location } }: any) => {
        if (
          location.latitude === state.carsSearch.location?.latitude
          && location.longitude === state.carsSearch.location?.longitude
        ) {
          state.carsSearch.location = null;
        } else {
          state.carsSearch.location = location;
        }
      }
    },
    clearCarSearch: {
      base: (state: IState) => {
        state.carsSearch = { ...initialState.carsSearch };
      }
    },
    setScrollableData: {
      base: (state: IState, { payload: { id, data } }: any) => {
        state.scrollableElements[id] = data;
      }
    },
    setNeedsDelivery: {
      base: (state: IState, { payload }: any) => {
        state.needsDelivery = payload;
      }
    },
    setDisplayDates: {
      base: (state: IState, { payload }: any) => {
        state.displayDates = payload;
      }
    },
    setGMTOffset: {
      base: (state: IState, { payload }: any) => {
        state.gmtOffset = payload;
      }
    },
    setPercentageComplete: {
      base: (state: IState, { payload }: any) => {
        state.percentageComplete = payload;
      }
    },
  },
});

export { reducer as ui, actions as uiActions, selectors as uiSelectors };
