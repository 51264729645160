import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { ToastContainer, toast } from 'react-toastify';

import { Eon } from 'assets/icons';
import { Typography, InputField, Button } from 'components/common';
import { ownerSelectors } from 'redux/slices';
import { IOwner } from 'types';
import * as api from 'api';
import { VALIDATIONS } from '../../../constants';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const owner = useSelector(ownerSelectors.selectOwner) as IOwner;
  const [showCheckEmail, setShowCheckEmail] = useState(false);

  const formikForgotPassword = useFormik({
    initialValues: {
      email: owner?.email,
    },
    validateOnChange: true,
    validateOnBlur: true,
    validate: vals => {
      const error = {} as any;

      if (!VALIDATIONS.EMAIL_REGEXP.test(vals.email)) {
        error.email = 'Invalid email address';
      }

      return error;
    },
    onSubmit: async vals => {
      try {
        await api.resetOwnerPasswordResetRequest(vals);
        setShowCheckEmail(true);
      } catch (error: any) {
        toast.error('Something went wrong. Please try again later.');
      }
    },
    enableReinitialize: true,
  });

  if (showCheckEmail) {
    return (
      <div className="d-flex flex-column align-items-center justify-content-center">
        <div style={{ marginTop: '60px' }}>
          <Eon style={{ width: '60px', height: '60px' }} />
        </div>

        <div className="mt-2 d-flex gap-2 flex-column p-3">
          <Typography variant="h1" style={{ textAlign: 'center' }}>
            Check Your Email
          </Typography>

          <Typography style={{ textAlign: 'center' }}>
            We&apos;ve sent you an email with instructions on resetting your password
          </Typography>
        </div>
      </div>
    );
  }

  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center"
    >
      <ToastContainer autoClose={2500} />

      <div className="d-flex gap-2 flex-column p-3" style={{ marginTop: '60px' }}>
        <Typography variant="h1" style={{ textAlign: 'center' }}>
          Reset your password
        </Typography>

        <Typography style={{ textAlign: 'center' }}>
          We will send you an email with instructions on how to reset your password.
        </Typography>
      </div>

      <div style={{ width: '300px' }}>
        <InputField
          className="mb-2"
          label="Email"
          type="email"
          value={formikForgotPassword.values.email || ''}
          onChange={({ target: { value } }: any) => {
            formikForgotPassword.setFieldValue('email', value);
          }}
          required
          disabled={owner?.email}
          error={formikForgotPassword.touched.email && formikForgotPassword.errors.email}
        />

        <Button
          variant="primary"
          className="flex-grow-1 w-100 mt-3"
          type="submit"
          onClick={() => {
            formikForgotPassword.handleSubmit();
          }}
        >
          Continue
        </Button>
      </div>

      <div className="mt-3">
        <a
          style={{ textDecoration: 'none', fontSize: '12px', color: '#707070' }}
          onClick={(e) => {
            e.preventDefault();
            navigate(-1);
          }}
        >
          Go back
        </a>
      </div>
    </div>
  );
};

export default ForgotPassword;
