import { all, put, call, takeLatest } from 'redux-saga/effects';
import mixpanel from 'mixpanel-browser';

import * as api from '../../api';
import { checkoutActions, profileActions } from '../slices';
import { TOKEN_STORAGE, TOKEN_STORAGE_TIMESTAMP } from '../../constants';

export function* getProfileFlow(): Generator {
  try {
    if (localStorage.getItem(TOKEN_STORAGE)) {
      const data: any = yield call(api.getProfile);
      yield put(profileActions.getProfile.success(data.me));
    } else {
      throw new Error('Not authorized');
    }
  } catch (err) {
    yield put(profileActions.getProfile.failed(err));
  }
}

export function* updateProfileFlow({ payload }: any): Generator {
  try {
    yield call(api.updateProfile, payload);
    mixpanel.people.set({
      $name: `${payload.firstname} ${payload.lastname}`,
      $email: payload.email,
      $phone: payload.phone,
    });

    const data: any = yield call(api.getProfile);
    yield put(profileActions.updateProfile.success(data.me));
  } catch (err) {
    yield put(profileActions.updateProfile.failed(err));
  }
}

export function* loginStartFlow({ payload: { phone, partner, referralCode } }: any): Generator {
  try {
    const data: any = yield call(api.startLogin, { phone, partner, referralCode });

    yield put(profileActions.loginStart.success(data));
  } catch (err) {
    yield put(profileActions.loginStart.failed(err));
  }
}

export function* loginCompleteFlow({ payload: { phone, code } }: any): Generator {
  try {
    const data: any = yield call(api.verifyLogin, { phone, otp: code });
    yield put(profileActions.loginComplete.success(data));
    localStorage.setItem(TOKEN_STORAGE, data.auth_token);
    localStorage.setItem(TOKEN_STORAGE_TIMESTAMP, new Date().toISOString());
  } catch (err) {
    yield put(profileActions.loginComplete.failed(err));
  }
}

export function* logoutFlow(): Generator {
  try {
    localStorage.removeItem(TOKEN_STORAGE);
    localStorage.removeItem(TOKEN_STORAGE_TIMESTAMP);
    yield put(profileActions.logout.success());
  } catch (err) {
    yield put(profileActions.logout.failed(err));
  }
}

export function* loadPaymentMethodsFlow(): Generator {
  try {
    const data: any = yield call(api.getPaymentMethods);
    yield put(profileActions.loadPaymentMethods.success(data));
  } catch (err) {
    yield put(profileActions.loadPaymentMethods.failed(err));
  }
}

export function* deletePaymentMethodFlow({ payload: { id } }: any): Generator {
  try {
    yield call(api.deletePaymentMethods, id);
    yield put(profileActions.deletePaymentMethod.success({ id }));
    yield put(checkoutActions.setCheckoutData.base({ key: 'payment', data: null }));
  } catch (err) {
    yield put(profileActions.deletePaymentMethod.failed(err));
  }
}

export default function* root() {
  yield all([
    takeLatest(profileActions.getProfile.types.BASE, getProfileFlow),
    takeLatest(profileActions.updateProfile.types.BASE, updateProfileFlow),
    takeLatest(profileActions.loginStart.types.BASE, loginStartFlow),
    takeLatest(profileActions.loginComplete.types.BASE, loginCompleteFlow),
    takeLatest(profileActions.logout.types.BASE, logoutFlow),
    takeLatest(profileActions.loadPaymentMethods.types.BASE, loadPaymentMethodsFlow),
    takeLatest(profileActions.deletePaymentMethod.types.BASE, deletePaymentMethodFlow),
  ]);
}
