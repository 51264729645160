/* eslint-disable */
import cx from 'classnames';

const Extend = ({ className, ...props }: any) => {
  return (
    <svg className={cx(className)} width="16" height="16" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.5 13.5" {...props}>
      <path d="M9.25.5h3.75M13,.5v3.75M13,.5l-4.38,4.38M4.25,13H.5M.5,13v-3.75M.5,13l4.38-4.38M13,9.25v3.75M13,13h-3.75M13,13l-4.38-4.38M.5,4.25V.5M.5.5h3.75M.5.5l4.38,4.38" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export { Extend };


