import { useState, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles.scss';

import SelectCard from '../SelectCard';

const SingleSelectCardList = ({
  selectCards,
  onClick,
}: {
  selectCards: any[];
  onClick: () => void;
}) => {
  const [selectCardIdx, setSelectCardIdx] = useState(-1);
  const sliderRef = useRef<Slider>(null);

  return (
    <div style={{ width: '21.375rem' }}>
      <Slider
        ref={sliderRef}
        {
        ...{
          dots: false,
          infinite: false,
          arrows: false,
          speed: 500,
          slidesToShow: 1.1,
          slidesToScroll: 1,
          draggable: true,
          swipe: true,
          swipeToSlide: true,
        }
        }
      >
        {
          selectCards.map((selectCard: any, idx: number) => {
            return (
              <SelectCard
                key={idx}
                mediaPath={selectCard.mediaPath}
                title={selectCard.title}
                subTitle={selectCard.subTitle}
                body={selectCard.body}
                isActive={selectCardIdx === idx}
                onClick={() => {
                  setSelectCardIdx(idx);
                  onClick();
                  sliderRef.current?.slickGoTo(idx);
                }}
              />
            );
          })
        }
      </Slider>
    </div>
  );
};

export default SingleSelectCardList;
