import PhoneInput, { Value } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

import variables from 'assets/scss/variables.scss';

interface Props {
  value: string;
  onChange: (value: Value) => void;
  onKeyDown?: (e: any) => void;
  style?: any;
  error?: any;
}

const InputPhone = ({
  value,
  onChange,
  onKeyDown,
  style,
  error,
}: Props) => {
  return (
    <>
      <PhoneInput
        international
        limitMaxLength
        defaultCountry="US"
        countryCallingCodeEditable={false}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        style={style}
        numberInputProps={{
          ...(error && { style: { boxShadow: `0 0 0 1px ${variables.red1} inset` } })
        }}
      />
      {typeof error === 'string' && error && (
        <div
          className="error-msg pt-2"
          style={{
            whiteSpace: 'normal',
            textAlign: 'center',
            color: variables.red1,
          }}
        >
          {error}
        </div>
      )}
    </>
  );
};

export { InputPhone };
