import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import { Search } from 'assets/icons';
import Modal from 'react-modal';
import { useFormik } from 'formik';
import axios from 'axios';

import {
  locationsSelectors,
  uiActions,
  uiSelectors,
} from '../../redux/slices';
import { IDisplayDates, ILocation, IMapLocation, IStoreLocations, APIStatus } from '../../types';
import { pages, routes } from '../../pages';

import { Button, Navigation, Typography, Scrollable, InputField } from '../common';
import { useDeepEffect } from '../../hooks';
import { PoweredBy } from '../PoweredBy';
import { AGENCY_TOKEN_STORAGE, DATES, PARTNER_KEY, REFERRAL_CODE_KEY, VALIDATIONS } from '../../constants';
import { getFormattedDateStringFromInput, getFormattedDisplayParameterString } from '../../helpers/utils';

declare const window: any;

const Locations = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [suggestedLocation, setSuggestedLocation] = useState<ILocation>();
  const { isLoading, data } = useSelector(locationsSelectors.selectLocations) as IStoreLocations;
  const currentPosition = useSelector(uiSelectors.selectCurrentPosition) as IMapLocation | null;
  const displayDates = useSelector(uiSelectors.selectDisplayDates) as IDisplayDates;
  const dispatch = useDispatch();
  const [cityFilter, setCityFilter] = useState('');
  const [showAgencyLogin, setShowAgencyLogin] = useState(false);
  const [submitFormStatus, setSubmitFormStatus] = useState<APIStatus>({
    loading: false,
    success: false,
    error: null,
  });
  const darkMode = useSelector(uiSelectors.selectDarkMode);

  useEffect(() => {
    dispatch(uiActions.setPercentageComplete.base(0));
  }, []);

  useEffect(() => {
    const subdomain = window.location.hostname.replace(process.env.REACT_APP_BASE_URL!, '').replace('.', '');
    if (subdomain === 'agent' && !localStorage.getItem(AGENCY_TOKEN_STORAGE)) {
      setShowAgencyLogin(true);
    }
  }, []);

  useEffect(() => {
    mixpanel.track('Page Viewed', {
      Page: 'Locations',
    });
  }, []);

  useDeepEffect(() => {
    const params = new URLSearchParams(search);
    if (params.get(REFERRAL_CODE_KEY) && params.get(REFERRAL_CODE_KEY)?.replaceAll(' ', '') !== '') {
      dispatch(uiActions.setReferralCode.base(params.get(REFERRAL_CODE_KEY)));
    } else {
      dispatch(uiActions.setReferralCode.base(''));
    }
  }, []);

  useDeepEffect(() => {
    if (!data.length) return;

    const params = new URLSearchParams(search);

    if (params.get('city')) {
      const cityName = params.get('city');

      if (!cityName) return;

      const item = data?.find((location: ILocation) => location.city === cityName);

      if (!item) return;

      let searchParams = '';
      if (params.get(PARTNER_KEY)) {
        searchParams += `?${PARTNER_KEY}=${params.get(PARTNER_KEY)}`;
      }

      if (params.get(REFERRAL_CODE_KEY) && params.get(REFERRAL_CODE_KEY)?.replaceAll(' ', '') !== '') {
        searchParams += `&${REFERRAL_CODE_KEY}=${params.get(REFERRAL_CODE_KEY)}`;
      }

      navigate({
        pathname: routes[pages.city].replace(':city', String(item.id)),
        search: searchParams,
      });
    }
  }, [data]);

  useDeepEffect(() => {
    if (currentPosition && data.length && window.google && window.google.maps && window.google.maps.geometry) {
      const arr = data.map((item: ILocation, index: number) => ({
        index,
        dist: window.google.maps.geometry.spherical.computeDistanceBetween(currentPosition, {
          lat: item.latitude,
          lng: item.longitude,
        })
      }));
      setSuggestedLocation(
        data[arr.sort((a: any, b: any) => a.dist - b.dist)[0]?.index]
      );
    }
  }, [currentPosition, data]);

  const { values, errors, touched, handleSubmit, handleChange } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validate: value => {
      const error = {} as any;
      if (!VALIDATIONS.EMAIL_REGEXP.test(value.email)) {
        error.email = true;
      }
      if (values.password.length < VALIDATIONS.PASSWORD_LENGTH) {
        error.password = 'Password must be at least 8 characters';
      }
      return error;
    },
    onSubmit: async (data) => {
      const payload = {
        ...data,
      };

      const api = `${process.env.REACT_APP_API_ENDPOINT}/api/v1`;

      const request = axios.create();

      setSubmitFormStatus({
        loading: true,
        success: false,
        error: null,
      });

      request
        .post(`${api}/agencies/login`, payload)
        .then(res => res.data)
        .then((data) => {
          setSubmitFormStatus({
            loading: false,
            success: true,
            error: null,
          });

          localStorage.setItem(AGENCY_TOKEN_STORAGE, data.auth_token);
          setShowAgencyLogin(false);
        })
        .catch(error => {
          setSubmitFormStatus({
            loading: false,
            success: false,
            error: { code: error.response.status, message: error.response.data.error },
          });
        });
    },
  });

  const handleOpenLocation = (item: ILocation) => {
    mixpanel.track('Button Clicked', {
      'Button Name': 'Location',
      'Location City': item.city,
    });

    const params = new URLSearchParams(search);
    let searchParams = '';
    if (params.get(PARTNER_KEY)) {
      searchParams += `?${PARTNER_KEY}=${params.get(PARTNER_KEY)}`;
    }
    if (params.get(DATES.START)) {
      let startDateParam = params.get('startDate');
      if (startDateParam) {
        startDateParam = displayDates.startDT ? getFormattedDateStringFromInput(displayDates.startDT)
          : getFormattedDisplayParameterString(startDateParam);
        searchParams += `&startDate=${startDateParam}`;
      }
    }
    if (params.get(DATES.END)) {
      let endDateParam = params.get('endDate');
      if (endDateParam) {
        endDateParam = displayDates.endDT ? getFormattedDateStringFromInput(displayDates.endDT)
          : getFormattedDisplayParameterString(endDateParam);
        searchParams += `&endDate=${endDateParam}`;
      }
    }
    if (item.gmt_offset && item.gmt_offset !== '-0000') {
      searchParams += `&gmtOffset=${item.gmt_offset}`;
    }
    if (params.get(REFERRAL_CODE_KEY) && params.get(REFERRAL_CODE_KEY)?.replaceAll(' ', '') !== '') {
      searchParams += `&${REFERRAL_CODE_KEY}=${params.get(REFERRAL_CODE_KEY)}`;
    }
    navigate({
      pathname: routes[pages.city].replace(':city', String(item.id)),
      search: searchParams,
    });
  };

  const filteredLocations = useMemo(() => {
    if (!cityFilter) return data;

    console.log('cityFilter', cityFilter);

    const filteredData = data.filter((location: ILocation) => {
      const searchStr = cityFilter.toLowerCase();
      const nameMatches = location.city.toLowerCase().includes(searchStr);

      return nameMatches;
    });

    return filteredData;
  }, [data, cityFilter]);

  const renderLocations = () => {
    if (isLoading) {
      return (
        <>
          <Navigation className="mb-2" subtitle="-" title="-" showPlaceholder />
          <Navigation className="mb-2" subtitle="-" title="-" showPlaceholder />
          <Navigation className="mb-2" subtitle="-" title="-" showPlaceholder />
        </>
      );
    }

    if (!filteredLocations?.length) {
      return (
        <div className="d-flex justify-content-center">
          <Typography variant="h4" className="">
            No locations found. Please try another search.
          </Typography>
        </div>
      );
    }

    return (
      filteredLocations?.map((location: ILocation) => (
        <Navigation
          key={location.id}
          className="mb-2"
          title={location.city}
          subtitle={location.state}
          onClick={() => handleOpenLocation(location)}
        />
      ))
    );
  };

  return (
    <div className="d-flex flex-column" style={{ overflowY: 'hidden' }}>
      <div className="ps-4 pe-4 pb-2">
        <InputField
          className="mt-4"
          label="Filter locations"
          type="search"
          startIcon={<Search />}
          onChange={(e: any) => {
            setCityFilter(e.target.value);
          }}
          autoComplete="off"
          value={cityFilter}
        />
      </div>
      <div className="d-flex flex-grow-1" style={{ overflowY: 'scroll' }}>
        <Scrollable
          id="locations"
          className="d-flex flex-column p-4 position-relative w-100"
        >
          {suggestedLocation && (
            <>
              <Typography variant="h2" className="mb-3">Nearby Location</Typography>
              <Navigation
                subtitle={suggestedLocation.state}
                title={suggestedLocation.city}
                onClick={() => handleOpenLocation(suggestedLocation)}
              />
            </>
          )}
          <Typography variant="h2" className={suggestedLocation ? 'my-3' : 'mb-3'}>All Locations</Typography>
          {renderLocations()}
          <div style={{ paddingBottom: 50 }} />
          <PoweredBy />
        </Scrollable>
      </div>

      <Modal
        ariaHideApp={false}
        isOpen={showAgencyLogin}
        style={{
          overlay: {
            zIndex: 1000,
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '29px',
            width: '400px',
            height: '420px',
            backgroundColor: darkMode ? 'black' : 'white',
            overflow: 'hidden',
          },
        }}
      >
        <div className="d-flex flex-grow-1 flex-column justify-content-center">
          <Typography variant="h2">Agency Login</Typography>

          <InputField
            className="mb-2"
            label="Email"
            type="text"
            name="email"
            value={values.email}
            error={touched.email && errors.email}
            onChange={handleChange}
            required
          />

          <InputField
            className="mb-2"
            label="Password"
            type="password"
            name="password"
            value={values.password}
            error={touched.password && errors.password}
            onChange={handleChange}
            required
          />

          <div className="d-flex flex-column gap-2 mt-3">
            <Button
              variant="primary"
              className="w-100"
              type="submit"
              onClick={() => handleSubmit()}
            >
              Login
            </Button>

            <Button
              variant="default"
              className="w-100"
              type="submit"
              onClick={() => {
                window.location = 'https://eon.rent';
              }}
            >
              Not an agent?
            </Button>

            {
              submitFormStatus.error && (
                <div>
                  <Typography variant="body2" className="text-danger text-center mt-1">
                    {submitFormStatus.error.message}
                  </Typography>
                </div>
              )
            }
          </div>
        </div>
      </Modal>
    </div>
  );
};

export { Locations };
