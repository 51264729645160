import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Icon } from '@mui/material';

import Navbar from 'components/Navbar';
import { Button, Typography } from 'components/common';
import { LoginFlow } from 'components/Authentication';
import { profileSelectors } from 'redux/slices';
import { IProfile, IReservation, APIStatus, ICheckin } from 'types';
import * as api from 'api';
import { LoadingSpinner } from 'components/LoadingSpinner';
import Complete from './Complete';
import Tutorial from './Tutorial';

const INTRO_INSTRUCTIONS = [
  {
    description: `When you arrive, your car will be unlocked and you can activate the Tesla
    by entering the security pin in the console.`,
    icon: <Icon>lock_open</Icon>
  },
  {
    description: 'Please read all check in steps to ensure your rental goes smoothly.',
    icon: <Icon>visibility</Icon>
  },
];

const Checkin = () => {
  const [reservation, setReservation] = useState<IReservation>();
  const [checkin, setCheckin] = useState<ICheckin>();
  const [showTutorial, setShowTutorial] = useState(false);
  const profile = useSelector(profileSelectors.selectProfile) as IProfile;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const reservationId = queryParams.get('reservation_id');
  const [getTripStatus, setGetTripStatus] = useState<APIStatus>({
    loading: false,
    success: false,
    error: null,
  });
  const profileLoading = useSelector(profileSelectors.selectProfileLoading);

  const isLoggedIn = !!profile?.id;

  const isCheckedIn = reservation?.is_checkin_complete || checkin?.is_complete;

  useEffect(() => {
    if (!reservationId || !isLoggedIn) return;

    const fetchTrip = async () => {
      setGetTripStatus({ loading: true, success: false, error: null });

      try {
        const data = await api.getReservation(reservationId);
        setReservation(data);
        setGetTripStatus({ loading: false, success: true, error: null });
      } catch (error: any) {
        setGetTripStatus({
          loading: false,
          success: false,
          error: { code: 500, message: error },
        });
        // eslint-disable-next-line no-console
        console.error('Error fetching data:', error);
      }
    };

    fetchTrip();
  }, [reservationId, isLoggedIn]);

  const pickupInstructions = useMemo(() => {
    if (!reservation) return '';

    if (reservation.custom_pickup_location) return reservation.custom_pickup_location;

    const address = reservation?.pickup_location.address;

    if (reservation.pickup_location.pickup_instructions) {
      return (
        <>
          {address}
          <br />
          {`Pickup Instructions: ${reservation.pickup_location.pickup_instructions}`}
        </>
      );
    }

    return address;
  }, [reservation]);

  const handleCreateCheckin = async () => {
    try {
      const data = await api.createCheckin(reservation!.id!) as ICheckin;
      setCheckin(data);
      setShowTutorial(true);
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.error('Error fetching data:', error);
      alert(error.response.data.error);
    }
  };

  const handleTutorialComplete = async () => {
    setShowTutorial(false);

    if (checkin?.is_complete || reservation?.is_checkin_complete) return;

    try {
      const data = await api.completeCheckin(reservation!.id!);
      setCheckin(data);
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.error('Error fetching data:', error);
      alert(error.response.data.error);
    }
  };

  const renderBody = () => {
    if (profileLoading) {
      return null;
    }

    if (isCheckedIn && !showTutorial) {
      return (
        <div style={{ padding: '0 16px 0 16px' }}>
          <Complete
            reservation={reservation!}
            car={reservation!.car}
            onStartOver={() => setShowTutorial(true)}
          />
        </div>
      );
    }

    if (showTutorial && reservation) {
      return (
        <div>
          <Tutorial
            reservation={reservation}
            onComplete={handleTutorialComplete}
          />
        </div>
      );
    }

    if (reservation) {
      return (
        <div
          className="d-flex flex-column align-items-center gap-3"
          style={{ padding: '16px' }}
        >
          <div className="text-center">
            <h1>Check in</h1>
          </div>

          {
            INTRO_INSTRUCTIONS.map((item: any) => (
              <div key={item.description} className="d-flex gap-3 align-items-start w-100">
                {item.icon}
                <div className="w-fit">
                  <Typography variant="body2" className="m-0">{item.description}</Typography>
                </div>
              </div>
            ))
          }

          <div className="d-flex gap-3 align-items-start w-100">
            <Icon>flag</Icon>
            <div className="w-fit">
              <Typography variant="body2" className="m-0">
                {pickupInstructions}
              </Typography>
            </div>
          </div>

          <div className="d-flex gap-3 align-items-start w-100">
            <Icon>flag</Icon>
            <div className="w-fit">
              <Typography variant="body2" className="m-0">
                {
                  `License Plate: ${reservation?.car.license_plate}`
                }
              </Typography>
            </div>
          </div>

          <Button
            className="btn-primary mt-3"
            style={{ width: '100%' }}
            onClick={handleCreateCheckin}
          >
            Start
          </Button>
        </div>
      );
    }

    return null;
  };

  if (profileLoading || getTripStatus.loading) {
    return (
      <LoadingSpinner absolute />
    );
  }

  if (!isLoggedIn) {
    return (
      <div>
        <Navbar />

        <div
          className="d-flex justify-content-center"
        >
          <div
            className="d-flex justify-content-center align-items-center flex-column"
            style={{
              maxWidth: '500px',
              overflow: 'hidden',
              padding: '0 16px 0 16px',
            }}
          >
            <LoginFlow buttonText="Login to check in" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Navbar />

      <div
        className="d-flex justify-content-center mt-4"
      >
        <div
          className="d-flex justify-content-center align-items-center flex-column"
          style={{
            maxWidth: '500px',
            overflow: 'hidden',
          }}
        >
          {renderBody()}
        </div>
      </div>

    </div>
  );
};

export default Checkin;
