// @ts-ignore
/* eslint-disable */

import React from 'react';
import cx from 'classnames';

const Price = ({ className, ...props }: any) => (
  <svg className={cx('svg-icon', className)} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g >
      <path d="M11.25 20.75V18.65C10.3833 18.5 9.63767 18.1627 9.013 17.638C8.38767 17.1127 7.925 16.375 7.625 15.425L9 14.85C9.26667 15.6333 9.65 16.229 10.15 16.637C10.65 17.0457 11.3167 17.25 12.15 17.25C12.8667 17.25 13.5 17.0793 14.05 16.738C14.6 16.396 14.875 15.85 14.875 15.1C14.875 14.45 14.6707 13.9333 14.262 13.55C13.854 13.1667 13 12.7667 11.7 12.35C10.3333 11.9167 9.38333 11.4083 8.85 10.825C8.31667 10.2417 8.05 9.51667 8.05 8.65C8.05 7.65 8.40433 6.85833 9.113 6.275C9.821 5.69167 10.5333 5.36667 11.25 5.3V3.25H12.75V5.3C13.5167 5.41667 14.1583 5.67067 14.675 6.062C15.1917 6.454 15.6 6.96667 15.9 7.6L14.525 8.225C14.3083 7.74167 14.0043 7.36667 13.613 7.1C13.221 6.83333 12.7 6.7 12.05 6.7C11.3 6.7 10.696 6.88333 10.238 7.25C9.77933 7.61667 9.55 8.08333 9.55 8.65C9.55 9.23333 9.78333 9.7 10.25 10.05C10.7167 10.4 11.5917 10.7667 12.875 11.15C14.0583 11.5 14.9373 12.0083 15.512 12.675C16.0873 13.3417 16.375 14.1417 16.375 15.075C16.375 16.1583 16.025 17.004 15.325 17.612C14.625 18.2207 13.7667 18.5833 12.75 18.7V20.75H11.25Z" />
    </g>
  </svg>
);

export { Price };
