// @ts-ignore
/* eslint-disable */

import React from 'react';
import cx from 'classnames';

const Lock = ({ className, ...props }: any) => (
  <svg className={cx('svg-icon', className)} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g>
      <path d="M12 2C13.25 2 14.3127 2.43733 15.188 3.312C16.0627 4.18733 16.5 5.25 16.5 6.5V8.5H17.7C18.2 8.5 18.625 8.675 18.975 9.025C19.325 9.375 19.5 9.8 19.5 10.3V19.7C19.5 20.2 19.325 20.625 18.975 20.975C18.625 21.325 18.2 21.5 17.7 21.5H6.3C5.8 21.5 5.375 21.325 5.025 20.975C4.675 20.625 4.5 20.2 4.5 19.7V10.3C4.5 9.8 4.675 9.375 5.025 9.025C5.375 8.675 5.8 8.5 6.3 8.5H7.5V6.5C7.5 5.25 7.93733 4.18733 8.812 3.312C9.68733 2.43733 10.75 2 12 2ZM12 3.5C11.1667 3.5 10.4583 3.79167 9.875 4.375C9.29167 4.95833 9 5.66667 9 6.5V8.5H15V6.5C15 5.66667 14.7083 4.95833 14.125 4.375C13.5417 3.79167 12.8333 3.5 12 3.5ZM6.3 20H17.7C17.7833 20 17.8543 19.971 17.913 19.913C17.971 19.8543 18 19.7833 18 19.7V10.3C18 10.2167 17.971 10.1457 17.913 10.087C17.8543 10.029 17.7833 10 17.7 10H6.3C6.21667 10 6.146 10.029 6.088 10.087C6.02933 10.1457 6 10.2167 6 10.3V19.7C6 19.7833 6.02933 19.8543 6.088 19.913C6.146 19.971 6.21667 20 6.3 20ZM12 13.25C11.5167 13.25 11.104 13.4207 10.762 13.762C10.4207 14.104 10.25 14.5167 10.25 15C10.25 15.4833 10.4207 15.896 10.762 16.238C11.104 16.5793 11.5167 16.75 12 16.75C12.4833 16.75 12.896 16.5793 13.238 16.238C13.5793 15.896 13.75 15.4833 13.75 15C13.75 14.5167 13.5793 14.104 13.238 13.762C12.896 13.4207 12.4833 13.25 12 13.25Z" />
    </g>
  </svg>
);

export { Lock };
