import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { ToastContainer, toast } from 'react-toastify';

import { Button, InputField, Typography } from 'components/common';
import { Input } from 'components/common/Input';
import { ownerSelectors } from 'redux/slices';
import { IOwner } from 'types';
import * as api from 'api';
import variables from 'assets/scss/variables.scss';
import { VALIDATIONS } from '../../constants';

const ReportBug = () => {
  const owner = useSelector(ownerSelectors.selectOwner) as IOwner;

  const fileInputRef = useRef<HTMLInputElement>(null);

  const formikForm = useFormik({
    initialValues: {
      firstname: owner?.firstname || '',
      lastname: owner?.lastname || '',
      email: owner?.email || '',
      description: '',
      file: '',
    },
    validate: vals => {
      const error = {} as any;

      if (String(vals.firstname).length < VALIDATIONS.FIRSTNAME_LENGTH) {
        error.firstname = 'Required';
      }

      if (String(vals.lastname).length < VALIDATIONS.LASTNAME_LENGTH) {
        error.lastname = 'Required';
      }

      if (!VALIDATIONS.EMAIL_REGEXP.test(vals.email)) {
        error.email = 'Invalid email';
      }

      if (!vals.description) {
        error.description = 'Required';
      }

      if (vals.description.length < 50) {
        error.description = 'Description must be at least 50 characters';
      }

      return error;
    },
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async vals => {
      const params = { ...vals };

      try {
        await api.createOwnerBugReport(params);
        toast.success('Bug reported successfully!');
        formikForm.resetForm();
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      } catch (error: any) {
        toast.error(error.response.data.error);
      }
    },
    enableReinitialize: true,
  });

  return (
    <div
      style={{ padding: '29px', paddingTop: '10px' }}
      className="d-flex flex-column align-items-center"
    >
      <ToastContainer autoClose={2500} />
      <div
        style={{ maxWidth: '560px' }}
        className="d-flex flex-column gap-3 align-items-center"
      >
        <Typography variant="h2" className="mt-3">
          Report an Issue
        </Typography>

        <InputField
          label="First name"
          type="text"
          name="firstname"
          value={formikForm.values.firstname || ''}
          error={formikForm.touched.firstname && formikForm.errors.firstname}
          onChange={formikForm.handleChange}
          required
        />

        <InputField
          label="Last name"
          type="text"
          name="lastname"
          value={formikForm.values.lastname || ''}
          error={formikForm.touched.lastname && formikForm.errors.lastname}
          onChange={formikForm.handleChange}
          required
        />

        <InputField
          label="Email"
          type="text"
          name="email"
          value={formikForm.values.email || ''}
          error={formikForm.touched.email && formikForm.errors.email}
          onChange={formikForm.handleChange}
          required
        />

        <Input
          type="textbox"
          value={formikForm.values.description || ''}
          placeholder="What is the issue?"
          containerStyle={{ height: '150px' }}
          style={{ height: '150px', width: '325px', resize: 'none' }}
          onChange={(e: any) => {
            formikForm.setFieldValue('description', e.target.value);
          }}
        />

        {
          formikForm.touched.description && formikForm.errors.description && (
            <div
              className="error-msg pt-2"
              style={{
                whiteSpace: 'normal',
                textAlign: 'center',
                color: variables.red1,
              }}
            >
              {formikForm.errors.description}
            </div>
          )
        }

        <div className="d-flex w-100 justify-content-center">
          <input
            ref={fileInputRef}
            type="file"
            style={{ width: '200px' }}
            onChange={(e: any) => {
              const file = e.target.files[0];
              formikForm.setFieldValue('file', file);
            }}
          />
        </div>

        <Button
          variant="primary"
          type="submit"
          onClick={formikForm.handleSubmit}
        >
          Report Bug
        </Button>
      </div>
    </div>
  );
};

export default ReportBug;
