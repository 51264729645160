import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams, useLocation } from 'react-router-dom';
import cx from 'classnames';
import { isEqual } from 'lodash';
import { loadStripe } from '@stripe/stripe-js';
import { useStripe, useElements, Elements } from '@stripe/react-stripe-js';
import mixpanel from 'mixpanel-browser';

import * as api from 'api';
import { Typography, Card, Button } from '../../common';
import { ArrowDown, ArrowUp } from '../../../assets/icons';
import { LoadingSpinner } from '../../LoadingSpinner';
import { NewCard } from './NewCard';

import { ICheckout, IPaymentMethod, IProfile, IReservation } from '../../../types';
import {
  checkoutActions,
  checkoutSelectors,
  profileActions,
  profileSelectors,
  uiSelectors,
} from '../../../redux/slices';

// @ts-ignore
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const StripeWrapper = (Component: any) => (props: any) => { // eslint-disable-line
  const {
    stripePaymentIntentSecret,
  } = useSelector(checkoutSelectors.selectCheckout) as ICheckout;
  const { search } = useLocation();
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const params = new URLSearchParams(search);
    if (stripePaymentIntentSecret && !params.get('spis')) {
      setSearchParams(params => {
        params.set('spis', stripePaymentIntentSecret);
        return params;
      });
    }

    if (!stripePaymentIntentSecret && params.get('spis')) {
      dispatch(checkoutActions.setCheckoutData.base({ key: 'stripePaymentIntentSecret', data: params.get('spis') }));
    }
  }, [stripePaymentIntentSecret]);

  if (!stripePaymentIntentSecret) return null;

  return (
    <Elements
      stripe={stripePromise}
      options={{
        locale: 'en',
        clientSecret: stripePaymentIntentSecret,
      }}
    >
      <Component {...props} />
    </Elements>
  );
};
StripeWrapper.displayName = 'StripeWrapper';

interface IProps {
  className?: string;
  onSuccessfulPayment(usedCredits: boolean): () => void;
}

const PaymentForm = ({ className, onSuccessfulPayment }: IProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const isLoading = useSelector(profileSelectors.selectPaymentMethodsLoading);
  const methods = useSelector(profileSelectors.selectPaymentMethods) as [IPaymentMethod] | [];
  const {
    payment,
    stripePaymentIntentSecret,
  } = useSelector(checkoutSelectors.selectCheckout) as ICheckout;
  const isValid = useSelector(checkoutSelectors.selectCheckoutValid) as boolean;
  const isMapFullSize = useSelector(uiSelectors.selectMapFullSize) as boolean;
  const profile = useSelector(profileSelectors.selectProfile) as IProfile;
  const reservation = useSelector(checkoutSelectors.selectReservation) as IReservation;
  const [bookingInProgress, setBookingInProgress] = useState(false);
  const [useCredits, setUseCredits] = useState(false);
  const darkMode = useSelector(uiSelectors.selectDarkMode);
  const subdomain = window.location.hostname.replace(process.env.REACT_APP_BASE_URL!, '').replace('.', '');

  useEffect(() => {
    if (subdomain === 'agent') {
      return;
    }

    dispatch(profileActions.loadPaymentMethods.base());
  }, []);

  useEffect(() => {
    elements?.update({ appearance: { theme: darkMode ? 'night' : 'none' } });
  }, [elements]);

  const creditsCoverTotal = useMemo(() => {
    if (!reservation || !profile) return false;

    return reservation.total_cost <= (profile.credits / 100);
  }, [useCredits, reservation, profile]);

  const useCreditsText = useMemo(() => {
    if (!reservation || !profile) return '';

    if (!useCredits) {
      return `Balance: $${profile.credits / 100}`;
    }

    if (creditsCoverTotal) {
      const creditsRemaining = (profile.credits / 100) - reservation.total_cost;
      return `Balance: $${profile.credits / 100} ($${creditsRemaining} remaining)`;
    }

    return `Balance: $${profile.credits / 100} ($0 remaining)`;
  }, [useCredits, creditsCoverTotal, profile, reservation]);

  const handleMethodClick = (data: any) => () => {
    if (!isEqual(data, payment)) {
      dispatch(checkoutActions.setCheckoutData.base({ key: 'payment', data }));

      if (data.id === '-1') {
        mixpanel.track('Started New Card');
      } else {
        mixpanel.track('Selected Existing Card');
      }
    } else {
      dispatch(checkoutActions.setCheckoutData.base({ key: 'payment', data: {} }));
    }
  };

  const handleNewCard = (e: any) => {
    if (e.complete) {
      mixpanel.track('Finished New Card');
      dispatch(checkoutActions.setCheckoutData.base({ key: 'payment', data: { id: '-1', valid: true } }));
    } else {
      dispatch(checkoutActions.setCheckoutData.base({ key: 'payment', data: { id: '-1', valid: false } }));
    }
  };

  const handleFinalize = async () => {
    if (!stripe || !elements || !stripePaymentIntentSecret || !payment) {
      return;
    }

    setBookingInProgress(true);

    const { error: submitError } = await elements.submit();
    if (submitError) {
      setBookingInProgress(false);
      alert(submitError);
      return;
    }

    let paymentMethod = payment.id.toString();

    // new card and don't need to charge card, use setupintent to add card
    // for later transactions like deposit, tolls, etc.
    if (paymentMethod === '-1' && useCredits && creditsCoverTotal) {
      const secret = await api.createStripeSetupIntent();

      const { setupIntent, error } = await stripe.confirmSetup({
        elements,
        clientSecret: secret,
        redirect: 'if_required',
      });

      if (error) {
        setBookingInProgress(false);
        alert(error.message);
        return;
      }

      paymentMethod = setupIntent.payment_method as string;
    }

    if (useCredits && creditsCoverTotal) {
      setBookingInProgress(false);
      onSuccessfulPayment(useCredits);
      return;
    }

    let prevAmount = 0;
    let newAmount = 0;
    if (useCredits && !creditsCoverTotal) {
      try {
        const { old_amount, new_amount } = await api.updateReservationUseCredits(reservation.id);

        prevAmount = old_amount;
        newAmount = new_amount;
      } catch (e) {
        setBookingInProgress(false);
        alert(e);
        return;
      }
    }

    let errorDuringBooking = null;

    if (paymentMethod !== '-1') {
      const { error } = await stripe.confirmCardPayment(stripePaymentIntentSecret, {
        payment_method: paymentMethod as string,
      });
      errorDuringBooking = error;
    } else if (paymentMethod === '-1') {
      const { error } = await stripe.confirmPayment({
        elements,
        redirect: 'if_required',
      });
      errorDuringBooking = error;
    }

    setBookingInProgress(false);

    if (errorDuringBooking) {
      if (useCredits && !creditsCoverTotal) {
        try {
          await api.updateReservationPaymentIntent(reservation.id, prevAmount);
        } catch (e) {
          return;
        }
      }

      // eslint-disable-next-line no-alert
      alert(errorDuringBooking.message);
      return;
    }

    onSuccessfulPayment(useCredits);
  };

  const handleDeleteCard = (id: string | number) => (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(profileActions.deletePaymentMethod.base({ id }));
  };

  return (
    <div className={cx('position-relative', className)}>
      <Typography variant="h2">Payment</Typography>
      {Boolean(isLoading) && <LoadingSpinner className="mt-3" />}
      {!isLoading && !methods.length && (
        <NewCard onChange={handleNewCard} />
      )}
      {!isLoading && Boolean(methods.length) && (
        <>
          {methods.map((method: IPaymentMethod) => (
            <Card
              key={method.id}
              name="paymentMethod"
              type="radio"
              onClick={(e) => {
                e.stopPropagation();
                handleMethodClick({ id: method.id, valid: true })();
              }}
              checked={payment?.id === method.id}
              className={cx('d-flex flex-column gap-3 cursor-default focus-disable active-disable mb-3', {
                hover: payment?.id === method.id,
                'border-dark': payment?.id === method.id,
                'hover-disabled': payment?.id !== method.id
              })}
            >
              <div
                className="d-flex gap-3 align-items-center justify-content-between"
                onClick={(e: any) => e.stopPropagation()}
              >
                <Typography
                  variant="body2"
                  className="m-0"
                  onClick={(e: any) => e.stopPropagation()}
                >
                  {`Credit card **** ${method.last4} `}
                </Typography>
                <div onClick={(e) => e.stopPropagation()}>
                  {payment?.id === method.id ? <ArrowUp /> : <ArrowDown />}
                </div>
              </div>
              {payment?.id === method.id && (
                <div className="" onClick={(e: any) => e.stopPropagation()}>
                  <Button onClick={handleDeleteCard(method.id)} className="">
                    Delete credit card
                  </Button>
                </div>
              )}
            </Card>
          ))}

          <Card
            name="paymentMethod"
            type="radio"
            onClick={handleMethodClick({ id: '-1' })}
            checked={payment?.id === '-1'}
            className={cx('d-flex flex-column gap-3 cursor-default focus-disable active-disable', {
              hover: payment?.id === '-1',
              'border-dark': payment?.id === '-1',
              'hover-disabled': payment?.id !== '-1'
            })}
          >
            <div
              className="d-flex w-100 gap-3 align-items-center justify-content-between"
              onClick={(e) => e.stopPropagation()}
            >
              <Typography
                variant="body2"
                className="m-0"
                onClick={(e: any) => e.stopPropagation()}
              >
                Add card
              </Typography>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {payment?.id === '-1' ? <ArrowUp /> : <ArrowDown />}
              </div>
            </div>
            {payment?.id === '-1' && (
              <NewCard onChange={handleNewCard} />
            )}
          </Card>

          <div className="mt-2">
            <Typography variant="body2">
              A credit card is required regardless if your Eon credits cover the total cost.
            </Typography>
          </div>
        </>
      )}

      {
        subdomain !== 'agent' && (
          <>
            <div>
              ________________________________________________
            </div>
            <Card
              type="checkbox"
              onClick={(e) => {
                e.stopPropagation();
                setUseCredits(!useCredits);
              }}
              checked={useCredits}
              className={cx('d-flex flex-column gap-3 cursor-default focus-disable active-disable mb-3 mt-4', {
              })}
              disabled={profile?.credits <= 0}
            >
              <div
                className="d-flex gap-3 align-items-center justify-content-between"
                onClick={(e: any) => e.stopPropagation()}
              >
                <Typography
                  variant="body2"
                  className="m-0"
                  onClick={(e: any) => e.stopPropagation()}
                >
                  Eon Credits
                </Typography>
              </div>

              <div>
                <Typography variant="body2">
                  {useCreditsText}
                </Typography>
              </div>
            </Card>
          </>
        )
      }

      {
        useCredits && !creditsCoverTotal && (
          <div>
            <Typography>
              <b>Your credit card will be charged: </b>

              {`$${reservation.total_cost - (profile.credits / 100)}`}
            </Typography>
          </div>
        )
      }

      <div className="py-4 py-md-0" />
      <div
        className="position-fixed position-md-relative row-x2 row-md-none pb-md-4 mt-4"
        style={{ bottom: 0, width: '100%', opacity: isMapFullSize ? 0 : 1 }}
      >
        <Button
          variant="primary"
          sticky="sm"
          className="w-100 w-md-fit"
          style={{ minWidth: '50%' }}
          disabled={!isValid || bookingInProgress}
          onClick={handleFinalize}
        >
          Complete Booking
        </Button>
      </div>
    </div>
  );
};

PaymentForm.defaultProps = {
  className: undefined,
};

PaymentForm.displayName = 'Payment';

const Payment = StripeWrapper(PaymentForm);

export { Payment };
