import { createSlice } from '../../helpers/store';
import { IStoreLocations, IStoreCars, ICar } from '../../types';
import { CARS_FILTERS } from '../../constants';

interface IState {
  locations: IStoreLocations;
  cars: IStoreCars;
}

const initialState: IState = {
  locations: {
    isLoading: true,
    data: [],
  },
  cars: {
    isLoading: false,
    data: []
  }
};

const { actions, selectors, reducer } = createSlice({
  prefix: 'locations',
  initialState,

  selectors: {
    selectLocations: (state: IState) => state.locations,
    selectLocationById: (state: IState, _: any, id: number) => state.locations.data.find(l => l.id === id),
    selectCars: (state: IState, global: any, withoutLocationFilter: boolean | undefined) => ({
      isLoading: state.cars.isLoading,
      data: state.cars.data.filter((car: ICar) => {
        const { filters, location } = global.ui.carsSearch;
        if (!car.is_available || (global.ui.needsDelivery && !car.supports_delivery)) {
          return false;
        }
        if (!withoutLocationFilter && location) {
          if (!(
            car.default_location.latitude === location.latitude
            && car.default_location.longitude === location.longitude
          )) {
            return false;
          }
        }
        let filtersCount = 0;
        const compareResults = [] as any;
        Object.keys(filters).forEach(key => {
          // @ts-ignore
          const value = car[CARS_FILTERS[key].filterKey];
          if (Object.keys(filters[key]).length) {
            filtersCount++;
            Object.keys(filters[key]).forEach(cond => {
              // @ts-ignore
              const conditions = CARS_FILTERS[key].conditions[cond];
              const conditionsResults = [] as any;
              Object.keys(conditions).forEach(sign => {
                switch (sign) {
                  case '>=':
                    conditionsResults.push(value >= conditions[sign]);
                    break;
                  case '<=':
                    conditionsResults.push(value <= conditions[sign]);
                    break;
                  case '==':
                    conditionsResults.push(value === conditions[sign]);
                    break;
                  default:
                }
              });
              if (conditionsResults.filter((f: boolean) => f).length === Object.keys(conditions).length) {
                compareResults.push(true);
              }
            });
          }
        });
        return compareResults.length === filtersCount;
      })
    }),
  },

  reducers: {
    loadLocations: {
      base: (state: IState) => {
        state.locations.isLoading = true;
      },
      success: (state: IState, { payload: { cities } }: any) => {
        state.locations.data = cities;
        state.locations.isLoading = false;
      },
      failed: (state: IState) => {
        state.locations.isLoading = false;
      }
    },
    loadAvailableCars: {
      base: (state: IState) => {
        state.cars.isLoading = true;
      },
      success: (state: IState, { payload: { cars } }: any) => {
        state.cars.isLoading = false;
        state.cars.data = cars;
      },
      failed: (state: IState) => {
        state.cars.isLoading = false;
      }
    }
  },
});

export { reducer as locations, actions as locationsActions, selectors as locationsSelectors };
