import React, { ReactElement } from 'react';
import cx from 'classnames';

import { Placeholder } from '../Placeholder';
import { Typography } from '../Typography';
import { ArrowRight } from '../../../assets/icons';

interface IProps {
  title?: string;
  subtitle?: string;
  icon?: ReactElement;
  className?: string;
  onClick?: (e: any) => void;
  showPlaceholder?: boolean;
}

const Navigation = ({ title, subtitle, icon, className, onClick, showPlaceholder }: IProps) => showPlaceholder ? (
  <Placeholder animation className={cx('navigation', 'pe-none', className)}>
    <div className="d-flex align-items-center gap-3 flex-grow-1">
      {icon && React.cloneElement(icon)}
      <div className="d-flex flex-column flex-grow-1">
        {subtitle && <Placeholder className="text-body3 mb-2" style={{ width: '20%' }} />}
        <Placeholder className="text-body2" style={{ width: '80%' }} />
      </div>
    </div>
    <ArrowRight />
  </Placeholder>
) : (
  <button type="button" className={cx('navigation', className)} onClick={onClick}>
    <div className="d-flex align-items-center gap-3">
      {icon && React.cloneElement(icon)}
      <div className="d-flex flex-column">
        {subtitle && <Typography className="subtitle" inline variant="body3">{subtitle}</Typography>}
        <Typography inline variant="body2">{title}</Typography>
      </div>
    </div>
    <ArrowRight />
  </button>
);

Navigation.defaultProps = {
  className: undefined,
  title: undefined,
  subtitle: undefined,
  icon: undefined,
  onClick: undefined,
  showPlaceholder: false,
};

export { Navigation };
