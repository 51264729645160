// @ts-ignore
/* eslint-disable */

import React from 'react';
import cx from 'classnames';

const Check = ({ className, ...props }: any) => (
  <svg className={cx('svg-icon', className)} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g>
      <path d="M9.5501 17.65L4.2251 12.325L5.2751 11.25L9.5501 15.525L18.7251 6.35001L19.7751 7.42501L9.5501 17.65Z" />
    </g>
  </svg>
);

export { Check };
