import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import cx from 'classnames';

import { checkoutActions, locationsActions, locationsSelectors, uiActions, uiSelectors } from '../../redux/slices';
import { IAddress, ILocation, IMap, IMapLocation, IStoreCarsSearch } from '../../types';
import { CAR_TABS_MODE, DELIVERY_ADDRESSES, DEFAULT_MAP_HEIGHT, MIN_MAP_HEIGHT } from '../../constants';
import { NavFinish, NavStart } from '../../assets/icons';

import { PlaceAutocomplete } from './PlaceAutocomplete';
import { useDeepEffect } from '../../hooks';

interface IProps {
  refData: any;
  inputFocused: string | null;
  setInputFocused: (data: string | null) => void;
}

declare const google: any;

const CarsDelivery = ({ inputFocused, setInputFocused, refData }: IProps) => {
  const dispatch = useDispatch();
  const { city } = useParams();
  const navigate = useNavigate();
  const { search } = useLocation();
  const location = useSelector(state => locationsSelectors.selectLocationById(state, Number(city))) as ILocation;
  const map = useSelector(uiSelectors.selectMap) as IMap;
  const currentPosition = useSelector(uiSelectors.selectCurrentPosition) as IMapLocation;
  const { address } = useSelector(uiSelectors.selectCarsSearch) as IStoreCarsSearch;

  useDeepEffect(() => {
    const height = address.deliveryTo && address.collectAt && !inputFocused ? DEFAULT_MAP_HEIGHT : MIN_MAP_HEIGHT;
    dispatch(uiActions.setScrollableData.base({ id: 'map', data: height }));
    return () => dispatch(uiActions.setScrollableData.base({ id: 'map', data: DEFAULT_MAP_HEIGHT }));
  }, [address, inputFocused]);

  const handleGoToPickUp = () => {
    const params = new URLSearchParams(search);
    params.delete('car');
    navigate({ search: `?${params.toString()}`, hash: `#${CAR_TABS_MODE.PICK_UP}` }, { replace: true });
    dispatch(uiActions.setCarsTabMode.base(CAR_TABS_MODE.PICK_UP));
    dispatch(uiActions.setNeedsDelivery.base(false));
    dispatch(checkoutActions.clearPurchaseData.base());
  };

  const handleSetAddress = (key: string) => (addr: IAddress | null) => {
    dispatch(uiActions.setAddress.base({ key, address: addr }));
    dispatch(locationsActions.loadAvailableCars.base());
    // If we have a car selected, we need to clear it
    const params = new URLSearchParams(search);
    params.delete('car');
    navigate({ search: `?${params.toString()}`, hash: `#${CAR_TABS_MODE.DELIVERY}` }, { replace: true });
    dispatch(checkoutActions.setCarDetails.base(null));
    setInputFocused(null);
    if (map && addr && address.deliveryTo && (address.collectAt || key === 'collectAt')) {
      const bounds = new google.maps.LatLngBounds();
      bounds.extend(key === 'collectAt' ? address.deliveryTo?.geo : addr.geo);
      bounds.extend(key === 'deliveryTo' ? address.collectAt?.geo : addr.geo);
      map.setCenter(bounds.getCenter());
      map.fitBounds(bounds);
      map.setZoom(16);
    } else if (addr && map) {
      map.setCenter(addr.geo);
      map.setZoom(16);
    }
  };

  const handleGoToDelivery = () => {
    setInputFocused(DELIVERY_ADDRESSES.DELIVERY_TO);
  };

  return (
    <div className={cx('px-4', { 'box-shadow2': refData?.scrollTop > 0 })}>
      {inputFocused !== DELIVERY_ADDRESSES.COLLECT_AT && (
        <PlaceAutocomplete
          className="mb-2"
          title={inputFocused === DELIVERY_ADDRESSES.DELIVERY_TO || !address.deliveryTo
            ? 'Delivery to...' : undefined}
          onGoBack={() => address.deliveryTo ? setInputFocused(null) : handleGoToPickUp()}
          label="Delivery address"
          hintText="Delivery to airport, hotel or destination of your choice. Includes both delivery and pick up."
          icon={<NavStart />}
          location={{ lat: location?.latitude, lng: location?.longitude }}
          origin={currentPosition}
          toggleFocus={(status: boolean) => setInputFocused(status ? DELIVERY_ADDRESSES.DELIVERY_TO : null)}
          isFocused={inputFocused === DELIVERY_ADDRESSES.DELIVERY_TO}
          onSelect={handleSetAddress(DELIVERY_ADDRESSES.DELIVERY_TO)}
          value={address.deliveryTo}
          autoFocus={!address.deliveryTo}
        />
      )}
      {address.deliveryTo && inputFocused !== DELIVERY_ADDRESSES.DELIVERY_TO && (
        <PlaceAutocomplete
          className="mb-2 pb-3"
          title={inputFocused === DELIVERY_ADDRESSES.COLLECT_AT ? 'Collect at...' : undefined}
          onGoBack={handleGoToDelivery}
          label="Return address"
          hintText="Delivery to airport, hotel or destination of your choice. Includes both delivery and pick up."
          icon={<NavFinish />}
          location={{ lat: location?.latitude, lng: location?.longitude }}
          origin={currentPosition}
          toggleFocus={(status: boolean) => setInputFocused(status ? DELIVERY_ADDRESSES.COLLECT_AT : null)}
          isFocused={inputFocused === DELIVERY_ADDRESSES.COLLECT_AT}
          onSelect={handleSetAddress(DELIVERY_ADDRESSES.COLLECT_AT)}
          value={address.collectAt}
        />
      )}
    </div>
  );
};

export { CarsDelivery };
