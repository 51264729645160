import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ownerSelectors } from 'redux/slices';
import { IOwner } from 'types';
import CopyLink from 'components/common/CopyLink';
import { Typography } from 'components/common';

const Referrals = () => {
  const owner = useSelector(ownerSelectors.selectOwner) as IOwner;

  const guestReferralLink = useMemo(() => {
    if (!owner) return '';

    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const PORT = process.env.REACT_APP_PORT;

    return `${window.location.protocol}//${BASE_URL}${PORT ? `:${PORT}` : ''}?ref=${owner.referral_code}`;
  }, [owner]);

  const ownerReferralLink = useMemo(() => {
    if (!owner) return '';

    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const PORT = process.env.REACT_APP_PORT;

    return `${window.location.protocol}//${BASE_URL}${PORT ? `:${PORT}` : ''}/owner/signup?ref=${owner.referral_code}`;
  }, [owner]);

  return (
    <div
      className="d-flex flex-column w-100 align-items-center"
    >
      <div
        className="d-flex flex-column justify-content-center align-items-center gap-4 mt-5"
        style={{ maxWidth: '95%', width: '350px' }}
      >
        <Typography variant="h1">
          Refer a Friend! 👋
        </Typography>

        <div className="w-100">
          <Typography>
            Refer a guest and earn $35 in Eon credit when they complete their first trip.
          </Typography>

          <CopyLink link={guestReferralLink} />
        </div>

        <div className="w-100">
          <Typography>
            Refer an owner and grow the Eon community and earn $150 in Eon credit once a trip
            is completed with one of their cars.
          </Typography>

          <CopyLink link={ownerReferralLink} />
        </div>
      </div>
    </div>
  );
};

export default Referrals;
