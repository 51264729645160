import { DISCOUNTS } from './constants';
import { ICar } from './types';

export const getPriceWithDiscount = (car: ICar, days: number, isFullPrice?: boolean) => {
  let newRate = car.daily_rate;
  DISCOUNTS.forEach((item: any) => {
    let check = true;
    if (item.daysMore && days < item.daysMore) {
      check = false;
    }
    if (item.daysLess && days >= item.daysLess) {
      check = false;
    }
    if (check) {
      if (item.key === 'week') newRate = car.weekly_discount;
      if (item.key === 'month') newRate = car.monthly_discount;
    }
  });
  return isFullPrice ? parseFloat((newRate * days).toFixed(2)) : parseFloat(newRate.toFixed(2));
};

export const formatNumber = (num: number | undefined) => {
  if (!num) return 0;

  const formattedAmount = num.toLocaleString('en-US', {
    minimumFractionDigits: num % 1 === 0 ? 0 : 2,
    maximumFractionDigits: 2,
  });

  return formattedAmount;
};

export const getDiscountPrice = (car: ICar, days: number, isFullPrice?: boolean) => {
  const originalPrice = isFullPrice ? car.daily_rate * days : car.daily_rate;
  const price = getPriceWithDiscount(car, days, isFullPrice);
  return parseFloat((originalPrice - price).toFixed(2));
};

export const getPriceWithDelivery = (car: ICar, days: number, isFullPrice?: boolean) => {
  let totalPrice = 0;
  totalPrice += getPriceWithDiscount(car, days, isFullPrice);
  totalPrice += car.delivery_cost || 0;

  return totalPrice;
};
