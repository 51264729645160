import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import { useFormik } from 'formik';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';

import { Button, InputField, Typography } from 'components/common';
import { ownerActions, ownerSelectors } from 'redux/slices';
import { ICar, APIStatus } from 'types';

interface IProps {
  isOpen?: boolean;
  car?: ICar | undefined;
  startDate?: moment.Moment;
  endDate?: moment.Moment;
  onClose?: () => void;
}

const CreateBlock = ({
  isOpen = false,
  car,
  startDate,
  endDate,
  onClose,
}: IProps) => {
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(isOpen);
  const createReservationBlockStatus = useSelector(ownerSelectors.selectCreateReservationBlockStatus) as APIStatus;

  useEffect(() => {
    if (createReservationBlockStatus.success) {
      setIsVisible(false);
      toast.success(
        'Your reservation block was made successfully',
      );
      dispatch(ownerActions.resetCreateReservationBlock.base());
    } else if (createReservationBlockStatus.error) {
      toast.error(
        'Error creating a reservation block',
      );
      dispatch(ownerActions.resetCreateReservationBlock.base());
    }
  }, [createReservationBlockStatus]);

  useEffect(() => {
    setIsVisible(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (!isVisible && onClose) {
      onClose();
    }
  }, [isVisible]);

  const formikForm = useFormik({
    initialValues: {
      start_date: startDate || moment(),
      end_date: endDate || (moment().add(1, 'day')),
    },
    validateOnChange: true,
    validateOnBlur: true,
    validate: vals => {
      const error = {} as any;

      if (!vals.start_date) {
        error.start_date = 'Start date required';
      }

      if (!vals.end_date) {
        error.end_date = 'End date required';
      }

      if (vals.start_date && vals.end_date && vals.start_date.isAfter(vals.end_date)) {
        error.start_date = 'Start date must be before end date';
      }

      return error;
    },
    onSubmit: async vals => {
      const { id, default_location: { timezone } } = car!;

      dispatch(ownerActions.createReservationBlock.base({
        start_date: moment(vals.start_date).tz(timezone, true),
        end_date: moment(vals.end_date).tz(timezone, true),
        car_id: id,
      }));

      setIsVisible(false);
      // eslint-disable-next-line no-unused-expressions
      onClose && onClose();
    },
  });

  useEffect(() => {
    if (startDate) {
      formikForm.setFieldValue('start_date', startDate);
    }

    if (endDate) {
      formikForm.setFieldValue('end_date', endDate);
    }
  }, [startDate, endDate]);

  if (!car) return null;

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isVisible}
      style={{
        overlay: {
          zIndex: 1000,
        },
        content: {
          zIndex: 1000,
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          padding: '29px',
        },
      }}
      onRequestClose={() => setIsVisible(false)}
    >
      <div className="d-flex justify-content-end">
        <Button
          onClick={() => setIsVisible(false)}
          style={{ width: '25px', height: '25px', padding: 0 }}
        >
          X
        </Button>
      </div>

      <div className="d-flex flex-column gap-3 mt-4 align-items-center">
        <div>
          <Typography variant="h3">
            Start time
          </Typography>

          <InputField
            label="Start time"
            type="datetime-local"
            name="Start time"
            value={formikForm.values.start_date?.format('YYYY-MM-DDTHH:mm')}
            onBlur={(e: any) => formikForm.setFieldValue('start_date', moment(e.target.value))}
            required
            style={{ maxWidth: '290px', height: '50px' }}
            error={formikForm.errors.start_date}
          />
        </div>

        <div>
          <Typography variant="h3">
            End time
          </Typography>

          <InputField
            label="End time"
            type="datetime-local"
            name="End time"
            value={formikForm.values.end_date?.format('YYYY-MM-DDTHH:mm')}
            onBlur={(e: any) => formikForm.setFieldValue('end_date', moment(e.target.value))}
            required
            style={{ maxWidth: '290px', height: '50px' }}
            error={formikForm.errors.end_date}
          />
        </div>

        <div>
          <Typography>
            {
              car.default_location?.timezone
              && `All times are in ${moment().tz(car.default_location.timezone).format('z')}`
            }
          </Typography>
        </div>

        <div className="mt-3 d-flex flex-row gap-3 pb-4 w-100 justify-content-space-between">
          <Button
            onClick={() => setIsVisible(false)}
          >
            Cancel
          </Button>

          <div className="d-flex gap-1">
            <Button
              onClick={formikForm.handleSubmit}
            >
              Block
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CreateBlock;
