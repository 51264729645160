import { useState, useRef, useMemo, useEffect } from 'react';
import cx from 'classnames';
import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';

import { Key, Settings, Charging, SteeringWheel, CheckeredFlag, Warning } from 'assets/icons';
import { Button } from 'components/common';
import { LinearProgressBar } from './LinearProgressBar';
import './styles.scss';
import ConfirmSelectCard from './ConfirmSelectCard';
import SingleSelectCardList from './SingleSelectCardList';
import Questionnaire from './Questionnaire';
import Banner from './Banner';
import DoneCard from './DoneCard';
import UserForm from './UserForm';

const defaultSections: any[] = [
  // {
  //   title: 'Have You Ever Driven A Tesla Before?',
  //   titleShort: 'Welcome',
  //   icon: <Wave />,
  //   steps: [
  //     {
  //       componentName: 'SingleSelectCardList',
  //       selectCards: [
  //         {
  //           mediaPath: '/images/model-y-car-instructions/kid-driving-car.gif',
  //           title: 'First Time',
  //           subTitle: 'driver',
  //           body: 'We’ll walk you through EVERYTHING you need to know for your first time driving a Tesla',
  //         },
  //         {
  //           mediaPath: '/images/model-y-car-instructions/grandma-driving.gif',
  //           title: 'Once or Twice',
  //           subTitle: 'driver',
  //           body: 'Quickly going over the basics so you can feel comfortable enough to get on the road fast.',
  //         },
  //         {
  //           mediaPath: '/images/model-y-car-instructions/tesla-autopilot.gif',
  //           title: '',
  //           subTitle: 'My name is Musk, Elon Musk',
  //           body: 'You’re an expert on Teslas, lets just get going!',
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    title: 'Getting Started: The Basics',
    titleShort: 'Doors, locks, keys',
    icon: <Key />,
    steps: [
      {
        componentName: 'ConfirmSelectCard',
        mediaPath: '/images/model-y-car-instructions/open-door.gif',
        body: 'Press on the thick portion of the door handle to pop it out and pull with the rest of your hand.',
      },
      {
        componentName: 'ConfirmSelectCard',
        mediaPath: '/images/model-y-car-instructions/open-door-button.png',
        body: 'To open the door from the inside press the button on the top of the handle inside the car.',
      },
      {
        componentName: 'ConfirmSelectCard',
        mediaPath: '/images/model-y-car-instructions/open-door-manual.png',
        body: 'Doors can also be opened from inside using the manual release handle.',
      },
      {
        componentName: 'ConfirmSelectCard',
        mediaPath: '/images/model-y-car-instructions/door-keycard.gif',
        body: 'Place keycard on the window pillar under the camera to lock and unlock the car from outside.',
      },
      {
        componentName: 'ConfirmSelectCard',
        mediaPath: '/images/model-y-car-instructions/console-keycard.gif',
        body: 'Tap the keycard on the center console behind the cupholders to activate the car.',
      },
      {
        componentName: 'ConfirmSelectCard',
        mediaPath: '/images/model-y-car-instructions/warn-card.png',
        footer: (
          <>
            ON/OFF button

            <Warning />
          </>
        ),
        enableEnlarge: false,
        body: 'There is no ON or OFF button on the car, it’s always awake.',
      },
      {
        componentName: 'ConfirmSelectCard',
        mediaPath: '/images/model-y-car-instructions/open-trunks.webp',
        body: 'Open back and front trunks using the buttons on the screen.',
      },
      {
        componentName: 'ConfirmSelectCard',
        mediaPath: '/images/model-y-car-instructions/frunk.png',
        body: 'Close the front trunk by pressing down on it firmly. The rear trunk has a button to close it.',
      },
      {
        componentName: 'ConfirmSelectCard',
        mediaPath: '/images/model-y-car-instructions/gear-stick.png',
        body: 'To shift gears, pull the right hand stick down to Drive, '
          + 'up to Reverse, and push the button at the end to Park.',
      },
      {
        componentName: 'ConfirmSelectCard',
        mediaPath: '/images/model-y-car-instructions/signals-stick.png',
        body: 'Turn signals are activated using the left hand stick behind the wheel.',
      },
      {
        componentName: 'ConfirmSelectCard',
        mediaPath: '/images/model-y-car-instructions/hazard-button.png',
        body: 'Your hazard lights are on the roof above you in front of the rear view mirror.',
      },
      {
        componentName: 'Questionnaire',
        questions: [
          {
            question: 'Where is the car’s on button?',
            options: [
              'Under the steering wheel',
              'Inside the settings menu on the screen',
              'There is no button, car is always on',
              'Clicking the steering wheel thumb scrollers',
            ],
            answers: [2],
            type: 'single',
          },
          {
            question: 'How do you park the car?',
            options: [
              'Hold the break for 15 seconds',
              'Pull down on the left steering stick',
              'Tap the tesla logo on the screen',
              'Click on the button at the end of the right hand stick shift',
            ],
            answers: [3],
            type: 'single',
          },
          {
            question: 'How do I activate the car?',
            options: [
              'Tap key on the center console',
              'Whisper your undying love for Elon Musk into the air vents',
              'Honk, Hard',
              'All of the above',
            ],
            answers: [0],
            type: 'single',
          },
          {
            question: 'How do I lock the car?',
            options: [
              'Tap keycard on the center console',
              'Tap keycard on the window panel under the camera',
              'Touch your elbows together and spin 3 times',
              'Walk away from the car',
            ],
            answers: [1],
            type: 'single',
          },
        ],
      },
    ],
  },
  {
    title: 'Adjustments: Getting It Just Right',
    titleShort: 'Adjustments, screen settings',
    icon: <Settings />,
    steps: [
      {
        componentName: 'ConfirmSelectCard',
        mediaPath: '/images/model-y-car-instructions/seat-adjustments.png',
        body: 'Let’s get you comfortable! Seat adjustments are here.',
      },
      {
        componentName: 'ConfirmSelectCard',
        mediaPath: '/images/model-y-car-instructions/adjust-mirrors.jpg',
        body: `After tapping on the Steering controls
        in the settings menu, use the thumb scrollers to adjust your mirrors.`,
      },
      {
        componentName: 'ConfirmSelectCard',
        mediaPath: '/images/model-y-car-instructions/adjust-steering-wheel.jpg',
        body: 'Using the same menu, adjust your steering wheel using the thumb scrollers.',
      },
      {
        componentName: 'ConfirmSelectCard',
        mediaPath: '/images/model-y-car-instructions/warn-card.png',
        footer: (
          <>
            Chill mode

            <Warning />
          </>
        ),
        enableEnlarge: false,
        body: `Warning: Teslas have powerful acceleration.
        Get comfortable with the car’s power by first by putting it into “chill mode”.`,
      },
      {
        componentName: 'ConfirmSelectCard',
        mediaPath: '/images/model-y-car-instructions/chill-mode.jpg',
        body: 'Turn on Chill Mode by selecting it from the pedals and steering menu.',
      },
      {
        componentName: 'ConfirmSelectCard',
        mediaPath: '/images/model-y-car-instructions/warn-card.png',
        footer: (
          <>
            Regenerative breaking

            <Warning />
          </>
        ),
        enableEnlarge: false,
        body: 'Note: Teslas have regenerative breaking that automatically '
          + 'slows them down when taking your foot off the acceleration.',
      },
      {
        componentName: 'ConfirmSelectCard',
        mediaPath: '/images/model-y-car-instructions/regen-breaking.webp',
        body: 'Choose your preference from the same menu. '
          + 'Regenerative breaking provides a small increase in range.',
      },
      {
        componentName: 'ConfirmSelectCard',
        mediaPath: '/images/model-y-car-instructions/dark-light-mode.webp',
        body: 'Change between dark and light modes in the Display menu.',
      },
      {
        componentName: 'ConfirmSelectCard',
        mediaPath: '/images/model-y-car-instructions/bluetooth.jpg',
        body: 'If you don’t see the Bluetooth icon at the bottom of your screen, '
          + 'it can be found in the app drawer(three dots) there.',
      },
      {
        componentName: 'Banner',
        title: 'Done!',
        type: 'success',
        body: 'You did it, Bravo 👏🏻 Now  It’s time to proceed to the next step',
      },
    ],
  },
  {
    title: 'Charging: All You Need To Know',
    titleShort: 'Battery charging',
    icon: <Charging />,
    steps: [
      {
        componentName: 'ConfirmSelectCard',
        mediaPath: '/images/model-y-car-instructions/battery-status.jpg',
        body: 'Your current battery status is at the top left of your main screen.',
      },
      {
        componentName: 'ConfirmSelectCard',
        mediaPath: '/images/model-y-car-instructions/find-superchargers.gif',
        body: 'Find Superchargers in the search bar, or as red pins on the map.',
      },
      {
        componentName: 'ConfirmSelectCard',
        mediaPath: '/images/model-y-car-instructions/available-superchargers.jpg',
        body: 'Some superchargers will be occupied, the car will tell you which are available.',
      },
      {
        componentName: 'ConfirmSelectCard',
        mediaPath: '',
        body: (
          <div className="d-flex flex-column" style={{ gap: '0.5rem' }}>
            <div>
              ⚡️⚡️⚡️ Superchargers: can charge car to full in 20 minutes or less, great for fast charging.
            </div>

            <div>
              ⚡⚡ Destination chargers: can charge up a car in 5-6 hours, great for overnight or light top-ups.
            </div>

            <div>
              ⚡ Trickle chargers: 24+ hours to charge - mainly for emergencies.
            </div>
          </div>
        ),
      },
      {
        componentName: 'ConfirmSelectCard',
        mediaPath: '',
        body: `
         Estimated Range shown on screen IS NOT always the same as real world range.
          Teslas can often have significantly less range depending on road conditions,
        how you drive and your use of of Heating/ AC - always assume you have less battery
          than shown until you develop a good sense of the car’s capabilities.
        `,
      },
      {
        componentName: 'ConfirmSelectCard',
        mediaPath: '/images/model-y-car-instructions/charge-port.gif',
        body: `When using a Tesla charger, click on top of it to open the charge port(back left side).
        Click and hold to release it from the car once finished.`,
      },
      {
        componentName: 'ConfirmSelectCard',
        mediaPath: '/images/model-y-car-instructions/supercharging-idle-fees.jpg',
        body: `Idle Fees are a nightmare - don’t leave your car plugged into a supercharger
        once it’s done charging or you will be billed idle fees which get extremely expensive. `,
      },
      {
        componentName: 'ConfirmSelectCard',
        mediaPath: '/images/model-y-car-instructions/warn-card.png',
        footer: (
          <>
            Overnight battery

            <Warning />
          </>
        ),
        enableEnlarge: false,
        body: `Warning: Tesla batteries drain over night,
      don’t leave your Tesla with low battery overnight.`,
      },
      {
        componentName: 'Questionnaire',
        questions: [
          {
            question: 'What happens if your battery runs out?',
            options: [
              'You get a puppy',
              `
                You’ll be stranded outside while you wait for hours for a tow truck to take your car
                to a tesla service center, leave you with no car until repairs are complete
      (which might be 3 days if it’s a weekend) and curse the universe, us,
    the ghost of Nikola Tesla and the very concept of electric cars once
                you receive an invoice of $1000+ for a tow and potential battery replacement.
              `,
            ],
            answers: [1],
            type: 'single',
          },
          {
            question: 'When should you leave a supercharger?',
            options: [
              'As soon as you’re done charging',
              'When someone asks you nicely',
              'Never. Finder’s Keepers',
            ],
            answers: [0],
            type: 'single',
          },
          {
            question: 'If your car is low on battery before going to bed, when should you charge it?',
            options: [
              'Tomorrow, it’s no big deal',
              'Before you leave the car',
              'Eventually',
            ],
            answers: [1],
            type: 'single',
          },
        ],
      },
    ],
  },
  {
    title: "Autopilot: Do's and Dont's",
    titleShort: 'Autopilot',
    icon: <SteeringWheel />,
    steps: [
      {
        componentName: 'ConfirmSelectCard',
        footer: (
          <>
            Mandatory watching

            <Warning />
          </>
        ),
        enableEnlarge: false,
        mediaPath: 'https://www.youtube.com/embed/-FeMwPUAOLM',
        body: 'Finally, for a more in depth feature walkthrough, '
          + 'including how to use the autopilot functions, Watch the Autopilot tutorial video to continue.',
      },
      {
        componentName: 'Questionnaire',
        questions: [
          {
            question: 'How do you turn off traffic aware cruise control?',
            options: [
              'Click both thumb scroll wheels at the same time',
              'Double pull down on the drive shaft',
              'Push up on the drive shaft',
              'Tap on the autopilot symbol in the main screen',
            ],
            answers: [2],
            type: 'single',
          },
          {
            question: 'When do you need to turn on autopilot?',
            options: [
              'On the highway',
              'On straight roads',
              "It's optional",
              'When driving it in reverse',
            ],
            answers: [2],
            type: 'single',
          },
          {
            question: 'When autopilot or traffic aware cruise control is on, what are your responsibilities?',
            options: [
              'Hands on the wheel at all times',
              'Keep eyes on the road and ready to take over',
              'Feet on the pedals ready to take over',
              'All of the above',
            ],
            answers: [3],
            type: 'single',
          },
        ],
      },
    ],
  },
  {
    title: 'Last Questions: The Finish Line',
    titleShort: 'Review',
    icon: <CheckeredFlag />,
    steps: [
      {
        componentName: 'Questionnaire',
        questions: [
          {
            question: 'Which of the following are not allowed?',
            options: [
              'Running the battery down to 0',
              'Driving more than 300 miles per day',
              'Using autopilot functions',
              'Smoking of any kind in the car',
              'Allowing an unauthorised person to drive',
            ],
            answers: [0, 3, 4],
            type: 'multiple',
          },
          {
            question: 'Where can I leave the car to charge overnight?',
            options: [
              'A supercharger',
              'Anywhere that’s NOT a supercharger',
            ],
            answers: [1],
            type: 'single',
          },
          {
            question: 'If you have 51 miles of range and your destination is 49 miles away, what should you do?',
            options: [
              'Drive straight there, you have enough battery',
              'Route through a supercharger on the way',
            ],
            answers: [1],
            type: 'single',
          },
        ],
      },
      {
        componentName: 'UserForm',
      },
      {
        componentName: 'DoneCard',
      },
    ],
  },
];

const formattedSections = defaultSections.reduce((acc, section) => {
  const yo = section.steps
    .map((step: any, idx: number) => {
      return { ...step, sectionIdx: defaultSections.indexOf(section), stepIdx: idx };
    });

  return [...acc, { ...section, steps: yo }];
}, []);

const CarInstructions = () => {
  const [activeSectionIdx, setActiveSectionIdx] = useState(0);
  const [activeStepIdx, setActiveStepIdx] = useState(0);
  const sliderRef = useRef<Slider>(null);
  const [sections, setSections] = useState(formattedSections);

  // useEffect(() => {
  //   const currSlide = sliderRef.current?.innerSlider?.list?.querySelector(`[data-index="${activeStepIdx}"]`);
  //   const slickSlider = document.querySelector('.slick-slider');
  //   if (slickSlider && currSlide && currSlide.clientHeight > 1) {
  //     console.log('currSlide.clientHeight', currSlide.clientHeight);
  //     (slickSlider as any).style.height = `${currSlide.clientHeight}px`;
  //   }
  // }, [activeStepIdx]);

  const filteredSteps = useMemo(() => {
    return sections.reduce((acc: any, section: any) => {
      const yo = section.steps
        .map((step: any, idx: number) => {
          return { ...step, sectionIdx: sections.indexOf(section), stepIdx: idx };
        });

      return [...acc, ...yo];
    }, []);
  }, [sections]);

  const percentCompleted = useMemo(() => {
    // const totalSteps = sections.reduce((sum, section) => sum + section.steps.length, 0);

    // const currStepOutOfAllSteps = sections.reduce((sum, section, idx) => {
    //   if (idx < activeSectionIdx) {
    //     return sum + section.steps.length;
    //   }

    //   if (idx === activeSectionIdx) {
    //     return sum + activeStepIdx;
    //   }

    //   return sum;
    // }, 0);

    if (activeStepIdx === filteredSteps.length - 1) {
      return 1;
    }

    return activeStepIdx / filteredSteps.length;
  }, [activeStepIdx]);

  const showGoBackButton = useMemo(() => {
    if (activeStepIdx === 0) {
      return false;
    }

    // if (filteredSteps[activeStepIdx].componentName === 'Banner') {
    //   return false;
    // }

    return true;
  }, [activeStepIdx]);

  const showNextButton = useMemo(() => {
    if (
      filteredSteps[activeStepIdx].componentName === 'Questionnaire'
      && !filteredSteps[activeStepIdx].isComplete
    ) {
      return false;
    }

    if (filteredSteps[activeStepIdx].componentName === 'UserForm') {
      return false;
    }

    // if (filteredSteps[activeStepIdx].componentName === 'Banner') {
    //   return false;
    // }

    return activeSectionIdx <= sections.length - 1;
  }, [activeStepIdx, filteredSteps[activeStepIdx].isComplete]);

  useEffect(() => {
    sliderRef.current?.slickGoTo(activeStepIdx);
  }, []);

  const handleGoBack = () => {
    const currStep = filteredSteps[activeStepIdx];

    setSections((prevSections: any) => {
      const updatedSections = [...prevSections];
      updatedSections[activeSectionIdx].steps[currStep.stepIdx].isComplete = false;

      return updatedSections;
    });

    const prevStep = filteredSteps[activeStepIdx - 1];

    setActiveSectionIdx(prevStep.sectionIdx);
    setActiveStepIdx(activeStepIdx - 1);

    sliderRef.current?.slickPrev();
  };

  const renderBody = () => {
    return (
      <div>
        <Slider
          ref={sliderRef}
          {
          ...{
            dots: false,
            infinite: false,
            arrows: false,
            speed: 1500,
            slidesToShow: 1,
            slidesToScroll: 1,
            lazyLoad: 'anticipated',
            swipe: false,
            draggable: false,
            adaptiveHeight: true,
            waitForAnimate: false,
          }
          }
        >
          {
            // currSection.steps.map((step: any, idx: number) => {
            filteredSteps.map((step: any, idx: number) => {
              let component;

              if (step.componentName === 'SingleSelectCardList') {
                component = (
                  <SingleSelectCardList
                    selectCards={step.selectCards}
                    onClick={() => {
                      setSections((prevSections: any) => {
                        const updatedSections = [...prevSections];
                        updatedSections[activeSectionIdx].steps[step.stepIdx].isComplete = true;

                        return updatedSections;
                      });
                    }}
                  />
                );
              }

              if (step.componentName === 'Banner') {
                component = (
                  <Banner
                    visible
                    title={step.title}
                    type={step.type}
                    body={step.body}
                  />
                );
              }

              if (step.componentName === 'DoneCard') {
                component = (
                  <DoneCard />
                );
              }

              if (step.componentName === 'UserForm') {
                component = (
                  <UserForm
                    onSubmit={() => {
                      setActiveStepIdx(activeStepIdx + 1);
                      sliderRef.current?.slickNext();
                    }}
                  />
                );
              }

              if (step.componentName === 'ConfirmSelectCard') {
                component = (
                  <ConfirmSelectCard
                    mediaPath={step.mediaPath}
                    body={step.body}
                    active={step.isComplete}
                    enableEnlarge={step.enableEnlarge}
                    footer={step.footer}
                    onClick={(isActive: boolean) => {
                      setSections((prevSections: any) => {
                        const updatedSections = [...prevSections];
                        updatedSections[activeSectionIdx].steps[step.stepIdx].isComplete = isActive;

                        return updatedSections;
                      });
                    }}
                  />
                );
              }

              if (step.componentName === 'Questionnaire') {
                component = (
                  <Questionnaire
                    questions={step.questions}
                    reset={filteredSteps[activeStepIdx].isComplete === false}
                    onDone={(hasCorrectAnswers: boolean) => {
                      setSections((prevSections: any) => {
                        const updatedSections = [...prevSections];
                        updatedSections[activeSectionIdx].steps[step.stepIdx].isComplete = hasCorrectAnswers;
                        updatedSections[activeSectionIdx].steps[step.stepIdx].disableGoBackButton = true;

                        return updatedSections;
                      });

                      if (hasCorrectAnswers && activeSectionIdx === sections.length - 1) {
                        // setShowDoneCard(true);
                        // return;
                      }
                    }}
                    onRedo={() => {
                      const firstStepInSectionIdx = filteredSteps.findIndex((s: any) => {
                        return s.sectionIdx === step.sectionIdx && s.stepIdx === 0;
                      });

                      setSections((prevSections: any) => {
                        const updatedSections = [...prevSections];
                        const resettedSteps = updatedSections[activeSectionIdx].steps.map((s: any) => {
                          return { ...s, isComplete: false, disableGoBackButton: undefined };
                        });

                        updatedSections[activeSectionIdx].steps = resettedSteps;

                        return updatedSections;
                      });

                      setActiveStepIdx(firstStepInSectionIdx);

                      sliderRef.current?.slickGoTo(firstStepInSectionIdx);
                    }}
                  />
                );
              }

              return (
                <div key={`${idx}-${step.componentName}-${activeSectionIdx}`}>
                  {component}
                </div>
              );
            })
          }
        </Slider>
      </div>
    );
  };

  // console.log('sections', sections);
  // console.log('filteredSteps', filteredSteps);
  // console.log('activeStepIdx', activeStepIdx);
  // console.log('filteredSteps[activeStepIdx]', filteredSteps[activeStepIdx]);

  return (
    <div
      className="d-flex flex-column align-items-center"
      style={{
        backgroundColor: '#F4F4F4',
      }}
    >
      <div
        className="car-instructions-container w-100"
        style={{ flexGrow: '1', minHeight: '100vh', maxWidth: '390px' }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          <div className="header">
            <div style={{ display: 'flex', alignItems: 'center', gap: '1.25rem' }}>
              <img
                src="/images/eon-logo.svg"
                alt="eon logo"
              />

              <div className="header__title">
                Check-in Instructions
              </div>
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <div className="title">
              {
                sections[activeSectionIdx].title
              }
            </div>

            <div className="title__subtext">
              You will receive your agent's phone number, car plate, and code to drive at the end.
            </div>
          </div>
        </div>

        <div className="mt-2 mb-2" style={{ width: '100%' }}>
          <LinearProgressBar
            currentStep={
              activeStepIdx === filteredSteps.length - 1
                ? sections.length
                : activeSectionIdx
            }
            steps={sections}
            barHeight={2}
          />
        </div>

        <div className="d-flex flex-row justify-content-between">
          <div
            className="d-flex flex-row justify-content-center gap-1 align-items-center"
          >
            <div
              style={{
                color: '#1A1A1F',
                fontFamily: 'sf-pro',
                fontSize: '0.875rem',
                fontWeight: 600,
                lineHeight: '1.25rem',
              }}
            >
              {
                `0${activeSectionIdx + 1}.`
              }
            </div>

            <div
              style={{
                color: '#1A1A1F',
                fontFamily: 'sf-pro',
                fontSize: '0.875rem',
                fontWeight: 400,
                lineHeight: '1.25rem',
              }}
            >
              {
                sections[activeSectionIdx].titleShort
              }
            </div>
          </div>

          <div
            className="timer"
            style={{
              backgroundImage: percentCompleted === 0
                ? '#fff'
                : `
                linear-gradient(
                  to right,
                  #06C167 0%,
                  #76CCCC ${(percentCompleted * 100)}%,
                  #fff ${(percentCompleted * 100)}%
                )
              `,
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                gap: '0.375rem',
              }}
            >
              <div>
                {
                  `${Math.ceil(percentCompleted * 100)}% `
                }
              </div>
            </div>
          </div>
        </div>

        {
          renderBody()
        }

        <div className="d-flex flex-column align-items-center gap-3">
          {
            showNextButton && (
              <button
                className={cx(
                  'btn',
                  'btn-primary',
                  'btn-lg',
                  'w-100',
                )}
                style={{
                  backgroundColor: (
                    filteredSteps[activeStepIdx].isComplete
                    || filteredSteps[activeStepIdx].componentName === 'Banner'
                  ) ? '#1A1A1F' : '#D9D9DF',
                  color: 'white',
                  fontFamily: 'sf-pro',
                  lineHeight: '1.5rem',
                  fontSize: '1.125rem',
                  height: '3.5rem',
                }}
                onClick={() => {
                  if (activeStepIdx === filteredSteps.length - 1) {
                    return;
                  }

                  const nextStep = filteredSteps[activeStepIdx + 1];

                  setActiveSectionIdx(nextStep.sectionIdx);
                  setActiveStepIdx(activeStepIdx + 1);
                  sliderRef.current?.slickNext();
                }}
                disabled={
                  (!filteredSteps[activeStepIdx].isComplete
                    && filteredSteps[activeStepIdx].componentName !== 'Banner')
                  || filteredSteps[activeStepIdx].componentName === 'DoneCard'
                }
              >
                Next
              </button>
            )
          }

          {
            showGoBackButton && (
              <Button
                style={{
                  boxShadow: 'none',
                  color: (
                    activeStepIdx !== 0
                    && !filteredSteps[activeStepIdx].disableGoBackButton
                    && filteredSteps[activeStepIdx].stepIdx !== 0
                    && filteredSteps[activeStepIdx - 1].componentName !== 'Questionnaire'
                    && filteredSteps[activeStepIdx].componentName !== 'Questionnaire'
                    && filteredSteps[activeStepIdx].componentName !== 'DoneCard'
                  )
                    ? '#67677B'
                    : '#E0E0E5',
                  backgroundColor: '#F4F4F4',
                  textDecoration: 'underline',
                }}
                onClick={handleGoBack}
                disabled={
                  activeStepIdx === 0
                  || filteredSteps[activeStepIdx].disableGoBackButton
                  || filteredSteps[activeStepIdx].stepIdx === 0
                  || filteredSteps[activeStepIdx - 1].componentName === 'Questionnaire'
                  || filteredSteps[activeStepIdx].componentName === 'Questionnaire'
                  || filteredSteps[activeStepIdx].componentName === 'DoneCard'
                }
              >
                Go back
              </Button>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default CarInstructions;
