import React, { useState } from 'react';
import cx from 'classnames';
import moment from 'moment';
import mixpanel from 'mixpanel-browser';

import { Card, Link, Typography } from '../common';
import { ArrowDown, ArrowUp, Model } from '../../assets/icons';
import { CARS_OPTIONS, DATES_FORMAT_FULL, DISCOUNTS } from '../../constants';
import { ICar, IDates, IExtra, IInsurance, ILocation, } from '../../types';
import { getNumDays } from '../../helpers/utils';

interface IProps {
  onGoBack: () => void;
  className?: string;
  insuranceData: IInsurance | undefined;
  extraData: IExtra[] | [];
  deliveryData: IExtra | undefined;
  carDetails: ICar;
  totalPrice: number;
  totalDeliveryCost: number;
  discount: number;
  location: ILocation;
  dates: IDates;
  address: {
    deliveryTo: string | undefined;
    collectAt: string | undefined;
  };
}

const SelectedOptions = ({
  onGoBack, className, insuranceData, extraData, deliveryData, carDetails,
  totalPrice, totalDeliveryCost, discount, location, dates, address
}: IProps) => {
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const selectedDays = getNumDays(dates);

  const insuranceRate = (
    insuranceData?.percentage && (carDetails.daily_rate * (insuranceData.percentage / 100))
  ) || 0;

  return (
    <Card
      type="button"
      className={cx('d-flex flex-column gap-3 cursor-default focus-disable active-disable', className, {
        hover: showDetails,
        'border-dark': showDetails,
        'hover-disabled': !showDetails
      })}
    >
      <div
        className="d-flex gap-3 w-100 align-items-center cursor-pointer"
        onClick={() => {
          mixpanel.track('Button Clicked', {
            'Button Name': 'Show Trip Summary',
            'Trip Summary Visible': !showDetails,
          });

          setShowDetails(!showDetails);
        }}
      >
        <Model />
        <Typography variant="body2" className="flex-grow-1 m-0">
          {`${carDetails.model_name} ${carDetails.model_type}`}
        </Typography>
        <Typography variant="h3" className="m-0">
          {`$${totalPrice.toFixed(2)}`}
        </Typography>
        {showDetails ? <ArrowUp className="icon-small" /> : <ArrowDown className="icon-small" />}
      </div>
      {showDetails && (
        <div className="w-100">
          {!address.deliveryTo && (
            <Typography variant="body2" className="m-0 d-flex gap-3 align-items-start justify-content-between">
              <span>Where</span>
              <span className="text-right">
                {location.address}
              </span>
            </Typography>
          )}
          {address.deliveryTo && address.collectAt && (
            <>
              <Typography variant="body2" className="m-0 d-flex gap-3 align-items-center justify-content-between">
                <span>Delivery address</span>
                <span className="text-right">{address.deliveryTo}</span>
              </Typography>
              <Typography variant="body2" className="m-0 d-flex gap-3 align-items-center justify-content-between">
                <span>Return address</span>
                <span className="text-right">{address.collectAt}</span>
              </Typography>
            </>
          )}
          <Typography variant="body2" className="m-0 d-flex gap-3 align-items-center justify-content-between">
            <span>Pick up</span>
            <span className="text-right">
              {moment(dates.startDate)
                .format(DATES_FORMAT_FULL)}
            </span>
          </Typography>
          <Typography variant="body2" className="m-0 d-flex gap-3 align-items-center justify-content-between">
            <span>Drop off</span>
            <span className="text-right">
              {moment(dates.endDate)
                .format(DATES_FORMAT_FULL)}
            </span>
          </Typography>
          <Typography variant="body2" className="m-0 d-flex gap-3 align-items-center justify-content-between mt-3">
            <span>Price</span>
            <span className="text-right">
              {`$${carDetails.daily_rate * selectedDays} ($${carDetails.daily_rate} / day)`}
            </span>
          </Typography>
          {insuranceData && (
            <Typography variant="body2" className="m-0 d-flex gap-2 align-items-center justify-content-between">
              <span>{insuranceData.category}</span>
              <span className="text-right">
                {insuranceRate
                  ? `$${(insuranceRate * selectedDays).toFixed(2)} ($${insuranceRate.toFixed(2)} / day)`
                  : 'Free'}
              </span>
            </Typography>
          )}
          {extraData?.map((extra: IExtra) => (
            extra.id !== deliveryData?.id && (
              <Typography
                key={extra.id}
                variant="body2"
                className="m-0 d-flex gap-3 align-items-center justify-content-between"
              >
                <span>{extra.title}</span>
                <span className="text-right">
                  {extra.amount && extra.recurring ? `$${extra.amount * selectedDays} ($${extra.amount} / day)` : ''}
                  {extra.amount && !extra.recurring ? `$${extra.amount}` : ''}
                  {!extra.amount ? 'Free' : ''}
                </span>
              </Typography>
            )
          ))}
          {address.deliveryTo && address.collectAt && (
            <Typography
              variant="body2"
              className="m-0 d-flex gap-3 align-items-center justify-content-between mt-3"
            >
              <span>{deliveryData?.title || CARS_OPTIONS.DELIVERY.title}</span>
              <span className="text-right">
                {`$${totalDeliveryCost || deliveryData?.amount || CARS_OPTIONS.DELIVERY.amount}`}
              </span>
            </Typography>
          )}
          {Boolean(discount) && (
            <Typography
              variant="body2"
              className={cx('m-0 d-flex gap-3 align-items-center justify-content-between', {
                'mt-3': !(address.deliveryTo && address.collectAt)
              })}
            >
              <span>
                {(
                  selectedDays >= Number(DISCOUNTS.find(d => d.key === 'week')?.daysMore || 0)
                  && selectedDays < Number(DISCOUNTS.find(d => d.key === 'week')?.daysLess || 0)
                ) ? 'Weekly Discount' : ''}
                {selectedDays >= Number(DISCOUNTS.find(d => d.key === 'month')?.daysMore || 0)
                  ? 'Monthly Discount' : ''}
                {selectedDays < Number(DISCOUNTS.find(d => d.key === 'week')?.daysMore || 0)
                  ? 'Discount' : ''}
              </span>
              <span className="text-right text-danger">{`- $${discount.toFixed(2)}`}</span>
            </Typography>
          )}
          <Typography
            variant="h3"
            className={cx('m-0 d-flex gap-3 align-items-center justify-content-between', {
              'mt-3': !address.deliveryTo && !discount
            })}
          >
            <span>{`Total for ${selectedDays} ${selectedDays === 1 ? 'day' : 'days'}`}</span>
            <span className="text-right">{`$${totalPrice.toFixed(2)}`}</span>
          </Typography>
          <Typography variant="body2" className="m-0 mt-3">
            <span>{'Changed your mind? '}</span>
            <Link onClick={onGoBack}>Alter options.</Link>
          </Typography>
        </div>
      )}
    </Card>
  );
};

SelectedOptions.defaultProps = {
  className: undefined,
};

export { SelectedOptions };
