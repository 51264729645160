import { useEffect, useState } from 'react';

import { Typography } from 'components/common';
import { Icon } from '@mui/material';

interface Props {
  children: any;
  title: string;
  style?: any;
  isOpen?: boolean;
  onChange?: (isOpen: boolean) => void;
}

const Section = ({
  children,
  title,
  style,
  isOpen = false,
  onChange,
}: Props) => {
  const [show, setShow] = useState(isOpen);

  useEffect(() => {
    setShow(isOpen);
  }, [isOpen]);

  return (
    <div style={style}>
      <div
        className="d-flex flex-row align-items-center"
        onClick={() => {
          setShow(!show);
          // eslint-disable-next-line no-unused-expressions
          onChange && onChange(!show);
        }}
      >
        <Icon>{show ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}</Icon>
        <Typography variant="h2" style={{ margin: 0 }}>
          {title}
        </Typography>
      </div>

      {
        show && (
          <div>
            {children}
          </div>
        )
      }
    </div>
  );
};

export { Section };
