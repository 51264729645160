import { useState } from 'react';
import { useSelector } from 'react-redux';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-medium-image-zoom/dist/styles.css';
import { Icon } from '@mui/material';

import { IProfile, APIStatus } from 'types';
import { profileSelectors } from 'redux/slices';
import { LoginFlow } from 'components/Authentication';
import Navbar from 'components/Navbar';
import { Button, Typography } from 'components/common';
import { LoadingSpinner } from 'components/LoadingSpinner';
import * as api from 'api';
import Dropzone from 'components/common/Dropzone';

const VerifyPaymentUser = () => {
  const profile = useSelector(profileSelectors.selectProfile) as IProfile;
  const profileLoading = useSelector(profileSelectors.selectProfileLoading);
  const [imagesToUpload, setImagesToUpload] = useState<File[]>();
  const [uploadVerifyPhotoStatus, setUploadVerifyPhotoStatus] = useState<APIStatus>({
    loading: false,
    success: false,
    error: null,
  });

  const isLoggedIn = !!profile?.id;

  const handleImagesChanged = (files: any[]) => {
    setImagesToUpload(files);
  };

  if (profileLoading) {
    return (
      <LoadingSpinner absolute />
    );
  }

  const handlePhotosUpload = async () => {
    try {
      setUploadVerifyPhotoStatus({ loading: true, success: false, error: null });

      const payload = {
        verify_payment_photos: imagesToUpload,
      };

      await api.uploadCustomerVerifyPaymentPhoto({ payload });
      setUploadVerifyPhotoStatus({ loading: false, success: true, error: null });
      setImagesToUpload([]);
    } catch (error: any) {
      setUploadVerifyPhotoStatus({
        loading: false,
        success: false,
        error: { code: error.response.status, message: error.response.data.errors },
      });
      // eslint-disable-next-line no-console
      console.error('Error fetching data:', error);
    }
  };

  if (!isLoggedIn) {
    return (
      <div>
        <Navbar />

        <div
          className="d-flex justify-content-center"
        >
          <div
            className="d-flex justify-content-center align-items-center flex-column"
            style={{
              maxWidth: '500px',
              overflow: 'hidden',
              padding: '0 16px 0 16px',
            }}
          >
            <LoginFlow buttonText="Login" />
          </div>
        </div>
      </div>
    );
  }

  const renderBody = () => {
    if (uploadVerifyPhotoStatus.success) {
      return (
        <div className="d-flex flex-column align-items-center text-center gap-3">
          <Icon style={{ color: 'green', fontSize: '100px' }}>
            check
          </Icon>

          <Typography variant="h3">
            We've received your photo and our team will reach out if anything else is needed.
          </Typography>

          <Typography variant="body2">
            You may close this window.
          </Typography>
        </div>
      );
    }

    return (
      <div className="w-100">
        <Typography variant="h2">
          Verify your payment method
        </Typography>

        <div className="d-flex flex-column gap-3 mb-2">
          <Typography>
            {`
                Hi ${profile?.firstname}, we’re working on verifying your rental profile,
                but your payment method flagged a fraud alert - please upload a selfie of you holding your card.
            `}
          </Typography>

          <Typography>
            For your safety, please cover up all details on the card except for the last 4 digits
            and your name on the card. Make sure these are clearly visible with your face in the frame.
          </Typography>

          <Typography>
            If you used a virtual card,
            please upload a screenshot of the charge from 'TeslaRents' in your bank transaction history.
          </Typography>
        </div>

        <Dropzone
          accept={{
            'image/png': ['.png'],
            'image/jpeg': ['.jpg', '.jpeg'],
          }}
          onImagesChanged={handleImagesChanged}
          defaultFiles={imagesToUpload || []}
          previewStyle={{
            width: '100px',
          }}
          placeholder="Drag and drop a photo of you holding your card"
        />

        {
          imagesToUpload && imagesToUpload?.length > 0 && (
            <div
              className="w-100 d-flex justify-content-center"
            >
              <Button
                onClick={handlePhotosUpload}
                className="mb-3 mt-3"
              >
                Upload photo
              </Button>
            </div>
          )
        }

        {
          uploadVerifyPhotoStatus.error && (
            <div className="text-danger">
              {`Error uploading your photo, please try again. error: ${uploadVerifyPhotoStatus.error.message}`}
            </div>
          )
        }

        <div className="mt-3 text-center">
          If you run into any issues,
          please call or text (857) 399-0442 or email us at support@eonrides.com
        </div>
      </div>
    );
  };

  return (
    <div>
      <Navbar />

      {
        uploadVerifyPhotoStatus.loading && (
          <LoadingSpinner absolute />
        )
      }

      <div
        className="d-flex justify-content-center mt-4"
      >
        <div
          className="d-flex justify-content-center align-items-center flex-column gap-4"
          style={{
            maxWidth: '500px',
            overflow: 'hidden',
            padding: '0 16px 0 16px',
          }}
        >
          {renderBody()}
        </div>
      </div>
    </div>
  );
};

export default VerifyPaymentUser;
