import { useState, useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import moment from 'moment';
import Select from 'react-select';
import { useSelector } from 'react-redux';

import * as api from 'api';
import { Typography, Button, InputField } from 'components/common';
import { APIStatus, IReservation, IIncidental, IncidentalCategory, IOwner } from 'types';
import { LoadingSpinner } from 'components/LoadingSpinner';
import Dropzone from 'components/common/Dropzone';
import variables from 'assets/scss/variables.scss';
import { ownerSelectors } from 'redux/slices';
import { snakeToTitleCase } from 'helpers/utils';

interface Props {
  reservation: IReservation;
  isOpen?: boolean;
  onClose?: () => void;
  onCreate?: (incidental: IIncidental) => void;
}

const options: any[] = [
  {
    value: IncidentalCategory.PARKING_TICKET,
    label: snakeToTitleCase(IncidentalCategory.PARKING_TICKET),
    availableDayAfterTrip: true,
    amountVisible: true,
  },
  {
    value: IncidentalCategory.SPEEDING_TICKET,
    label: snakeToTitleCase(IncidentalCategory.SPEEDING_TICKET),
    availableDayAfterTrip: true,
    amountVisible: true,
  },
  {
    value: IncidentalCategory.SMOKING,
    label: snakeToTitleCase(IncidentalCategory.SMOKING),
    availableDayAfterTrip: false,
    amountVisible: true,
    amount: 150,
    description: 'Pictures required to confirm validity.',
  },
  {
    value: IncidentalCategory.GLASS,
    label: snakeToTitleCase(IncidentalCategory.GLASS),
    availableDayAfterTrip: false,
    amountVisible: false,
    description: 'Attach pictures of the damage, and if approved, we’ll follow up with next steps.',
  },
  {
    value: IncidentalCategory.FLAT_TIRE,
    label: snakeToTitleCase(IncidentalCategory.FLAT_TIRE),
    availableDayAfterTrip: false,
    amountVisible: true,
    amount: 100,
    description: `
      If car is found to have a flat tire upon return, please submit images -
      this only applies to slow leaks, patch work, minor damage and cars that are found flat after return.
      Major blowouts that occur during a rental will have been handled by roadside directly while on rental.
    `,
  },
  {
    value: IncidentalCategory.SCRATCHED_RIMS,
    label: snakeToTitleCase(IncidentalCategory.SCRATCHED_RIMS),
    availableDayAfterTrip: false,
    amountVisible: true,
    amount: 100,
    description: 'Note that not all incidents are covered, before and after pictures necessary.',
  },
  {
    value: IncidentalCategory.ACCIDENT_BODY_DAMAGE,
    label: 'Accident/Body Damage',
    availableDayAfterTrip: false,
    amountVisible: false,
    description: `
      Attach pictures of the damage, and if approved, we’ll follow up with next steps.
      Before pictures required to have been uploaded at rental start for approval. Please upload at least 5 photos
      showing the car from all angles and at least 3 of the damaged area specifically.
    `,
  },
  {
    value: IncidentalCategory.INTERIOR_DAMAGE,
    label: snakeToTitleCase(IncidentalCategory.INTERIOR_DAMAGE),
    availableDayAfterTrip: false,
    amountVisible: false,
    description: `
      Examples: pet hair, liquids, etc.
      Attach pictures of the damage, and if approved, we’ll follow up with next steps.
      Before pictures required to have been uploaded at rental start for approval. Please upload at least 5 photos
      showing the car from all angles and at least 3 of the damaged area specifically.
    `,
  },
];

const AddIncidentalForm = ({
  reservation,
  isOpen = false,
  onClose,
  onCreate,
}: Props) => {
  const owner = useSelector(ownerSelectors.selectOwner) as IOwner;
  const [isVisible, setIsVisible] = useState(isOpen);
  const [createIncidentalStatus, setCreateIncidentalStatus] = useState<APIStatus>({
    loading: false,
    success: false,
    error: null,
  });

  useEffect(() => {
    setIsVisible(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (!isVisible && onClose) {
      onClose();
    }
  }, [isVisible]);

  const formikAddIncidentalForm = useFormik({
    initialValues: {
      category: IncidentalCategory.PARKING_TICKET,
      amount: 0,
      offense_date: '',
      images: [],
    },
    validateOnChange: true,
    validateOnBlur: true,
    validate: vals => {
      const error = {} as any;

      if (!vals.category) {
        error.category = 'Required';
      }

      if (vals.amount <= 0) {
        error.amount = 'Required';
      }

      if (vals.offense_date === '') {
        error.offense_date = 'Required';
      }

      if (vals.offense_date && moment(vals.offense_date).isAfter(moment())) {
        error.offense_date = 'Date of offense must be in the past';
      }

      if (vals.images.length === 0) {
        error.images = 'Images required';
      }

      return error;
    },
    onSubmit: async vals => {
      const payload = {
        ...vals,
        category: vals.category,
        amount: vals.amount ? vals.amount * 100 : 0,
        reservation_id: reservation.id,
      };

      setCreateIncidentalStatus({ loading: true, success: false, error: null });

      try {
        const data = await api.createOwnerIncidental(payload);
        setCreateIncidentalStatus({ loading: false, success: true, error: null });
        toast.success('Incidental created successfully');
        setIsVisible(false);

        // eslint-disable-next-line no-unused-expressions
        onCreate && onCreate(data);
        formikAddIncidentalForm.resetForm();
      } catch (error: any) {
        setCreateIncidentalStatus({
          loading: false,
          success: false,
          error: { code: error.response.status, message: error.response.data.error },
        });
        toast.error(error.response.data.error);
      }
    },
  });

  const formattedOptions = useMemo(() => {
    const now = moment();

    const endTime = moment(reservation.end_time);

    if (now.isAfter(endTime.clone().add(1, 'day')) && !owner?.is_superadmin) {
      return options.filter(option => option.availableDayAfterTrip);
    }

    return options;
  }, [reservation.end_time]);

  const handleClose = () => {
    // eslint-disable-next-line no-unused-expressions
    onClose && onClose();
    setIsVisible(false);
  };

  const handleImagesChanged = (files: any[]) => {
    formikAddIncidentalForm.setFieldValue('images', files);
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isVisible}
      style={{
        overlay: {
          zIndex: 1000,
        },
        content: {
          width: '400px',
          zIndex: 1000,
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          padding: '29px',
          maxWidth: '90%',
          maxHeight: '90%',
        },
      }}
      onRequestClose={handleClose}
    >
      {
        createIncidentalStatus.loading && (
          <LoadingSpinner absolute />
        )
      }

      <div className="d-flex justify-content-end">
        <Button
          onClick={handleClose}
          style={{ width: '25px', height: '25px', padding: 0 }}
        >
          X
        </Button>
      </div>

      <div className="d-flex flex-column gap-3 mt-4 align-items-center">
        <Typography variant="h2">
          Add New Incidental
        </Typography>

        <div className="w-100">
          <Typography>
            Type
          </Typography>

          <Select
            onChange={(option: any) => {
              formikAddIncidentalForm.setFieldValue('category', option.value);

              formikAddIncidentalForm.setFieldValue('amount', option.amount);
            }}
            options={formattedOptions}
            defaultValue={formattedOptions[0]}
          />

          {
            formikAddIncidentalForm.errors.category && (
              <div style={{ color: 'red' }}>
                {formikAddIncidentalForm.errors.category}
              </div>
            )
          }
        </div>

        {
          options.find(o => o.value === formikAddIncidentalForm.values.category)?.description && (
            <Typography variant="body2">
              {options.find(o => o.value === formikAddIncidentalForm.values.category)?.description}
            </Typography>
          )
        }

        <div className="w-100">
          {`Time is in ${moment().tz(moment.tz.guess()).format('z')}`}

          <InputField
            label="Date of offense"
            type="datetime-local"
            name="offense_date"
            value={formikAddIncidentalForm.values.offense_date || ''}
            onChange={formikAddIncidentalForm.handleChange}
            error={formikAddIncidentalForm.errors.offense_date}
            required
            max={moment().format('YYYY-MM-DDTHH:mm')}
          />
        </div>

        {
          options.find(o => o.value === formikAddIncidentalForm.values.category)?.amountVisible && (
            <InputField
              label="$ amount in USD"
              type="number"
              name="amount"
              value={
                formikAddIncidentalForm.values.amount
                || ''
              }
              onChange={formikAddIncidentalForm.handleChange}
              error={formikAddIncidentalForm.errors.amount}
              required
              disabled={options.find(o => o.value === formikAddIncidentalForm.values.category)?.amount}
            />
          )
        }

        <div className="d-flex flex-column align-items-center">
          <Dropzone
            accept={{
              'image/png': ['.png'],
              'image/jpeg': ['.jpg', '.jpeg'],
              'application/pdf': ['.pdf'],
            }}
            onImagesChanged={handleImagesChanged}
            defaultFiles={formikAddIncidentalForm.values.images || []}
            convertImgToJpeg
          />

          {
            formikAddIncidentalForm.errors.images && (
              <div
                className="pt-2"
                style={{
                  whiteSpace: 'normal',
                  textAlign: 'center',
                  color: variables.red1,
                }}
              >
                {formikAddIncidentalForm.errors.images}
              </div>
            )
          }
        </div>

        <Button
          onClick={formikAddIncidentalForm.handleSubmit}
        >
          Create
        </Button>
      </div>
    </Modal>
  );
};

export default AddIncidentalForm;
