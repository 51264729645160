import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

import { useDeepEffect } from '../../../hooks';
import { ILocation, IMap, IStoreLocations } from '../../../types';
import { pages, routes } from '../../../pages';
import { DEFAULT_GOOGLE_DATA, PARTNER_KEY } from '../../../constants';

import variables from '../../../assets/scss/variables.scss';

import { locationsSelectors, uiSelectors } from '../../../redux/slices';

declare const google: any;

interface IProps {
  allMarkers: any;
}

const LocationMarkers = ({ allMarkers }: IProps) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const map = useSelector(uiSelectors.selectMap) as IMap;
  const { data } = useSelector(locationsSelectors.selectLocations) as IStoreLocations;

  const handleNavigate = (item: ILocation) => {
    mixpanel.track('Button Clicked', {
      'Button Name': 'Location Marker',
      'Location Marker State': item.state_short,
    });

    const params = new URLSearchParams(search);
    navigate({
      pathname: routes[pages.city].replace(':city', String(item.id)),
      search: params.get(PARTNER_KEY) ? `?${PARTNER_KEY}=${params.get(PARTNER_KEY)}` : ''
    });
  };

  useDeepEffect(() => {
    allMarkers.forEach((marker: any) => {
      marker.setMap(null);
    });
    allMarkers.splice(0, allMarkers.length);
    if (data.length && map) {
      data.forEach((location: ILocation) => {
        const marker = new google.maps.Marker({
          position: new google.maps.LatLng(location.latitude, location.longitude),
          map,
          icon: {
            url: '/images/pin-black.svg',
            origin: new google.maps.Point(-1, -1)
          },
          label: {
            text: location.state_short,
            color: variables.white,
            fontSize: '12px',
          }
        });
        marker.addListener('click', () => handleNavigate(location));
        allMarkers.push(marker);
      });
      map.setCenter(DEFAULT_GOOGLE_DATA.center);
      map.setZoom(DEFAULT_GOOGLE_DATA.zoom);
    }
  }, [data, map]);

  return null;
};

export { LocationMarkers };
