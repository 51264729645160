import { createSlice } from '../../helpers/store';
import { ICar, IExtra, IInsurance, IPurchase, IReservation, ICheckout } from '../../types';

interface IState {
  carDetails: ICar | null;
  extraData: [IExtra] | [];
  insurances: [IInsurance] | []
  purchase: IPurchase;
  reservation: IReservation | null;
  checkout: ICheckout;
}

const initialState: IState = {
  carDetails: null,
  extraData: [],
  insurances: [],
  purchase: {
    selectedInsurance: null,
    selectedOptions: [],
    discountData: {
      opened: false,
      amount: 0,
      error: null,
      code: '',
      isChecking: false
    },
  },
  reservation: null,
  checkout: {
    signature: null,
    payment: null,
    isSubmitted: false,
    stripePaymentIntentSecret: null,
  }
};

const { actions, selectors, reducer } = createSlice({
  prefix: 'checkout',
  initialState,

  selectors: {
    selectCarDetails: (state: IState) => state.carDetails,
    selectExtraData: (state: IState) => state.extraData,
    selectInsurances: (state: IState) => state.insurances,
    selectPurchase: (state: IState) => state.purchase,
    selectReservation: (state: IState) => state.reservation,
    selectCheckout: (state: IState) => state.checkout,
    selectCheckoutValid: (state: IState) => (
      state.checkout.payment?.valid && state.checkout.signature
    ),
  },

  reducers: {
    setCarDetails: {
      base: (state: IState, { payload }: any) => {
        state.carDetails = payload;
      },
    },
    clearPurchaseData: {
      base: (state: IState) => {
        state.purchase = { ...initialState.purchase };
        state.carDetails = null;
      }
    },
    setPurchaseData: {
      base: (state: IState, { payload: { key, data } }: any) => {
        // @ts-ignore
        state.purchase[key] = data;
        if (data?.clear) {
          delete data.clear;
          // @ts-ignore
          state.purchase[key] = { ...initialState.purchase[key], ...data };
        }
      },
    },
    setCheckoutData: {
      base: (state: IState, { payload: { key, data } }: any) => {
        // @ts-ignore
        state.checkout[key] = data;
      },
    },
    loadExtraData: {
      success: (state: IState, { payload: { extras } }: any) => {
        state.extraData = extras;
      },
      failed: (state: IState) => {
        state.extraData = [];
      },
    },
    loadInsurances: {
      success: (state: IState, { payload: { insurances } }: any) => {
        state.insurances = insurances;
      },
      failed: (state: IState) => {
        state.extraData = [];
      },
    },
    createReservation: {},
    loadReservation: {
      base: (state: IState) => {
        state.reservation = null;
      },
      success: (state: IState, { payload }: any) => {
        state.reservation = payload;
      },
      failed: (state: IState) => {
        state.reservation = null;
      },
    },
    cancelReservation: {},
    finalizeReservation: {
      base: (state: IState) => {
        state.checkout.isSubmitted = true;
      },
      success: (state: IState) => {
        state.checkout = { ...initialState.checkout };
        if (state.reservation) {
          state.reservation.status = 'open';
        }
      },
      failed: (state: IState) => {
        state.checkout.isSubmitted = false;
      },
    },
    clearReservation: {
      base: (state: IState) => {
        state.checkout = { ...initialState.checkout };
        state.reservation = null;
      }
    },
    clearCheckout: {
      base: (state: IState) => {
        state.carDetails = null;
        state.purchase = { ...initialState.purchase };
        state.reservation = null;
        state.checkout = { ...initialState.checkout };
      }
    },
  },
});

export { reducer as checkout, actions as checkoutActions, selectors as checkoutSelectors };
