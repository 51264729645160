import { PaymentElement } from '@stripe/react-stripe-js';

const NewCard = ({ onChange }: { onChange: (e: any) => void }) => (
  <div className="w-100">
    <PaymentElement
      onChange={onChange}
      options={{
        business: { name: 'Eon' },
      }}
    />
  </div>
);

export { NewCard };
