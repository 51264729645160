import React from 'react';
import cx from 'classnames';

interface IProps {
  className?: string;
  animation?: boolean;
  children?: any;
  [k: string]: any
}

const Placeholder = ({ animation, className, children, ...props }: IProps) => (
  <div className={cx({ placeholder: !animation, 'placeholder-glow': animation }, className)} {...props}>
    {children}
  </div>
);

Placeholder.defaultProps = {
  className: undefined,
  animation: undefined,
  children: undefined,
};

export { Placeholder };
