import React from 'react';

import variables from '../../../assets/scss/variables.scss';

const BoxStyle = {
  width: '100%',
  padding: 10,
};

const Colors = () => (
  <div>
    <h1>Colors</h1>
    <h2 className="my-3">Text colours</h2>
    <div style={{ display: 'flex', background: variables.white }}>
      <div style={{ ...BoxStyle, color: variables.blackprimary }}>
        {`$black-primary: ${variables.blackprimary}`}
      </div>
      <div style={{ ...BoxStyle, color: variables.blacksecondary }}>
        {`$black-secondary: ${variables.blacksecondary}`}
      </div>
      <div style={{ ...BoxStyle, color: variables.blackdisabled }}>
        {`$black-disabled: ${variables.blackdisabled}`}
      </div>
    </div>
    <div style={{ display: 'flex', background: variables.black }}>
      <div style={{ ...BoxStyle, color: variables.whiteprimary }}>
        {`$white-primary: ${variables.whiteprimary}`}
      </div>
      <div style={{ ...BoxStyle, color: variables.whitesecondary }}>
        {`$white-secondary: ${variables.whitesecondary}`}
      </div>
      <div style={{ ...BoxStyle, color: variables.whitedisabled }}>
        {`$white-disabled: ${variables.whitedisabled}`}
      </div>
    </div>
    <h2 className="my-3">Primary</h2>
    <div className="d-flex gap-2">
      <div style={{ ...BoxStyle, background: variables.blue1 }}>$blue-1</div>
      <div style={{ ...BoxStyle, background: variables.blue2 }}>$blue-2</div>
      <div style={{ ...BoxStyle, background: variables.blue3 }}>$blue-3</div>
      <div style={{ ...BoxStyle, background: variables.blue4 }}>$blue-4</div>
      <div style={{ ...BoxStyle, background: variables.blue5 }}>$blue-5</div>
    </div>
    <h2 className="my-3">Secondary</h2>
    <div className="d-flex gap-2">
      <div style={{ ...BoxStyle, color: '#fff', background: variables.gray1 }}>{`$gray-1: ${variables.gray1}`}</div>
      <div style={{ ...BoxStyle, background: variables.gray2 }}>{`$gray-2: ${variables.gray2}`}</div>
      <div style={{ ...BoxStyle, background: variables.gray3 }}>{`$gray-3: ${variables.gray3}`}</div>
      <div style={{ ...BoxStyle, background: variables.gray4 }}>{`$gray-4: ${variables.gray4}`}</div>
      <div style={{ ...BoxStyle, background: variables.gray5 }}>{`$gray-5: ${variables.gray5}`}</div>
    </div>
    <h2 className="my-3">Success</h2>
    <div className="d-flex gap-2">
      <div style={{ ...BoxStyle, background: variables.green1 }}>{`$green-1: ${variables.green1}`}</div>
      <div style={{ ...BoxStyle, background: variables.green2 }}>{`$green-2: ${variables.green2}`}</div>
      <div style={{ ...BoxStyle, background: variables.green3 }}>{`$green-3: ${variables.green3}`}</div>
      <div style={{ ...BoxStyle, background: variables.green4 }}>{`$green-4: ${variables.green4}`}</div>
      <div style={{ ...BoxStyle, background: variables.green5 }}>{`$green-5: ${variables.green5}`}</div>
    </div>
    <h2 className="my-3">Danger</h2>
    <div className="d-flex gap-2">
      <div style={{ ...BoxStyle, background: variables.red1 }}>{`$red-1: ${variables.red1}`}</div>
      <div style={{ ...BoxStyle, background: variables.red2 }}>{`$red-2: ${variables.red2}`}</div>
      <div style={{ ...BoxStyle, background: variables.red3 }}>{`$red-3: ${variables.red3}`}</div>
      <div style={{ ...BoxStyle, background: variables.red4 }}>{`$red-4: ${variables.red4}`}</div>
      <div style={{ ...BoxStyle, background: variables.red5 }}>{`$red-5: ${variables.red5}`}</div>
    </div>
    <h2 className="my-3">Base</h2>
    <div className="d-flex gap-2">
      <div style={{ ...BoxStyle, color: '#fff', background: variables.black }}>{`$black: ${variables.black}`}</div>
      <div style={{ ...BoxStyle, color: '#000', background: variables.white }}>{`$white: ${variables.white}`}</div>
    </div>
  </div>
);

export { Colors };
