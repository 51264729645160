import React, { useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import { PageTitle, Typography, Scrollable } from '../common';
import { Lock } from '../../assets/icons';

import { pages, routes } from '../../pages';
import { ICheckout, IExtra, IReservation } from '../../types';
import { CAR_TABS_MODE, CARS_OPTIONS, DATES } from '../../constants';
import { checkoutActions, checkoutSelectors, uiSelectors, uiActions } from '../../redux/slices';

import { CurrentProfile } from './CurrentProfile';
import { Information } from './Information';
import { Signature } from './Signature';
import { Payment } from './Payment';
import { LoadingSpinner } from '../LoadingSpinner';
import { SelectedOptions } from '../Checkout/SelectedOptions';
import { PoweredBy } from '../PoweredBy';
import { getFormattedDateStringFromInput, getFormattedDisplayParameterString } from '../../helpers/utils';

const ReservationFinalize = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const scrollableData = useSelector(state => uiSelectors.selectScrollableData(state, 'reservation'));
  const extraData = useSelector(checkoutSelectors.selectExtraData) as [IExtra] | [];
  const reservation = useSelector(checkoutSelectors.selectReservation) as IReservation;
  const { isSubmitted } = useSelector(checkoutSelectors.selectCheckout) as ICheckout;

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', () => {
      window.history.pushState(null, document.title, window.location.href);
    });

    dispatch(uiActions.setPercentageComplete.base(100));
  }, []);

  const handleCancelReservation = () => {
    // dispatch(checkoutActions.cancelReservation.base({ id }));
    const params = new URLSearchParams();
    params.set('car', String(reservation.car.id));
    const tempDateString = new Date().toString().substring(28, 33);
    if (tempDateString === reservation.pickup_location.gmt_offset) {
      params.set(DATES.START, getFormattedDateStringFromInput(new Date(reservation.start_time)));
      params.set(DATES.END, getFormattedDateStringFromInput(new Date(reservation.end_time)));
    } else {
      params.set(DATES.START, getFormattedDisplayParameterString(reservation.start_time));
      params.set(DATES.END, getFormattedDisplayParameterString(reservation.end_time));
    }
    const city = searchParams.get('city') ? String(searchParams.get('city')) : String(reservation.pickup_location.id);
    navigate({
      hash: `#${reservation.custom_pickup_location ? CAR_TABS_MODE.DELIVERY : CAR_TABS_MODE.PICK_UP}`,
      search: `?${params.toString()}`,
      pathname: routes[pages.city].replace(':city', city || String(id)),
    });
  };

  const handleFinalize = (usedCredits: boolean) => {
    const subdomain = window.location.hostname.replace(process.env.REACT_APP_BASE_URL!, '').replace('.', '');
    const fromAgency = subdomain === 'agent';

    dispatch(checkoutActions.finalizeReservation.base({
      id: reservation.id,
      usedCredits,
      fromAgency,
    }));
  };

  const deliveryData = useMemo(() => (
    extraData.find((item: IExtra) => item.title === CARS_OPTIONS.DELIVERY.id)
  ), [extraData]);
  const datesToShow = {
    startDate: reservation.start_time,
    endDate: reservation.end_time
  };

  const tempDate = new Date(reservation.start_time).toString();
  if (reservation.pickup_location.gmt_offset !== tempDate.substring(28, 33)) {
    datesToShow.startDate = getFormattedDisplayParameterString(reservation.start_time);
    datesToShow.endDate = getFormattedDisplayParameterString(reservation.end_time);
  }

  return (
    <div className="position-relative w-100 overflow-y-hidden">
      <PageTitle
        title="Checkout"
        onGoBack={handleCancelReservation}
        className={cx('p-3', { 'box-shadow2': scrollableData?.scrollTop > 0 })}
      >
        <div className="d-flex align-items-center gap-2">
          <Typography variant="body2" inline>Secure</Typography>
          <Lock />
        </div>
      </PageTitle>
      <Scrollable id="reservation">
        {isSubmitted && <LoadingSpinner absolute />}
        <div className="pb-4 d-flex flex-column gap-2">
          <SelectedOptions
            onGoBack={handleCancelReservation}
            insuranceData={reservation.insurance}
            extraData={reservation.extras}
            deliveryData={deliveryData}
            carDetails={reservation.car}
            totalPrice={reservation.total_cost}
            discount={reservation.total_discount}
            location={reservation.pickup_location}
            totalDeliveryCost={reservation.total_delivery_cost}
            dates={{
              startDate: datesToShow.startDate,
              endDate: datesToShow.endDate,
              gmtOffset: reservation.pickup_location.gmt_offset
            }}
            address={{
              deliveryTo: reservation.custom_pickup_location,
              collectAt: reservation.custom_return_location,
            }}
          />
          <CurrentProfile callback={handleCancelReservation} />
        </div>
        <Information className="py-3" />
        <Signature className="py-4 w-100 d-flex flex-column" />
        <Payment className="py-4" onSuccessfulPayment={handleFinalize} />
        <PoweredBy />
        <div style={{ paddingBottom: 70 }} />
      </Scrollable>
    </div>
  );
};

export { ReservationFinalize };
