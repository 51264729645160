import { store, injectReducer, sagaMiddleware } from '../helpers/store';

import sagas from './sagas';
import { locations, ui, checkout, profile, owner, partner } from './slices';

injectReducer('locations', locations);
injectReducer('ui', ui);
injectReducer('checkout', checkout);
injectReducer('profile', profile);
injectReducer('owner', owner);
injectReducer('partner', partner);

sagaMiddleware.run(sagas);

export { store };
