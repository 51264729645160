import React from 'react';

import { Typography } from '../Typography';
import { Card } from '../Card';
import { Placeholder } from '../Placeholder';

const Cards = () => (
  <div>
    <Typography variant="h1">Cards</Typography>
    <code>
      {'<Card type="button" showPlaceholder disabled onClick="() => {}" radius="small">Text</Card>'}
      <br />
      Props:
      <ul>
        <li>type?: button | checkbox | radio</li>
        <li>radius?: small | large</li>
        <li>className?: string;</li>
        <li>disabled?: boolean;</li>
        <li>showPlaceholder?: boolean;</li>
        <li>onClick?: any;</li>
        <li>name?: string; (only for checkbox and radio)</li>
        <li>checked?: string; (only for checkbox and radio)</li>
      </ul>
    </code>
    <Typography variant="h2">Style</Typography>
    <div className="d-flex gap-3 mt-3">
      <Card type="button">Default</Card>
      <Card type="button" className="hover">Hover</Card>
      <Card type="button" className="active">Active</Card>
      <Card type="button" disabled>Disabled</Card>
      <Card type="button" className="focus">Focus</Card>
    </div>
    <Typography variant="h2">Variants</Typography>
    <div className="d-flex gap-3 mt-3">
      <Card type="button">Show as button</Card>
      <Card type="checkbox" name="1">Show as checkbox</Card>
      <Card type="checkbox" name="3">Show as checkbox</Card>
      <Card type="radio" name="2">Show as radio</Card>
      <Card type="radio" name="2">Show as radio</Card>
      <Card showPlaceholder><Placeholder style={{ height: 20, width: '100%' }} /></Card>
    </div>
  </div>
);

export { Cards };
