import { useState } from 'react';
import { Button, Card, Typography } from 'components/common';

interface IQuestion {
  correct_answers: string[],
  id: string;
  options: [{ id: string; value: string }],
  question: string,
}

const DyanmicQuestion = ({
  question,
  onClick,
  onResetClick,
}: {
  question: IQuestion;
  onClick: () => void;
  onResetClick: () => void;
}) => {
  const [selectedAnswers, setSelectedAnswers] = useState<string[]>([]);
  const [showError, setShowError] = useState(false);
  const gaveCorrectAnswers = selectedAnswers.sort().toString() === question.correct_answers.sort().toString();

  return (
    <div className="d-flex flex-column gap-3">
      <Typography>
        {
          question.question
        }
      </Typography>

      {
        showError && (
          <Typography
            variant="body2"
            className="text-danger"
          >
            Your answer is not correct. Please try again or read the information again.
          </Typography>
        )
      }

      {
        question.options.map((option) => {
          return (
            <Card
              key={`${option.id}`}
              type="checkbox"
              name={option.value}
              value={option.id}
              checked={selectedAnswers.includes(option.value)}
              onChange={() => {
                if (selectedAnswers.includes(option.id)) {
                  const filteredAnswers = selectedAnswers
                    .filter((answer) => answer !== option.id);

                  setSelectedAnswers(filteredAnswers);
                } else {
                  setSelectedAnswers([...selectedAnswers, option.id]);
                }

                setShowError(false);
              }}
            >
              <Typography variant="body2" className="text-secondary mb-0">
                {option.value}
              </Typography>
            </Card>
          );
        })
      }

      <div className="w-100 d-flex flex-column gap-3 align-items-center">
        <Button
          className="btn-primary w-100"
          onClick={() => {
            if (gaveCorrectAnswers) {
              onClick();
            } else {
              setShowError(true);
            }
          }}
        >
          Submit
        </Button>

        <Typography
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
          onClick={onResetClick}
        >
          Read information
        </Typography>
      </div>
    </div>
  );
};

export default DyanmicQuestion;
