import React from 'react';
import cx from 'classnames';

const TEXT_VARIANTS = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  body1: 'text-body1',
  body2: 'text-body2',
  body3: 'text-body3',
};

interface IProps {
  children: any;
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'body1' | 'body2' | 'body3';
  className?: string;
  inline?: boolean;
  [k: string]: any;
}

const Typography = ({ children, variant, className, inline, ...props }: IProps) => {
  let tag;
  switch (variant) {
    case TEXT_VARIANTS.h1: tag = 'h1'; break;
    case TEXT_VARIANTS.h2: tag = 'h2'; break;
    case TEXT_VARIANTS.h3: tag = 'h3'; break;
    case TEXT_VARIANTS.h4: tag = 'h4'; break;
    default: tag = inline ? 'span' : 'p';
  }

  // @ts-ignore
  const fontClass = TEXT_VARIANTS[variant];

  return React.createElement(tag, {
    className: cx(fontClass, className),
    ...props
  }, children);
};

Typography.defaultProps = {
  variant: TEXT_VARIANTS.body1,
  className: undefined,
  inline: undefined,
};

export { Typography, TEXT_VARIANTS };
