import { all, put, call, takeLatest } from 'redux-saga/effects';
import mixpanel from 'mixpanel-browser';

import { ownerActions } from 'redux/slices/owner';

import * as api from '../../api';
import {
  OWNER_TOKEN_STORAGE,
  OWNER_TOKEN_STORAGE_TIMESTAMP,
} from '../../constants';

export function* getOwnerFlow(): Generator {
  try {
    if (localStorage.getItem(OWNER_TOKEN_STORAGE)) {
      const data: any = yield call(api.getOwner);
      yield put(ownerActions.getOwner.success(data.me));
    } else {
      throw new Error('Not authorized');
    }
  } catch (err) {
    yield put(ownerActions.getOwner.failed(err));
  }
}

export function* updateOwnerFlow({ payload }: any): Generator {
  try {
    const data: any = yield call(api.updateOwner, payload);
    yield put(ownerActions.updateOwner.success(data));
    mixpanel.people.set({
      $name: `${payload.firstname} ${payload.lastname}`,
      $email: payload.email,
      $phone: payload.phone,
    });
  } catch (err: any) {
    yield put(ownerActions.updateOwner.failed(err.response.data.error));
  }
}

export function* loginFlow({
  payload: {
    googleCredential,
    appleCredentials,
    email,
    password,
    referralCode,
  },
}: any): Generator {
  try {
    const data: any = yield call(api.loginOwner, {
      googleCredential,
      appleCredentials,
      email,
      password,
      referralCode,
    });
    yield put(ownerActions.login.success(data));

    localStorage.setItem(OWNER_TOKEN_STORAGE, data.auth_token);
    localStorage.setItem(OWNER_TOKEN_STORAGE_TIMESTAMP, new Date().toISOString());
  } catch (err: any) {
    yield put(ownerActions.login.failed(err.response.data.error));
  }
}

export function* loginCompleteFlow({ payload: { phone, code } }: any): Generator {
  try {
    const data: any = yield call(api.verifyLogin, { phone, otp: code });
    yield put(ownerActions.loginComplete.success(data));
    localStorage.setItem(OWNER_TOKEN_STORAGE, data.auth_token);
    localStorage.setItem(OWNER_TOKEN_STORAGE_TIMESTAMP, new Date().toISOString());
  } catch (err) {
    yield put(ownerActions.loginComplete.failed(err));
  }
}

export function* resetPasswordConfirmFlow({ payload: { token, newPassword: password } }: any): Generator {
  try {
    yield call(api.resetOwnerPasswordConfirm, { token, password });
    yield put(ownerActions.resetPasswordConfirm.success());
  } catch (err) {
    yield put(ownerActions.resetPasswordConfirm.failed(err));
  }
}

export function* logoutFlow(): Generator {
  try {
    localStorage.removeItem(OWNER_TOKEN_STORAGE);
    localStorage.removeItem(OWNER_TOKEN_STORAGE_TIMESTAMP);
    yield put(ownerActions.logout.success());
  } catch (err) {
    yield put(ownerActions.logout.failed(err));
  }
}

export function* getAllCarsFlow(): Generator {
  try {
    const data: any = yield call(api.getAllOwnerCars);
    yield put(ownerActions.getCars.success(data));
  } catch (err) {
    yield put(ownerActions.getCars.failed(err));
  }
}

export function* getCarsFlow({ payload: { page, per_page, search } }: any): Generator {
  try {
    const data: any = yield call(api.getOwnerCars, { page, per_page, search });
    yield put(ownerActions.getCars.success(data));
  } catch (err) {
    yield put(ownerActions.getCars.failed(err));
  }
}

export function* getLocationsFlow(): Generator {
  try {
    const data: any = yield call(api.getOwnerLocations);
    yield put(ownerActions.getLocations.success(data));
  } catch (err) {
    yield put(ownerActions.getLocations.failed(err));
  }
}

export function* addLocationFlow({ payload }: any): Generator {
  try {
    const data: any = yield call(api.addOwnerLocation, payload);
    yield put(ownerActions.addLocation.success(data));
  } catch (err) {
    yield put(ownerActions.addLocation.failed(err));
  }
}

export function* getCleanersFlow(): Generator {
  try {
    const data: any = yield call(api.getOwnerCleaners);
    yield put(ownerActions.getCleaners.success(data));
  } catch (err) {
    yield put(ownerActions.getCleaners.failed(err));
  }
}

export function* createCleanerFlow({ payload }: any): Generator {
  try {
    const data: any = yield call(api.createOwnerCleaner, payload);
    yield put(ownerActions.createCleaner.success(data));
  } catch (err) {
    yield put(ownerActions.createCleaner.failed(err));
  }
}

export function* getCalendarReservationsFlow({ payload: { startDate, endDate, city } }: any): Generator {
  try {
    const data: any = yield call(api.getOwnerCalendarReservations, { startDate, endDate, city });
    yield put(ownerActions.getCalendarReservations.success(data));
  } catch (err) {
    yield put(ownerActions.getCalendarReservations.failed(err));
  }
}

export function* createReservationBlockFlow({ payload }: any): Generator {
  try {
    const data: any = yield call(api.createOwnerReservationBlock, payload);
    yield put(ownerActions.createReservationBlock.success(data));
  } catch (err) {
    yield put(ownerActions.createReservationBlock.failed(err));
  }
}

export function* getUpcomingReservationsFlow({ payload: { page, per_page } }: any): Generator {
  try {
    const data: any = yield call(api.getOwnerUpcomingReservations, { page, per_page });
    yield put(ownerActions.getUpcomingReservations.success(data));
  } catch (err) {
    yield put(ownerActions.getUpcomingReservations.failed(err));
  }
}

export function* getReservationsToReviewFlow(): Generator {
  try {
    const { data }: any = yield call(api.getOwnerReservationsToReview);
    yield put(ownerActions.getReservationsToReview.success(data));
  } catch (err) {
    yield put(ownerActions.getReservationsToReview.failed(err));
  }
}

export function* getReservationsFlow({ payload: { page, per_page, search } }: any): Generator {
  try {
    const data: any = yield call(api.getOwnerReservations, { page, per_page, search });
    yield put(ownerActions.getReservations.success(data));
  } catch (err) {
    yield put(ownerActions.getReservations.failed(err));
  }
}

export default function* root() {
  yield all([
    takeLatest(ownerActions.getOwner.types.BASE, getOwnerFlow),
    takeLatest(ownerActions.updateOwner.types.BASE, updateOwnerFlow),
    takeLatest(ownerActions.login.types.BASE, loginFlow),
    // takeLatest(ownerActions.loginComplete.types.BASE, loginCompleteFlow),
    takeLatest(ownerActions.resetPasswordConfirm.types.BASE, resetPasswordConfirmFlow),
    takeLatest(ownerActions.logout.types.BASE, logoutFlow),

    takeLatest(ownerActions.getAllCars.types.BASE, getAllCarsFlow),
    takeLatest(ownerActions.getCars.types.BASE, getCarsFlow),

    takeLatest(ownerActions.getLocations.types.BASE, getLocationsFlow),
    takeLatest(ownerActions.addLocation.types.BASE, addLocationFlow),

    takeLatest(ownerActions.getCleaners.types.BASE, getCleanersFlow),
    takeLatest(ownerActions.createCleaner.types.BASE, createCleanerFlow),

    takeLatest(ownerActions.getCalendarReservations.types.BASE, getCalendarReservationsFlow),
    takeLatest(ownerActions.createReservationBlock.types.BASE, createReservationBlockFlow),

    takeLatest(ownerActions.getUpcomingReservations.types.BASE, getUpcomingReservationsFlow),
    takeLatest(ownerActions.getReservationsToReview.types.BASE, getReservationsToReviewFlow),
    takeLatest(ownerActions.getReservations.types.BASE, getReservationsFlow),
  ]);
}
