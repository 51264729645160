// @ts-ignore
/* eslint-disable */

import React from 'react';
import cx from 'classnames';

const Account = ({ className, ...props }: any) => (
  <svg className={cx('svg-icon', className)} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g>
      <path d="M12 11.7C11.0333 11.7 10.2083 11.354 9.525 10.662C8.84167 9.97066 8.5 9.15 8.5 8.2C8.5 7.23333 8.84167 6.40833 9.525 5.725C10.2083 5.04166 11.0333 4.7 12 4.7C12.9667 4.7 13.7917 5.04166 14.475 5.725C15.1583 6.40833 15.5 7.23333 15.5 8.2C15.5 9.15 15.1583 9.97066 14.475 10.662C13.7917 11.354 12.9667 11.7 12 11.7ZM4.5 19.3V17.075C4.5 16.5917 4.63333 16.1417 4.9 15.725C5.16667 15.3083 5.525 14.9833 5.975 14.75C6.95833 14.2667 7.95433 13.904 8.963 13.662C9.971 13.4207 10.9833 13.3 12 13.3C13.0167 13.3 14.0293 13.4207 15.038 13.662C16.046 13.904 17.0417 14.2667 18.025 14.75C18.475 14.9833 18.8333 15.3083 19.1 15.725C19.3667 16.1417 19.5 16.5917 19.5 17.075V19.3H4.5ZM6 17.8H18V17.075C18 16.875 17.9417 16.6917 17.825 16.525C17.7083 16.3583 17.55 16.2167 17.35 16.1C16.4833 15.6833 15.6043 15.3623 14.713 15.137C13.821 14.9123 12.9167 14.8 12 14.8C11.0833 14.8 10.179 14.9123 9.287 15.137C8.39567 15.3623 7.51667 15.6833 6.65 16.1C6.45 16.2167 6.29167 16.3583 6.175 16.525C6.05833 16.6917 6 16.875 6 17.075V17.8ZM12 10.2C12.55 10.2 13.021 10.004 13.413 9.612C13.8043 9.22066 14 8.75 14 8.2C14 7.65 13.8043 7.179 13.413 6.787C13.021 6.39566 12.55 6.2 12 6.2C11.45 6.2 10.9793 6.39566 10.588 6.787C10.196 7.179 10 7.65 10 8.2C10 8.75 10.196 9.22066 10.588 9.612C10.9793 10.004 11.45 10.2 12 10.2Z" />
    </g>
  </svg>
);

export { Account };
