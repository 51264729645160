// @ts-ignore
/* eslint-disable */

import React from 'react';
import cx from 'classnames';

const ArrowLeft = ({ className, ...props }: any) => (
  <svg className={cx('svg-icon', className)} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g>
      <path d="M16.0016 21.6501L6.35156 12.0001L16.0016 2.3501L17.4266 3.7751L9.17656 12.0001L17.4266 20.2251L16.0016 21.6501Z" />
    </g>
  </svg>
);

export { ArrowLeft };

