import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { uiActions, uiSelectors } from '../redux/slices';
import { useDeepEffect } from './useDeepEffect';
import { IMap, IMapLocation } from '../types';

const useCurrentPosition = () => {
  const dispatch = useDispatch();
  const [hasCentered, setHasCentred] = useState<boolean>(false);

  const map = useSelector(uiSelectors.selectMap) as IMap;
  const currentPosition = useSelector(uiSelectors.selectCurrentPosition) as IMapLocation;

  useEffect(() => {
    // Try HTML5 geolocation.
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => dispatch(uiActions.setCurrentPosition.base({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }))
      );
    }
  }, []);

  useDeepEffect(() => {
    if (map && currentPosition && !hasCentered) {
      // map.setCenter(currentPosition);
      setHasCentred(true);
    }
  }, [map, currentPosition, hasCentered, setHasCentred]);
};

export { useCurrentPosition };
