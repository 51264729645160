// @ts-ignore
/* eslint-disable */

import React from 'react';
import cx from 'classnames';

const NavFinish = ({ className, ...props }: any) => (
  <svg className={cx('svg-icon', className)} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g>
      <path d="M5.5 20.75V4.5H13.6L14 6.5H19.5V15.5H13.4L13 13.5H7V20.75H5.5ZM15 14H17V12H18V10H17V8H15V10H13V8H11V6H9V8H7V10H9V12H11V10H13V12H15V14ZM11 10H9V8H11V10ZM15 12V10H17V12H15Z"/>
    </g>
  </svg>
);

export { NavFinish };

