import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import moment from 'moment';

import { profileActions, profileSelectors } from '../../redux/slices';

import { IProfile } from '../../types';
import { InputField } from '../common';
import { DAY_MILLISECONDS, VALIDATIONS } from '../../constants';

interface IProps {
  children?: any;
  className?: string;
  disabled?: boolean;
  referralCode?: string;
}

const ProfileForm = ({ children, className, disabled, referralCode }: IProps) => {
  const dispatch = useDispatch();
  const profile = useSelector(profileSelectors.selectProfile) as IProfile;
  const isProfileNewCustomer = useSelector(profileSelectors.selectIsNewCustomer) as boolean;

  const { values, errors, touched, handleSubmit, handleChange, resetForm, setFieldValue } = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
      dob: '',
      phone: '',
    },
    validate: value => {
      const error = {} as any;
      if (String(value.firstname).length < VALIDATIONS.FIRSTNAME_LENGTH) {
        error.firstname = true;
      }
      if (String(value.lastname).length < VALIDATIONS.LASTNAME_LENGTH) {
        error.lastname = true;
      }
      if (!VALIDATIONS.EMAIL_REGEXP.test(value.email)) {
        error.email = true;
      }
      if (Math.floor(moment().diff(value.dob) / DAY_MILLISECONDS / 365) < VALIDATIONS.MIN_AGE) {
        error.dob = 'You must be at least 21 years old';
      }
      return error;
    },
    onSubmit: data => {
      if (disabled) return;

      const payload = {
        ...data,
        ...referralCode && { referralCode },
      };
      dispatch(profileActions.updateProfile.base(payload));
    },
  });

  useEffect(() => {
    if (profile && (profile.firstname === 'MobileApp' || isProfileNewCustomer)) {
      resetForm();
      setFieldValue('phone', profile.phone);
    }
    if (profile && profile.firstname !== 'MobileApp' && !isProfileNewCustomer) {
      resetForm();
      setFieldValue('firstname', profile.firstname);
      setFieldValue('lastname', profile.lastname);
      setFieldValue('email', profile.email);
      setFieldValue('dob', profile.dob);
      setFieldValue('phone', profile.phone);
    }
  }, [profile]);

  return (
    <form onSubmit={handleSubmit} className={className}>
      <InputField
        className="mb-2"
        label="First name"
        type="text"
        name="firstname"
        value={values.firstname}
        error={touched.firstname && errors.firstname}
        onChange={handleChange}
        disabled={disabled}
        required
      />
      <InputField
        className="mb-2"
        label="Last name"
        type="text"
        name="lastname"
        value={values.lastname}
        error={touched.lastname && errors.lastname}
        onChange={handleChange}
        disabled={disabled}
        required
      />
      <InputField
        className="mb-2"
        label="Email address"
        type="email"
        name="email"
        value={values.email}
        error={touched.email && errors.email}
        onChange={handleChange}
        disabled={disabled}
        required
      />
      <InputField
        className="mb-2"
        label="Phone number"
        type="tel"
        autoComplete="tel"
        name="phone"
        value={formatPhoneNumberIntl(values.phone)}
        disabled
        required
      />
      <InputField
        className="mb-2"
        label="Date of Birth"
        type="date"
        name="dob"
        value={values.dob}
        error={touched.dob && errors.dob}
        onChange={handleChange}
        disabled={disabled}
        required
      />
      {children}
    </form>
  );
};

ProfileForm.defaultProps = {
  children: undefined,
  className: undefined,
  disabled: false,
};

export { ProfileForm };
