/* eslint-disable */
import cx from 'classnames';

const CheckeredFlag = ({ className, ...props }: any) => {
  return (
    <svg className={cx(className)} width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M1 21.1251V2.75014C8 -3.31252 13.25 8.81279 20.25 2.75014V15.8751C13.25 21.9378 8 9.81248 1 15.8751M7.46979 9.58576L9.38385 11.4998L13.7589 7.12482" stroke="current" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export { CheckeredFlag };
