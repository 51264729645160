import { createSlice } from 'helpers/store';
import { formatCar } from 'redux/helpers';
import {
  IProfile,
  ICar,
  ILocation,
  IReservation,
  IOwner,
  APIStatus,
  IPartner,
} from 'types';

export interface UpcomingReservations {
  data: IReservation[];
  page: number;
  per_page: number;
  total_pages: number;
  status: APIStatus;
}

export interface ReservationsToReview {
  data: IReservation[];
  status: APIStatus;
}

export interface Reservations {
  data: IReservation[];
  page: number;
  per_page: number;
  total_pages: number;
  status: APIStatus;
}

export interface Reservation {
  data: IReservation;
  status: APIStatus;
}

export interface CalendarReservations {
  data: IReservation[];
  status: APIStatus;
}

export interface Cars {
  data: ICar[];
  page?: number;
  per_page?: number;
  total_pages?: number;
  status: APIStatus;
}

interface IState {
  owner: IOwner | null | undefined;
  ownerUpdateLoading: boolean;
  ownerUpdateSuccess: boolean;
  ownerUpdateError: string | null;

  loginLoading: boolean;
  loginSuccess: boolean;
  loginError: string | null;

  passwordResetSuccessful: boolean;
  passwordResetError: string | null;

  cars: Cars;
  locations: ILocation[];
  cleaners: IProfile[];
  calenderReservations: CalendarReservations;
  createReservationBlockStatus: APIStatus;
  upcomingReservations: UpcomingReservations;
  reservationsToReview: ReservationsToReview;
  reservations: Reservations;
  reservation: Reservation;
  partner: IPartner;
}

const initialState: IState = {
  owner: null,
  ownerUpdateLoading: false,
  ownerUpdateSuccess: false,
  ownerUpdateError: null,

  loginLoading: false,
  loginSuccess: false,
  loginError: null,

  passwordResetSuccessful: false,
  passwordResetError: null,

  cars: {
    data: [],
    page: 1,
    per_page: 10,
    total_pages: 1,
    status: {
      loading: false,
      success: false,
      error: null,
    },
  },
  locations: [],
  cleaners: [],
  calenderReservations: {
    data: [],
    status: {
      loading: false,
      success: false,
      error: null,
    },
  },
  createReservationBlockStatus: {
    loading: false,
    success: false,
    error: null,
  },
  upcomingReservations: {
    data: [],
    page: 1,
    per_page: 10,
    total_pages: 1,
    status: {
      loading: false,
      success: false,
      error: null,
    },
  },
  reservationsToReview: {
    data: [],
    status: {
      loading: false,
      success: false,
      error: null,
    },
  },
  reservations: {
    data: [],
    page: 1,
    per_page: 10,
    total_pages: 1,
    status: {
      loading: false,
      success: false,
      error: null,
    },
  },
  reservation: {
    data: {} as IReservation,
    status: {
      loading: false,
      success: false,
      error: null,
    },
  },
  partner: {} as IPartner,
};

const { actions, selectors, reducer } = createSlice({
  prefix: 'owner',
  initialState,

  selectors: {
    selectOwner: (state: IState) => state.owner,
    selectOwnerUpdateLoading: (state: IState) => state.ownerUpdateLoading,
    selectOwnerUpdateSuccess: (state: IState) => state.ownerUpdateSuccess,
    selectOwnerUpdateError: (state: IState) => state.ownerUpdateError,

    selectLoginLoading: (state: IState) => state.loginLoading,
    selectLoginSuccess: (state: IState) => state.loginSuccess,
    selectLoginError: (state: IState) => state.loginError,
    selectPasswordResetSuccessful: (state: IState) => state.passwordResetSuccessful,
    selectPasswordResetError: (state: IState) => state.passwordResetError,

    selectCars: (state: IState) => state.cars,
    selectLocations: (state: IState) => state.locations,
    // @ts-ignore
    selectCalendarLocations: (state: IState) => {
      return state.locations.filter((v, i, a) => a.findIndex(t => (t.city === v.city)) === i);
    },
    selectCleaners: (state: IState) => state.cleaners,
    selectCalendarReservations: (state: IState) => state.calenderReservations,
    selectCreateReservationBlockStatus: (state: IState) => state.createReservationBlockStatus,
    selectUpcomingReservations: (state: IState) => state.upcomingReservations,
    selectReservationsToReview: (state: IState) => state.reservationsToReview,
    selectReservations: (state: IState) => state.reservations,
    selectReservation: (state: IState) => state.reservation,
    selectPartner: (state: IState) => state.partner,
  },

  reducers: {
    getAllCars: {
      base: (state: IState) => {
        state.cars = {
          ...state.cars,
          status: {
            loading: true,
            success: false,
            error: null,
          },
        };
      },
      success: (state: IState, { payload }: any) => {
        const cars = payload.data.map((car: ICar) => {
          return formatCar(car);
        });

        state.cars = {
          data: cars,
          status: {
            loading: false,
            success: true,
            error: null,
          },
        };
      },
      failed: (state: IState, { payload }: any) => {
        state.cars = {
          ...state.cars,
          status: {
            loading: false,
            success: false,
            error: payload,
          },
        };
      },
    },
    getCars: {
      base: (state: IState, { payload: { page } }: any) => {
        state.cars = {
          ...state.cars,
          page,
          status: {
            loading: true,
            success: false,
            error: null,
          },
        };
      },
      success: (state: IState, { payload }: any) => {
        const cars = payload.data.map((car: ICar) => {
          return formatCar(car);
        });

        state.cars = {
          data: payload.current_page === 1 ? cars : [...state.cars.data, ...cars],
          page: payload.current_page,
          per_page: payload.per_page,
          total_pages: payload.total_pages,
          status: {
            loading: false,
            success: true,
            error: null,
          },
        };
      },
      failed: (state: IState, { payload }: any) => {
        state.cars = {
          ...state.cars,
          status: {
            loading: false,
            success: false,
            error: payload,
          },
        };
      },
    },
    setCars: {
      base: (state: IState, { payload }: any) => {
        state.cars = {
          ...payload,
        };
      },
    },
    getOwner: {
      base: () => {
        // state.profileLoading = true;
      },
      success: (state: IState, { payload }: any) => {
        state.owner = payload;
        // state.profileLoading = false;
      },
      failed: () => {
        // state.profileLoading = false;
      }
    },
    updateOwner: {
      base: (state: IState) => {
        state.ownerUpdateError = null;
        state.ownerUpdateLoading = true;
        state.ownerUpdateSuccess = false;
      },
      success: (state: IState, { payload }: any) => {
        state.owner = payload;
        state.ownerUpdateError = null;
        state.ownerUpdateLoading = false;
        state.ownerUpdateSuccess = true;
      },
      failed: (state: IState, { payload }: any) => {
        state.ownerUpdateError = payload;
        state.ownerUpdateLoading = false;
        state.ownerUpdateSuccess = false;
      },
    },
    clearOwnerUpdate: {
      base: (state: IState) => {
        state.ownerUpdateError = null;
        state.ownerUpdateLoading = false;
        state.ownerUpdateSuccess = false;
      },
    },
    clearOwner: {
      base: (state: IState) => {
        state.owner = null;
      },
    },
    login: {
      base: (state: IState) => {
        state.loginError = null;
        state.loginLoading = true;
        state.loginSuccess = false;
      },
      success: (state: IState, { payload: { owner } }: any) => {
        state.owner = owner;
        state.loginLoading = false;
        state.loginSuccess = true;
        state.loginError = null;
      },
      failed: (state: IState, { payload }: any) => {
        state.loginError = payload;
        state.loginLoading = false;
        state.loginSuccess = false;
      },
    },
    resetPasswordConfirm: {
      success: (state: IState) => {
        state.passwordResetSuccessful = true;
        state.passwordResetError = null;
      },
      failed: (state: IState, { payload }: any) => {
        state.passwordResetError = payload.response.data.error;
        state.passwordResetSuccessful = false;
      },
    },
    refreshCarState: {
      success: (state: IState, { payload: { carState, carId } }: any) => {
        state.cars = {
          ...state.cars,
          data: state.cars.data.map((car: ICar) => {
            if (car.id === carId) {
              car.car_state = carState;
            }

            return car;
          }),
        };
      },
    },
    getLocations: {
      success: (state: IState, { payload }: any) => {
        state.locations = payload;
      },
    },
    addLocation: {
      success: (state: IState, { payload }: any) => {
        state.locations = [payload, ...state.locations];
      },
    },
    getCleaners: {
      success: (state: IState, { payload }: any) => {
        state.cleaners = payload;
      },
    },
    createCleaner: {
      success: (state: IState, { payload }: any) => {
        state.cleaners = [payload, ...state.cleaners];
      },
    },
    updateCar: {
      success: (state: IState, { payload }: any) => {
        state.cars = {
          ...state.cars,
          data: [payload, ...state.cars.data.filter((c: ICar) => c.id !== payload.id)]
        };
      },
    },
    getCalendarReservations: {
      base: (state: IState) => {
        state.calenderReservations = {
          ...state.calenderReservations,
          status: {
            loading: true,
            success: false,
            error: null,
          },
        };
      },
      success: (state: IState, { payload }: any) => {
        state.calenderReservations = {
          data: payload,
          status: {
            loading: false,
            success: true,
            error: null,
          },
        };
      },
      failed: (state: IState, { payload }: any) => {
        state.calenderReservations = {
          ...state.calenderReservations,
          status: {
            loading: false,
            success: false,
            error: payload,
          },
        };
      },
    },
    createReservationBlock: {
      base: (state: IState) => {
        state.createReservationBlockStatus.loading = true;
        state.createReservationBlockStatus.success = false;
        state.createReservationBlockStatus.error = null;
      },
      success: (state: IState, { payload }: any) => {
        state.calenderReservations = {
          ...state.calenderReservations,
          data: [payload, ...state.calenderReservations.data],
        };
        state.createReservationBlockStatus.success = true;
      },
      failed: (state: IState, { payload }: any) => {
        state.createReservationBlockStatus.loading = false;
        state.createReservationBlockStatus.error = payload;
      },
    },
    resetCreateReservationBlock: {
      base: (state: IState) => {
        state.createReservationBlockStatus.loading = false;
        state.createReservationBlockStatus.success = false;
        state.createReservationBlockStatus.error = null;
      },
    },
    getUpcomingReservations: {
      base: (state: IState) => {
        state.upcomingReservations = {
          ...state.upcomingReservations,
          status: {
            loading: true,
            success: false,
            error: null,
          },
        };
      },
      success: (state: IState, { payload }: any) => {
        const reservations = payload.data.map((reservation: IReservation) => {
          return {
            ...reservation,
            car: formatCar(reservation.car),
          };
        });

        state.upcomingReservations = {
          data: payload.current_page === 1 ? reservations : [...state.upcomingReservations.data, ...reservations],
          page: payload.current_page,
          per_page: payload.per_page,
          total_pages: payload.total_pages,
          status: {
            loading: false,
            success: true,
            error: null,
          },
        };
      },
      failed: (state: IState, { payload }: any) => {
        state.upcomingReservations = {
          ...state.upcomingReservations,
          status: {
            loading: false,
            success: false,
            error: payload,
          },
        };
      },
    },
    setUpcomingReservations: {
      base: (state: IState, { payload }: any) => {
        state.upcomingReservations = {
          ...payload,
        };
      },
    },
    getReservationsToReview: {
      base: (state: IState) => {
        state.reservationsToReview = {
          ...state.reservationsToReview,
          status: {
            loading: true,
            success: false,
            error: null,
          },
        };
      },
      success: (state: IState, { payload }: any) => {
        state.reservationsToReview = {
          data: payload,
          status: {
            loading: false,
            success: true,
            error: null,
          },
        };
      },
      failed: (state: IState, { payload }: any) => {
        state.reservationsToReview = {
          ...state.reservationsToReview,
          status: {
            loading: false,
            success: false,
            error: payload,
          },
        };
      },
    },
    getReservations: {
      base: (state: IState) => {
        state.reservations = {
          ...state.reservations,
          status: {
            loading: true,
            success: false,
            error: null,
          },
        };
      },
      success: (state: IState, { payload }: any) => {
        state.reservations = {
          data: payload.current_page === 1 ? payload.data : [...state.reservations.data, ...payload.data],
          page: payload.current_page,
          per_page: payload.per_page,
          total_pages: payload.total_pages,
          status: {
            loading: false,
            success: true,
            error: null,
          },
        };
      },
    },
    updateReservation: {
      success: (state: IState, { payload }: any) => {
        state.reservations = {
          ...state.reservations,
          data: [payload, ...state.reservations.data.filter((r: IReservation) => r.id !== payload.id)]
        };
      },
    },
    updatePartner: {
      success: (state: IState, { payload }: any) => {
        state.partner = payload;
      },
    },
    logout: {
      success: (state: IState) => {
        Object.keys(initialState).forEach((key: string) => {
          // @ts-ignore
          state[key] = initialState[key];
        });
      }
    },
    // loginStart: {
    //   success: (state: IState, { payload }: any) => {
    //     state.isNewCustomer = Boolean(payload.new_customer);
    //   },
    // },
    // resetLoginFailed: {
    //   base: (state: IState) => {
    //     state.isLoginFailed = false;
    //   },
    // },
    // loginComplete: {
    //   base: (state: IState) => {
    //     state.isLoginFailed = false;
    //   },
    //   success: (state: IState, { payload: { customer } }: any) => {
    //     state.profile = customer;
    //     state.isLoginFailed = false;
    //   },
    //   failed: (state: IState) => {
    //     state.isLoginFailed = true;
    //   },
    // },
  },
});

export { reducer as owner, actions as ownerActions, selectors as ownerSelectors };
